import checkUserRights from "./checkUserRights";

export const checkAreaAccess = async (areaId) => {
  let write = true;
  let access = false;
  const result = await checkUserRights();

  const type = result.type

  if (result && result.type && (result.type === 'hi' || result.type === 'monster')) return { access: true, write: true, type: result.type };
  if (result && result.type && result.type === 'see') write = false;

  if (result && result.rights && result.rights.length ){
    const CollCheck = result.rights.find((e) => e.collid.toString() === areaId );


    console.warn('+++++++++++++++++++++++++CollCheck', CollCheck);
    
    if (CollCheck){ access = true }
  }


  return { access, write, type };
};
