import React from 'react'
import gql from 'graphql-tag';

// import 'moment/locale/ru'



export const CutTime = (time ) => {
  if (!time) return null;
  const date = new Date(time) || null;

  if (!date) return null;

  return date.toDateString() || null;
};

export const CutTimeObj = (time) => {
  let dateObj = {
    date: null,
    full: null,
    unix: null,
    y: null,
    m: null,
    d: null,
    hh: null,
    mm: null,
  };

  if (!time) return dateObj;
  
  let Time = Number(time);

  if(typeof time === 'number'){
    Time = time;

    console.warn(time, Number(time));

  }else if(time.match(/^\d+$/gi) === null){
    Time = Number(Time);

    console.warn('ELSE', time, Time, Number(Time));

  }else{
    Time = time;
  }

  
  let _date = Time && new Date(Time) || null;

  if (!_date || !_date.toDateString()) return dateObj;

  const HH = (_date.getUTCHours() + 3).toString();
  const MM = _date.getUTCMinutes().toString();
  const D = _date.getUTCDate().toString();
  const M = (_date.getUTCMonth() + 1).toString();


  const date = _date.toDateString() || null;
  const _unix = Date.parse(`${_date.getUTCFullYear()}-${_date.getUTCMonth() + 1}-${_date.getUTCDate()}`) - 1000 || null;
  const y = _date.getUTCFullYear() || null;
  const m =   (M && M.length === 1 ? '0' + M : M) || null;
  const d =   (D && D.length === 1 ? '0' + D : D) || null;
  const hh =  (HH && HH.length === 1 ? '0' + HH : HH)  || null;
  const mm =  (MM && MM.length === 1 ? '0' + MM : MM) || null;
  const full = `${y}-${m}-${d}` || null;


  dateObj = {
    date,
    full,
    unix: _unix,
    y,
    m,
    d,
    hh,
    mm,
  };

  return dateObj;
};
