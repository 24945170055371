import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Type } from 'react-bootstrap-table2-editor';
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_QA } from '../GQL/SERVER/QUERIES/ServerQuerys';
import { CutTime, ToHashColor, GETWEEKNEW, HoverCell, sortObjects } from '../CONSTANTS/Transformers';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import Comment from '../COMPONENTS/TABLEBOOTSTRAP/Comment';
import EditCapacityComment from '../COMPONENTS/TABLEBOOTSTRAP/EditCapacityComment';
import CreateWorkType from '../COMPONENTS/TABLEBOOTSTRAP/CreateWorkType';
import Loader from '../../Loader';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';
import { Modal } from 'react-bootstrap';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

let brigadeFilterFunc, objectFilterFunc, constructionFilterFunc, worktypeFilterFunc;

const changes = {
  coefficient: 'день',
  value: 'объем',
  factor: 'людей',
}

export default class Area extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaData: {},
      modal: false,
      historymodal: false,
      historyid: null,
      emptyStrings: false, //скрывает пустые строки
      week: null,
      editComment: '',
      curDay: moment().format("YYYY-MM-DD"),
      dayCount: 4,
      canEdit: false, 
      brigadeFilter: '',
      objectFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      currentFilter: '', // текущий вид фильтра
      colums: [],
      // headers: [],
      daysCheck: [], // список проверенных периодов
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, write, type }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else this.setState({canEdit: write, rights: type}, ()=>this.getData())
  }

  changeWeekCount = (number) => {
    this.setState({dayCount: number}, () => { this.getData() });
  }

  showModal = () => {
    this.setState({modal: true})
  }

  hideModal = () => {
    this.setState({modal: false})
    this.getData()
  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.getData()})
  }

  resetFilter = () => {
    brigadeFilterFunc();
    objectFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();

    this.setState({worktypeFilter: '',constructionFilter: '', objectFilter: '', brigadeFilter: '', currentFilter: ''})
  };

  validate = (newValue, fulldate, column) => {
    if (fulldate > Date.parse(new Date(Date.now()))) {
      return {
        valid: false,
        message: 'данные нельзя заполнять на будущие дни'
      };
    }

    if (isNaN(newValue) || !newValue || newValue === null || newValue === undefined)
    {
      return {
        valid: true,
        message: null
      };
    }
    if (isNaN(newValue)) {
      return {
        valid: false,
        message: 'данные не являются числом!'
      };
    }
    if (newValue < 0) {
      return {
        valid: false,
        message: 'данные не могут быть отрицательными!'
      };
    }

    if (column && column.cellType && column.cellType === "factor" && parseInt(newValue) !== ToFloat(newValue))
    {
      return {
        valid: false,
        message: 'данные не являются целым числом!'
      };
    }

    return true;
  }


  emptyData =() => {}

  getData = () => {
    const { match } = this.props;
    const { curDay, dayCount } = this.state;
    const id = match.params.id;
    // const week = GETWEEK({date: curDay, last: dayCount});
    const week = GETWEEKNEW({date: curDay, last: dayCount});

    this.setState({ week, loaded: 0 })

    const start = moment(curDay).subtract(dayCount, 'days').weekday(-6).format("x") // проверка закрытых недель начальником

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_QA({id: id, day: curDay, dayNumber: dayCount, start })).then((data)=>{
      if(data && data.area) {
        this.setState({
          areaData: data.area,
          daysCheck: data.dayscheck ? data.dayscheck : [],
          loaded: 1
        },()=>{
          this.getupdate(data.area)
        })
      } else this.setState({loaded: 2})

    })
  }

  Historymodal = (id) => {
    this.setState({
      historymodal: !this.state.historymodal,
      historyid: id ? id : null, 
    })
  }

  getHeaders = () => {
    const { week, areaData, objectFilter, brigadeFilter, constructionFilter , worktypeFilter, canEdit, rights, currentFilter, daysCheck } = this.state;
    let filterData = []
    let brigades = {}, objects = {}, constructions = {}, worktype = {}

    if(!areaData) return null;

    areaData.workTypes && areaData.workTypes.length && areaData.workTypes.forEach((e) => {
      filterData = [...filterData, { brigade: e.brigade, object: e.object, construction: e.construction, worktype: `${e.value}, ${e.unit}`}]
    });

    //для фильтров кроме текущего 
    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) && (brigadeFilter ? v.brigade === brigadeFilter : true)
      && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter !== "object") objects[e.object] = e.object
        if (currentFilter !== "brigade") brigades[e.brigade] = e.brigade
        if (currentFilter !== "construction") constructions[e.construction] = e.construction
        if (currentFilter !== "worktype") worktype[e.worktype] = e.worktype
      });

    //для текущего выбранного фильтра
    filterData.filter((v) => ((objectFilter && currentFilter !== "object") ? v.object === objectFilter : true) && ((brigadeFilter && currentFilter !== "brigade") ? v.brigade === brigadeFilter : true)
      && ((constructionFilter && currentFilter !== "construction") ? v.construction === constructionFilter : true) && ((worktypeFilter && currentFilter !== "worktype") ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter === "object") objects[e.object] = e.object
        if (currentFilter === "brigade") brigades[e.brigade] = e.brigade
        if (currentFilter === "construction") constructions[e.construction] = e.construction
        if (currentFilter === "worktype") worktype[e.worktype] = e.worktype
      });

    objects = sortObjects(objects)
    brigades = sortObjects(brigades)
    constructions = sortObjects(constructions)
    worktype = sortObjects(worktype)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }

    if(!week || !week.length) return null;
    
    const headers = [
      { formatter: HoverCell, dataField: 'id', text: "id", editable: false , hidden: true },
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal, currentFilter: "object"})})/* , footer: "ИТОГО" */},
      { formatter: HoverCell, dataField: 'brigade', text: "Бригада", editable: false, sort: true, headerStyle: { width: '110px' },  
        filter: selectFilter({options: brigades, style:selectStyle, placeholder: 'все', getFilter: (filter) => {brigadeFilterFunc = filter}, onFilter: filterVal => this.setState({brigadeFilter: filterVal, currentFilter: "brigade"})}),
        // footer: "по"
      },
      { formatter: HoverCell, dataField: 'construction', text: 'Сооружение', editable: false, sort: true, headerStyle: { width: '120px' }, 
        filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все',
          getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal, currentFilter: "construction"})}),
        // footer: "странице"
      },
      { formatter: HoverCell, dataField: 'worktype', text: 'Тип работ', editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: worktype, style:selectStyle, placeholder: 'все',
        getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal, currentFilter: "worktype"})}),
      // footer: ""
      },
      ...week.map((e, i) => {
        const color = ToHashColor(e.date+i);
        const permissionDay = !(e.ufull > Date.now()) && ((canEdit && (daysCheck.findIndex(date => ((date.start <= e.ufull) && (date.end >= e.ufull))) === -1 ? true : false)) || (rights === "hi" || rights === "monster"));
        
        return([
          { 
            dataField: 'dayF' + i + 1, 
            dateU: e.date, 
            cellType: "factor", 
            text: "F, рабочих", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            validator: (newValue, _, column) => this.validate(newValue, e.ufull, column),
            formatter: (cell) => {
              if (ToFloat(cell) === 0 ) return ""

              return cell
            },
            editable: () => permissionDay,
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconF',text:"Сколько человек были заняты"}),
            // footer: columnData => columnData.reduce((acc, item) => {
            //   if (!item || isNaN(item) || item === undefined) return acc

            //   return acc + ToFloat(item)
            // }, 0).toFixed(2),
          }, 
          { 
            dataField: 'dayC' + i + 1, 
            dateU: e.date, 
            cellType: "coefficient", 
            text: "K, режим", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  }, 
            editorStyle: {fontSize: '12px'}, 
            editor: {
              type: Type.SELECT,
              options: [
                {
                  value: '0',
                  label: '0'
                }, {
                  value: '1',
                  label: '1'
                }, {
                  value: '0.5',
                  label: '1/2'
                }
              ]
            },
            formatter: (cell) => {
              if (ToFloat(cell) === 0 ) return ""

              return cell
            },
            editable: () => permissionDay,
            // footer: "",
            validator: (newValue) => this.validate(newValue, e.ufull),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconK',text:"Работали весь день или половину"}),
          }, 
          { 
            dataField: 'day' + i + 1, 
            dateU: e.date, 
            cellType: "value", 
            text: "фактор", 
            headerStyle: { fontSize: '13px', background: color, padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            formatter: (cell, row, rowIndex, formatExtraData)=>Comment(cell, row, rowIndex, formatExtraData, row["dayId" + i + 1] ? (() => this.Historymodal(row["dayId" + i + 1])) : null ),
            formatExtraData: {
              comment: "comment" + i + 1,
            },
            editorRenderer: (editorProps, value, row, column) => (
              <EditCapacityComment { ...editorProps} id={row.id} date={column.dateU} value={ value } update={this.getData} comment = {row["comment" + i + 1]} dayId = {row["dayId" + i + 1]} />
            ),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconV',text:"Объем выполненных работ"}),
            validator: (newValue) => this.validate(newValue, e.ufull),
            editable: () => permissionDay,
            // footer: columnData => columnData.reduce((acc, item) => {
            //   if (!item || isNaN(item) || item === undefined) return acc

            //   return acc + ToFloat(item)
            // }, 0).toFixed(2),
          } 
        ])}
      ).flat(),

    ]

    return headers
  }

  workTypes = (data) => {
    const { week, emptyStrings } = this.state;

    if (!data) return null;
    let DATA = data.workTypes || [];
    let colums = []

    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= moment(week[0].date) && moment(b.day)<= moment(week[week.length-1].date))) : e.days
      
      if (emptyStrings && days.length <1) return

      e.cols = { id: e.id, brigade: e.brigade, construction: e.construction, object: e.object, completed: e.completed, deleted: e.deleted, worktype: `${e.value}, ${e.unit}` }


      week.forEach((element, index) => {
        e.cols['day' + index + 1] = "";
        e.cols['dayF' + index + 1] = "";
        e.cols['dayC' + index + 1] = "";
        
        days.find(ins=>{
          if(!ins || !ins.day || !element.date) return false;

          let d = CutTime(ins.day);
          let d2 = CutTime(element.date);

          if(d === d2){
            e.cols['day' + index + 1] = ins.value || "";
            e.cols['comment' + index + 1] = (ins.comment && ins.comment) || ""
            e.cols['dayId' + index + 1] = ins.id || ""; 

            e.cols['dayF' + index + 1] = ins.factor || "";
            e.cols['dayC' + index + 1] = ins.coefficient || "";
            

            return true;
          }
          
          return false
        });
      });
      colums.push(e.cols)

      return e;
    })
    // .filter(e=> e.dayC01);

    return colums;
  }

  getupdate = (data) => {
    const {emptyStrings, areaData} = this.state;
    const Data = data && data || areaData;

    if(!Data) return null;
    const newData = {name: Data.name, description: Data.description, id: Data.id, workTypes: emptyStrings ? ((Data.workTypes.length && [...Data.workTypes].filter(e => e.days.length)) || []) : Data.workTypes }
    const colums = this.workTypes(newData);
    // const headers = this.getHeaders();

    if(colums && (!this.state.colums || !this.state.colums.length || this.state.colums !== colums)) this.setState({colums});
    // if(headers && (!this.state.headers || !this.state.colums.headers || this.state.headers !== headers)){
    //   this.setState({
    //     headers,
    //   });
    // }
  }

  render() {
    const { match } = this.props;
    const { areaData, modal, canEdit, dayCount, loaded, colums, emptyStrings, historymodal, historyid, curDay } = this.state;
    const id = match.params.id;

    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
    
    const headers = this.getHeaders();

    return (
      <div className={'Area'} style={{width: '100%',margin:'0'}}>
        <div style={{width: '100%', height: 'auto', margin:'0 auto', position: 'fixed', top: '0', right:'auto', left:'auto', display:'flex', flexDirection:'row', justifyContent:'center',
          alignContent:'center', alignItems:'center', zIndex: '9999',}}>
          {loaded === 0 && <Loader />}
        </div>
        {(<div className="" style={{maxWidth:"700px",margin: '5px'}}>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="btnGroupAddon"><b>{areaData.name}</b></div>
              <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
            </div>
            <input type="date"
              className="form-control"
              style={{width: '140px'}}
              aria-label="Input group example" aria-describedby="btnGroupAddon"
              name="trip-start"
              min="2018-01-01"
              value={curDay}
              max={(new Date().toISOString()).slice(0,10)}
              onChange={e=>this.changeDay(e)}
            />
            <button className={`btn btn-sm ${dayCount === 3 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(3)}>3</button>
            <button className={`btn btn-sm ${dayCount === 4 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(4)}>4</button>
            <button className={`btn btn-sm ${dayCount === 5 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(5)}>5</button>
            <button className={`btn btn-sm ${dayCount === 6 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(6)}>6</button>
            <button className={`btn btn-sm ${dayCount === 7 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(7)}>7</button>
            <button className={`btn btn-sm btn-secondary`} onClick={()=>this.setState({emptyStrings: !emptyStrings}, ()=>{this.getupdate()})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
          </div>
        </div>) || null}
        {(headers && headers.length && <Table headers={headers} cols={colums} update={this.emptyData} resetFilter={this.resetFilter} addString={this.showModal} tabletype={canEdit ? "AllTable n35" : "AllTable n34"} simple={!canEdit}/> )|| null}
        <CreateWorkType id={id} modal={modal} deletedCols={colums.filter(col => (col.deleted === true || col.completed === true) )} hideModal={this.hideModal}/>
      
        <Modal
          // size="sm"
          style={{color: "black"}}
          show={historymodal}
          onHide={this.Historymodal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Последние изменения
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {historymodal && historyid && <Query query={gql`query H($id: String!){
                  GetDayHistory(id: $id){
                    name
                    value
                    date
                    user {
                      name
                    }
                  }
                }`} variables={{id: historyid}}>{
                ({loading, data})=>{

                  if(loading) return 'loading'
                  if(data && data.GetDayHistory) {

                    return data.GetDayHistory.length && data.GetDayHistory.map((e,i)=>{

                      if(e && e.name) {
                        return <div className="ListTile" key={'dayhistory-'+i}>{ e.date && new Date(Number(e.date)).toLocaleString() } - Пользователь {e.user && e.user.name} изменил <i>{changes[e.name]}</i> на {e.value}</div>
                      }

                      return null;
                    }) || null
                  }

                  return 'none'
                }
              }
            </Query>}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
