import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router'
import '../../CSS/ROOTLEVEL/NAV/nav.css'
import Top from './NAVBARPARTS/NavBarTop';
import Mid from './NAVBARPARTS/NavBarMid';
import Footer from './NAVBARPARTS/NavBarFooter';
import { Place } from '../../CONSTANTS/operations';

class NavBar extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      full: false,
      toRoot: false,
      place: null,
    }
  }

  componentDidMount(){
    const Path = this.props.location && this.props.location.pathname && this.props.location.pathname.split('/').filter(e=>e);

    
    this.setState({
      place: Path[0],
    }, ()=>{
      Place({place: Path[0]})
    })

    // if(true){
    //   this.setState({toRoot: true,})
    // }else{
    //   this.setState({toRoot: true,})
    // }
  }
  // componentDidUpdate(){
  //   const Path = this.props.location && this.props.location.pathname && this.props.location.pathname.split('/').filter(e=>e);

  //   if(Path !== this.state.place){
  //     this.setState({
  //       place: Path[0],
  //     }, ()=>{
  //       Place({place: Path[0]})
  //     })
  //   }
    
  //   // if(true){
  //   //   this.setState({toRoot: true,})
  //   // }else{
  //   //   this.setState({toRoot: true,})
  //   // }
  // }

  render() {
    const { full, toRoot } = this.state;

    if(toRoot){
      return <Redirect to={`/`} />
    }

    return (
      <div className={'NavBar'} >
        {/* <div className={'NavBar' + ((full && " full" )|| " notfull")} onMouseOver={()=>this.setState({full: true})} onMouseLeave={()=>this.setState({full: false})}> */}
        <Top full={full} {...this.props}/>
        <Mid full={full} {...this.props}/>
        <Footer full={full} {...this.props}/>
      </div>
    )
  }
}

export default withRouter(NavBar)
