import React, { Component, useState } from 'react'
import Loader from '../../../Loader';
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS';
import SelectSearch from 'react-select-search';

export class Lists extends Component {
  render() {
    const {array, arrayName, name, arrayProp, selected} = this.props;

    return (
      <div className="Lists">
        <div className="header">
          <h3>{arrayName || name}</h3>
          <div>
            <input type="search" onChange={this.filter} placeholder={"Поиск..."}/>
          </div>
        </div>
        <div className="inner">
          {(array && array.length &&
                array.sort((a,b)=>{
                  if (a.name < b.name ) {
                    return -1;
                  }
                  if (a.name > b.name ) {
                    return 1;
                  }
            
                  return 0;
                }).sort((a,b)=>{
                  if (a.parents < b.parents ) {
                    return -1;
                  }
                  if (a.parents > b.parents ) {
                    return 1;
                  }
            
                  return 0;
                }).map((e,i)=>{
                  const Parents = (e.parents && typeof e.parents === 'object' && e.parents.length && e.parents) || [e.parentName];

                  return(
                    <div className={"ListTile"+((selected && selected.id === e.id && " a") || "")} key={'hanbooklist-'+i} onClick={()=>this.selectList(e)}>
                      <span className="Name">{e[arrayProp] || e.name || e.value || "нет названия"}</span>
                      {/* {e.parentName && <span className="ParentName">{e.parentName}</span>} */}
                      {Parents && Parents.length && <span className="ParentName">{Parents.join(' > ')}</span>}
                      {e.param && <span className="ParentName">{ e.param }</span>}
                    </div>
                  )
                })) || <Loader />
          }
        </div>
      </div>
    )
  }
}
export class FetchedSelect extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      error: null,
      position: null,
      data: null,
      Val: '',
      array: [],
      selected: null,
      prevState: {},
      fetchname: null,
      fetchHelp: null,
      clear:false,
      selectedNames: [],
      selectedName: '',
      preventFetch: false,
    }
  }


  static propTypes = {
    change: PropTypes.func,
    fetch: PropTypes.array,
    prop: PropTypes.string,
  }

  fetchData = ({query, propQuery, selectedParam, graphname, position, name, help}) => {
    /**
     * query - функция принимающая параметры или строка
     * propQuery - название параметра который будет передан в query, не применяется для query без параметров
     * selectedParam - строка которая будет передана вместе с параметром в query, является ключом объекта предыдущего query, не применяется для query без параметров
     * graphname - ключ объекта предыдущего query, иначе первый
     * position - автоматический параметр, номер текущего query
     * name - название текущего query
     */
    // const {fetchname} = this.state;

    if(name){
      // let NewNames = fetchname && fetchname.length && [...fetchname, name] || [name];

      this.setState(
        {
          // fetchname: [...NewNames],
          fetchname: name,
          fetchHelp: help,
        }
      )
    }

    if (!query) return null;
    let Q = query;

    if (propQuery && selectedParam && typeof query === 'function') {
      Q = query({[propQuery]: selectedParam});
    }
    if ((!propQuery || !selectedParam) && typeof query === 'function') {
      Q = query();
    }
    QA(Q).then((data)=>{
      if(data && Object.keys(data) && Object.keys(data)[0] && data[Object.keys(data)[0]]){
        // const DATA = graphname && data[Object.keys(data)[0]][graphname] && data[Object.keys(data)[0]][graphname].length && data[Object.keys(data)[0]][graphname] || data[Object.keys(data)[0]];
        const DATA = (graphname && data && data[Object.keys(data) && Object.keys(data)[0]] && data[Object.keys(data)[0]][graphname] 
          && data[Object.keys(data)[0]][graphname].length && data[Object.keys(data)[0]][graphname]) || data[Object.keys(data)[0]];
        const D =  DATA || [];

        if (!D || !D.length){
          this.setState({preventFetch: true,error: "Не найдено ни одного типа - "+name});

          return null;
        }

        this.setState({
          data: D,
          position: position+1,
          array: D,
          prevFetch: {query, propQuery, selectedParam, graphname, position, name, help},
        })
      }
    })
  }
  nextData = (target, prop, name) => {
    try {
      const {change, fetch} = this.props;
      const {position, selectedNames} = this.state;

      this.setState({
        Val: target.value,
        selected: target,
        selectedNames: [...selectedNames, `${name || ''} ${target.name || target.value || ''}` ],
      })
  
      if(/*fetch.length === position || */fetch[Number(position) - 1 || 0].use){
        this.setState({
          preventFetch: true,
          selectedName: target.name,
        }, ()=>{
          change(target, prop)
        })
        
      } else {
        this.fetchData({
          query: fetch[Number(position)].query || null,
          propQuery: fetch[Number(position)].propQuery || null,
          selectedParam: (target[fetch[Number(position)].selectedParam || 'parent']) || null,
          graphname: fetch[Number(position)].graphname || null,
          position: position,
          name: fetch[Number(position)].name || null,
          help: fetch[Number(position)].help || null,
        })
      }
    } catch (error) {
      return null;
    }
  }

  clear = () => {
    this.setState({
      error: null,
      position: null,
      data: null,
      Val: '',
      array: [],
      selected: null,
      prevState: {},
      fetchname: null,
      fetchHelp: null,
      selectedNames: [],
      selectedName: '',
      preventFetch: false,
    })
    // this.fetchData(this.state.prevFetch)
    const {fetch} = this.props;

    fetch && fetch.length && this.fetchData({
      query: fetch[0].query || null,
      propQuery: fetch[0].propQuery || null,
      selectedParam: fetch[0].selectedParam || null,
      graphname: fetch[0].graphname || null,
      use: fetch[0].use || null,
      position: 0,
      name: fetch[0].name || null,
      help: fetch[0].help || null,
    })
  } 

  componentDidMount(){
    const {fetch} = this.props;

    fetch && fetch.length && this.fetchData({
      query: fetch[0].query || null,
      propQuery: fetch[0].propQuery || null,
      selectedParam: fetch[0].selectedParam || null,
      graphname: fetch[0].graphname || null,
      use: fetch[0].use || null,
      position: 0,
      name: fetch[0].name || null,
      help: fetch[0].help || null,
    })
  }

  render() {
    const {prop} = this.props;
    const {/* data, position, placeholder, clear, */ selected, Val, array, fetchname,selectedName, fetchHelp, selectedNames, preventFetch, error} = this.state;

    return(
      <div className="FetchSelect">
        {error &&
          <div className={'error'}>
            {error}
          </div>
        }

        
        <div onClick={()=>this.clear()}>{selectedName}</div>
        {!preventFetch && <div className="SelectInFetch">
          {selected && <div className="Tags">
            <div className="tag" onClick={()=>this.clear()}>
              {selectedNames && selectedNames.join(' > ')}
            </div>
          </div>}
          <SelectSearch
            name={'select'+prop}
            value={Val}
            options={array}
            placeholder={fetchHelp}
            onChange={(target)=>this.nextData(target, prop, fetchname)}
          />
          
        </div>}
        {(!selectedName && fetchHelp && <p className="small">{fetchHelp}</p>) || null}
      </div>
    );
  }
}


export class Delete extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      agree: false,
      disabled: true,
    }
  }

  click = () => {
    setTimeout(() => {
      this.setState({
        disabled: false,
      });
    }, 1500);
    this.setState({
      agree: true,
    });
  }
  agree = () => {
    const {onClick} = this.props;
    
    this.setState({
      agree: false,
      disabled: true,
    });
    onClick && typeof onClick === 'function' && onClick()
  }
  cancel = () => {
    this.setState({
      agree: false,
      disabled: true,
    });
  }
  
  render() {
    const {onClick, name, agreeText} = this.props;
    const {agree, disabled} = this.state;

    return (
      <div className="Agreetor">
        {!agree && <div className="btn btn-dark" onClick={this.click}>{name}</div>}
        {agree && <div className="Agree">
          <div className="Header">
            {agreeText}
          </div>
          <div className={"btn btn-dark" + (disabled ? " disabled" : "")} onClick={disabled ? null : this.agree}>{name}</div>
          <div className="btn btn-light" onClick={this.cancel}>{'нет'}</div>
        </div>
        }
      </div>
    )
  }
}


const Inp = ({returnValue, prop, defValue}) => {

  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <input type="text" defaultValue={defValue} onChange={(inp)=>{ typeof returnValue === 'function' && returnValue(inp.target.value, prop)}}/>
    </div>
  )
}
const TextArea = ({returnValue, prop, defValue}) => {

  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <textarea type="text" defaultValue={defValue} onChange={(inp)=>{ typeof returnValue === 'function' && returnValue(inp.target.value, prop)}}/>
    </div>
  )
}
const Select = async ({returnValue, prop, defValue, query}) => {

  const Arr = query && await QA(query).then((data)=>{

    return data;
  });
  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <select defaultValue={defValue}>
        {Arr && Arr.length && Arr.map((e,i)=>{

          return(
            <option key={'options-'+i} value={e.value}>{e.name}</option>
          )
        }) || null}
      </select>
    </div>
  )
}

export class RenderEditor extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      admin: false,
      value: "",
      defValue: "",
    }
  }

  componentDidMount(){
    const {Cell, Row} = this.props;

    this.setState({
      value: Cell,
      defValue: Cell,
    })
  }

  componentDidUpdate(){
    const {Cell,} = this.props;



    if (Cell !== this.state.defValue) {
      this.setState({
        value: Cell,
        defValue: Cell,
      })
    }
  }

  toEdit = () => {
    const {value, editCell, admin} = this.state;

    const Q = `{
      me{
        type
      }
    }`;

    if(!admin && this.props.rights && this.props.rights === 'admin'){
      QA(Q).then((q)=>{
        if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
          this.setState({
            editCell: true,
            admin: true,
          })
        }
      });

      return null;
    }
    
    if (admin || !this.props.rights) {

      this.setState({editCell: true,});
      
      return null;
    }

    // this.setState({editCell: true,});
  }

  save = () => {
    const {query, saveParams, prop, id, type} = this.props;
    const {value, editCell} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы

    // let TextArea = null;

    // if (type === "textarea") {
    //   TextArea = value && value.split('\n') || null;
    // }


    const Value = value || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });
    
    if(!Q) return null;
    


    QA(Q).then((data)=>{
      this.setState({
        value: Value,
        editCell: !editCell,
      });
    })
    
  }
  
  render() {
    const {type} = this.props;
    const {editCell, value, admin} = this.state;
    

    if (!editCell) {
      return(<div className="GridCell HoverParent" style={{height: '100%', width: '100%', minHeight: '30px'}}>
        {value}
        <div className="HoverChild" onClick={()=>{ this.toEdit()}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
        </div>
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%', minHeight: '30px'}} >
          <div>{type !== "textarea" && value}{type === "textarea" && value}</div>
          <div className="input-group">
            {type !== "textarea" && <input
              type={type || "text"}
              className="form-control"
              onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value} /> }
            {
              type === "textarea" && <textarea className="form-control" rows="3" onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value}/>
            }

            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}

export class RenderEditSelect extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      admin: false,
      value: "",
      defValue: "",
    }
  }

  componentDidMount(){
    const {Cell, Row} = this.props;

    this.setState({
      value: Cell,
      defValue: Cell,
    })
  }

  componentDidUpdate(){
    const {Cell,} = this.props;



    if (Cell !== this.state.defValue) {
      this.setState({
        value: Cell,
        defValue: Cell,
      })
    }
  }

  toEdit = () => {
    const {value, editCell, admin} = this.state;

    const Q = `{
      me{
        type
      }
    }`;

    if(!admin && this.props.rights && this.props.rights === 'admin'){
      QA(Q).then((q)=>{
        if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
          this.setState({
            editCell: true,
            admin: true,
          })
        }
      });

      return null;
    }
    
    if (admin || !this.props.rights) {

      this.setState({editCell: true,});
      
      return null;
    }
  }

  save = () => {
    const {query, saveParams, prop, id, type} = this.props;
    const {value, editCell} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы

    const Value = value || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });
    
    if(!Q) return null;

    QA(Q).then((data)=>{
      this.setState({
        value: Value,
        editCell: !editCell,
      });
    })
  }
  
  render() {
    const {type, array, Cell} = this.props;
    const {editCell, value, admin} = this.state;

    if (!editCell) {
      return(<div className="GridCell HoverParent" style={{height: '100%', width: '100%', minHeight: '30px'}}>
        {(array.find(f=>f.value === value) && array.find(f=>f.value === value).name) || Cell}
        <div className="HoverChild" onClick={()=>{ this.toEdit()}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
        </div>
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%', minHeight: '30px'}} >
          <div>{(array.find(f=>f.value === value) && array.find(f=>f.value === value).name) || Cell}</div>
          <div className="input-group">
            <RenderAddSelect
              value={value}
              array={array}
              set={(e)=>this.setState({value: e})}
            />


            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}



export function RenderAddSelect({array, className, value, propName, set, }) {

  // [{id, name, value}]
  return (
    <div className={className}>
      {array && array.length && <select className="custom-select" id="inputGroupSelect04" onChange={(e)=>{ set(e.target.value)}} value={value || ''}>
        <option value=""></option>
        {
          array.sort((a,b)=>{
            if(a.name > b.name) return 1;
            if(a.name < b.name) return -1;

            return null;
          }).map((opt, i)=>{

            if(opt){
              return(
                <option value={(propName && opt[propName]) || opt.value || opt.id} key={"option-"+ i}>
                  {opt.name || opt.value || '...'}
                </option>
              )
            }
          })
        }
      </select> || <Loader />}
    </div>
  )
}

export function RenderAddFetchSelect({className, value, propName, set, fetch, fetchName }) {
  const [array, setArray] = useState([]);

  if (!array.length && fetch && fetchName) {
    QA(fetch).then(data => {
      if(data && data[fetchName]) {
        setArray(data[fetchName].sort((a,b)=>{
          if(a.name > b.name) return 1;
          if(a.name < b.name) return -1;

          return null;
        }))
      }
    })
  }
  
  return (
    <div className={className}>
      {array && array.length && <select className="custom-select" id="inputGroupSelect04" onChange={(e)=>{ set(e.target.value)}} value={value || ''}>
        <option value=""></option>
        {
          array.sort((a,b)=>{
            if(a.name > b.name) return 1;
            if(a.name < b.name) return -1;

            return null;
          }).map((opt, i)=>{
  
            if(opt){
              return(
                <option value={(propName && opt[propName]) || opt.value || opt.id} key={"option-"+ i}>
                  {opt.name || opt.value || '...'}
                </option>
              )
            }
          })
        }
      </select> || <Loader />}
    </div>
  )
}

export function RenderAddMultiFetchSelect({className, value, propName, set, fetch }) {
  const [array, setArray] = useState([]);
  const [selected, setSelected] = useState({});


  const [selects, setSelects] = useState([]);
  const [position, setPosition] = useState(0);
  
  const [selectedProp, setSelectedProp] = useState({});
  const [data, setData] = useState({});

  const remove = (ind) => {
    if(ind){
      setSelects(selects.slice(0, ind))
    }
    setSelects([])
  }

  const Load = () => {
    if(!fetch || !fetch.length) return null;

    let F = fetch[position];

    QA().then(data => {
      if (data) {
        if(F.path.length === 2) {
          setArray(data[F.path[0]][F.path[1]])
        }else{
          setArray(data[F.path[0]])
        }
      }
    })

    // if(!selects || !selects.length || !array || !array.length) {
    //   let Q = fetch[0].query || null;

      
    //   QA((typeof Q === 'function' && Q()) || Q || null).then(data => {

    //     console.log('data',data);
        
    //     if(data) {
    //       let Arr = fetch[0].graphname && data[fetch[0].graphname] || [];

    //       setArray(Arr);
    //     }
    //   })
    // } else {

    //   console.log(selects);
    //   console.log(fetch[selects.length]);
      
    //   selects && selects.length && fetch[selects.length] && fetch[selects.length].query &&
    //   QA((typeof fetch[selects.length].query === 'function' && fetch[selects.length].query({[fetch[selects.length].propQuery || 'id']: selects[-1] })) || null).then(data => {
    //     if(data) {

    //       console.log('data 2',data);
    //       let Arr = fetch[selects.length] && data[fetch[selects.length].graphname] || [];

    //       setArray(Arr);
    //     }
    //   })
    // }

  }

  const Next = (e) => {
    const Value = e.target.value;

    console.log(Value);
    
    setSelects([...selects, Value ]);

    if(Value && selects && fetch[selects.length] === fetch.length) {
      console.log('load 0');
      set(Value)
    }else{
      set('')
      console.log('load 1');
      Load()
      
      if(selects && selects.length) {
        console.log('load 2');

      }
    }
    // if (selects && (selects.length !== (fetch.length - 1))) {
    //   const Fetch = fetch[position];

    //   if(Fetch && Fetch.query && selects && selects.length) {
    //     QA((typeof Fetch.query === 'function' && Fetch.query({[Fetch.name || 'id']: selects[-1] })) || null).then(data => {
    //       if(data && data[fetchName]) {
    //         setArray(data[fetchName])
    //       }
    //     })
    //   }
    // }
  }


  // fetch.forEach((element, index) => {

  //   const DataEl = data[element.prop];

  //   if (DataEl && DataEl.array && DataEl.array.length) {
        
  //     return null;
  //   }

  //   if ()

  //   QA(fetch).then(data => {
  //     if(data && data[fetchName]) {
  //       setArray(data[fetchName])
  //     }
  //   })
  // });

  !array.length && !selects.length && Load()

  
  return (
    <div className={className}>
      <div>
        {(selects && selects.length &&
        selects.map((el, ind)=>{

          return(<div className="tag" key={'tags'+ind} onClick={()=>remove(ind)}>{
            el.name || el.value || ind
          }</div>)
        })) || null }
      </div>
      {
        (array && array.length
        && <select className="custom-select" id="inputGroupSelect04" onChange={Next} value={value || ''}>
          <option value=""></option>
          {
            array.map((opt, i)=>{
  
              if(opt){
                return(
                  <option value={(propName && opt[propName]) || opt.value || opt.id} key={"option-"+ i}>
                    {opt.name || opt.value || '...'}
                  </option>
                )
              }
            })
          }
        </select> ) || <Loader />}
    </div>
  )
}







export class RenderEditorSelect extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      value: "",
      defvalue: "",
    }
  }

  componentDidMount(){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    this.setState({
      value: Cell,
      defvalue: Cell,
      array: selectArray && selectArray.length && selectArray,
    })
  }

  componentDidUpdate(){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    if (Cell !== this.state.defvalue) {
      this.setState({
        value: Cell,
        defvalue: Cell,
      })
    }
    if (selectArray && selectArray.length && selectArray !== this.state.array) {
      this.setState({
        array: selectArray && selectArray.length && selectArray,
      })
    }
  }

  selectQuery = () => {
    const {Cell, selectProp, selectQuery, selectArrayName} = this.props;
    const {value, editCell, array} = this.state;

    const Q = selectQuery && typeof selectQuery === 'string' && selectQuery || selectQuery && typeof selectQuery === 'function' && selectQuery();

    if(!Q || array && array.length) return null;

    QA(Q).then(data=>{

      if(data && data[selectArrayName]){
        this.setState({
          array: data[selectArrayName],
        })
      }
    })
  }

  openEditor = () => {
    const {value, editCell, array} = this.state;

    this.setState({editCell: true});
    !array && this.selectQuery()
  }

  save = () => {
    const {query, saveParams, prop, id, Col} = this.props;
    const {value, select, editCell, array} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы
    const Value = select || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });

    QA(Q).then((data)=>{
      this.setState({
        value: value,
        editCell: !editCell,
      });
    })
  }
  
  render() {
    const {editCell, value, array} = this.state;
    const {Cell, Col} = this.props;

    if (!editCell) {
      return(<div className="tableCellButton" style={{height: '100%', width: '100%', minHeight: '30px'}} onClick={this.openEditor}>
        {(array && array.length && (array.find(e => e.id === value) && array.find(e => e.id === value).name || array.find(e => e.id === value) && array.find(e => e.id === value).value || null)) || value}
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%'}} >
          {/* <div>{value}</div> */}
          <div className="input-group">
            {array && array.length && <select className="custom-select" id="inputGroupSelect04" onChange={(e)=>{ this.setState({select: e.target.value, value: (e.target.selectedOptions[0].label) ||"" })}} defaultValue={'0'}>
              <option value="0"></option>
              {
                array.map((opt, i)=>{

                  if(opt && opt.id){
                    return(
                      <option value={opt.id} key={"option-"+ opt.id + (Col && Col.prop) + '-' + i}>
                        {opt.name || opt.value || '...'}
                      </option>
                    )
                  }
                })
              }
            </select>}
            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell, value: Cell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}










export class RenderEditorMultiple extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      value: "",
      defvalue: "",
      NewValues: [],
      loading: false,
    }
  }

  componentDidMount(){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    this.setState({
      value: Cell,
      defvalue: Cell,
      array: selectArray && selectArray.length && selectArray,
    })
  }

  componentDidUpdate(){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    if (Cell !== this.state.defvalue) {
      this.setState({
        value: Cell,
        defvalue: Cell,
      })
    }
    // if (selectArray && selectArray.length && selectArray !== this.state.array) {
    //   this.setState({
    //     array: selectArray && selectArray.length && selectArray,
    //   })
    // }
  }

  selectQuery = () => {
      
    const {Cell, arrays, selectQuery, selectArrayName} = this.props;
    const {value, editCell, array} = this.state;

    arrays && arrays.length && array.foreach((arr)=>{

      if (arr && arr.array && arr.array.length) {
        return null;
      }

      // if ()

    })
  }

  getArrays = () => {
      
    const {Cell, arrays, selectQuery, getArrays, selectArrayName} = this.props;
    const {value, editCell, array} = this.state;

    if (arrays && arrays.length && arrays[0] && arrays[0].array && arrays[0].array.length) {

      this.setState({
        arrays,
        editCell: true,
        loading: false,
      });

      return null;
    } else {
      this.setState({
        loading: true,
      });
      getArrays()

      setTimeout(() => {
        this.getArrays()
      }, 2000);

      return null;
    }
  }

  openEditor = () => {
    const {value, editCell, array} = this.state;

    this.getArrays()
    // this.setState({editCell: true});
    // !array && this.selectQuery()
  }

  save = () => {
    const {query, saveParams, prop, id, Col, Row} = this.props;
    const {value, select, editCell, arrays} = this.state;

    let ClearState = {};
    let QueryParams = {};
    let NewValues = [];
    let ValuesData = arrays && arrays.length && arrays.map((e,i)=>{
      if(!e || !e.prop) return null;

      ClearState["prop"+e.prop] = "";
      NewValues.push(this.state["selectedValue"+e.prop]);

      if(this.state["prop"+e.prop]){
        QueryParams[e.prop] = this.state["prop"+e.prop];
      }

      return(`${[e.prop]}: "${this.state["prop"+e.prop]}"`);
    }).join(', ');
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы
    // const Value = select || "";
    // this.setState({
    //   NewValues: [...this.state.NewValues, NewValues.join(' ')],
    //   ...ClearState
    // })
    
    const Q = id && query && typeof query === 'function' && query({[prop]: id, ...QueryParams });

    QA(Q).then((data)=>{
      this.setState({
        NewValues: [...this.state.NewValues, NewValues.join(' ')],
        ...ClearState
      })
    })
  }


  makeValue = ({element, props}) => {
    return (element && props && props.length && props.map((e)=>{
      if(!e || !element[e]) return null;

      return element[e];
    })) || null;
  }
  
  render() {
    const {editCell, value, arrays, NewValues, loading} = this.state;
    const {Cell, Col, Row, propsRender} = this.props;

    if(loading){
      return(
        <span>Загрузка</span>
      )
    }
    if (!editCell) {
      return(<div className="tableCellButton" style={{height: '100%', width: '100%', minHeight: '30px'}} onClick={this.openEditor}>
        {Cell && typeof Cell === 'string' && Cell}
        {Cell && typeof Cell === 'object' && !Cell.length && 
        (
          <div>
            {
              Object.keys(Cell).map(key => {
                if(propsRender && propsRender.length && propsRender.includes(key)){
                  return Cell[key]+" ";
                }

                return null;
              }) || null
            }

          </div>
        ) || null

        }
        {Cell && typeof Cell === 'object' && Cell.length && Cell.map((cellPart, i) => {
          return(
            <div key={"cellPart-"+i}>
              {
                Object.keys(cellPart).map(key => {
                  if(propsRender && propsRender.length && propsRender.includes(key)){
                    return cellPart[key]+" ";
                  }

                  return null;
                }) || null
              }

            </div>
          )
        }) || null}
        {/* {(propsRender && propsRender.length && propsRender.map((e,i) => <div key={i}>{}</div> ))|| null} */}
        {
          NewValues && NewValues.length && NewValues.map((e,i)=>{

            return(
              <div key={'newvalues'+i}>
                {e}
              </div>
            )
          }) || null
        }
        {/* {(arrays && arrays.length && (arrays.find(e => e.id === value) && arrays.find(e => e.id === value).name || arrays.find(e => e.id === value) && arrays.find(e => e.id === value).value || null)) || value} */}
      </div>)
    }
    if (editCell) {
      return(
        <div style={{height: '100%', width: '100%'}} >
          {
            NewValues && NewValues.length && NewValues.map((e,i)=>{

              return(
                <div key={'newvalues'+i}>
                  {e}
                </div>
              )
            }) || null
          }
          
          {
            arrays && arrays.length && arrays.map((arr, i)=>{
              if(!arr || !arr.prop) return null;
                
              if(arr && arr.prop && (!arr.array && !arr.type)){

                return(
                  <div className="input-group" key={'multiple-selects-'+i} >
                    {arr.header && <div className="input-group-prepend">
                      <span className="input-group-text">{arr.header}</span>
                    </div>}
                    <input type={arr.inputType || "text"} onChange={(e)=>{this.setState({["prop"+arr.prop]: e.target.value, ["select"+i]: e.target.value, ["selectedValue"+arr.prop]: e.target.value, ["value"+i]: (e.target.value) ||"" })}} value={this.state["prop"+arr.prop] || ""}/>
                  </div>
                )
              }

              return(
                <div className="input-group" key={'multiple-selects-'+i} >
                  {arr.header && <div className="input-group-prepend">
                    <span className="input-group-text">{arr.header}</span>
                  </div>}
                  <select className="custom-select" id="inputGroupSelect04" onChange={(e)=>{this.setState({["prop"+arr.prop]: e.target.value, ["selectedValue"+arr.prop]: (e.target.selectedOptions[0].label) ||"", ["select"+i]: e.target.value, ["value"+i]: (e.target.selectedOptions[0].label) ||"" })}} value={this.state["prop"+arr.prop] || ""}>
                    <option value="0"></option>
                    {
                      arr.array && arr.array.length && arr.array.map((opt, i)=>{
      
                        if(opt && opt.id){
                          return(
                            <option value={opt.id} key={"option-" + opt.id + (Col && Col.prop) + '-' + i}>
                              {(arr.propsShow && arr.propsShow.length && this.makeValue({element:opt, props: arr.propsShow }).join(' ')) || opt.name || opt.value || '...'}
                            </option>
                          )
                        }

                        return null;
                      })
                    }
                  </select>
                </div>
              )
            }) || null
          }
          
          <button className="btn btn-outline-success" type="button" onClick={this.save}>+ Добавить</button>
          <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell, value: Cell})}>отмена</button>
        </div>
      )
    }
  
    return(null)
  }
}



export class QueryEditor extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      admin: false,
      value: "",
      defValue: "",
    }
  }

  componentDidMount(){
    const {Cell, Row} = this.props;

    this.setState({
      value: Cell,
      defValue: Cell,
    })
  }

  componentDidUpdate(){
    const {Cell,} = this.props;



    if (Cell !== this.state.defValue) {
      this.setState({
        value: Cell,
        defValue: Cell,
      })
    }
  }

  toEdit = () => {
    const {value, editCell, admin} = this.state;

    const Q = `{
      me{
        type
      }
    }`;

    if(!admin && this.props.rights && this.props.rights === 'admin'){
      QA(Q).then((q)=>{
        if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
          this.setState({
            editCell: true,
            admin: true,
          })
        }
      });

      return null;
    }
    
    if (admin || !this.props.rights) {

      this.setState({editCell: true,});
      
      return null;
    }

    // this.setState({editCell: true,});
  }

  save = () => {
    const {query, saveParams, prop, id, type} = this.props;
    const {value, editCell} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы

    // let TextArea = null;

    // if (type === "textarea") {
    //   TextArea = value && value.split('\n') || null;
    // }


    const Value = value || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });
    
    if(!Q) return null;
    


    QA(Q).then((data)=>{
      this.setState({
        value: Value,
        editCell: !editCell,
      });
    })
    
  }
  
  render() {
    const {type} = this.props;
    const {editCell, value, admin} = this.state;
    

    if (!editCell) {
      return(<div className="GridCell HoverParent" style={{height: '100%', width: '100%', minHeight: '30px'}}>
        {value}
        <div className="HoverChild" onClick={()=>{ this.toEdit()}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
        </div>
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%', minHeight: '30px'}} >
          <div>{type !== "textarea" && value}{type === "textarea" && value}</div>
          <div className="input-group">
            {type !== "textarea" && <input
              type={type || "text"}
              className="form-control"
              onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value} /> }
            {
              type === "textarea" && <textarea className="form-control" rows="3" onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value}/>
            }

            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}