import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_DAYS } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import Loader from '../../Loader';
import headerFormatter, { faktFormatter } from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';
import { sortObjects } from '../CONSTANTS/Transformers';
import Confirmation from '../COMPONENTS/PARTS/Confirmation';
import { DAY_CHECK } from '../GQL/SERVER/MUTATOR/ServerMutators';

let brigadeFilter, objectFilter;

const sortFunc = (a, b, order) => {
  if (order === 'desc') {
    return ToFloat(b) - ToFloat(a);
  }
  
  return ToFloat(a) - ToFloat(b); // desc
}

const diffStyle = (cell) => {
  if(ToFloat(cell) < -10) {
    return {
      backgroundColor: 'rgba(194, 41, 41, 0.1)'
    };
  }
  if(ToFloat(cell) > 30) {
    return {
      backgroundColor: 'rgba(49, 170, 59, 0.1)'
    };
  }
}

const diff = (plan,fact) => {
  let diff = 0

  if (plan !== 0 || fact !== 0) diff = (1-fact/plan)*100
  // diff = ((fact-plan)/((plan+fact)/2)) *50

  return diff.toFixed(2)
}

export default class RGrArea extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaName: "",
      emptyStrings: false, //скрывает пустые строки
      queryData: [],
      showObjects: false,
      weekCount: 4,
      curDay: moment().format("YYYY-MM-DD"),
      dates: [],
      colums: [],
      headers:[],
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
      daysCheck: [], // список проверенных периодов
      modal: false,
      start: "", //старт и конец (неделя) проверенного начальником периода для записи
      end: "",
      modaltitle: "Подтверждение",
      rights: ""
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.changeDate()
    this.checkUser()
  }

  changeWeekCount = (number) => {
    this.setState({weekCount: number}, () => { this.changeDate() });
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, type }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2, rights: type})
    else this.setState({rights: type},()=>this.getData())

  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.changeDate()});
  }

  changeDate = () => {
    const { weekCount, curDay } = this.state;
    let i = weekCount
    const curDates = []

    while (i--) curDates.push({
      first: moment(curDay).weekday(1-(i*7)), 
      last: moment(curDay).weekday(7-(i*7)),
      plan: 0,
      fact: 0
    })

    this.setState({
      dates: curDates
    },
    ()=>{
      this.getupdate()
    })
  }

  hideModal = () => {
    this.setState({
      modal: false,
      start: "",
      end: "",
    })
  }

  doAction = async () => {
    const { match } = this.props;
    const { start, end } = this.state
    const id = match.params.id;
    const data = DAY_CHECK({
      area: id,
      start,
      end,
      status: true,
    });

    await QA(data)

    this.setState({
      modal: false,
      start: "",
      end: "",
    },
    ()=>{
      this.getData()
    })
  }

  confirmation = (start, end) => {
    this.setState({
      modal: true,
      start: start.format("x"),
      end: end.format("x"),
      modaltitle: "Подтвердить проверку данных: " + start.format("DD/MM") + " - " + end.format("DD/MM") + " ???"
    })
  }

  resetFilter = () => {
    const { showObjects } = this.state

    brigadeFilter();
    showObjects && objectFilter();
  };

  switchObjects = () => {
    const { showObjects } = this.state;

    this.setState({showObjects: !showObjects}, () => { this.getData() });
  }

  getData = () => {
    const { match } = this.props;
    const { showObjects } = this.state;
    const id = match.params.id;

    this.setState({ loaded: 0 })

    if(!id) return null;

    QA(GET_AREA_DAYS({id: id, objects: showObjects, start: 0})).then((data)=>{
      if (data && data.groupDays && data.area && data.groupDays.length > 0) {
        this.setState({
          queryData: data.groupDays,
          areaName: data.area.name,
          daysCheck: data.dayscheck ? data.dayscheck : [],
          loaded: 1
        }, ()=>{
          this.getupdate(data.groupDays)
        })
      } else {
        this.setState({loaded: 2})
      }
    }
    )

  }

  getHeaders = (brigades, objects/* , sumPlanAll, sumAll */) => {
    const { showObjects, dates, daysCheck, rights } = this.state
    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }

    let headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { dataField: 'object', text: "Объект", hidden: !showObjects, editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilter = filter;}}), footer:""},
      { dataField: 'brigade', text: "Бригада", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: brigades, style:selectStyle, placeholder: 'все',
        getFilter: (filter) => {brigadeFilter = filter;}}),footer: "ИТОГО на странице"
      } ]

    let weekHeaders = []

    for (let j=0; j < dates.length; j++){
      const week = [
        { 
          dataField: 'weekPlan' + j, 
          text: "затраты с " + dates[j].first.format("DD/MM") + " по " + dates[j].last.format("DD/MM") + " (норма)", 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, dates[j].first.format("DD/MM") + " - " + dates[j].last.format("DD/MM"), {icon:'iconF',text: "трудозатраты по норме за предыдущую неделю, чел-дн", footer: "по норме"}),
          footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
          // footer: dates[j].plan.toFixed(2)
        }, 
        { 
          dataField: 'weekFact' + j, 
          text: "затраты с " + dates[j].first.format("DD/MM") + " по " + dates[j].last.format("DD/MM") + " (факт)", 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => faktFormatter(column, colIndex, dates[j].first, dates[j].last, {icon:'iconF',text: "трудозатраты (факт) за предыдущую неделю, чел-дн", footer: "факт"}, daysCheck, rights, ()=>this.confirmation(dates[j].first, dates[j].last)),
          footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
          // footer: dates[j].fact.toFixed(2)
        }, 
        { 
          dataField: 'weekDiff' + j, 
          text: "Разница, %", 
          style: (cell) => diffStyle(cell),
          sort: true,
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, dates[j].first.format("DD/MM") + " - " + dates[j].last.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
          footer: ""
        }
      ]

      weekHeaders = [...weekHeaders, ...week]
    }

    const endHeaders = [
      { dataField: 'planAll', text: 'затраты итого (норма)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconF',text: "трудозатраты по норме, чел-дн", footer: "по норме"}),
        footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
        // footer: sumPlanAll.toFixed(2)
      },
      { dataField: 'factAll', text: 'затраты итого (факт)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconF',text: "трудозатраты (факт), чел-дн", footer: "факт"}),
        footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
        // footer: sumAll.toFixed(2)
      },
      { 
        dataField: 'workAllDiff', 
        text: 'Разница, %', 
        editable: false, 
        sort: true, 
        style: (cell) => diffStyle(cell),
        sortFunc: (a, b, order) => sortFunc(a, b, order),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconP',text: "разница, %", footer: "%"}),
        footer: ""
      },
    ]

    headers = [...headers, ...weekHeaders, ...endHeaders]

    return headers
  }

  groupDays = (data) => {
    const { dates, emptyStrings } = this.state

    if (!data || !data.length) return null;
    let colums = [], brigades = {}, objects = {};

    // let sumPlanAll = 0, sumAll = 0;

    const sumDates = dates; // ФИЧА С ССЫЛКАМИ!!

    for (let j=0; j < dates.length; j++){
      sumDates[j].plan = 0
      sumDates[j].fact = 0
    }

    data.map(e => {      
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= dates[0].first &&  moment(b.day)<= dates[dates.length-1].last)) : e.days

      if (emptyStrings && days.length <1) return

      brigades[e.brigade] = e.brigade;
      objects[e.object] = e.object;

      let sumWorkPlan = 0;
      let curDates = [];

      for (let j=0; j < dates.length; j++){
        curDates.push ({plan: 0, fact: 0})
      }

      const sumWork = e.days.reduce(function(a, b) {

        for (let j=0; j < dates.length; j++){
          if (moment(b.day) >=dates[j].first && moment(b.day) <=dates[j].last) {

            // workWeekPlan = e.preDefValue ? ToFloat(e.preDefValue)*volWeek/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0) ? e.coeffNorm : 1) : 0 
            // workWeekprevPlan = e.preDefValue ? ToFloat(e.preDefValue)*volWeekprev/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0 )? e.coeffNorm : 1) : 0 
            // sumWorkPlan = e.preDefValue ? ToFloat(e.preDefValue)*sumvalue/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0) ? e.coeffNorm : 1) : 0 

            curDates[j].plan = (curDates[j].plan ? curDates[j].plan : 0)   + (((b.value ? ToFloat(b.value) : 0)*(b.preDefValue ? ToFloat(b.preDefValue)*((b.coeffNorm && ToFloat(b.coeffNorm) !== 0 )? ToFloat(b.coeffNorm) : 1) : 0))/11 )
            sumDates[j].plan = (sumDates[j].plan ? sumDates[j].plan : 0)   + (((b.value ? ToFloat(b.value) : 0)*(b.preDefValue ? ToFloat(b.preDefValue)*((b.coeffNorm && ToFloat(b.coeffNorm) !== 0 )? ToFloat(b.coeffNorm) : 1) : 0))/11 ) // ФИЧА С ССЫЛКАМИ!!
            curDates[j].fact = (curDates[j].fact ? curDates[j].fact : 0) + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
            sumDates[j].fact = (sumDates[j].fact ? sumDates[j].fact : 0) + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0)); // ФИЧА С ССЫЛКАМИ!!
          }
        }
        sumWorkPlan = sumWorkPlan + (((b.value ? ToFloat(b.value) : 0)*(b.preDefValue ? ToFloat(b.preDefValue)*((b.coeffNorm && ToFloat(b.coeffNorm) !== 0 )? ToFloat(b.coeffNorm) : 1) : 0))/11)

        return a + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
      }, 0)

      // sumPlanAll = sumPlanAll + sumWorkPlan;
      // sumAll = sumAll + sumWork;
      e.cols = { id: e.id, brigade: e.brigade, object: e.object,
        factAll: ToFloat(sumWork).toFixed(2) ,
        planAll: ToFloat(sumWorkPlan).toFixed(2) ,
        workAllDiff: sumWorkPlan !== 0 ? diff(ToFloat(sumWorkPlan), ToFloat(sumWork)) : 0,
      }

      for (let j=0; j < dates.length; j++){
        e.cols["weekPlan" + j] = curDates[j].plan.toFixed(2)
        e.cols["weekFact" + j] = curDates[j].fact.toFixed(2)
        e.cols["weekDiff" + j] = curDates[j].plan !==0 ? diff(ToFloat(curDates[j].plan), ToFloat(curDates[j].fact)) : ""
      }

      colums.push(e.cols)

      return e;
    });

    objects = sortObjects(objects)
    brigades = sortObjects(brigades)

    return {colums, brigades, objects/* , sumPlanAll, sumAll */};
  }


  getupdate = (queryData) => {
    let Data = queryData || this.state.queryData;

    if(!Data || !Data.length) return null;

    const {colums, brigades, objects} = this.groupDays(Data);
    const headers = this.getHeaders(brigades, objects/* , sumPlanAll, sumAll */);

    if(colums && (!this.state.colums || !this.state.colums.length || this.state.colums !== colums)){

      this.setState({
        colums,
      });
    }
    if(headers && (!this.state.headers || !this.state.colums.headers || this.state.headers !== headers)){
      this.setState({
        headers,
      });
    }

  }

  render() {
    const { areaName, showObjects, weekCount, loaded, colums, headers, emptyStrings, curDay, modal, modaltitle} = this.state;
    
    // if (loaded === 1) {
      
    // if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>

    return (
      <div className={'Area'} style={{width: '100%',margin:'0'}}>
        <div style={{width: '100%', height: 'auto', margin:'0 auto', position: 'fixed', top: '0', right:'auto', left:'auto', display:'flex', flexDirection:'row', justifyContent:'center',
          alignContent:'center', alignItems: 'center', zIndex: '9999',}}>
          {loaded === 0 && <Loader />}
        </div>
        {colums && colums.length && headers && headers.length && <div className="" style={{maxWidth:"850px",margin: '5px'}}>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="btnGroupAddon"><b>{areaName}</b></div>
              <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
            </div>
            <input type="date"
              className="form-control"
              aria-label="Input group example" aria-describedby="btnGroupAddon"
              name="trip-start"
              min="2018-01-01"
              max={(new Date().toISOString()).slice(0,10)}
              value={curDay}
              onChange={e=>this.changeDay(e)}
            />
            <button className={`btn btn-sm ${weekCount === 2 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(2)}>2</button>
            <button className={`btn btn-sm ${weekCount === 3 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(3)}>3</button>
            <button className={`btn btn-sm ${weekCount === 4 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(4)}>4</button>
            <button className={`btn btn-sm ${weekCount === 5 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(5)}>5</button>
            <button className="btn btn-sm btn-primary" onClick={this.switchObjects}>{showObjects ? "Скрыть" : "Показать"} объекты</button>
            <button className={`btn btn-sm btn-btn-secondary } `} onClick={()=>this.setState({emptyStrings: !emptyStrings}, ()=>{this.getupdate()})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
          </div>
        </div> || null}
        {colums && colums.length && headers && headers.length && <Table headers={headers} cols={colums} update={this.getData} updateState={this.updateState} resetFilter={this.resetFilter} tabletype={showObjects ? "AllTable n32": "AllTable n31"} simple={true} /> || null}
        <Confirmation modal={ modal } hideModal={ this.hideModal } doAction={ this.doAction } title={ modaltitle }/>
      </div>
    )
    // }


  }
}
