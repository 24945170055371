import gql from "graphql-tag";

export const GetHandBooks = gql`
  query GetHandBooks($id: ID) {
    handbook(id: $id) {
      id
      name
      queryName
    }
  }
`;
export const GetCatalogByName = (queryName) => gql`
  query Q($id: ID) {
    ${queryName}(id: $id) {
      id
      name
    }
  }
`;



export const GET_AREAS = gql`
{
  areas{
		name
    description
    id
    __typename
    objects{
      __typename
  }
}
}
`;

export const GET_AREAS_ALL = gql`
{
  areas{
		name
    description
    id
    __typename
    objects{
      id
      name
      __typename
        workTypes{
          __typename
          id
          type
          brigade
          construction
          unit
          value
          section
          comment
          days{
            id
            comment
            day
            value
            factor
      }
    }
    }
}
}
`;


export const GET_AREA_ID = gql`
query($id: String!){
  area(id: $id){
		name
    description
    id
    __typename
    objects{
      id
      name
      __typename
        workTypes{
          __typename
      }
      }
    }
  }
`;
export const GET_AREA_BRIGADES_ID = gql`
query($id: String!){
  area(id: $id){
		name
    description
    id
    __typename
    brigades{
      id
      description
      name
      __typename
      }
    }
  }
`;
export const GET_AREA_WORKTYPE = gql`
query($id: String!, $day: String ){
  area(id: $id, day: $day){
    name
    description
    id
    workTypes {
      id
      type
      brigade
      construction
      object
      unit
      value
      section
      comment
      days {
        id
        comment
        commentUser
        day
        value
        factor
        coefficient
      }
    }
    }
  }
`;
export const GET_AREA_WORKTYPE_QA = ({id, day, dayNumber, last, start}) => {
  try {
    return `{
      dayscheck (area: "${id}", start: "${start}") {
        start
        end
        status
      }
      area(
        workType: "full", 
        id: "${id}", 
        ${(day && `, day: "${day}"`) || ""},
        ${(dayNumber && `, dayNumber: "${dayNumber}"`) || ""}
        ${(last && `, last: ${last}`) || ""}
        )
        {
        name
        description
        id
        workTypes {
          id
          type
          brigade
          construction
          object
          unit
          value
          section
          comment
          completed
          deleted
          days {
            id
            comment
            # commentUser
            day
            dateParse
            value
            factor
            coefficient
          }
        }
        }
      }`;
  } catch (error) {
    return null;
  }
};

export const GET_AREA_WORKTYPE_CSV = ({id}) => {
  try {
    return `{
      area(
        workType: "csv", 
        id: "${id}", 
        )
        {
        name
        id
        workTypes {
          id
          value
          brigade
          construction
          object
          groupName
          sectionName
          days {
            id
            day
            dateParse
            value
            factor
            coefficient
          }
        }
        }
      }`;
  } catch (error) {
    return null;
  }
};


export const GET_AREA_WORKTYPE_GANTT = ({id, day, dayNumber}) => {
  try {
    return `{
      area(
        workType: "full", 
        id: "${id}", 
        ${(day && `, day: "${day}"`) || ""},
        ${(dayNumber && `, dayNumber: "${dayNumber}"`) || ""}
        )
        {
        name
        description
        id
        workTypes {
          id
          type
          brigade
          construction
          object
          unit
          value
          section
          comment
          completed
          deleted
          days {
            id
            comment
            dateParse
            value
          }
        }
        }
      }`;
  } catch (error) {
    return null;
  }
};

export const GET_TRANSPORT_DAYS = ({id, day, dayNumber, showObjects}) => {
  try {
    return `{
      area (id: "${id}") {
        name
      }
      transports(
        id: "${id}", 
        day: "${day}", 
        dayNumber: "${dayNumber}",
        showObjects: ${showObjects},
        ){
          id
          firm
          name
          number
          type
          object
          days {
            day
            hours
          }
        }
    }`;
  } catch (error) {
    return null;
  }
};

export const GET_TRANSPORT_CSV = ({id, csv}) => {
  try {
    return `{
      area (id: "${id}") {
        name
      }
      transports(
        id: "${id}", 
        csv: ${csv}, 
        ){
          id
          firm
          name
          number
          type
          object
          construction
          worktype
          group
          section
          days {
            day
            dateParse
            hours
          }
        }
    }`;
  } catch (error) {
    return null;
  }
};

export const GET_BRIGADE_DAYS = ({id, day, dayNumber}) => {
  try {
    return `{
      area (id: "${id}") {
        name
      }
      brigades(
        area: "${id}", 
        day: "${day}", 
        dayNumber: "${dayNumber}",
        ){
          id
          name
          days {
            id
            day
            onWork
            notWork {
              sick
              absenteeism
              dayoff
              vacation
              fired
            }
            factor
            coefficient
          }
        }
    }`;
  } catch (error) {
    return null;
  }
};


export const GET_AREA_WORKTYPE_TRANSPORT =  ({id, day, dayNumber}) => {
  try {
    return `{
      area(
        workType: "transport", 
        id: "${id}", 
        ${(day && `, day: "${day}"`) || ""},
        ${(dayNumber && `, dayNumber: "${dayNumber}"`) || ""}
        ){
        name
        description
        id
        transports {
          id
          name
          number
          type
          firm
        }
        workTypes {
          id
          type
          construction
          object
          unit
          value
          section
          comment
          completed
          deleted
          coeffNorm
          ${(!day && `transports{
            name
            preDefValue
          }`) || ""}
          days {
            id
            comment
            day
            value
            valuePlanTr
            transports {
              id
              hours
            }
          }
        }
        }
      }`;
  } catch (error) {
    return null;
  }
};

export const GET_AREA_WORKTYPE_QA_FULL = ({id}) => {
  try {
    return `{
      area(id: "${id}", workType: "full"){
        name
        description
        id
        workTypes {
          id
          type
          brigade
          construction
          object
          unit
          value
          preDefValue
          section
          comment
          completed
          deleted
          valueProject
          coeffNorm
          days {
            id
            day
            value
            valuePlan
            factor
            coefficient
          }
        }
        }
      }`;
  } catch (error) {
    return null;
  }
};

export const GET_AREA_DAYS = ({id, objects, start}) => {
  try {
    return `{
      area (id: "${id}") {
        name
      }
      dayscheck (area: "${id}", start: "${start}") {
        start
        end
        status
      }
      groupDays(id: "${id}", objects: ${objects}) {
        id
        brigade
        brigadeId
        object
        objectId
        days {
          id
          day
          dateParse
          value
          valuePlan
          factor
          coefficient
          preDefValue
          coeffNorm
        }
      }
    }`;
  } catch (error) {
    return null;
  }
};

export const GET_AREA_DAYS_MONTH = ({id, month = false, showConstruction = false}) => {
  try {
    return `{
      area (id: "${id}") {
        name
      }
      groupDays(id: "${id}", month: ${month}, showConstruction: ${showConstruction}) {
        id
        object
        objectId
        days {
          id
          day
          dateParse
          factor
          coefficient
        }
      }
    }`;
  } catch (error) {
    return null;
  }
};

export const GET_COMMENT_USER = ({id}) => {
  try {
    return `{
      day(id: "${id}"){
        commentUser
        }
      }`;
  } catch (error) {
    return null;
  }
};


export const GET_AREA_WTYPES = gql`
query($id: String!){
  area(id: $id){
    id
    name
    workTypes{
      id
      section
      days{
        day
        id
      }
      unit
    }
    }
  }
`;


export const GET_OBJECT_ID = gql`
query($id: String!){
    object(id: $id){
      id
      name
      __typename
        workTypes{
          __typename
          id
          type
          brigade
          construction
          unit
          value
          section
          comment
          days{
            id
            comment
            day
            value
            factor
            coefficient
      }
    }
    }
  }
`;

export const GET_BRIGADE_ID = gql`
query($id: String!){
    brigade(id: $id){
      id
      name
      __typename
        workTypes{
          __typename
          id
          type
          brigade
          object
          construction
          unit
          value
          section
          comment
          days{
            id
            comment
            day
            value
            factor
            coefficient
      }
    }
    }
  }
`;

export const GET_BOW = gql`
  query($id: String!) {
    brigades(area: $id) {
      id
      name
    }
    objects(parent: $id) {
      id
      name
    }
    # constructions(parent: $id) {
    #   id
    #   name
    # }
  #   sections {
  #     id
  #     value
  #     workTypes {
  #       id
  #       value
  #       unit
  #       section
  #     }
  # }
    sections(top: true) {
      id
      value
    }
    worktypes(actual: true){
      id
      value
      unit
    }
  }
`;

export const GET_CONSTRUCTION = (id) => `
  {
      constructions(parent:  "${id}") {
      id
      name
    }
  }
`;

export const GET_SECTION = (id) => `
  {
    sections(id:  "${id}") {
      id
      value
      workTypes {
        id
        value
      }
    }
  }
`;

export const GET_FILES = ({workId, day, id}) => `
  {
    files(
      ${(workId && `workId: "${workId}"`) || ""}
      ${(day && `day: "${day}"`) || ""}
      ${(id && `id: "${id}"`) || ""}
      ) {
      id
      name
      mimeType
    }
  }
`;

export const Query_worktypes_Units_brigades = `{
  worktypes{
    id
    value
    preDefValue
    name: value
    section
    parentName: sectionName
    parents
    unit
    param: unit
  }
  units{
    id
    value
    name: value
  }
  brigades{
    id
    name
    value: id
  }
  areas{
    id
    name
    value: id
  }
  objects{
    id
    parent
    parentName: area
    area
    name
    value: id
  }
  constructions{
    id
    name
    value: name
    parentName: objectName
    parents
  }
  users{
    id
    name
  }
  sections{
    id
    value
    name: value
    section
    parentName
  }
}`;

export const WT_UNITS_QA = `{
  worktypes{
    id
    value
    preDefValue
    name: value
    section
    sectionName
    groupName
    parentName: sectionName
    parents
    unit
    param: unit
    transports{
      id
      name
      preDefValue
    }
  }
  units{
    id
    value
    name: value
  }
}`;

export const WTYPES_BY_SECT_QA = (section) => `{
  worktypes(section: "${section}"){
    id
    value
    preDefValue
    name: value
    section
    parentName: sectionName
    parents
    unit
    param: unit
  }
}`;

export const GET_INFO = () => `{
  info{
    id
    name
    text
    position
  }
}`;


export const AREAS_OBJ_QA = `{
  areas{
    id
    name
    value: id
  }
  objects{
    id
    parent
    name
    value: id
  }
}`;


export const WT_QA = `{
  worktypes{
    id
    value
    preDefValue
    name: value
    section
    comment
    groupName: sectionName
    parentName: sectionName
    parents
    unit
    param: unit
    
    description
  }
}`;

export const WT_QA2 = `{
  worktypes{
    id
    value
    preDefValue
    name: value
    section
    comment
    groupName
    sectionName
    unit
    description
    transports{
      id
      name
      preDefValue
    }
  }
}`;


export const UNITS_QA = `{
  units{
    id
    value
    name: value
  }
}`;
export const BRIGADES_QA = `{
  areas{
    id
    name
    value: id
  }
  brigades{
    id
    name
    area
    parentName: area
    value: id
  }
}`;
export const AREAS_QA = `{
  areas{
    id
    name
    value: id
  }
}`;

export const TRANSP_TYPES_QA = `{
  transportTypes{
    id
    name
    value: name
  }
}`;
export const TRANSP_QA = `{
  areas{
    id
    name
    value: id
  }
  transports{
    id
    name
    area
    number
    type
    firm
  }
}`;

export const AREA_ID_QA = ({id}) => {
  try {
    return `{
      area(id: "${id}"){
        id
        name
        value: id
        objects{
          id
          parent
          name
          value: id
        }
      }
    }`;
  } catch (error) {
    return null;
  }
};

export const OBJECTS_QA = `{
  objects{
    id
    parent
    name
    value: id
  }
}`;
export const CONSTR_QA = `{
  constructions{
    id
    name
    value: name
    parentName: objectName
    parents
    objectName
  }
}`;
export const USERS_QA = `{
  users{
    id
    name
    fio
    type
  }
}`;
export const RIGHTS_QA = `{
    rights{
      id
      name
      users{
        fio
        name
        id
      }
      rights{
        access
        collid
      }
      navs{
        name
        access
      }
    }
}`;
export const SECT_QA = `{
  sections{
    id
    value
    name: value
    section
    parentName
  }
}`;
export const SECT_ID_QA = ({id}) => `{
  section(id: "${id}"){
    id
    value
    name: value
    section
    parentName
  }
}`;
export const SECT_PARENT_ID_QA = ({id}) => `{
  sections(id: "${id}"){
    id
    value
    name: value
    section
    parentName
  }
}`;
export const SECT_PARENTS_QA = `{
  sections(top: true){
    id
    value: id
    name: value
    section
    parentName
  }
}`;

export const OK = `
{
  ok
}
`;
