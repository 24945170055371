
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';

import { dayUpdate } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { QA } from '../../CONSTANTS';
import UploadFile from './UploadFile';
import { GET_COMMENT_USER } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import { ToFloat } from '../../CONSTANTS/operations';

class EditCapacityComment extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      comment: "",
      commentUser: "",
    };
  }
  
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onUpdate: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    dayId: PropTypes.string,
    date: PropTypes.string.isRequired,
    comment: PropTypes.string,
  }

  static defaultProps = {
    value: 0
  }

  componentDidMount() {
    const { comment, dayId } = this.props

    if (comment && dayId) this.getData(dayId);
  }

  getData = (id) => {
    QA(GET_COMMENT_USER({id})).then((data)=>
      this.setState({
        commentUser: data.day.commentUser,
      })
    )
  }

  getValue() {
    return (this.range.value && ToFloat(this.range.value).toFixed(1)) || null;
  }
  
  saveCell = () => {
    const { id, date } = this.props
    const { comment } = this.state
    const data = dayUpdate({
      comment: comment ? comment.replace(/\n/gi,"\\n") : "empty",
      parent: id,
      day: date,
    });

    QA(data)
    // .then((data => {
    //   // if (data) 
    //   //   this.props.update();
    // }
    // ))

  }
  
  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, date, update, value, onUpdate, comment, dayId, ...rest } = this.props;
    const { modal, commentUser } = this.state;

    return [
      <input
        { ...rest }
        key="range"
        ref={ node => this.range = node }
        type="text"
      />,
      <div
        key="submit"
        className="tdevent button"
        onClick={ () => this.setState({modal: true}) }
      >
        <div className="text">+</div>
      </div>,
      // <div
      //   key="submit"
      //   className="tdevent"
      //   onClick={ () => this.setState({modal: true}) }
      // >
      //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/>
      //     <path fill="#76D0A4" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/></svg>
      // </div>,
      <div key="modal2">
        <Modal
          size="lg"
          style={{color: "#fff9"}}
          show={modal}
          onHide={()=>{this.setState({modal: !modal}); update()}}
          aria-labelledby="example-modal-sizes-title-lg"
        >                    
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Добавить комментарий
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">комментарий {comment && commentUser ? "от " + commentUser : ""}</span>
              </div>
              <textarea type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" 
                defaultValue={(comment && comment) || ''} onChange={(e)=>this.setState({comment: e.target.value})} />
            </div>
            <button type="button" className="btn btn-success" onClick={()=>{this.saveCell();this.setState({modal: !modal})}}>Сохранить</button>
          </Modal.Body>
          <Modal.Footer>
            <UploadFile id={id} date={date} dayId={dayId} />
          </Modal.Footer>
        </Modal>
      </div>
    ];
  }
}
  
export default EditCapacityComment