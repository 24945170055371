import React, { Component } from 'react'

import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { ADD_OBJECT_QA, EDIT_OBJECT_QA, DELETE_OBJECT_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { AREAS_OBJ_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
// import Editorable from './Editorable.third.1';
import { RenderEditor } from './EditorParts';
import AdminGridEditor from './AdminGridEditor';
import SelectSearch from 'react-select-search';

export default class ObjectMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => await QA(AREAS_OBJ_QA).then((data => {
    if(data.areas && data.objects){

      const Objects = data.objects.length && data.objects.map((obj)=>{
        if(!obj) return null;

        if(!obj.parent) return obj;

        let AreaName = data.areas.length && data.areas.find(ar => ar.id === obj.parent);

        return {...obj, parentName: AreaName.name || null}

      }) || [];

      data && this.setState({
        areas: data.areas,
        objects: Objects,
      })
    }
  }));
  
  render() {
    const { areas, objects, } = this.state;

    const ELEMENTS = [
      {
        name: 'Название',
        type: 'input',
        important: true,
        prop: 'name',
        propName: null,
        array: [],
      },
      {
        name: 'Площадка',
        important: true,
        prop: 'parent',
        propName: 'id',
        onRender: (props) => <SelectSearch {...props} options={areas} onChange={(target)=>props.set(target.value)} />,
      },

    ];

    const headers = [
      {
        name: '',
        prop: 'id',
        maxWidth: '30px',
      },
      {
        name: 'Объект',
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_OBJECT_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        prop: 'name',
        compareProps: ['name'],
      },
      {
        name: 'Площадка',
        prop: 'parentName',
        select: true,
        compareProps: ['parentName'],
      },
    ];

    return(<AdminGridEditor
      // GET={TRANSP_QA}
      ADD={ADD_OBJECT_QA}
      EDIT={EDIT_OBJECT_QA}
      DEL={DELETE_OBJECT_QA}
      name={'Объект'}
      arrayName={'Объекты'}
      array={objects}
      answerName={'objects'}
      refetch={this.GetData}
      headers={headers}
      elements={ELEMENTS}
      searchKeys={['name','parentName']}
    />)
    
    // return (
    //   <Editorable
    //     ADD={ADD_OBJECT_QA}
    //     EDIT={EDIT_OBJECT_QA}
    //     DEL={DELETE_OBJECT_QA}
    //     elements={ELEMENTS}
    //     name={'Объект'}
    //     arrayName={'Объекты'}
    //     array={objects}
    //     refetch={this.GetData}
    //     tableHeaders={headers}
    //     searchKeys={['name','parentName']}
    //   />
    // )
  }
}
