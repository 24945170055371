import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_CSV } from '../GQL/SERVER/QUERIES/ServerQuerys';
import { CutTime, ToHashColor, GETWEEKNEW, HoverCell, sortObjects } from '../CONSTANTS/Transformers';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import Loader from '../../Loader';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';

let objectFilterFunc, constructionFilterFunc, brigadeFilterFunc, worktypeFilterFunc, wtGroupFilterFunc, wtSectionFilterFunc;

export default class CSVBrigades extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaName: "",
      wtData: [],
      emptyStrings: false, //скрывает пустые строки
      week: null,
      curDay: moment().format("YYYY-MM-DD"),
      objectFilter: '',
      brigadeFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      wtGroupFilter: '',
      wtSectionFilter: '',
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else this.getData()
  }


  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.getData()})
  }

  resetFilter = () => {
    objectFilterFunc();
    brigadeFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();
    wtGroupFilterFunc(); 
    wtSectionFilterFunc();

    this.setState({
      objectFilter: '',
      brigadeFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      wtGroupFilter: '',
      wtSectionFilter: '',
    })
  };

  getData = () => {
    const { match } = this.props;
    const { curDay} = this.state;
    const id = match.params.id;
    const last = moment(curDay).diff(moment(curDay).startOf('month'), 'days') + 1
    const week = GETWEEKNEW({date: curDay, last});
    
    this.setState({ week, loaded: 0 })

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_CSV({id: id})).then((data)=>{
      console.warn("aaa", data.workTypes);
      
      if(data && data.area && data.area.workTypes) {
        this.setState({
          wtData: data.area.workTypes,
          areaName: data.area.name,
          loaded: 1
        })
      } else this.setState({loaded: 2})

    })
  }

  getHeaders = () => {
    const { week, wtData, objectFilter, constructionFilter, brigadeFilter, worktypeFilter, wtGroupFilter, wtSectionFilter } = this.state;
    let filterData = [], objects = {}, brigades = {}, constructions = {}, worktypes = {}, wtGroups = {}, wtSections = {};

    wtData.forEach((e) => {
      filterData = [...filterData, { 
        object: e.object, construction: e.construction, wtGroup: e.groupName, wtSection: e.sectionName, worktype: e.value, brigade: e.brigade 
      }]
    });
    
    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) && (brigadeFilter ? v.brigade === brigadeFilter : true)
    && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true)
    && (wtGroupFilter ? v.wtGroup === wtGroupFilter : true) && (wtSectionFilter ? v.wtSection === wtSectionFilter : true)
    )
      .forEach(e => {
        objects[e.object] = e.object
        brigades[e.brigade] = e.brigade
        constructions[e.construction] = e.construction
        worktypes[e.worktype] = e.worktype
        wtGroups[e.wtGroup] = e.wtGroup
        wtSections[e.wtSection] = e.wtSection
      });

    objects = sortObjects(objects)
    brigades = sortObjects(brigades)
    constructions = sortObjects(constructions)
    worktypes = sortObjects(worktypes)
    wtGroups = sortObjects(wtGroups)
    wtSections = sortObjects(wtSections)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }

    if(!week || !week.length) return null;

    const headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'construction', text: "Сооружение", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'brigade', text: "Бригада", editable: false, sort: true, headerStyle: { width: '110px' },  footer: "ИТОГО на странице", filter: selectFilter({options: brigades, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {brigadeFilterFunc = filter}, onFilter: filterVal => this.setState({brigadeFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'wtSection', text: "Раздел работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: wtSections, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {wtSectionFilterFunc = filter}, onFilter: filterVal => this.setState({wtSectionFilter: filterVal})})},               
      { formatter: HoverCell, dataField: 'wtGroup', text: "Группа работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: wtGroups, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {wtGroupFilterFunc = filter}, onFilter: filterVal => this.setState({wtGroupFilter: filterVal})})},                
      { formatter: HoverCell, dataField: 'worktype', text: "Тип работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: worktypes, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal})})},

      ...week.map((e, i) => {
        const color = ToHashColor(e.date+i);

        return([
          { 
            dataField: 'day' + i + 1, 
            dateU: e.date, 
            cellType: "value", 
            text: e.day + ' ' + e.month + " (V)", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', width: '36px'}, 
            editorStyle: {fontSize: '12px'},
            editable: false,
            headerFormatter: (column, colIndex, _, date = e.day + " " + e.month + " (V)") => headerFormatter(column, colIndex, date, {icon:'',text:"Объем"}),
            footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2),
            footerStyle: {
              fontSize: '10px'
            }
          }, 
          { 
            dataField: 'dayW' + i + 1, 
            dateU: e.date, 
            cellType: "work", 
            text: e.day + ' ' + e.month + " (ч/дн)", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', width: '36px'}, 
            editorStyle: {fontSize: '12px'},
            editable: false,
            headerFormatter: (column, colIndex, _, date = e.day + ' ' + e.month + " (ч/дн)") => headerFormatter(column, colIndex, date, {icon:'',text:"ч/дн"}),
            footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2),
            footerStyle: {
              fontSize: '10px'
            }
          }, 
        ])}
      ).flat(),
      { dataField: 'sumValue', text: 'итого объем', editable: false, headerStyle: { fontSize: '13px', padding: '2px', width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО V", {icon:'',text: "Итого объем"}) },
      { dataField: 'sumWork', text: 'итого ч/дн', editable: false, headerStyle: { fontSize: '13px', padding: '2px', width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО ч/дн", {icon:'',text: "Итого ч/дн"}) },
    ]

    return headers
  }

  getColumns = (data) => {
    const { week, emptyStrings } = this.state;

    if (!data) return null;
    let DATA = data || [];
    let colums = []

    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= moment(week[0].date) && moment(b.day)<= moment(week[week.length-1].date))) : e.days
      
      if (days.length <1) return

      e.cols = { 
        id: e.id, object: e.object, construction: e.construction, worktype: e.value, wtGroup: e.groupName, 
        wtSection: e.sectionName, brigade: e.brigade}

      week.forEach((element, index) => {
        e.cols['day' + index + 1] = "";
        e.cols['dayW' + index + 1] = "";

        days.find(ins=>{
          if(!ins || !ins.day || !element.date) return false;
          let d = CutTime(ins.day);
          let d2 = CutTime(element.date);

          if(d === d2){
            e.cols['day' + index + 1] = ins.value || ""; 
            e.cols['dayW' + index + 1] = ((ins.coefficient ? ToFloat(ins.coefficient) : 1)*(ins.factor ? parseInt(ins.factor) : 0));

            return true;
          }
          
          return false
        });
      });

      let sumValue = 0;
      const sumWork = days.reduce(function(a, b) {
        sumValue = sumValue +(b.value ? ToFloat(b.value) : 0)

        return a + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
      }, 0)

      e.cols.sumWork = sumWork.toFixed(2)
      e.cols.sumValue = sumValue.toFixed(2)
      colums.push(e.cols)

      return e;
    });

    return colums;
  }

  render() {
    const { wtData, loaded, curDay, areaName, emptyStrings } = this.state;

    if (loaded === 1) {
      const colums = this.getColumns(wtData);
      const headers = this.getHeaders();

      return (
        <div className={'Area'}>
          <div className="" style={{maxWidth:"750px",margin: '5px'}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="btnGroupAddon"><b>{areaName}</b></div>
                <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
              </div>
              <input type="date"
                className="form-control"
                aria-label="Input group example" aria-describedby="btnGroupAddon"
                name="trip-start"
                min="2018-01-01"
                max={(new Date().toISOString()).slice(0,10)}
                value={curDay}
                onChange={e=>this.changeDay(e)}
              />
              <button className={`btn btn-sm btn-btn-secondary } `} onClick={()=>this.setState({emptyStrings: !emptyStrings})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
            </div>
          </div>
          <Table headers={headers} cols={colums} update={this.getData} resetFilter={this.resetFilter} addString={this.showModal} csv={true} tabletype="AllTable n26" simple={true} />
        </div>
      )}
    if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
  }
}
