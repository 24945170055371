import React, { Component } from 'react'

import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { ADD_BRIGADE_QA, EDIT_BRIGADE_QA, DELETE_BRIGADE_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { BRIGADES_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
// import Editorable from './Editorable.third.1';
import { RenderEditor, RenderEditorSelect } from './EditorParts';
import AdminGridEditor from './AdminGridEditor';
import SelectSearch from 'react-select-search';

export default class BrigadeMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      brigades: [],
      areas: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => await QA(BRIGADES_QA).then((data => {
    console.warn("data", data)
    data && this.setState({
      brigades: data.brigades,
      areas: data.areas,
    })
  }));

  render() {
    const { brigades, areas } = this.state;
    const ELEMENTS = [
      {
        name: 'Название',
        type: 'input',
        important: true,
        prop: 'name',
      },
      {
        name: 'Площадка',
        important: false,
        prop: 'area',
        propName: null,
        array: [...areas],
        onRender: (props) => <SelectSearch {...props} options={areas} onChange={(target)=>props.set(target.value)} />,
      },
    ];

    const headers = [
      {
        name: '',
        prop: 'id',
        maxWidth: '30px',
      },
      {
        name: 'Название бригады',
        prop: 'name',
        select: true,
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_BRIGADE_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        compareProps: ['type']
      },
      {
        name: 'Площадка',
        select: true,
        onRender: ({Cell, Row, Col})=><RenderEditorSelect Col={Col} Row={Row} query={EDIT_BRIGADE_QA} selectProp={'id'} selectArray={areas} selectArrayName={'areas'} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        prop: 'area',
      },
    ];

    return(<AdminGridEditor
      ADD={ADD_BRIGADE_QA}
      EDIT={EDIT_BRIGADE_QA}
      DEL={DELETE_BRIGADE_QA}
      name={'Бригаду'}
      arrayName={'Бригады'}
      array={brigades}
      answerName={'brigades'}
      refetch={this.GetData}
      headers={headers}
      elements={ELEMENTS}
      searchKeys={['parentName','name']}
    />)
    // return (
    //   <Editorable
    //     ADD={ADD_BRIGADE_QA}
    //     DEL={DELETE_BRIGADE_QA}
    //     elements={ELEMENTS}
    //     name={'Бригаду'}
    //     arrayName={'Бригады'}
    //     array={brigades}
    //     tableHeaders={headers}
    //     refetch={this.GetData}
    //     searchKeys={['type','firm','area','name','number']}
    //   />
    // )
  }
}
