import React  from 'react';
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo';
import { Login } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { AUTH_TOKEN } from '../../CONSTANTS';
import { QA } from '../../CONSTANTS';
import { OK } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import '../../CSS/ROOTLEVEL/login.css'
// import { TableTilerFn } from '../HANDBOOK/TableTiler';

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      submitted: false,
      loginError: false,
    };
  }

  static propTypes = {
    isLogin: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { login } = this.props;

    if (localStorage.getItem(AUTH_TOKEN)) {
      QA(OK).then((data => {
        if (data && data.ok && login) login(true)
        else {
          if (login) login(false)
          localStorage.setItem(AUTH_TOKEN, "");
          localStorage.setItem('userId', "");
          localStorage.setItem('userName', "");
        }
      }));
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  logout = () => {
    const { login } = this.props;

    localStorage.setItem(AUTH_TOKEN, "");
    localStorage.setItem('userId', "");
    localStorage.setItem('userName', "");
    if (login) login(false)
  }

  login = (data) => {
    const { login } = this.props;

    localStorage.setItem(AUTH_TOKEN, data.jwt);
    localStorage.setItem('userId', data.id);
    localStorage.setItem('userName', data.name);
    this.setState({ loginError: false});
    if (login) login(true)
  }

  render() {
    const { username, password, submitted, loginError } = this.state;
    const { isLogin } = this.props;

    return (
      <div className="LoginPage" style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        margin: '0',
        padding: '0',
        overflow:'hidden',
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        background: '#eee'
      }}>
        {!isLogin ? 
          <Mutation mutation={Login}>
            {(Login) => (
              <div style={
                {
                  width: '100%',
                  maxWidth: '500px',
                  height: 'auto',
                  background: '#fff',
                  textAlign: 'center',
                  padding: '20px',
                  display: 'flex',
                  flexDirection:'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  borderRadius: '5px'
                }
              }>
                <h2>Авторизация</h2>
                <form name="form" onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ submitted: true });
                  Login({ variables: { name: username, password: password } })
                    .then((a) => {
                      if (a && a.data && a.data.login && a.data.login.jwt) {
                        this.login(a.data.login)
                      } else this.setState({ loginError: true});
                    });
                }}>
                  <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                    <label htmlFor="username">ФИО</label>
                    <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                    {submitted && !username &&
                      <div className="help-block">ФИО не заполнено</div>
                    }
                  </div>
                  <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                    <label htmlFor="password">Пароль</label>
                    <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                    {submitted && !password &&
                      <div className="help-block">Пароль не заполнен</div>
                    }
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary">Войти</button>
                  </div>
                  {loginError &&
                      <div className="help-block">Неудачная авторизация</div>
                  }
                </form>
              </div>
            )}
          </Mutation> :
          <button className="btn btn-primary" onClick={this.logout}>Выйти</button>
        }
      </div>
    );
  }
}

export default LoginScreen;