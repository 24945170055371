
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';

class EditBrigadeWork extends Component {
  constructor() {
    super();
    this.state = {
      modal: true,
      curWorkers: {
        absenteeism: null,
        dayoff: null,
        fired: null,
        sick: null,
        vacation: null,
      },
      actions: {
        absenteeism: "прогул",
        dayoff: "выходной",
        fired: "уволено",
        sick: "болеет",
        vacation: "отпуск",
      }
    };
  }

  static defaultProps = {
    value: ""
  }
  
  static propTypes = {
    value: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    dayId: PropTypes.string,
    date: PropTypes.string.isRequired,
  }

  componentDidMount() {
    const { value } = this.props
    const { curWorkers } = this.state

    if (value) {
      this.setState({
        curWorkers: {...curWorkers, ...value}
      })
    }
  }

  updateFunc = async () => {
    const { update, onUpdate } = this.props
    // const { modal } = this.state

    await update()
    await onUpdate(false)

    // this.setState({modal: !modal});
  }
  
  saveCell = async () => {
    const { curWorkers } = this.state
    const { onUpdate } = this.props

    const workers = {}

    for (var key in curWorkers) { 
      if (curWorkers[key] !== null && curWorkers[key] !== undefined) {
        workers[key] = curWorkers[key].toString()
      }
    }

    await onUpdate(workers)
  }

  changePpl = (value, key) => {
    const { curWorkers } = this.state

    if (!isNaN(value)) {
      const ppl = curWorkers

      ppl[key] = parseInt(value) || 0
      this.setState({
        curWorkers: ppl
      })
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, date, update, value, onUpdate, dayId, ...rest } = this.props;
    const { curWorkers, modal, actions } = this.state

    return (
      <Modal
        size="sm"
        style={{color: "#fff9"}}
        show={modal}
        onHide={this.saveCell} //FIXME: надо тупо закрыть модалку!!
        aria-labelledby="example-modal-sizes-title-sm"
      >                    
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
              Редактировать рабочих
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-2">
            {Object.keys(curWorkers).map((key, i) => (
              <div className="input-group-append" key={i}>
                <span className="input-group-text" style={{height: "50px", width: "200px"}}>{actions[key]}</span>
                <input
                  type="number"
                  min="0" max="100"
                  className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon"
                  placeholder="кол-во"
                  style={{height: "50px", width: "60px"}}
                  value={curWorkers[key] || 0}
                  onChange={(v)=>this.changePpl(v.target.value, key)}
                />
              </div> 
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-success" onClick={this.saveCell}>Сохранить</button>
        </Modal.Footer>
      </Modal>
  
    )
  }
}

export default EditBrigadeWork