import React, { Component, useState } from 'react'
import { QA } from '../../CONSTANTS';
import { DEL_TECHNICS_TYPES_QA, ADD_TECHNICS_TYPES_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { TRANSP_TYPES_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Editorable from './Editorable';

export default class TransportTypes extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      transport: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => {
    QA(TRANSP_TYPES_QA).then((data => {
      data && this.setState({
        transportTypes: data.transportTypes,
      })
    }));
  }
  
  selectList = (id) => {
    if( !id ) return null;

    // edit.value = name;
    this.setState({
      edit: true,
      id,
    })
  }

  change = (inp) => {
    const { loadedHB } = this.state;
    let Filter = loadedHB.filter(e => (e.value.indexOf(inp.target.value) >= 0 && e) || null);

    this.setState({
      worktypes: Filter || loadedHB,
    })
  }

  render() {
    const {transportTypes} = this.state;
    const ELEMENTS = [
      {
        name: 'Тип', // заголовок
        value: null, // дефолтное заполненное значение
        type: 'input', // Тип компонента - input, select, fetch
        add: true, // видно при создании
        edit: true, // видно при редактировании
        importantAdd: true, // обязательное при создании
        importantEdit: true, // обязательное при создании
        prop: 'name', // значение в объекте
        propName: null, 
        array: [], // массив объектов для выбора если select
      },
    ];

    return (
      <Editorable
        ADD={ADD_TECHNICS_TYPES_QA}
        EDIT={ADD_TECHNICS_TYPES_QA}
        DEL={DEL_TECHNICS_TYPES_QA}
        elements={ELEMENTS}
        name={'Типы Спецтехники'}
        arrayName={'Типы Спецтехники'}
        array={transportTypes}
        refetch={this.GetData}
        searchKeys={['type','firm','area','name','number']}
        lister
      />
    )
  }
}

