import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Nav_Links, SVG, MenuSVG } from '../../../CONSTANTS/Transformers';
import { withApollo, graphql, compose } from 'react-apollo';
import { Link } from "react-router-dom";
import { getAppState } from '../../../GQL/CACHE/QUERIES/CacheQueries';
import { BarName, Place, _checkUserRights } from '../../../CONSTANTS/operations';
import { QA } from '../../../CONSTANTS';
import checkUserRights from '../../TABLEBOOTSTRAP/checkUserRights';

class Mid extends Component {
  static propTypes = {
    GAPP:  PropTypes.object.isRequired, 
    full: PropTypes.bool, 
  }

  constructor(props) {
    super(props)
  
    this.state = {
      type: null,
      navs: [],
    }
  }
  
  async componentWillMount(){
    const Q = `{
      me{
        hi
        m
        type
      }
    }`;

    QA(Q).then((q)=>{
      if (q && q.me && q.me.type) {
        this.setState({
          type: q.me.type,
        })
      }
    })

    const UNav = await checkUserRights();

    UNav && this.setState({
      navs: UNav.navs
    })

  }

  to = (place, name) => {
    const { GAPP } = this.props;
    const now = (GAPP && GAPP.AppState && GAPP.AppState.place && GAPP.AppState.place) || null;

    if(!place || place === now) return null;
    Place({place, now});
    const Name = name;

    BarName(Name);
  }

  render() {
    const { full, GAPP } = this.props;
    const { admin, see, type, navs } = this.state;
    let place = (GAPP && GAPP.AppState && GAPP.AppState.place && GAPP.AppState.place) || null;

    return(
      <div className="Mid">
        {
          Nav_Links && Nav_Links.map((e,i)=>{
            if( !e || (!e.name && !e.icon) ) return null;
            // if(!e || (!e.name && !e.icon) || e.roles && e.roles.indexOf('all') === -1 && type && e.roles.indexOf(type) === -1 ) return null;

            if (type === 'monster' || ( e && navs && navs.find(f => f.name === e.place ))) {

              let a = '';

              if(place && place === e.place){
                a = ' a';
              }
            
              return(<Link to={`/${e.url || 'areas'}/`} key={'menu-'+i} onClick={()=>this.to(e.place, e.name)} className={'NavLinks'+a}>
                <span className="icon">{MenuSVG()[e.icon] || SVG()[e.icon] || SVG()['menu1']}</span>
                <span className="name">{e.name || e.icon}</span>
              </Link>)
            
              // if(full){
              //   return(<Link to={`/${e.url || 'areas'}/`} key={'menu-'+i} onClick={()=>this.to(e.place, e.name)} className={'NavLinks'+a}>{e.name || e.icon}</Link>)
              // }
                
              // return(<div key={'menu-'+i} className={'NavLinks'+a} onClick={()=>this.to(e.place, e.name)}>{MenuSVG()[e.icon] || SVG()[e.icon] || SVG()['menu1']}</div>)
            
            
            }

            return null;
          })
        }
      </div>
    )
  }
}

export default compose(
  graphql(getAppState, { name: "GAPP"})
)(withApollo(Mid))
