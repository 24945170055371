import gql from 'graphql-tag';


export const getAppState = gql`
  {
    AppState @client {
      cid
      ctype
      component
      v
      element
      position
      param
      parent
      place
      pid
      prevPid
      prevPlace
    }
  }
`;
export const getTopBar = gql`
  {
    TopBarState @client {
      name
      crumbs
      back{
        cId
        cName
        cParent
      }
    }
  }
`;