import React, { Component } from 'react'
import UniChild from '../../PARTS/UniChild';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";

class Top extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
  }


  render() {

    return (
      <div className="Top">
        <Link to={'/profile'}>
          <UniChild addClass={'Col Cent'} icon name={localStorage.getItem('userName')}/>
        </Link>
      </div>
    )
  }
}

export default Top
