export const _url = process.env.NODE_ENV === 'production'
  ? `${document.location.hostname}/api`
  : process.env.REACT_APP_ENDPOINT || 'localhost:4000';
// export const api = `${document.location.protocol}//${process.env.REACT_APP_ENDPOINT}`;
export const protocol = `${document.location.protocol}//`;
export const wsProtocol = protocol === 'https://' ? 'wss://' : 'ws://';
export const AUTH_TOKEN = 'auth-token';

export const Q = async (query = `{check}`) => {

  return await fetch(`${protocol}${_url}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      query
    })
  })
    .then(r => r.json())
    .then(data => {
      
      return data.data
    
    })
    .catch( () => false);
};
export const QA = async (query = `{check}`, debug = false, auth=localStorage.getItem(AUTH_TOKEN)) => {

  if(debug){
    return 'debug';
  }
  
  return await fetch(`${protocol}${_url}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${auth}`,
    },
    body: JSON.stringify({
      query
    })
  })
    .then(r => r.json())
    .then(data => data.data)
    .catch(() => false);
};