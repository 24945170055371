import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo';
import { Modal, Button } from 'react-bootstrap';
import SelectSearch from 'react-select-search';

import { QA } from '../../CONSTANTS';
import { GET_BOW, GET_CONSTRUCTION, GET_SECTION } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import { createBrigadeLink, createWorkType } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { _hover } from '../../CONSTANTS/operations';


export class RenderHovered extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      x: 0,
      y: 0,
      hover: false,
    }
  }

  static propTypes = {
    option: PropTypes.object.isRequired,
  }

  render() {
    const {option} = this.props;
    
    const styleParent = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      display: 'block',
    };

    return (
      <div className="renderParent"
        style={styleParent}
        onMouseOut={(e)=>{const { option } = this.props;

          _hover({text: option.name || null, x: e.pageX, y: e.pageY });
        }}
        onMouseOver={()=>_hover({text: null, x: null, y: null })}
      >
        {option.name}

      </div>
    )
  }
}

export const renderOption = (option) => {
  return (
    <RenderHovered option={option} />
  )
}

export default class CreateWorkType extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      currentWorkType: {},
      currentObject: {},
      currentConstruction: {},
      currentBrigade: {},
      currentSection: '',
      alert: 0,
      constructionsList: [],
      workTypesList: [],
    }
  }
  
  static propTypes = {
    id: PropTypes.string.isRequired,
    modal: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    brigadesDisable: PropTypes.bool,
    deletedCols: PropTypes.array,
  }

  clearModalFilters = () => {
    this.setState({currentObject: {}, currentSection: '', currentConstruction: {}, currentWorkType: {}, currentBrigade: {}})
  }

  addWorkTypes = () => {
    const { deletedCols } = this.props;
    const { currentBrigade, currentConstruction, currentWorkType, currentObject } = this.state;

    if  (!currentBrigade.value || !currentConstruction.value || !currentWorkType.value || !currentObject.value) {
      this.setState({
        alert: 2,
      })
      setTimeout(()=>{
        this.setState({
          alert: 0,
        })
      }, 3000)

      return
    }
    
    if (deletedCols && deletedCols.length) {
      const deleted = deletedCols.filter((v) => (v.object === currentObject.name) && (v.brigade === currentBrigade.name)
    && (v.construction === currentConstruction.name) && (v.worktype === currentWorkType.name))

      if  (deleted.length) {
        this.setState({
          alert: 5,
        })
        setTimeout(()=>{
          this.setState({
            alert: 0,
          })
        }, 3000)

        return
      }

    }
    const createLink = createBrigadeLink(currentBrigade.value, currentConstruction.value);

    QA(createLink).then((data => {
      if (data && data.brigadeLink && data.brigadeLink.create && data.brigadeLink.create.id) {
        const wtData = {
          parent: data.brigadeLink.create.id, 
          workType: currentWorkType.value,
        }
        const linkWT = createWorkType(wtData)

        QA(linkWT).then((data => {
          if (data && data.workType && data.workType.create && data.workType.create.id) 
            this.setState({
              alert: 1,
            })
          else 
            this.setState({
              alert: 3,
            })
          setTimeout(()=>{
            this.setState({
              alert: 0,
            })
          }, 3000)
        }))
      }
    })
    );
  };

  addTransportWorkTypes = () => {
    const { deletedCols } = this.props;
    const { currentConstruction, currentWorkType } = this.state;

    if  (!currentConstruction.value || !currentWorkType.value) {
      this.setState({
        alert: 2,
      })
      setTimeout(()=>{
        this.setState({
          alert: 0,
        })
      }, 3000)

      return
    }

    console.warn("Aaa",  currentConstruction, currentWorkType, deletedCols);
    
    if (deletedCols && deletedCols.length) {
      const deleted = deletedCols.filter((v) => (v.construction === currentConstruction.name) && (v.worktype === currentWorkType.name))

      if  (deleted.length) {
        this.setState({
          alert: 5,
        })
        setTimeout(()=>{
          this.setState({
            alert: 0,
          })
        }, 3000)

        return
      }
    }


    const data = {
      constructionId: currentConstruction.value, 
      workType: currentWorkType.value,
    }
    const WT = createWorkType(data)

    QA(WT).then((data => {
      if (data && data.workType && data.workType.create && data.workType.create.id) 
        this.setState({
          alert: 1,
        })
      else 
        this.setState({
          alert: 3,
        })
      setTimeout(()=>{
        this.setState({
          alert: 0,
        })
      }, 3000)
    }))

  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.id === nextProps.id && this.props.modal === nextProps.modal && this.state === nextState) {
      return false
    }

    return true;
  }

  saveCurrentObject = (value) => {
    this.setState({currentObject: value, currentSection: '', currentConstruction: {}, constructionsList: []})
    const getConst = GET_CONSTRUCTION(value.value);

    QA(getConst).then((data => {
      let constructions = [];

      data.constructions && data.constructions.map((e) => constructions = [...constructions, {name: e.name, value: e.id}]);
      this.setState({constructionsList: constructions})
    }))
  }

  saveCurrentSection = (value) => {
    const getSection = GET_SECTION(value);

    QA(getSection).then((data => {
      let workTypesList = [];

      data.sections && data.sections.forEach(e => 
        e.workTypes && e.workTypes.forEach (e => 
          workTypesList = [...workTypesList, {name: e.value, value: e.id}]
        )
      )
      console.warn(workTypesList)
      if (workTypesList.length === 0) {
        this.setState({alert: 4})
        setTimeout(()=>{
          this.setState({
            alert: 0,
          })
        }, 3000)
      }
      this.setState({currentSection: value, workTypesList: workTypesList})
    }))
  }

  render() {
    const { id, modal, hideModal, brigadesDisable } = this.props;
    const { currentBrigade, currentConstruction, currentObject, currentSection, currentWorkType, alert, constructionsList, workTypesList } = this.state;


    console.log('workTypesList', workTypesList);
    

    return (
      <Modal
        size="sm"
        style={{color: "black"}}
        show={modal}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
                          Добавить работу
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Query query={GET_BOW}  variables={{ id: id}}>
            {
              ({ data, loading, error }) => {
                if (loading) return 'loading'
                if (error) return 'error'
                if (data) {
                  let wtarray = [],
                    brigades = [],
                    objects = [],
                    sections = []

                  !brigadesDisable && data.brigades && data.brigades.map(e => brigades = [...brigades, {name: e.name, value: e.id}]);
                  data.sections && data.sections.map(e => sections = [...sections, {name: e.value, value: e.id}]);
                  data.worktypes && data.worktypes.map(e => wtarray = [...wtarray, {name: `${e.value}, ${e.unit}`, value: e.id}]);
                  data.objects && data.objects.map(e => objects = [...objects, {name: e.name, value: e.id}]);

                  return (
                    <Fragment>
                      {!brigadesDisable && <SelectSearch value={currentBrigade.value} options={brigades} renderOption={renderOption} onChange={(value) => { this.setState({currentBrigade: value}) }} placeholder="Бригада" />}
                      <SelectSearch options={objects} value={currentObject.value} renderOption={renderOption} onChange={(value) => { this.saveCurrentObject(value) }} placeholder="Объект" />
                      {currentObject && constructionsList.length > 0 && <SelectSearch options={constructionsList} value={currentConstruction.value} renderOption={renderOption}
                        onChange={(value) => { this.setState({currentConstruction: value}) }} placeholder="Сооружения" />}
                      <SelectSearch options={sections} renderOption={renderOption} value={currentSection} onChange={(value) => { this.saveCurrentSection(value.value) }} placeholder="Разделы работ" />
                      {!currentSection ? <SelectSearch options={wtarray} renderOption={renderOption} value={currentWorkType.value} onChange={(value) => { this.setState({currentWorkType: value}) }} placeholder="Тип работ" /> :
                        <SelectSearch options={workTypesList} renderOption={renderOption} value={currentWorkType.value} onChange={(value) => { this.setState({currentWorkType: value}) }} placeholder="Тип работ" /> }
                      {alert === 1 && <div className="alert alert-success" role="alert">Сохранено</div>}
                      {alert === 2 && <div className="alert alert-danger" role="alert">Не выбраны все данные</div>}
                      {alert === 3 && <div className="alert alert-danger" role="alert">Уже есть такая запись</div>}
                      {alert === 4 && <div className="alert alert-danger" role="alert">Данный раздел пуст!</div>}
                      {alert === 5 && <div className="alert alert-danger" role="alert">Уже есть такая запись (см. в скрытых или удаленных)</div>}
                      {currentObject.value && constructionsList.length === 0 && <div className="alert alert-danger" role="alert">В данном объекте нет сооруженийй</div>}
                      <Button className="btn btn btn-primary" onClick={() => { brigadesDisable ? this.addTransportWorkTypes() : this.addWorkTypes() }}>Добавить</Button> 
                      <Button className="btn btn btn-info" onClick={() => { this.clearModalFilters() }} style={{position: 'fixed', right: '22px'}}>Очистить</Button>
                    </Fragment>
                  )
                }
                    
                return null;
              }
            }
          </Query>
        </Modal.Body>
      </Modal>
    )
  }
}
