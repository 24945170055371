import gql from "graphql-tag";

const crypto = require('crypto');


const escapeRegExp = (str) => {

  // return(str.replace(/"/g, "'").replace(/\\/g, "").replace(/</g, "").replace(/>/g, ""));
  return str;
}

export const AppendHandbook = (handbook,method = 'create') => {
  if (!handbook){
    return {};
  }

  return gql`
  mutation M($id: String, $name: String) {
    ${handbook}(data: {id: $id, name: $name}) {
        ${method} {
          id
          name
      }
    }
  }
`};

export const createBrigadeLink = (brigade, construction) => `
mutation{
  brigadeLink(data: {
    brigade: "${brigade}"
    construction: "${construction}"
  }){
    create {
      id
    }
  }
}
`;

export const linkWorkType = (link, workType, comment) => `
mutation{
  workType(data: {
    worktypehandbook: "${workType}"
    parent: "${link}"
    comment: "${comment}"
  }
  ){
  create{
    id
  }
}
}
`;

export const createWorkType = (data) => {
  const comment = (data.comment && `comment: "${data.comment}" `) || ""
  const workType = (data.workType && `worktypehandbook: "${data.workType}" `) || ""
  const parent = (data.parent && `parent: "${data.parent}" `) || ""
  const constructionId = (data.constructionId && `constructionId: "${data.constructionId}" `) || ""

  try {
    return `mutation {
      workType(data: {
        ${parent}
        ${constructionId}
        ${comment}
        ${workType}
      }){
        create{
          id
        }
      }
    }
  `
  } catch (error) {
    return null;
  }
};

export const dayUpdate = (data) => {
  const comment = (data.comment && `comment: "${data.comment}" `) || ""
  const factor = (data.factor && `factor: "${data.factor}" `) || ""
  const coefficient = (data.coefficient && `coefficient: "${data.coefficient}" `) || ""
  const value = (data.value && `value: "${data.value}" `) || ""
  const valuePlan = (data.valuePlan && `valuePlan: "${data.valuePlan}" `) || ""
  const valuePlanTr = (data.valuePlanTr && `valuePlanTr: "${data.valuePlanTr}" `) || ""
  const transports = (data.transports && `transports: ${(JSON.stringify(data.transports)).replace(/\"([^(\")"]+)\":/g,"$1:")} `) || ""

  return `
  mutation{
    Day(data: {
      day: "${data.day}"
      parent: "${data.parent}"
      ${value}
      ${comment}
      ${factor}
      ${coefficient}
      ${valuePlan}
      ${valuePlanTr}
      ${transports}
    }
    ){
    create{
      id
    }
  }
  }
`
};


export const dayBrigadeUpdate = (data) => {
  const onWork = (data.onWork && `onWork: "${data.onWork}" `) || ""
  const notWork = (data.notWork && `notWork: ${(JSON.stringify(data.notWork)).replace(/\"([^(\")"]+)\":/g,"$1:")} `) || ""

  return `
  mutation{
    dayBrigade(data: {
      day: "${data.day}"
      parent: "${data.parent}"
      ${onWork}
      ${notWork}
    }
    ){
    create{
      id
    }
  }
  }
`
};

export const ADD_WORKTYPE = gql`
  mutation($link: String!, $value: String!) {
    hbworkType(data:{link: $link, value: $value}){
      create{
        id
      }
    }
  }
`;

export const EDIT_CONSTR = gql`
  mutation($id: String, $name: String!) {
    construction(data: {id: $id, name: $name}){
      update
    }
  }
`;

export const EDIT_WTYPE = gql`
  mutation($id: ID, $value: String!) {
    hbworkType(data: {id: $id, value: $name}){
      update
    }
  }
`;

export const EDIT_UNIT = gql`
  mutation($id: ID, $value: String!) {
    hbwtunits(data: {id: $id, value: $value}){
      update
    }
  }
`;

export const ADD_OBJECT = gql`
  mutation($name: String!, $parent: String!) {
    object(data: {name: $name, parent: $parent}){
      create{
        id
        name
      }
    }
  }
`;

export const ADD_CONSTRUCTION = gql`
  mutation($name: String!, $parent: String!) {
    construction(data: {name: $name, parent: $parent}){
      create{
        id
        name
      }
    }
  }
`;

export const ADD_UNIT = gql`
  mutation($value: String!) {
    hbwtunits(data: {value: $value}){
      create{
        id
      }
    }
  }
`;

export const ADD_BRIGADE = gql`
  mutation($name: String!) {
    brigade(data: {name: $name}){
      create{
        id
        name
      }
    }
  }
`;

export const ADD_AREA = gql`
  mutation($name: String!) {
    area(data: {name: $name}){
      create{
        id
        name
      }
    }
  }
`;

export const EDIT_AREA = gql`
  mutation($id: String, $name: String!) {
    area(data: {id: $id, name: $name}){
      update
    }
  }
`;
export const EDIT_BRIGADE = gql`
  mutation($id: String, $name: String!) {
    brigade(data: {id: $id, name: $name}){
      update
    }
  }
`;

export const EDIT_OBJECT = gql`
  mutation($id: String, $name: String!) {
    object(data: {id: $id, name: $name}){
      update
    }
  }
`;

export const Login = gql`
  mutation($name: String!, $password: String!) {
    login(user: {name: $name, password: $password})
      {
        id
        name
        jwt
      }
  }
`;
export const Signup = gql`
  mutation($name: String!, $password: String!) {
    signup(user: {name: $name, password: $password})
      {
        id
        name
        jwt
      }
  }
`;

export const uploadFile = gql`
  mutation($file: Upload!, $id: ID!, $date: String!) {
    uploadFile(id: $id, file: $file, date: $date) {
      id
      name
      size
      mimeType
    }
  }
`;


export const Signup_QA = ({name, fio, password, type}) => {
  try {
    return `mutation {
      signup(user: {
        ${(name && `name: "${name}"`) || ""}
        ${(fio && `fio: "${fio}"`) || ""}
        ${(password && `password: "${password}"`) || ""}
        ${(type && `type: "${type}"`) || ""}
        }){
            id
            name
            jwt
          }
      }
    `;
  } catch (error) {
    return null;
  }
};


export const ADD_BRIGADE_QA = ({name, area}) => {
  try {
    return `mutation {
      brigade(data: {
        ${(name && `name: "${name}"`) || ""}
        ${(area && `area: "${area}"`) || ""}
      }){
        create{
          id
          name
        }
      }
    }
  `
  } catch (error) {
    return null;
  }
};

export const ADD_AREA_QA = ({name}) => {
  try {
    return `mutation {
      area(data: {
        ${(name && `name: "${name}"`) || ""}
      }){
        create{
          id
          name
        }
      }
    }
  `
  } catch (error) {
    return null;
  }
};

export const ADD_TRANSP_QA = ({name, type, number, firm, area}) => {
  try {
    return `mutation {
      transport(data: {
        ${(name && `name: "${name}"`) || ""}
        ${(type && `type: "${type}"`) || ""}
        ${(number && `number: "${number}"`) || ""}
        ${(firm && `firm: "${firm}"`) || ""}
        ${(area && `area: "${area}"`) || ""}
      }){
        create{
          id
          name
          type
          number
          firm
        }
      }
    }
  `
  } catch (error) {
    return null;
  }
};
export const EDIT_TRANSP_QA = ({id, name, type, number, firm, area}) => {
  if(!id) return false;

  try {
    return `mutation {
      transport(data: {
        ${(id && `id: "${id}"`) || ""}
        ${(name !== undefined && `name: "${name}"`) || ""}
        ${(type !== undefined && `type: "${type}"`) || ""}
        ${(number !== undefined && `number: "${number}"`) || ""}
        ${(firm !== undefined && `firm: "${firm}"`) || ""}
        ${(area !== undefined && area !== null && `area: "${area}"`) || ""}
      }){
        update
      }
    }
  `
  } catch (error) {
    return null;
  }
};
export const EDIT_HANDBWT_QA = ({id, description,}) => {
  if(!id) return false;

  try {
    return `mutation{
      hbworkType(data:{
        ${(id && `id: "${id}"`) || ""}
        ${(description !== undefined && `type: "${description}"`) || ""}
        }){
        update
      }
    }
  `
  } catch (error) {
    return null;
  }
};
export const DELETE_TRANSP_QA = ({id}) => {
  if(!id) return null;

  return `mutation {
      transport(data: {
        ${(id && `id: "${id}"`) || ""}
      }){
        delete
      }
    }
  `
};

export const USEREDIT_QA = ({id, fio, password, type}) => {
  return `mutation {
      useredit(user: {
        ${(id && `id: "${id}"`) || ""}
        ${(fio && `fio: "${fio}"`) || ""}
        ${(type && `type: "${type}"`) || ""}
        ${(password && `password: "${password}"`) || ""}
      })
    }
  `
};

export const EDIT_AREA_QA = ({name, id}) => {
  try {
    return `mutation {
      area(data: {
        ${(id && `id: "${id}"`) || ""}
        ${(name && `name: "${name}"`) || ""}
      }){
        update
      }
    }
  `
  } catch (error) {
    return null;
  }
};
export const DELETE_AREA_QA = ({id}) => {
  try {
    return `mutation{
      area(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};
export const DELETE_BRIGADE_QA = ({id}) => {
  try {
    return `mutation{
      brigade(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};
export const EDIT_BRIGADE_QA = ({name, id, area}) => {
  try {
    return `mutation {
      brigade(data: {
        ${(id && `id: "${id}"`) || ""}
        ${(name && `name: "${name}"`) || ""}
        ${(area && `area: "${area}"`) || ""}
      }){
        update
      }
    }
  `
  } catch (error) {
    return null;
  }
};

export const ADD_WORKTYPE_QA = ({link, value, section, preDefValue}) => {
  try {
    return `mutation{
      hbworkType(data:{
        ${(link && `link: "${link}"`) || ""}
        ${(value && `value: "${value}"`) || ""}
        ${(preDefValue && `preDefValue: "${preDefValue}"`) || ""}
        ${(section && `section: "${section}"`) || ""}
        }){
        create{
          id
        }
      }
    }
  `;
  } catch (error) {
    return null;
  } 
};


export const EDIT_WORK_QA = ({ id, complete, remove, completebool, removebool, valueProject, coeffNorm }) => {
  try {
    return `mutation{
        workType(data: {
          ${(id && `id: "${id}"`) || ""}
          ${(complete && `completed: ${completebool}`) || ""}
          ${(remove && `deleted: ${removebool}`) || ""}
          ${(valueProject && `valueProject: "${valueProject}"`) || ""}
          ${(coeffNorm && `coeffNorm: "${coeffNorm}"`) || ""}
        }){
          update
        }
      }
    `;
  } catch (error) {
    return null;
  }
};
export const DELETE_WORK_QA = ({id}) => {
  try {
    return `mutation{
      workType(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};

export const ADD_SECTION_QA = ({value, section}) => {
  try {
    return `mutation{
      sections(data:{
        ${(value && `value: "${value}"`) || ""}
        ${(section && `section: "${section}"`) || ""}
        }){
        create{
          id
        }
      }
    }
  `;
  } catch (error) {
    return null;
  } 
};

export const EDIT_SECTION_QA = ({id, value, section}) => {
  try {
    return `mutation{
      sections(data:{
        ${(id && `id: "${id}"`) || ""}
        ${(value && `value: "${value}"`) || ""}
        ${(section && `section: "${section}"`) || ""}
        }){
        update
      }
    }
  `;
  } catch (error) {
    return null;
  } 
};
export const DELETE_SECTION_QA = ({id}) => {
  try {
    return `mutation{
      sections(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};

export const ADD_UNIT_QA = ({value}) => {
  try {
    return `mutation{
        hbwtunits(data: {
          ${(value && `value: "${value}"`) || ""}
        }){
          create{
            id
          }
        }
      }
    `
  } catch (error) {
    return null;
  }
};

export const EDIT_OBJECT_QA = ({id, name,}) => {
  try {
    return `mutation{
        object(data: {
          ${(id && `id: "${id}"`) || ""}
          ${(name && `name: "${name}"`) || ""}
        }){
          update
        }
      }
    `;
  } catch (error) {
    return null;
  }
};

export const DELETE_OBJECT_QA = ({id}) => {
  try {
    return `mutation{
        object(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};

export const EDIT_CONSTR_QA = ({name, id}) => {
  try {
    return `mutation {
        construction(data: {
          ${(id && `id: "${id}"`) || ""}
          ${(name && `name: "${name}"`) || ""}
        }){
          update
        }
      }`;
  } catch (error) {
    return null;
  }
};


export const DELETE_CONSTR_QA = ({id}) => {
  try {
    return `mutation{
      construction(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};


export const MUTATE_INFO = ({id, name, text, position}) => {
  return `mutation{
      info(data: {
          ${(id && `id: "${id}"`) || ""}
          ${(name && `name: "${name}"`) || ""}
          ${(text && `text: "${text.replace(/\n/g, '\\n').replace(/"/g, '\'')}"`) || ""}
          ${(position && `position: "${position}"`) || ""}
        }){
          mutate
        }
      }
    `;
};

export const DELETE_INFO = ({id}) => {
  return `mutation{
      info(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
};

export const EDIT_WTYPE_QA = ({value, id, link, section, preDefValue}) => {
  try {
    return `
    mutation {
      hbworkType(data: {
        ${(id && `id: "${id}"`) || ""}
        ${(value && `value: "${value}"`) || ""}
        ${(preDefValue && `preDefValue: "${preDefValue}"`) || ""}
        ${(link && `link: "${link}"`) || ""}
        ${(section && `section: "${section}"`) || ""}
      }){
        update
      }
    }
  `;
  } catch (error) {
    return null;
  }
};

export const DELETE_WTYPE_QA = ({id}) => {
  try {
    return `mutation{
      hbworkType(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};

export const ADD_TECHNICS_NORMS_QA = ({area, workType, transport, preDefValue}) => {
  return `mutation{
      transportNorm(data: {
          ${(area && `area: "${area}"`) || ""}
          ${(workType && `workType: "${workType}"`) || ""}
          ${(transport && `transport: [${transport.toString()}]`) || ""}
          ${(preDefValue && `preDefValue: "${preDefValue}"`) || ""}
        }){
          mutate {
            id
            area
            transport
            workType
            preDefValue
          }
        }
      }
    `;
};
export const REM_TECHNICS_NORMS_QA = ({id}) => {
  return `mutation{
      transportNorm(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          remove
        }
      }
    `;
};

export const ADD_TECHNICS_TYPES_QA = ({ id, name }) => {
  return `mutation{
        transportType(
          ${(id && `id: "${id}"`) || ""}
          ${(name && `name: "${name}"`) || ""}
          ){
          mutate
        }
      }
    `;
};

export const DEL_TECHNICS_TYPES_QA = ({ id }) => {
  return `mutation{
        transportType(${(id && `id: "${id}"`) || ""}){
          delete
        }
      }
    `;
};


export const EDIT_UNIT_QA = ({value, id}) => {
  try {
    return `
      mutation {
        hbwtunits(data: {
          ${(id && `id: "${id}"`) || ""}
          ${(value && `value: "${value}"`) || ""}
        }){
          update
        }
      }
    `
  } catch (error) {
    return null;
  }
}
;
export const DELETE_UNIT_QA = ({id}) => {
  try {
    return `mutation{
      hbwtunits(data: {
          ${(id && `id: "${id}"`) || ""}
        }){
          delete
        }
      }
    `;
  } catch (error) {
    return null;
  }
};
export const DELETE_USER_QA = ({id}) => {
  try {
    return `mutation{
      userdelete(id: "${id}")
      }
    `;
  } catch (error) {
    return null;
  }
};

export const ADD_OBJECT_QA = ({name, parent}) => {
  try {
    return `mutation{
      object(data: {
        ${(name && `name: "${name}"`) || ""}
        ${(parent && `parent: "${parent}"`) || ""}
      }){
        create{
          id
          name
        }
      }
    }
  `;
  } catch (error) {
    return null;
  }
};

export const ADD_CONSTRUCTION_QA = ({name, parent}) => {
  try {
    return `mutation {
      construction(data: {
        ${(name && `name: "${name}"`) || ""}
        ${(parent && `parent: "${parent}"`) || ""}
      }){
        create{
          id
          name
        }
      }
    }
  `;
  } catch (error) {
    return null;
  }
};



export const DELETE_FILE = ({id}) => {
  try {
    return `mutation {
      deleteFile(id: "${id}")
    }`;
  } catch (error) {
    return null;
  }
};

export const DAY_CHECK = ({area, start, end, status}) => {
  try {
    return `mutation {
      DayCheck(data: {
        ${(area && `area: "${area}"`) || ""}
        ${(start && `start: "${start}"`) || ""}
        ${(end && `end: "${end}"`) || ""}
        ${(status && `status: ${status}`) || ""}
      }){
        create
      }
    }
  `;
  } catch (error) {
    return null;
  }
};