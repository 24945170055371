/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, {Fragment} from 'react'
import { Link } from "react-router-dom";
import { SVG } from '../../CONSTANTS/Transformers';

const Tile = (props) => {
  const {id, name, type, desc, data, icon, click, bgcolor, color, imgcolor, box, borderless, svg, link} = props;
  const Content = ({id, name, type, desc, data, icon, click, bgcolor, color, imgcolor, box, borderless, svg, link}) => {
    return(
      <Fragment>
        {icon && <div className={"Icon" + ((box && " BoxedIcon") || "") + ((borderless && " NoBorder") || "")} style={/*{background: (imgcolor && imgcolor) || null }*/null}>
          {!svg && <img src={icon || "i.jpg"} alt="" />}
          {svg && 
            SVG()[icon]
          }
        </div>}
        <div className="Name" style={{color: (color && color) || null }}>{name || "Без названия"}</div>
        {desc && <div className="Descr">{desc}</div>}
      </Fragment>
    )
  }

  return (
    <div className="Tile" onClick={()=>click && typeof click === "function" && click({id, name, type, data})} style={{background: (bgcolor && bgcolor) || null }}>
      {
        link ? <Link to={{
          pathname: `/${link.place}/${link.id || ""}`,
          state: { id: link.id }
        }}>
          {<Content {...props} />}
        </Link> : <Content {...props} />
      }
      {imgcolor && <div className={'coloredLine'} style={{width:'100px', background: imgcolor, height: '5px', borderRadius: '2px', margin: '2px auto 0',}}></div>}

    </div>
  )
}

export default Tile
