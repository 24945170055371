import React, { Component } from 'react'
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types'
import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import Loader from '../../../Loader'
import RecursiveViewer from './RecursiveViewer';
import { FetchedSelect, Delete } from './EditorParts';

export default class Editorable extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      editor: null,
      selected: null,
      array: [],
      ok: "",
      listName: "",
      currentParent: null,
      selects: {},
      inputs: {},
      defValue: '',
      clear: null,
      prevState: {},
      startPos: null,
      startParam: null,
      SearchErr: null,
    }
  }
  
  static propTypes = {
    ADD:  PropTypes.func, 
    EDIT: PropTypes.func, 
    name:  PropTypes.string, 
    array: PropTypes.array, 
    refetch: PropTypes.func, 
    arrayProp: PropTypes.string, 
    parentsArray: PropTypes.array, 
    parentName: PropTypes.string, 
    arrayName: PropTypes.string, 
    parentProp: PropTypes.string,
    change: PropTypes.func,
    fetch: PropTypes.func,
    elements: PropTypes.array,
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.array !== prevProps.array) {
      const { array } = this.props;

      this.setState({
        prevState: this.state,
        array: array,
      })
    }
  }

  add = () => {
    const { ADD, refetch, elements } = this.props;
    const { inputs, selects, editor } = this.state;

    if(!elements || !elements.length) return null;
    let DATA = {};
    let RUNS = [];

    elements.forEach(element => {
      if(!element || !element.prop || (!element.add && !editor)) return null;
      if(element.array && element.array.length){
        let E = selects['select'+element.prop];

        if(E){
          DATA[element.prop] = E[element.propName] || E.id;
        }
      } else if(element.type && element.type === 'fetch'){
        let E = selects['select'+element.prop];

        if(E){
          DATA[element.prop] = E[element.propName] || E.id;
        }
      } else {
        let E = inputs['input'+element.prop];

        if(E){
          DATA[element.prop] = E;
        }
      }
      if (element.importantAdd) {
        RUNS.push(element.prop)
      }

    });
    RUNS = RUNS.map(e => {
      if(!DATA[e]) return e;

      return false
    }).filter(e => e);
    if(RUNS.length || !DATA){
      this.setState({
        error: "Необходимо заполнить поля: " + RUNS.join(', '),
      })

      return null;}
    let A = ADD(DATA);

    if(!A) return null; // TODO STATE ERROR 
    QA(A).then(()=>{
      // let DATA = {};

      // /**Work */
      // elements.forEach(e => {
      //   if(!e || !e.prop) return null;
      //   if(e.array && e.array.length){
      //     DATA['select'+e.prop] = null;
      //   } else {
      //     DATA['input'+e.prop] = "";
      //   }
      // });
      // this.setState({...DATA});

      let I = inputs;
      let S = selects;
      
      for (let pr in I) {
        I[pr] = "";
      }
      
      for (let pr in S) {
        S[pr] = null;
      }

      // this.setState({
      //   inputs: I,
      //   selects: S,
      //   clear: true,
      // });

      this.rerender();
      refetch && typeof refetch === 'function' && refetch()
    })

  }
  edit = () => {
    const { EDIT, refetch, elements } = this.props;
    const { inputs, selects, editor, selected } = this.state;

    if(!elements || !elements.length || !selected || !selected.id) return null;
    let DATA = {};
    let RUNS = [];

    elements.forEach(element => {
      if(!element || !element.prop || (!element.edit && editor)) return null;

      if(element.array && element.array.length){
        let E = selects['select'+element.prop];

        if(E && E !== 'непонятно'){
          DATA[element.prop] = E[element.propName] || E.id;
        }
      } else {
        let E = inputs['input'+element.prop];

        if(E && E !== 'непонятно'){
          DATA[element.prop] = E;
        }
      }
      if (element.importantEdit) {
        RUNS.push(element.prop)
      }
    });

    RUNS = RUNS.map(e => {
      if(!DATA[e]) return e;

      return false
    }).filter(e => e);

    if(RUNS.length || !DATA) return null;

    DATA.id = selected.id;
    let A = EDIT(DATA);
    
    if(!A) return null; // TODO STATE ERROR 
    QA(A).then(()=>{
      // let DATA = {};

      // elements.forEach(e => {
      //   if(!e || !e.prop) return null;
      //   if(e.array && e.array.length){
      //     DATA['select'+e.prop] = null;
      //   } else {
      //     DATA['input'+e.prop] = "";
      //   }
      // });
      // this.setState({...DATA});

      let I = inputs;
      let S = selects;
      
      for (let pr in I) {
        I[pr] = "";
      }
      
      for (let pr in S) {
        S[pr] = null;
      }
      this.rerender();
      refetch && typeof refetch === 'function' && refetch()
    })
  }

  delete = () => {
    const {selected} = this.state;
    const { DEL, refetch } = this.props;

    if(selected && selected.id && DEL){

      let A = DEL({id: selected.id});
      
      if(!A) return null;
      QA(A)
    }

    this.setState({
      editor: false,
    });
    this.rerender();
    refetch && typeof refetch === 'function' && refetch()
  }

  selectList = (e) => {
    if( !e ) return null;
    const { elements, EDIT } = this.props;

    if(!EDIT) return null;
    
    if (!elements || !elements.length) return null;

    const {/* selects, */ inputs} = this.state;
    // let S = selects;
    let I = inputs;

    // S['select'+prop] = null;

    elements && elements.length && elements.forEach((el)=>{
      if (el.type === 'input') {
        I['input' + el.prop] =  (e[el.prop] && e[el.prop]) || 'непонятно'

        // if (I.inputvalue) {I.inputvalue =  e.name || e.value || 'непонятно' }
      }
    })

    this.setState({
      editor: true,
      selected: e,
      inputs: I,
    })
  }

  resetSelect = ( prop) => {
    const {selects} = this.state;
    let STATE = selects;

    STATE['select'+prop] = null;
    this.setState({selects: STATE })
  }

  filter = (e) => {
    const SARR = this.state.array || [];
    const PARR = this.props.array || [];

    this.setState({
      SearchErr: null,
    })

    if(!e || !e.target || !e.target.value || !SARR || !SARR.length) {
      this.setState({
        array: [...PARR],
        // SearchErr: false,
      });

      return true;
    }
    const V = e.target.value.toLowerCase();
    let Arr = PARR && PARR.length && PARR.filter(e => {
      if (e) {
        const regexp = new RegExp(V, "i");
        
        if(e.value && e.value.toLowerCase().match(regexp)) {
          
          return e;
        } 
        if(e.name && e.name.toLowerCase().match(regexp)){
          return e;
        }

        return false;
      }
      
      return false;
    });

    this.setState({
      array: (Arr && Arr.length && Arr) || PARR,
      SearchErr: Arr.length || 0,
    })
  }

  handleChange = (e) => {
    if(!e || !e.target || !e.target.name) return null;
    const { inputs } = this.state;
    let STATE = inputs;

    STATE[e.target.name] = e.target.value || "";
    this.setState({inputs: STATE })

    // this.setState({
    //   [e.target.name]: e.target.value,
    // })

  }

  selectChange = (parent, prop) => {
    if(!parent || !prop) return null;
    const {selects} = this.state;
    let STATE = selects;

    STATE['select'+prop] = parent;
    this.setState({selects: STATE })
  }

  componentWillMount(){
    this.revalue()
  }

  revalue = () => {
    const {elements} = this.props;

    elements && elements.length && elements.forEach(e => {
      
      if (e.prop && e.type && e.type === 'input' && e.value) {
        const DV = (typeof e.value === 'function' && e.value()) || e.value.toString();
        const E = {
          target: {
            value: DV,
            name: 'input'+e.prop
          }
        }

        this.handleChange(E);
      }
    });
  }

  rerender = () => {

    this.setState({
      editor: null,
      selected: null,
      // array: [],
      ok: "",
      listName: "",
      currentParent: null,
      selects: {},
      inputs: {},
      defValue: '',
      prevState: {},
      clear: true,
      prevFetch: {},
    });

    this.revalue();

    setTimeout(() => {
      this.setState({
        clear: false,
      })
    }, 300);
  }

  selectRecursive = (e, next, last, type) => {
    
    last && this.selectList(e)
    e && next && next(e)
  }

  render() {
    const {/* ADD, EDIT, parentsArray, parentName, parents, parentProp, refetch, */ name, arrayName, arrayProp, elements, children, lister, recursive, params, } = this.props;
    const {editor, /* id, ok, listName, currentParent, */ selected, inputs, selects, defValue, error, clear, array, startParam, startPos, SearchErr } = this.state;

    if (clear) return(<Loader />);

    let recursives = [];

    if(recursive && recursive.length){
      recursives = recursive.map((e,i)=>{
        let E = e;
        // E.onSelect = this.selectRecursive;
        // E.onNext = this.selectRecursive;


        return E;
      })
    }

    return (
      <div className="ROW">
        <div className="COL marginChilds">
          <h3>{editor ? "Изменить" : "Создать"} {name} {editor ? selected.name || selected.value : ""}</h3>
          {error && <div>
            <span className="error">{error}</span>
          </div>}
          {
            elements && elements.length && elements.map((e,i)=>{
              if (!e.prop || (!e.edit && editor) || (!e.add && !editor)) return null;

              if(e.type && e.type === 'fetch' && e.fetch && e.fetch.length) {
                return(
                  <FetchedSelect
                    key={'selectAdd'+i}
                    fetch={e.fetch}
                    run={null}
                    prop={e.prop}
                    value={null}
                    name={'select'+e.prop}
                    selects={null}
                    change={this.selectChange}
                    placeholder={e.name}
                  />)
              }
              if(e.array && e.array.length) {
                return(
                  <div key={'selectAdd'+i}>
                    <p>
                      {e.name}
                    </p>
                    <SelectSearch
                      name={'select'+e.prop}
                      value={selects && selects['select'+e.prop] && selects['select'+e.prop].value}
                      options={e.array}
                      onChange={(parent)=>this.selectChange(parent, e.prop)}
                      placeholder={e.name} />
                  </div>) 
              }
              
              if(e.type === 'input'){
                // const DV = e.value && typeof e.value === 'function' && e.value();
                return(
                  <div className="input-group" key={'inputsAdd'+i}>
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="btnGroupAddon">Добавить {e.name}</div>
                    </div>
                    <input
                      type={e.inputType || "text"}
                      name={'input'+e.prop}
                      className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon"
                      placeholder={e.name}
                      // defaultValue={editor ? selected.name || selected.value : ""}
                      value={inputs['input'+e.prop] || defValue}
                      onChange={this.handleChange}
                    />
                  </div>
                )
              }

              return <Loader key={"loader-selects"+i}/>;
            })
          }
          <button type="submit" className="btn btn-dark btn-lg" onClick={editor ? this.edit : this.add}>{editor ? "Изменить" : "Добавить"}</button>
          {editor ? <button type="cancel" className="btn btn-light" onClick={this.rerender}>Отмена</button> : null}
          {editor ? <Delete name="Удалить" agreeText="Вы уверены?" onClick={this.delete}/> : null}
          {/* {editor ? <button type="cancel" className="btn btn-light" onClick={()=>this.setState({editor: null, selected: null})}>Отмена</button> : null} */}
        </div>

        <div className="COL">
          {lister && <div className="Lists">
            <div className="header">
              <h3>{arrayName || name}</h3>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center'}}>
                <input type="search" onChange={this.filter} placeholder={"Поиск..."}/>
                {(SearchErr || SearchErr === 0) && <span className="Err">Найдено {SearchErr}</span>}
              </div>
            </div>
            <div className="inner">
              {(array && array.length &&
                array.sort((a,b)=>{
                  if (a.name < b.name ) {
                    return -1;
                  }
                  if (a.name > b.name ) {
                    return 1;
                  }
            
                  return 0;
                })
                  .sort((a,b)=>{
                    if (a.parents < b.parents ) {
                      return -1;
                    }
                    if (a.parents > b.parents ) {
                      return 1;
                    }
            
                    return 0;
                  })
                  // .sort((a,b)=>{
                  //   if (a.parents[0] && b.parents[0] && a.parents[0] < b.parents[0] ) {
                  //     return -1;
                  //   }
                  //   if (a.parents[0] && b.parents[0] && a.parents[0] > b.parents[0] ) {
                  //     return 1;
                  //   }
          
                //   return 0;
                // })
                // .sort((a,b)=>{
                //   if (a.parents[1] && b.parents[1] && a.parents[1] < b.parents[1] ) {
                //     return -1;
                //   }
                //   if (a.parents[1] && b.parents[1] && a.parents[1] > b.parents[1] ) {
                //     return 1;
                //   }
          
                  //   return 0;
                  // })
                  .map((e,i)=>{
                    const Parents = (e.parents && typeof e.parents === 'object' && e.parents.length && e.parents) || [e.parentName];

                    return(
                      <div className={"ListTile"+((selected && selected.id === e.id && " a") || "")} key={'hanbooklist-'+i} onClick={()=>this.selectList(e)}>
                        <span className="Name">{e[arrayProp] || e.name || e.value || "нет названия"}</span>
                        {/* {e.parentName && <span className="ParentName">{e.parentName}</span>} */}
                        {Parents && Parents.length && <span className="ParentName">{Parents.join(' > ')}</span>}
                        {/* {e.param && <span className="ParentName param">{ e.param }</span>} */}
                        <div>
                          {params && params.length &&
                              params.map((p,i)=>{

                                return(e[p] && <span key={"param"+i} className="param">{ e[p] }</span> || null) 
                              })
                          }
                        </div>

                      </div>
                    )
                  })) || <Loader />
              }
            </div>
          </div> || null}
          {
            recursives && recursives.length && <RecursiveViewer selectRecursive={(e, next, last)=>this.selectRecursive(e, next, last, null)} recursive={recursives} startPos={startPos} startParam={startParam} /> || null
          }
          {children || null}
        </div>
      </div>
    )
  }
}

