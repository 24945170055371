import React, { Component } from 'react'
import AdminScreen from '../COMPONENTS/ADMIN/AdminScreen';

export default class Admin extends Component {

  render() {
    return (
      <div className={'Admin'}>
        <AdminScreen />
      </div>
    )
  }
}
