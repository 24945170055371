import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo';
import Tile from '../COMPONENTS/PARTS/Tile';
import { GET_AREAS } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Loader from '../../Loader';

export default class Areas extends Component {
  static propTypes = {
    isReport: PropTypes.number
  }
  render() {
    const place = ((this.props.match.params.c) && this.props.match.params.c) || 'areas';

    return (
      <div className={'RootScreen Areas'}>
        <Query query={GET_AREAS}>
          {
            ({ data, loading, error }) => {
              if (loading) return <Loader />;
              if (error) return 'error';
              if (data){
                const Keys = Object.keys(data);

                if(Keys[0] === ("errors" || "error") ){
                  return <div className="Message">{data[Keys[0]].message}</div>
                }

                return (data && data[Keys[0]] && data[Keys[0]].map((e, i) => <Tile id={e.id} key={"key-"+i} name={e.name} type={e.__typename} desc={e.description} link={{id: e.id, place: place}} icon={'area'} svg borderless box/> )) || null
              }

              return null;
            }
          }
        </Query>
      </div>
    )
  }
}
