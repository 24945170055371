
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import { ToFloat } from '../../CONSTANTS/operations';

class EditTransport extends Component {
  constructor() {
    super();
    this.state = {
      modal: true,
      curTransports: [],
      alert: false
    };
  }
  
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]).isRequired,
    transports:  PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    dayId: PropTypes.string,
    date: PropTypes.string.isRequired,
    comment: PropTypes.string,
  }

  static defaultProps = {
    value: ""
  }

  componentDidMount() {
    const { value } = this.props

    if (value && value.length > 0) 
      this.setState({
        curTransports: [...value]
      })
  }

  updateFunc = async () => {
    const { update, onUpdate } = this.props

    await update()
    await onUpdate(false)
    
    // this.setState({modal: !modal});
  }
  
  saveCell = async () => {
    const { onUpdate } = this.props
    const { curTransports } = this.state

    await onUpdate(curTransports)
  }

  changeHours = (value, index) => {
    const { curTransports } = this.state
    let val = ""

    if (/^[0-9]+\.?\d*$/.test(value)) val = value
    else if (value !== "" && !isNaN(ToFloat(value))) val = ToFloat(value)
    if (val > 24) val = "24"
    const array = curTransports

    array[index].hours = val
    
    
    this.setState({
      curTransports: array
    })
  }

  changeTransport = (value, index) => {
    const { curTransports } = this.state
    const array = curTransports
    let alert = false

    if (array.findIndex(x => x.id === value) === 0) alert = true

    array[index].id = value
    this.setState({
      curTransports: array,
      alert
    })
  }

  deleteTransport = (index) => {
    const { curTransports } = this.state
    const array = curTransports

    this.setState({
      curTransports: array.filter((_, i) => i !== index)
    })
  }

  addTransport = () => {
    const { curTransports } = this.state
    const array = curTransports
    const data = { id: "", hours: "0" }

    this.setState({
      curTransports: [...array, data]
    })
  }
  
  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, date, update, value, onUpdate, comment, transports, dayId, ...rest } = this.props;
    const { curTransports, modal, alert } = this.state

    console.warn("props", this.props)

    return (
      <Modal
        size="lg"
        style={{color: "#fff9"}}
        show={modal}
        onHide={this.saveCell} //FIXME: надо тупо закрыть модалку!!
        aria-labelledby="example-modal-sizes-title-lg"
      >                    
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
              Редактировать технику
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-2">
            {curTransports && curTransports.length > 0 && curTransports.map((e, i) => {

              return ( 
                <div className="input-group-append" key={i}>
                  <span className="input-group-text" style={{height: "50px", width: "90px"}}>Техника</span>
                  <SelectSearch className="select-search-box" key={i} value={e.id} options={transports} onChange={(v) => { this.changeTransport(v.value, i) }} placeholder="Техника" />
                  <span className="input-group-text" style={{height: "50px", width: "70px"}}>Часы</span>
                  <input
                    type="text"
                    className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon"
                    placeholder="кол-во часов"
                    style={{height: "50px", width: "60px"}}
                    value={e.hours}
                    onChange={(v)=>this.changeHours(v.target.value, i)}
                  />
                  <button className="btn btn-sm btn-secondary" type="button" style={{height: "50px", width: "100px"}} onClick={()=>this.deleteTransport(i)}>Удалить</button>
                </div> )
            })}
          </div>
          <button type="button" className="btn btn-sm btn-primary" onClick={this.addTransport}>Добавить технику</button>
        </Modal.Body>
        <Modal.Footer>
          {alert && <div className="alert alert-danger" role="alert">Уже есть такой транспорт!!</div>}
          {!alert && <button type="button" className="btn btn-success" onClick={this.saveCell}>Сохранить</button>}
        </Modal.Footer>
      </Modal>
  
    )
  }
}

export default EditTransport