import React, { Component, Fragment } from 'react'

import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { ADD_SECTION_QA, EDIT_SECTION_QA, DELETE_SECTION_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { SECT_PARENTS_QA, SECT_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Editorable from './Editorable.third.1';
// import RecursiveViewer from './RecursiveViewer';
import { RenderEditor, FetchedSelect } from './EditorParts';
import AdminGridEditor from './AdminGridEditor';

export default class SectionMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
      sectionParents: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => await QA(SECT_QA).then((data => {
    data && this.setState({
      sections: data.sections,
    })
  }));
  // QA(SECT_PARENTS_QA).then((data => {
  //   data && this.setState({
  //     sectionParents: data.sections,
  //   })
  // }));
  
  selectUnit = (unit) => {
    this.setState({
      unit: unit.target.value,
    })
  }
  selectList = (id) => {
    if( !id ) return null;

    // edit.value = name;
    this.setState({
      edit: true,
      id,
    })
  }

  change = (inp) => {
    const { loadedHB } = this.state;
    let Filter = loadedHB.filter(e => (e.value.indexOf(inp.target.value) >= 0 && e) || null);

    this.setState({
      worktypes: Filter || loadedHB,
    })
  }

  render() {

    const { sections } = this.state;

    const ELEMENTS = [
      {
        name: 'Название',
        type: 'input',
        // add: true,
        // edit: true,
        important: true,
        // importantEdit: true,
        prop: 'value',
      },
      {
        name: 'Разделы',
        type: 'fetch',
        // add: true,
        // edit: false,
        important: false,
        // importantEdit: false,
        prop: 'section',
        onRender: (props) => <FetchedSelect {...props} change={(target)=>props.set(target.value)} fetch = {[
          { query: SECT_PARENTS_QA, propQuery: 'id', selectedParam: 'id', graphname: 'section', use: true, name: 'Раздел' },
        ]} />
      },
      // {
      //   name: 'Разделы',
      //   value: null,
      //   type: null,
      //   add: true,
      //   edit: false,
      //   importantAdd: false,
      //   importantEdit: false,
      //   prop: 'section',
      //   propName: null,
      //   array: sectionParents,
      // },
    ];
    
    // const recursive = [
    //   {
    //     name: "Разделы",
    //     query: `{
    //       sections(top: true){
    //       id
    //       value
    //       section
    //     }}`,
    //     onNext: 'next' || (()=>true),
    //     onSelectProp: 'id',
    //     arrayName: 'top',
    //     queryValue: 'id',
    //     queryProp: 'id',
    //     showProps: { header:'value', somethin: 'name' },
    //   },
    //   {
    //     name: "Группы",
    //     query: ({id})=>`{sections(id: "${id}"){
    //       id
    //       value
    //     }}`,
    //     queryValue: 'id',
    //     queryProp: 'id',
    //     onSelect: ()=>true,
    //     arrayName: 'top',
    //   },
    // ];

    const headers = [
      {
        name: '',
        prop: 'id',
        maxWidth: '30px',
      },
      {
        name: 'Название группы/раздела',
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_SECTION_QA} Cell={Cell} prop={Col && Col.prop} Row={Row} id={Row && Row.id} />,
        prop: 'value',
        renderClass: ({Cell, Row, Col})=> {
          if(!Row.parentName) {
            return 'Razdel'
          }

          return 'Gruppa'
        },
        compareProps: ['value'],
      },
      {
        name: 'Входит в раздел (если пусто - является разделом)',
        prop: 'parentName',
        renderClass: ({Cell, Row, Col})=> {
          if(!Row.parentName) {
            return 'RazdelParent'
          }
          
          return 'GruppaParent';
        },
        select: true,
        compareProps: ['parentName'],
      },
    ];
    
    return (
      <Fragment>
        <AdminGridEditor
          ADD={ADD_SECTION_QA}
          EDIT={EDIT_SECTION_QA}
          DEL={DELETE_SECTION_QA}
          name={'Разделы'}
          arrayName={'Разделы'}
          array={sections}
          answerName={'sections'}
          refetch={this.GetData}
          headers={headers}
          elements={ELEMENTS}
          searchKeys={['section','value','parent','parentName','name']}
        />
        {/* <Editorable
          ADD={ADD_SECTION_QA}
          EDIT={EDIT_SECTION_QA}
          DEL={DELETE_SECTION_QA}
          elements={ELEMENTS}
          name={'Разделы'}
          arrayName={'Разделы'}
          array={sections}
          searchKeys={['section','value','parent','parentName']}
          refetch={this.GetData}
          tableHeaders={headers}
          // lister
        />*/}

        <div className="Help">
          <div className="Header">
            Создание <b>Раздела</b> или <b>Группы</b> для <b>Типов Работ</b>
          </div>
          <div className="Text">
            <p>
            Чтобы создать новый <b>Раздел</b> укажите <b>название</b>.
            </p>
            <p>
            Чтобы создать новую <b>Группу</b> укажиите <b>название</b> группы и выберите один из имеющихся <b>Разделов</b>
            </p>
          </div>
        </div>
      </Fragment>
    )
  }
}
