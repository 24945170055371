import React, { Component } from 'react'
import { QA } from '../../CONSTANTS';
import { ADD_AREA_QA, EDIT_AREA_QA, DELETE_AREA_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { AREAS_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Editorable from './Editorable';

export default class AreaMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => {
    QA(AREAS_QA).then((data => {
      data && this.setState({
        areas: data.areas,
      })
    }));
  }
  
  selectList = (id) => {
    if( !id ) return null;

    // edit.value = name;
    this.setState({
      edit: true,
      id,
    })
  }

  change = (inp) => {
    const { loadedHB } = this.state;
    let Filter = loadedHB.filter(e => (e.value.indexOf(inp.target.value) >= 0 && e) || null);

    this.setState({
      worktypes: Filter || loadedHB,
    })
  }

  render() {
    const {areas} = this.state;
    const ELEMENTS = [
      {
        name: 'Название',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        importantAdd: true,
        importantEdit: true,
        prop: 'name',
        propName: null,
        array: [],
      },
    ];
    
    return (
      <Editorable
        ADD={ADD_AREA_QA}
        EDIT={EDIT_AREA_QA}
        DEL={DELETE_AREA_QA}
        elements={ELEMENTS}
        name={'Площадку'}
        arrayName={'Площадки'}
        array={areas}
        refetch={this.GetData}
        lister
        searchKeys={['type','firm','area','name','number']}
      />
    )
  }
}

