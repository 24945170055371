import React from 'react'


export default function Loader({wh}) {
  const A = Math.floor(Math.random() * Math.floor(3));

  return(
    <div className={"LOADER"} style={{width: '100%',maxWidth: wh || '80px',height: '100%',maxHeight: wh || '80px', margin: 'auto', overflow: 'hidden', zIndex: '9999',
      // background: 'rgba(0,0,0,0.2)'
    }}>
      {(!A || A === 0) && 
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" >
                <rect fill="#ff7c81" x="21.5" y="21.5" width="25" height="25" rx="3" ry="3">
                  <animate attributeName="x" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="-1.375s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="-1s" repeatCount="indefinite"></animate>
                </rect>
                <rect fill="#ffec58" x="21.5" y="53.5" width="25" height="25" rx="3" ry="3">
                  <animate attributeName="x" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="-0.875s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="-0.5s" repeatCount="indefinite"></animate>
                </rect>
                <rect fill="#7cd7ff" x="53.5" y="42.919" width="25" height="25" rx="3" ry="3">
                  <animate attributeName="x" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="-0.375s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" calcMode="linear" values="21.5;53.5;53.5;53.5;53.5;21.5;21.5;21.5;21.5" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="1.5" begin="0s" repeatCount="indefinite"></animate>
                </rect>
              </svg> || null
      }
      {A &&
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="lds-gooeyring" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" style={{animationPlayState:"running",animationDelay:"0s",background:"0 0"}}>
          <defs style={{animationPlayState:"running",animationDelay:"0s"}}>
            <filter id="a" width="300%" height="300%" x="-100%" y="-100%" colorInterpolationFilters="sRGB" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <feGaussianBlur in="SourceGraphic" stdDeviation="4" style={{animationPlayState:"running",animationDelay:"0s"}}/>
              <feComponentTransfer result="cutoff" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <feFuncA intercept="-5" slope="10" type="linear"/>
              </feComponentTransfer>
            </filter>
          </defs>
          <g filter="url(#a)" style={{animationPlayState:"running",animationDelay:"0s"}} transform="translate(50 50)">
            <g transform="rotate(258)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="10.53" fill="#fcb711" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-4s" dur="4s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="0s" dur="4s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
            <g transform="rotate(276)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="7.6" fill="#f37021" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-3.35s" dur="2s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="-0.66s" dur="2s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
            <g transform="rotate(54)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="8.4" fill="#cc004c" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-2.66s" dur="1.33s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="-1.33s" dur="1.33s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
            <g transform="rotate(312)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="8.13" fill="#6460aa" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-2s" dur="1s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="-2s" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
            <g transform="rotate(330)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="10" fill="#0089d0" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-1.33s" dur="0.8s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="-2.66s" dur="0.8s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
            <g transform="rotate(108)" style={{animationPlayState:"running",animationDelay:"0s"}}>
              <circle cx="25" r="10.8" fill="#0db14b" style={{animationPlayState:"running",animationDelay:"0s"}}>
                <animate attributeName="r" begin="-0.66s" dur="0.66s" keyTimes="0;0.5;1" repeatCount="indefinite" values="6;14;6"/>
              </circle>
              <animateTransform attributeName="transform" begin="-3.35s" dur="0.66s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0;360"/>
            </g>
          </g>
        </svg>
      }
    </div>
  )
}
