import React from 'react'
import { HoverCell } from '../../CONSTANTS/Transformers';

export const transportHours = (cell, row, rowIndex, formatExtraData) => {
  return (
    <div className="tableHeader">
      {cell && cell.length && cell.map((e, i, arr) => 
        <div key={e.id +'-'+ i}> 
          {formatExtraData.transports.find(x => x.value === e.id) ? formatExtraData.transports.find(x => x.value === e.id).name : "удаленная спецтехника"} - {e.hours} ч.
          {(arr.length - 1 !== i) && <div className="dropdown-divider" />}
        </div>
      )}
    </div>
  );
}

export const transportOnly = (cell) => {
  return (
    <div className="tableHeader">
      {cell && cell.length && cell.map((e, i, arr) => 
        <div key={e.id +'-'+ i}> 
          
          {HoverCell(e.name)}
          {/* <span style={{
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>{e.name}</span> */}
          {(arr.length - 1 !== i) && <div className="dropdown-divider" />}
        </div>
      )}
    </div>
  );
}

export const transportHoursOnly = (cell) => {
  // console.warn("cc", cell);
  
  // let transports = []

  // cell && cell.length && cell.map(e => {
  //   const find = formatExtraData.transports.find(x => x.value === e.id)

  //   const params = {
  //     id: e.id,
  //     type: find ? find.type : "неизвестно",
  //     hours: e.hours,
  //   }

  //   transports = [...transports, params]
  // })

  // transports.sort(function(a, b) {          
  //   if (a.type === b.type) {
  //     return b.id > a.id ? 1 : -1;
  //   }

  //   return a.type > b.type ? 1 : -1;
  // })

  return (
    <div className="tableHeader">
      {cell && cell.length && cell.map((e, i, arr) => 
        <div key={e.id +'-'+ i}> 
          {e.hours}
          {(arr.length - 1 !== i) && <div className="dropdown-divider" />}
        </div>
      )}
    </div>
  );
}

export const transportNorms = (cell) => {
  
  return (
    <div className="tableHeader">
      {cell && cell.length && cell.map((e, i, arr) => 
        <div key={e.id +'-'+ i}> 
          {e.norm ? <span style={e.norm <-10 ? { backgroundColor: 'rgba(194, 41, 41, 0.1)'} : {}}>{parseFloat(e.norm).toFixed(2)}</span> : (arr.length - 1 !== i && arr[i+1].type !== e.type ? "-" : <br/>)}
          {arr.length - 1 !== i /* && arr[i+1].type !== e.type */ && <div className="dropdown-divider" style={arr[i+1].type === e.type ? {borderTop: "1px solid transparent"} : {}}/>}
        </div>
      )}
    </div>
  );
}

