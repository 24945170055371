import React, { Component, useState } from 'react'
import { QA } from '../../CONSTANTS';
import { ADD_TRANSP_QA, EDIT_TRANSP_QA, DELETE_TRANSP_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { TRANSP_QA, AREAS_QA, TRANSP_TYPES_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Editorable from './Editorable.third.1';
import { RenderEditor, RenderEditorSelect, RenderAddSelect, RenderAddFetchSelect } from './EditorParts';
import AdminGridEditor from './AdminGridEditor';

export default class Transport extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      transport: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => {
    QA(TRANSP_QA).then((data => {
      data && this.setState({
        transports: data.transports,
        areas: data.areas,
      })
    }));
    QA(TRANSP_TYPES_QA).then((data => {
      data && this.setState({
        transportTypes: data.transportTypes,
      })
    }));
  }
  
  selectList = (id) => {
    if( !id ) return null;

    this.setState({
      edit: true,
      id,
    })
  }

  change = (inp) => {
    const { loadedHB } = this.state;
    let Filter = loadedHB.filter(e => (e.value.indexOf(inp.target.value) >= 0 && e) || null);

    this.setState({
      worktypes: Filter || loadedHB,
    })
  }

  render() {
    const {transports, areas} = this.state;
    const ELEMENTS = [
      {
        name: 'Тип',
        value: null,
        type: 'fetch', // Тип компонента - select с запросом к серверу
        add: true,
        edit: true,
        importantAdd: true,
        importantEdit: false,
        prop: 'type',
        propName: null,  // какой Prop выбрать в конечном селекте иначе Select.value или ID
        array: null,
        fetch: [
          {
            query: TRANSP_TYPES_QA,
            selectedParam: null, // какой Prop Value выбрать в селекте как параметр следующего селекта
            propQuery: 'type', // куда записать выбранный Prop
            graphname: 'transportTypes', // Название объекта в data, иначе первый попавшийся, который может быть message при некритичных ошибках
            use: true, // Закончить выбор после этого селекта
            name: 'Тип', // Название
            help: 'Выберите тип' // Заголовок
          },
        ],
      },
      {
        name: 'Владелец',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        importantAdd: false,
        importantEdit: false,
        prop: 'firm',
        array: [],
      },
      {
        name: 'Информация',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        importantAdd: true,
        importantEdit: false,
        prop: 'name',
        array: [],
      },
      {
        name: 'Гос Номер',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        importantAdd: false,
        importantEdit: false,
        prop: 'number',
        array: [],
      },
      {
        name: 'Площадка',
        type: 'fetch',
        add: true,
        edit: false,
        importantAdd: true,
        importantEdit: false,
        prop: 'area',
        fetch: [
          { query: AREAS_QA, propQuery: 'area', selectedParam: 'id', graphname: 'areas', use: true, name: 'Площадка', help: 'Выберите Площадку' },
        ],
      },
      // {
      //   name: 'Площадка',
      //   value: null,
      //   type: null,
      //   add: true,
      //   edit: true,
      //   importantAdd: true,
      //   importantEdit: true,
      //   prop: 'area',
      //   propName: 'id',
      //   array: [...areas],
      // },
    ];
    const Elements = [ // Поля для создания нового объекта таблицы
      {
        name: 'Тип', // название
        value: null, // дефолтное значение
        type: 'fetch', // Тип компонента - select с запросом к серверу, input, select, number, float
        important: true, // обязательное поле для создания
        prop: 'type', // название проперти для запиздючивания его в mutation, так он будет называться
        propName: null,  // какой Prop выбрать в конечном селекте иначе Select.value или ID
        array: null, // готовый массив из которого надо выбрать селектом
        onRender: (props)=>RenderAddSelect({...props, /*propName: 'type',*/ array: this.state.transportTypes, }), // заменить рендер
      },
      {
        name: 'Владелец',
        type: 'input',
        important: false,
        prop: 'firm',
      },
      {
        name: 'Информация',
        type: 'input',
        important: true,
        prop: 'name',
      },
      {
        name: 'Гос Номер',
        type: 'input',
        important: false,
        prop: 'number',
      },
      {
        name: 'Площадка',
        type: 'fetch',
        important: true,
        prop: 'area',
        onRender: (props)=>RenderAddFetchSelect({...props, /*propName: 'type',*/ fetch: AREAS_QA, fetchName: 'areas' }), // заменить рендер
      },
    ];

    const headers = [
      {
        name: '',
        prop: 'id',
        maxWidth: '30px',
        // onRender: ({Cell, Row, Col, CheckBoxSelect}) => <input type="checkbox" name="fixedFilter" onChange={(el)=>{CheckBoxSelect && typeof CheckBoxSelect === 'function' && CheckBoxSelect(Row.id, DELETE_TRANSP_QA)}} />
      },
      {
        name: 'Тип',
        prop: 'type',
        select: true,
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_TRANSP_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        compareProps: ['type']
      },
      {
        name: 'Владелец',
        prop: 'firm',
        select: true,
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_TRANSP_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        compareProps: ['firm']
      },
      {
        name: 'Площадка',
        onRender: ({Cell, Row, Col})=><RenderEditorSelect Col={Col} Row={Row} query={EDIT_TRANSP_QA} selectProp={'id'} selectArray={areas} selectArrayName={'areas'} selectQuery={AREAS_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        prop: 'area',
      },
      {
        name: 'Информация',
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_TRANSP_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        onClick: null,
        onEdit: null,
        editCell: null,
        onSelect: null,
        prop: 'name',
        select: true,
        compareProps: ['name']
      },
      {
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_TRANSP_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        name: 'Гос Номер',
        prop: 'number',
      },
    ];


    

    return(<AdminGridEditor
      // GET={TRANSP_QA}
      ADD={ADD_TRANSP_QA}
      EDIT={EDIT_TRANSP_QA}
      DEL={DELETE_TRANSP_QA}
      name={'Спецтехника'}
      arrayName={'Спецтехника'}
      array={transports}
      answerName={'transport'}
      refetch={this.GetData}
      headers={headers}
      elements={Elements}
      searchKeys={['type','firm','area','name','number']}
    />)
    
    return (
      <Editorable
        ADD={ADD_TRANSP_QA}
        EDIT={EDIT_TRANSP_QA}
        DEL={DELETE_TRANSP_QA}

        elements={ELEMENTS}
        name={'Спецтехника'}
        arrayName={'Спецтехника'}
        array={transports}
        tableHeaders={headers}
        refetch={this.GetData}
        searchKeys={['type','firm','area','name','number']}
      />
    )
  }
}

