import React, { /*Component,*/ useState, useEffect,  } from 'react'
import { /*Switch, Redirect,*/ withRouter } from 'react-router'
// import PropTypes from 'prop-types';
import checkUserRights from '../TABLEBOOTSTRAP/checkUserRights';
import Areas from '../../ROUTS/Areas';
import Area from '../../ROUTS/Area';
import AreaTransport from '../../ROUTS/AreaTransport';
import TransportDays from '../../ROUTS/TransportDays';
import Admin from '../../ROUTS/Admin';
import HandBook from '../HANDBOOK/Handbook';
import RArea from '../../ROUTS/rArea';
import RGrArea from '../../ROUTS/rGrArea';
import RGrAreaMonth from '../../ROUTS/rGrArea.month';
import BrigadeDays from '../../ROUTS/BrigadeDays';
import InfoTraining from '../InfoTraining/InfoTraining';
import Test from '../Test';
import RTrArea from '../../ROUTS/rTrArea';
import Gantt from '../../ROUTS/Gantt';
import Profile from '../Profile';
import CSVTransport from '../../ROUTS/CSVTransport';
import CSVBrigades from '../../ROUTS/CSVBrigades';


const _Comps = (props) => ({
  profile: <Profile {...props} />,

  areas: <Areas {...props} />,
  report: <Areas {...props} />,
  report2: <Areas {...props} />,
  report3: <Areas {...props} />,
  areatr: <Areas {...props} />,
  trreport1: <Areas {...props} />,
  brigadedays: <Areas {...props} />,
  rtrarea: <Areas {...props} />,
  gantt: <Areas {...props} />,
  csvtr: <Areas {...props} />,
  csvppl: <Areas {...props} />,
  handbook: <HandBook {...props} />,
  admin: <Admin {...props} />,
  help: <InfoTraining {...props} />,
  test: <Test {...props} />,
});

const _CompsID = (props) => ({
  areas: <Area {...props} />,
  report: <RArea {...props} />,
  report2: <RGrArea {...props} />,
  report3: <RGrAreaMonth {...props} />,
  areatr: <AreaTransport {...props} />,
  rtrarea: <RTrArea {...props} />,
  gantt: <Gantt {...props} />,
  csvtr: <CSVTransport {...props} />,
  csvppl: <CSVBrigades {...props} />,
  trreport1: <TransportDays {...props} />,
  brigadedays: <BrigadeDays {...props} />,
});

const RightsChecker = (props) => {
  const {/*children, */location, match} = props;
  const [navs, setNavs] = useState([]);
  const [type, setType] = useState(null);
  const [path, setPath] = useState(null);


  const getNavs = async () => {
    if(type && navs && navs.length && path) return null;

    const Path = location && location.pathname && location.pathname.split('/').filter(e=>e);

    Path && Path !== path && setPath(Path[0]);
  
    if(!type){
      const UNav = await checkUserRights();
      let Navs = UNav && UNav.navs && UNav.navs.length && UNav.navs || null;

      Navs && Navs !== navs && setNavs(Navs);
      UNav && UNav.type !== type && setType(UNav.type);
    }
  }

  useEffect(()=>{
    (!navs || !type) && getNavs()
  }, [path, match]);
 
  if(
    (
      match.params.id && match.params.c
      && type === 'monster'
    )
    ||
    (match.params.id && match.params.c
    &&
    (navs && navs.length && navs.find(nav => nav.name === match.params.c)))
  ){
    
    return (_CompsID(props)[match.params.c] || 'null')
  }
  if(
    (
      match.params.c
      && type === 'monster'
    )
    ||
    (match.params.c
      &&
      (navs && navs.length && navs.find(nav => nav.name === match.params.c)))
  ){

    return (_Comps(props)[match.params.c] || 'null')
  }

  return null;
}

export default withRouter(RightsChecker);