import React, { Component } from "react";
import PropTypes from 'prop-types'
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import { dayUpdate, EDIT_WORK_QA, dayBrigadeUpdate } from "../../GQL/SERVER/MUTATOR/ServerMutators";
import { QA } from '../../CONSTANTS';
import Confirmation from "../PARTS/Confirmation";

import "../../CSS/THIRDPARTY/bootstrap.min.css";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

// eslint-disable-next-line react/prop-types
const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <div className="btn-group" role="group">
    {
      options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;

        return (
          <button
            key={ option.text }
            type="button"
            onClick={ () => onSizePerPageChange(option.page) }
            className={ `btn ${isSelect ? 'btn-success' : 'btn-secondary'}` }
          >
            { option.text }
          </button>
        );
      }) || 'size'
    }
  </div>
);

const pageOptions = {
  sizePerPageRenderer,
  sizePerPageList: [{
    text: '50', value: 50
  }, {
    text: '100', value: 100
  }, {
    text: '200', value: 200
  }, {
    text: '300', value: 300
  }, {    
    text: 'All', value: 10000000
  }] 
};


const workersStyle = (row) => {
  const style = {};

  style.fontSize = '12px';

  if (row.completed) {
    style.backgroundColor = 'rgba(49, 170, 59, 0.1)';
    style.fontWeight = 'bold';
    style.color = 'black';
  } 

  if (row.deleted || parseInt(row.valueAllDiff) < -10 || parseInt(row.workAllDiff) < -10 ) {
    style.backgroundColor = 'rgba(194, 41, 41, 0.1)';
    style.fontWeight = 'bold';
    style.color = 'black';
  } 

  return style;
};

const defaultSorted = [{
  dataField: 'brigade',
  order: 'asc'
}];

const diffSorted = [{
  dataField: 'workAllDiff',
  order: 'asc'
}];

export default class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      showCompleted: false,
      showDeleted: false,
      modal: false,
      action: "",
      actionbool: false,
      modaltitle: "Подтверждение"
    }
  }
  static propTypes = {
    cols: PropTypes.array.isRequired,
    headers:  PropTypes.array.isRequired,
    tabletype:  PropTypes.string.isRequired,
    update: PropTypes.func.isRequired,
    resetFilter: PropTypes.func,
    addString: PropTypes.func,
    simple: PropTypes.bool,
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);

    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  saveCell = async (id, date, type, value) => {
    let data;

    // if (!value) return 0;

    switch (type) {
    case 'valueProject':
    case 'coeffNorm':
      data = EDIT_WORK_QA({
        id,
        [type]: value || "0",
      });
      break;
    case 'onWork':
    case 'notWork':
      data = dayBrigadeUpdate({
        [type]: value || "0",
        parent: id,
        day: date,
      });
      break;
    default:
      data = dayUpdate({
        [type]: value || "0",
        parent: id,
        day: date,
      });
    }

    await QA(data)
    this.props.update()
  }

  editWork = async () => {
    const { action, actionbool} = this.state;

    async function processArray(array) {
      for (const id of array) {
        const data = EDIT_WORK_QA({
          id,
          [action]: true,
          [`${action}bool`]: actionbool,
        });
    
        await QA(data)
      }
    }
    await processArray(this.state.selected)
    this.setState(() => ({
      selected: []
    }));
  }

  hideModal = () => {
    this.setState({
      modal: false,
      action : "",
      actionbool: false,
    })
  }

  doAction = async () => {
    await this.editWork();
    this.setState({
      modal: false,
      action : "",
      actionbool: false,
    })
    this.props.update()
  }

  confirmation = (action, actionbool = true, modaltitle = "Подтверждение") => {
    this.setState({
      modal: true,
      action,
      actionbool,
      modaltitle
    })
  }

  render() {
    const { cols, headers, resetFilter, addString, simple, csv } = this.props;
    const { showCompleted, showDeleted, modal, modaltitle } = this.state;

    let newData = cols

    if (!showCompleted) newData = newData.filter(col => col.completed !== true)
    if (!showDeleted) newData = newData = newData.filter(col => col.deleted !== true)

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <div className={"content" + ((this.props.tabletype && ` ${this.props.tabletype}`) || "")}>
        <ToolkitProvider
          keyField="id"
          data={ newData }
          columns={ headers }
          search
          exportCSV={ { onlyExportFiltered: true, exportAll: false, noAutoBOM: false } }
        >
          {
            props => (
              <div>
                {resetFilter &&<button className="btn btn-sm btn-info" onClick={ () => {resetFilter(); this.setState({selected: []})} }> Очистить фильтры </button>}
                {!simple && <button className="btn btn-sm btn-primary" onClick={ addString }> Добавить строку </button>}
                {!simple && <button className="btn btn-sm btn-secondary" onClick={ ()=> this.setState({showCompleted: !showCompleted}) }>{showCompleted ? "Скрыть": "Показать"} завершенные</button> }
                {!simple && <button className="btn btn-sm btn-secondary" onClick={ ()=> this.setState({showDeleted: !showDeleted}) }>{showDeleted ? "Скрыть": "Показать"} удаленные</button> }

                {!simple && this.state.selected.length >0 && <button className="btn btn-sm btn-danger" onClick={()=>this.confirmation("remove", true, "Удалить работы?") }> Удалить работы </button>}
                {!simple && this.state.selected.length >0 && <button className="btn btn-sm btn-success" onClick={()=>this.confirmation("complete", true, "Завершить работы?") }> Завершить работы </button>}

                {!simple && showDeleted && this.state.selected.length >0 && <button className="btn btn-sm btn-warning" onClick={()=>this.confirmation("remove", false, "Восстановить работы?") }>
                  Восстановить работы </button>}
                {!simple && showCompleted && this.state.selected.length >0 && <button className="btn btn-sm btn-warning" onClick={()=>this.confirmation("complete", false, "Открыть работы?") }>
                  Открыть работы </button>}

                <SearchBar { ...props.searchProps } placeholder="Поиск" style={ { margin: 0, padding: 0 } } />
                {/* <ClearSearchButton { ...props.searchProps } className="btn btn-sm btn-info" placeholder="Поиск" /> */}
                <button className="btn btn-sm btn-info" onClick={  () => props.searchProps.onSearch('')  }> Очистить </button>
                {csv && <ExportCSVButton { ...props.csvProps }  className="btn btn-sm btn-dark">Export CSV!!</ExportCSVButton>}
                <BootstrapTable 
                  bootstrap4
                  { ...props.baseProps }
                  filter={ filterFactory() } 
                  condensed 
                  hover 
                  striped 
                  multiColumnSort={2}  
                  defaultSorted={ simple ? diffSorted : defaultSorted } 
                  defaultSortDirection="asc" 
                  rowStyle={ workersStyle }
                  pagination={ paginationFactory(pageOptions) } 
                  selectRow={ !simple ? selectRow : { hideSelectColumn: true,  mode: 'radio' } }
                  footerClasses="footer-class"
                  
                  cellEdit={ cellEditFactory({ 
                    mode: 'click', 
                    blurToSave: true,
                    // onStartEdit: (row, column, rowIndex, columnIndex) => { console.warn('start to edit!!!'); },
                    // beforeSaveCell: (oldValue, newValue, row, column) => { this.saveCell(row.id, column.dateU, column.cellType, newValue); },
                    afterSaveCell: (oldValue, newValue, row, column) => {oldValue !== newValue && this.saveCell(row.id, column.dateU, column.cellType, newValue) }
                  }) }/>
              </div>
            )
          }
        </ToolkitProvider>
        <Confirmation modal={ modal } hideModal={ this.hideModal } doAction={ this.doAction } title={ modaltitle }/>
      </div>
      
    )
  }
}
