import React, {Fragment, useState} from 'react'
import { ToFloat, _hover, _getUserLevel } from '../../CONSTANTS/operations';

const Comment = (cell, row, rowIndex, formatExtraData, history) => {
  let datacomment = "";
  // const [modal,setModal] = useState(false);

  if (row[formatExtraData.comment]) {
    datacomment = row[formatExtraData.comment];
    datacomment = datacomment.split('\n');
  }


  return (
    <Fragment>
      {ToFloat(cell) !== 0 ? cell : ""}
      {history && typeof history === 'function' && _getUserLevel && _getUserLevel() && (_getUserLevel() === 'monster' || _getUserLevel() === 'hi') &&
        <div className="historyEvent"
          onMouseEnter={(e)=>{
            const rect = e.target.getBoundingClientRect();

            _hover({text: <div className="full" onClick={()=>{history(row.id);}}></div>, x: rect.x, y: rect.y, style: "t" });
          }}
        >
        </div>
      }
      {datacomment &&  
        <div className="tdevent">
          <div className="icon">...</div>
          {/* <svg className="icon" height="20" viewBox="0 0 1026 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M515.6 33.27687500000002c-264.43125 0-478.8 214.36875-478.8 478.8s214.36875 478.8 478.8 478.8 478.8-214.363125 478.8-478.8S780.03125 33.27687500000002 515.6 33.27687500000002z m0 683.150625c-9.8878125 0-19.665937500000002-0.5090625000000001-29.284687499999997-1.3875-26.289375 48.5090625-82.3059375 66.969375-176.03531249999997 51.5278125 36.912187499999995-17.2509375 62.025-39 76.1615625-77.6634375C300.4878125 649.784375 242 572.82125 242 484.26593749999995c0-128.1890625 122.49093750000002-232.1184375 273.59999999999997-232.1184375 151.09875 0 273.59999999999997 103.92937500000001 273.59999999999997 232.1184375 0 128.2171875-122.50125 232.1615625-273.59999999999997 232.1615625z" fill="#76D0A4" /><path d="M398.5475 457.356875c-18.4659375 0-33.4275 15.312187499999999-33.4275 34.2046875s14.9615625 34.199999999999996 33.4275 34.199999999999996c18.4753125 0 33.450937499999995-15.3075 33.450937499999995-34.199999999999996s-14.975625-34.2046875-33.450937499999995-34.2046875zM482.24937500000004 491.5521875a33.350625 33.350625 0 1 0 66.70125 0 33.350625 33.350625 0 1 0-66.70125 0zM632.729375 458.2015625a33.345937500000005 33.345937500000005 0 0 0-33.335625 33.36 33.3309375 33.3309375 0 0 0 33.335625 33.335625 33.32625 33.32625 0 0 0 33.356249999999996-33.335625 33.34125 33.34125 0 0 0-33.356249999999996-33.36z" fill="#76D0A4" /></svg> */}
          <div className="HoverTD">
            <div className="text">
              <div>
                {datacomment && datacomment.length && datacomment.map((c,i)=>{
                  if (!c) return null;

                  return <div key={'comment'+i}>{c}</div>;
                })}
              </div>
            </div>
            {/* <div className="username">
              {commentUser}
            </div> */}
          </div>
        </div>}
    </Fragment>
  )
}

export default Comment