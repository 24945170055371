import React, { Component } from 'react'

import Tile from '../PARTS/Tile';
import {ToColor, MutatorsList, sortObjects, sortObjects2} from '../../CONSTANTS/Transformers';
import { QA } from '../../CONSTANTS';

class AdminScreen extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      place: null,
      placeName: null,
      admin: null,
    }
  }

  back = () => {
    this.setState({
      place: null,
    })
  }

  componentWillMount(){
    const Q = `{
      me{
        hi
        m
        type
      }
    }`;

    QA(Q).then((q)=>{
      if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
        this.setState({
          admin: true,
        })
      }
    })
  }
  

  render() {
    const {place, admin, placeName} = this.state;

    if(!admin) return null;
    const List = MutatorsList();

    const ObjList = {art: 'Арт', goodvin: 'Гудвин', garamond: 'Гарамонд', abaldui: 'Абалдуйка', caca:'Саса', lemon:'Лемон', huemoe: 'Хуевертина', badabom:'Бадабумина', zarahur: 'Зарахур'};

    let O = sortObjects2(ObjList);

    console.log(O);
    
    
    return (
      <div className="AdminScreen">
        {/* {place &&  <button type="submit" className="btn btn-primary btn-lg" onClick={this.back}>Назад</button>} */}
        {place &&  <h1 className="HeaderWithBackButton" onClick={this.back}><span>&lt;</span>{placeName}</h1>}
        {place && place in List && MutatorsList()[place].c}
        {
          !place && <div className="RootScreen AdminTiles">
            {
              Object.keys(List).map((l,i)=>{

                return(
                  <Tile
                    bgcolor={""}
                    box
                    borderless
                    svg
                    // color={ToColor(MutatorsList()[l].n)}
                    icon={MutatorsList()[l].icon}
                    imgcolor={ToColor(l)}
                    id={Object.keys(List)[i]}
                    key={"list_"+Object.keys(List)[i]+i}
                    name={MutatorsList()[l].n}
                    click={()=>this.setState({place: Object.keys(List)[i], placeName: List[l].n })}
                  />
                )
              })
            }
          </div>
        }
      </div>
    )
  }
}

export default AdminScreen
