import React, { Component } from 'react'
import { QA } from '../CONSTANTS'
import Loader from '../../Loader'
import { checkUserRights } from '../CONSTANTS/operations'
import { USEREDIT_QA } from '../GQL/SERVER/MUTATOR/ServerMutators'

export default class Profile extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      photo: '', // url or base64,
      data: {},
      inputs: '',
      edit: false,
    }
  }
  

  componentDidMount(){

    // фото запрашиваем отдельным запросом чтобы текстовые данные грузились первыми
    QA().then((photo)=>{
      if(photo && photo.photo) {
        
        this.setState({
          photo: photo.photo,
        })
      }
    })

    const Q = `{
      me {
        id
        fio
        name
      }
    }`;

    QA(Q).then((user)=>{
      if(user && user.me) {
        
        this.setState({
          data: user.me,
        })
      }
    })
  }

  changename = () => {
    const {inputs, data} = this.state;

    console.log(inputs, data);
    

    if(data.id && inputs) {
      QA(USEREDIT_QA({id: data.id, fio: inputs})).then((answer)=>{
        console.log('answer', answer);
        if(answer) {
          this.setState({
            edit: false,
            data: {...data, fio: inputs}
          })
        }
      })
    }
  }

  input = (e) => {
    let Value = e.target.value;
    const {inputs, data} = this.state;

    Value = Value.replace(/[^A-zА-яЁё ]/gi, '');

    this.setState({
      inputs: Value,
    })
  }

  render() {
    const {photo, data, inputs, edit} = this.state;

    return (
      <div style={{
        display:'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems:'center',
        padding: '20px',
      }}>
        <div style={{
          width: '300px',
          minWidth: '300px',
          height: '300px',
          minHeight: '300px',
          background: '#eee',
          overflow:'hidden',
          borderRadius:'300px',
          display:'flex',
          flexDirection: 'column',
          alignItems:'center',
        }}>
          {photo ? <img src="" alt=""/> : <Loader wh='20px' />}
          
        </div>

        <div>
          <h3>Ваш логин:</h3>
          {(data.name && <p>{data.name}</p>) || <Loader />}
        </div>
        <div  onClick={()=>this.setState({edit: true})}>
          <h3>Полное ФИО: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg></h3>
          <p>{data.fio}</p>
        </div>
        {(edit && <div>
          <div className="input-group">
            <input type="text" className="form-control" value={inputs} onChange={this.input} placeholder="Фамилия Имя Отчество" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn btn-outline-primary" type="button" onClick={this.changename}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({edit: false})}>отменить</button>
            </div>
          </div>
        </div>) || null}
      </div>
    )
  }
}
