import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS'
import { _filt2 } from '../../CONSTANTS/operations'
import SelectSearch from 'react-select-search';
import Loader from '../../../Loader';
import { RemapEqObjects } from '../../CONSTANTS/Transformers';

/**
 * 
 * 
 * Получаем 1 массив для таблицы
 * Добавляем в elements render компоненты, оборачиваем данные в зависимости от рендера (если там ID то рендер будет сравнивать ID из своего списка и подставлять данные)
 * Редактирование в ячейках
 * Добавление в виде верхней строки
 * 
 * 
 */

export default class AdminGridEditor extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props)
  
    this.state = {
      add: true,
      editor: null,
      selected: null,
      array: [],
      fullArray: [],
      selectedId: [],
      selectedIds: {},
      ok: "",
      search: "",
      listName: "",
      currentParent: null,
      selects: {},
      inputs: {},
      defValue: '',
      clear: null,
      startPos: null,
      startParam: null,
      SearchErr: null,
      found: null,
      reset: null,
      load: null,
      fixedFilter: null,
      sort: null,
      sortMinus: false,
      resetArray: [],
    }
  }
  
  static propTypes = {
    ADD:  PropTypes.func, 
    EDIT: PropTypes.func,
    DEL: PropTypes.func,
    // GET:  PropTypes.func,
    name:  PropTypes.string,
    array: PropTypes.array,
    headers: PropTypes.array, 
    searchKeys: PropTypes.array, 
    refetch: PropTypes.func, 
    arrayProp: PropTypes.string, 
    parentsArray: PropTypes.array, 
    parentName: PropTypes.string, 
    arrayName: PropTypes.string, 
    parentProp: PropTypes.string,
    change: PropTypes.func,
    fetch: PropTypes.func,
    elements: PropTypes.array,
  }

  componentDidMount(){
    
    this.setData(null, true)
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    
    prevProps.array !== this.props.array && this.setData(null, true)
  }
  
  delete = (id) => {
    const { DEL } = this.props;

    if(id && DEL && typeof DEL === 'function'){
      let A = DEL({id});
      
      if(!A) return null;
      QA(A).then((d)=>{
        if (d && Object.keys(d) && ((d[Object.keys(d)[0]] && d[Object.keys(d)[0]].delete) || Object.keys(d)[0])) {
          this.selectId(id)
          this.sliceGrid(id)
        }
      })
    }
  }

  deleteMany = () => {
    let ids = [...this.state.selectedId];

    if (ids && ids.length) {
      ids.forEach(id => {
        this.delete(id)
      });
    }
  }

  selectId = (id) => {
    let ObjIds = this.state.selectedIds;
    let ids = [...this.state.selectedId];

    if (ObjIds[id]) {
      ObjIds[id] = false;
      ids = [...this.state.selectedId].filter(e=> e !== id)
    } else {
      ObjIds[id] = true;
      ids.push(id);
    }

    this.setState({
      selectedIds: ObjIds,
      selectedId: [...ids],
    })
  }
  // selectId = (id) => {
  //   let ids = [...this.state.selectedId];

  //   if (this.state.selectedId.find(e => e === id)) {
  //     ids = [...this.state.selectedId].filter(e=> e !== id)
  //   } else {
  //     ids.push(id)
  //   }

  //   this.setState({
  //     selectedId: [...ids],
  //   })
  // }

  appendGrid = (element) => {
    const {array} = this.state;

    this.setData([element,...array], true)
  }

  sliceGrid = (id) => {
    const {array} = this.state;
    const Arr = array.filter(e=> e.id !== id);

    this.setData([...Arr], true)
  }

  // filter = (props) => {
  //   const {key, keys, str, fixed} = props;
  //   const {array, fixedFilter} = this.state;
  //   const fullArray = this.props.array;

  //   if(!str || !str.length) {
  //     this.setState({
  //       array: this.props.array,
  //       found: null,
  //       search: '',
  //     });

  //     return null;
  //   }

  //   let Arr = [];
    

  //   if (!fixedFilter && !fixed) {
  //     const strings = str.split(' ');

  //     strings.forEach(element => {
  //       if(!element || !element.length){
  //         return null;
  //       }
  
  //       const F = _filt2({str: element, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });
  
  //       F.array && F.array.length && F.array.forEach((el)=>{
  //         let Res = Arr.filter(e => e === el);
  
  //         if(!Res || !Res.length){
  //           Arr.push(el);
  //         }
  //       });
  //     });
  //   } else {
  //     const F = _filt2({str, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });

  //     Arr = F.array;
  //   }

  //   this.setState({
  //     array: (Arr && Arr.length && Arr) || fullArray,
  //     found: Arr.length || (str && !Arr.length && '0') || null,
  //     search: str || '',
  //     // reset: false,
  //   })
  // }

  filter = (props) => {
    const {key, keys, str, fixed} = props;
    const {array, fixedFilter} = this.state;
    const fullArray = this.props.array;
    let Arr = [];

    if (!fixedFilter && !fixed) {
      const strings = str.split(' ');

      strings.forEach(element => {
        if(!element || !element.length){
          return null;
        }
  
        const F = _filt2({str: element, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });
  
        F.array && F.array.length && F.array.forEach((el)=>{
          let Res = Arr.filter(e => e === el);
  
          if(!Res || !Res.length){
            Arr.push(el);
          }
        });
      });
    } else {
      const F = _filt2({str, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });

      Arr = F.array;
    }
    
    this.setState({
      search: str,
      found: Arr.length,
    }, this.setData(Arr))
   
  }

  selectFilter = (props) => {
    const {key, str, name} = props;
    const Arr = this.state.array;

    this.setSelect(name, str)

    const F = _filt2({str, array: Arr, fullArray: Arr, keys: [key]});

    console.log(F);
    console.log(F.array);
    

    this.setData(F.array)
  }

  resetAll = () => {
    let Obj = {};
    const Arr = this.state.resetArray;

    Arr.forEach(element => {
      Obj[element] = '';
    });

    this.setState({
      ...Obj,
      search: '',
      found: null,
    }, this.setData(null,true))
  }

  setSelect = (name, value) => {
    if(name) {
      this.setState({
        ['select'+name]: value || '',
        resetArray: [...this.state.resetArray, 'select'+name],
      })

      return null;
    }
  }

  sorting = (sort, sortMinus = false) => {
    const {array} = this.state;

    this.setState({
      sort,
      sortMinus,
    })
    const NewSorting = [...array].sort((a,b) => {
      if(!a || !a[sort]) return sortMinus ? 1 : -1;
      if(!b || !b[sort]) return sortMinus ? -1 : 1;
      if(a[sort] > b[sort]) return sortMinus ? -1 : 1;
      if(a[sort] < b[sort]) return sortMinus ? 1 : -1;
      
      return 0;

      // let comparison = 0;

      // if(!a.hasOwnProperty(sort) || !b.hasOwnProperty(sort)) {

      //   return sortMinus ? 1 : -1;
      // }
      // if(!a[sort]) {
        
      //   return sortMinus ? -1 : 1;
      // }
      // if(!b[sort]) {
        
      //   return sortMinus ? 1 : -1;
      // }
  
      // const varA = (typeof a[sort] === 'string') ?
      //   a[sort].toUpperCase() : a[sort];
      // const varB = (typeof b[sort] === 'string') ?
      //   b[sort].toUpperCase() : b[sort];

      // if (varA > varB) {
      //   comparison = 1;
      // } else if (varA < varB) {
      //   comparison = -1;
      // }

      // return (
      //   sortMinus ? comparison  : (comparison * -1)
      // );
    });

    this.setData(NewSorting, true)
  }

  setData = (arr, full) => {
    const {array} = this.props;
    const Arr = arr || (full && array) || [];

    Arr && Arr.length && this.setState({
      array: [],
    }, ()=>{
      this.setState({
        array: Arr,
      })
    })
  }

  render() {
    const { headers, elements, searchKeys, onClick, ADD, answerName, refetch } = this.props;
    const { selectedId, search, found, array, sort, sortMinus, selectedIds, loader } = this.state;

    const cols = (headers && headers.length && headers.map( (s) => s.maxWidth || 'auto').join(' ')) || null;
    let fullArray = this.props.array;

    return (
      <div style={{padding: '0 10px'}}>

        <AdminGridAdd {...this.props} cols={cols} appendGrid={this.appendGrid} />


        <div className="SelectedBlock">
          {selectedId && selectedId.length && <div style={{position: 'fixed', right: '10px', left: 'auto', bottom:'10px', top:'auto', zIndex: '999', padding:'20px',background:'rgba(254,250,250,0.5)'}}>
            <button type="button" className="btn btn-danger" onClick={()=>this.deleteMany(selectedId)}>Удалить {selectedId.length}</button>
            <button type="button" className="btn btn-light" onClick={()=>this.setState({selectedId: [], selectedIds: {}})}>отмена</button>
          </div> || null}
        </div>

        <div className="input-group">
          <input type="search" onChange={(s)=>this.filter({keys: searchKeys, str: s && s.target && s.target.value || ''})}
            value={search} placeholder={"Поиск..."}
            style={{width: '100%', maxWidth: '500px', background: "#ffffff", padding: '5px',}}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <input type="checkbox" name="fixedFilter" onChange={(el)=>{this.setState({fixedFilter: el.target.checked, search: ''})}} />
            </div>
            <div className="input-group-text">
              <span>точный поиск</span>
            </div>
            {(found || found === 0) && <div className="input-group-text">
              <span className="Err">Найдено {found}</span>
            </div>}
            <div className="input-group-text">
              <svg xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}} onClick={()=> this.setState({loader: true,}, async () => await refetch().then(()=>{ this.setState({loader: false}) }))} width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
              </svg>
            </div>
            {(!array || !array.length || loader) && <div className="input-group-text">
              <Loader wh={'30px'}/>
            </div>}
            <div className="input-group-text">
              <button type="button" className="btn btn-light" onClick={this.resetAll}>Очистить</button>
            </div>
          </div>
        </div>

        <div className={'GridTable'} style={{gridTemplateColumns: cols || null,}}>
          {
            headers && headers.length && headers.map((e,i)=>{
              if(!e || !e.prop) return null;

              return(<div key={'headerCell'+e.id + i} className="GridCell Header HoverParent">
                {e.name && <span style={{margin: '0 5px 0 0'}}>{e.name}</span>}
                {(e.select && fullArray
                      && fullArray.length && <div>
                  <SelectSearch
                    // value={Val}
                    value={this.state['select'+e.prop] || ''}
                    options={[
                      // {name: 'Все', value: ''},
                      ...RemapEqObjects(array
                        .map((a,j,arr) =>{
                          if(!a) return null;
                     
                          return {name: a[e.prop], value: a[e.prop]};
                        })
                        .sort((a,b)=>{
                          if(a.name > b.name) return 1;
                          if(a.name < b.name) return -1;

                          return null;
                        }), 'value')]
                    }
                    onChange={(s)=>this.selectFilter({key: e.prop, str: s && s.value || '', name: e.prop})}
                    // onChange={(s)=>this.filter({keys: [e.prop], str: s && s.value || ''})}
                  />
                </div>) || null}
                <div className="HoverChild" style={{width: '22px', height: '22px'}} onClick={()=>{ this.sorting(e.prop,!sortMinus)}}>
                  ^
                </div>
              </div>) 
            })
          }
          {
            array && array.length && array
              .map((e,i)=>{
                return( (headers && headers.length && headers.map((a,k)=>{
                  if(a && a.prop && a.prop === 'id'){

                    return(
                      <div className={"GridCell Body" + (selectedId && selectedId.length && selectedId.find(id => id === e.id) && ' selected' || '')}
                        key={'prop-render-'+k+' '+i}>
                        <input type="checkbox" onChange={()=>this.selectId(e.id)} checked={selectedIds[e.id] || false}/>
                      </div>
                    )
                  }
                  if(a && a.prop){

                    return(
                      <div
                        className={`GridCell Body ${selectedId && selectedId.length && selectedId.find(id => id === e.id) && ' selected' || ''} ${(a.renderClass && typeof a.renderClass === 'function' && a.renderClass({ Cell: (e && e[a.prop]) || "", Row: e, Col: a }))|| ""}` }
                        key={'prop-render-'+k+' '+i}>
                        {
                          a.onRender && typeof a.onRender === 'function' ? a.onRender({ Cell: (e && e[a.prop]) || "", Row: e, Col: a, }) : (
                            <div className="tableCellButton" style={{height: '100%', minHeight: '30px',}} onClick={()=>{
                              !a.onRender && !a.onClick && onClick && typeof onClick === 'function' && onClick(e);
                              a.onClick && typeof a.onClick === 'function' && a.onClick(e);
                            }}>{((e && e[a.prop]) || "")}
                            </div>)
                        }
                      </div>
                    )
                  }

                  return null;
                })) || null)

              }) || null
          }
        </div>
        {(!array || !array.length) && <div className="GridCell Body">Тут ничего нет</div>}
      </div>
    )
  }
}

export function AdminGridAdd({ elements, ADD, appendGrid, answerName, answerParam, answerProp}) {
  const [params, setParams] = useState({});
  const [errors, setErrors] = useState([]);
  const [clean, setClean] = useState(false);


  const Clean = () => {
    setClean(true)
    setTimeout(() => {
      setClean(false)
    }, 100);
  }

  const Errors = (error) => {
    setErrors([...errors, error])
    setTimeout(() => {
      setErrors([]);
    }, 10000);
  }

  const Important = elements.filter(e => e.important);
  const cols = (elements && elements.length && elements.map( (s) => s.maxWidth || 'auto').join(' ')) || null;

  const Add = () => {
    let Errs = [];

    Important.forEach((check)=>{
      const e = params[check.prop]

      if(!e) {
        Errs.push(1)

        Errors(`Поле ${check.name} должно быть заполнено!`)
      }
    })
    // setParams({});
    if (Errs && Errs.length) return null;

    ADD && typeof ADD === 'function' && QA(ADD(params)).then((data)=>{

      // if (data && data[answerName] && data[answerName][answerParam] && data[answerName][answerParam][answerProp]) {
      if (data) {
        Errors(`Объект создан`)
        // Errors(`Объект создан ${params[Object.keys(params)[0]]}`)
        setParams({});
        if (appendGrid && typeof appendGrid === 'function') {
          appendGrid({id: (answerName && data[answerName] && data[answerName].create && data[answerName].create.id) || 0, ...params})
        }
      }

    }).then(()=>{
      Clean()
    })
  }



  return (
    <div style={{background: 'rgba(255,255,255,0.8)', padding: '10px 10px', margin: '10px 0'}}>
      {(errors && errors.length && <div className="Err">
        {errors.map((er,i)=>{

          return(<div key={'error-'+i}>{er}</div>)
        })}
      </div>) || null}
      <div className={'GridTable'} style={{gridTemplateColumns: cols || null,}}>
        {
          elements && elements.length && elements.map((e,i)=>{
            if(!e || !e.prop) return null;

            return(<div key={'headerCell'+e.id + i} className="GridCell HeaderAdd">
              {e.name}
            </div>) 
          })
        }
        {!clean &&
          (elements && elements.length && elements.map((El,k)=>{
            if (El) {
              if (El.onRender && typeof El.onRender === 'function') {
                return(<div key={'addNew'+k} className={"GridCell BodyAdd"}>{
                  El.onRender({value: params[El.prop] || '', set: (value) => setParams({...params, [El.prop]: value }) })
                }
                
                {El.important && <div className={'small'}>обязательное</div>}
                </div>)
              }


              return(<div key={'addNew'+k} className={"GridCell BodyAdd"}>
                <input type="text" defaultValue={El.value} value={params[El.prop] || ''} onChange={(e)=>{setParams({...params, [El.prop]: e.target.value })}}/>
                {El.important && <div className={'small'}>обязательное</div>}
              </div>)
            }

            return <div key={'addNew'} className={"GridCell BodyAdd"}></div>

          })) || null
        }
      </div>
      <div>
        <button type="button" className="btn btn-primary" onClick={Add}>Добавить</button>
      </div>
    </div>
  )
}
