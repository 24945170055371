import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router'
import PropTypes from 'prop-types'

export default class Redirector extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    return (
      <Redirect to={`/areas/`} />
    )
  }
}
