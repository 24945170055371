import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS';
import { WT_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import { _filter, _REPLSTR } from '../../CONSTANTS/operations';
import Loader from '../../../Loader';


export default class GridTable extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      resort: false,
      asc: false,
      key: null,
    }
  }

  resort = (key) => {
    const {sorting,} = this.props;
    const {asc,} = this.state;

    if(sorting && typeof sorting === 'function' && key){

      this.setState({
        key: key,
        asc: !asc,
        resort: true,
      }, () => {
        sorting(key, asc);
  
        setTimeout(() => {
          this.setState({
            resort: false,
          })
        }, 0);
      });

      return true;
    }

    return null;
  }
  
  render() {
    const {array, headers, defaultSort, fullArray, editCell, onClick, sorting, sort, filter, sortMinus, selectValue, setSelectValue, } = this.props;
    const Cols = (headers && headers.length && headers.map( (s) => s.maxWidth || 'auto').join(' ')) || null;

    // if(this.state.resort) {
    //   return null;
    // }

    return (
      <div className={'TableTiler'} style={{gridTemplateColumns: Cols || null,}}>
        {headers && headers.length && headers.map((e,i)=>{
          if(!e || !e.prop) return null;

          return(
            <div key={"render-header-"+i} className={'header2'} style={{width:'auto', maxWidth: e.maxWidth || 'auto', }}>
              <div style={{width:'auto' }} onClick={()=>{this.resort(e.prop)}}>
                {e.name}
              </div>
              <div>
                {e.select && <select value={selectValue} onChange={(s)=>{ filter && typeof filter === 'function' && filter({key: e.prop, str: s && s.target && s.target.value || ''}) /*_filter({string: s && s.target && s.target.value, thisValue: null, array, arrayZero: fullArray, stateProp: null, compareProps: e.compareProps }).array*/ }}>
                  <option value={""}>Все</option>
                  {
                    fullArray && fullArray.length && fullArray
                      .map((f,i, arr)=>{
                        if(!f || !e.prop || !f[e.prop] ) return null;
                        if(arr.indexOf((a)=>{return a[e.prop] && a[e.prop] === f[e.prop] && e}) >= 0) return null;

                        return f[e.prop];
                      })
                      .filter((e,j,arr) => {
                        return arr.indexOf(e) === j && e;
                      })
                      .map((v, j)=>{
                        if(!v) return null;

                        return <option key={v + j} value={v}>{v}</option>;
                      }) || null
                  }
                </select>}
              </div>
              <div>
                {e.prop && e.prop === sort && sortMinus ? 'v' : (e.prop && e.prop === sort && !sortMinus ? '^' : null)}
              </div>
            </div>
          )
        }) || null
        }
        {
          array && array.length && array
            .map((e,i)=>{
              return( (headers && headers.length && headers.map((a,k)=>{
                if(a && a.prop){

                  return(
                    <div
                      className="tableCell"
                      key={'prop-render-'+k+' '+i}>
                      {
                        a.onRender && typeof a.onRender === 'function' ? a.onRender({ Cell: (e && e[a.prop]) || "", Row: e, Col: a, }) : (
                          <div className="tableCellButton" style={{height: '100%', minHeight: '30px',}} onClick={()=>{
                            !a.onRender && !a.onClick && onClick && typeof onClick === 'function' && onClick(e);
                            a.onClick && typeof a.onClick === 'function' && a.onClick(e);
                          }}>{((e && e[a.prop]) || "")}
                          </div>)
                      }
                    </div>
                  )
                }

                return null;
              })) || null)

            }) || <div className="">Тут ничего нет</div>
        }
      </div>
    )
  }
}
