import React, { Component } from 'react'
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types'
import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import Loader from '../../../Loader'
// import RecursiveViewer from './RecursiveViewer';
import { FetchedSelect, Delete } from './EditorParts';
import { _filt, _filt2 } from '../../CONSTANTS/operations';
import GridTable from '../HANDBOOK/GridTable';

export default class Editorable extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      editor: null,
      selected: null,
      array: [],
      fullArray: [],
      selectedId: [],
      ok: "",
      search: "",
      listName: "",
      currentParent: null,
      selects: {},
      inputs: {},
      defValue: '',
      clear: null,
      startPos: null,
      startParam: null,
      SearchErr: null,
      found: null,
      reset: null,
      load: null,
      fixedFilter: null,
    }
  }
  
  static propTypes = {
    ADD:  PropTypes.func, 
    EDIT: PropTypes.func, 
    name:  PropTypes.string, 
    array: PropTypes.array, 
    refetch: PropTypes.func, 
    arrayProp: PropTypes.string, 
    parentsArray: PropTypes.array, 
    parentName: PropTypes.string, 
    arrayName: PropTypes.string, 
    parentProp: PropTypes.string,
    change: PropTypes.func,
    fetch: PropTypes.func,
    elements: PropTypes.array,
  }

  componentDidUpdate(prevProps) {
    if (this.props.array !== prevProps.array) {
      const { array } = this.props;

      this.setState({
        array: array,
        fullArray: array && [...array],
      })
    }
  }

  add = () => {
    const { ADD, refetch, elements } = this.props;
    const { inputs, selects, editor } = this.state;

    this.setState({
      load: true,
    })

    if(!elements || !elements.length) return null;
    let DATA = {};
    let RUNS = [];

    elements.forEach(element => {
      if(!element || !element.prop || (!element.add && !editor)) return null;
      if(element.array && element.array.length){
        let E = selects['select'+element.prop];

        if(E){
          DATA[element.prop] = (element.propName && E[element.propName] && E[element.propName]) || E.value || E.id;
        }
      } else if(element.type && element.type === 'fetch'){
        let E = selects['select'+element.prop];

        if(E){
          DATA[element.prop] = (element.propName && E[element.propName] && E[element.propName]) || E.value || E.id;
        }
      } else {
        let E = inputs['input'+element.prop];

        if(E){
          DATA[element.prop] = E;
        }
      }
      if (element.importantAdd) {
        RUNS.push(element.prop)
      }

    });

    RUNS = RUNS.map(e => {
      if(!DATA[e]) return e;

      return false
    }).filter(e => e);
    if(RUNS.length || !DATA){
      this.setState({
        error: "Необходимо заполнить поля: " + RUNS.join(', '),
      })

      return null;}
    let A = ADD(DATA);

    if(!A) return null; // TODO STATE ERROR 
    QA(A).then(()=>{
      let I = inputs;
      let S = selects;
      
      for (let pr in I) {
        I[pr] = "";
      }
      for (let pr in S) {
        S[pr] = null;
      }
      this.rerender();
      refetch && typeof refetch === 'function' && refetch()
    })

  }
  edit = () => {
    const { EDIT, refetch, elements } = this.props;
    const { inputs, selects, editor, selected } = this.state;

    this.setState({
      load: true,
    })

    if(!elements || !elements.length || !selected || !selected.id) return null;
    let DATA = {};
    let RUNS = [];

    elements.forEach(element => {
      if(!element || !element.prop || (!element.edit && editor)) return null;

      if(element.array && element.array.length){
        let E = selects['select'+element.prop];

        if(E && E !== 'непонятно'){
          DATA[element.prop] = (element.propName && E[element.propName] && E[element.propName]) || E.value || E.id;
        }
      } else if(element.type && element.type === 'fetch'){
        let E = selects['select'+element.prop];

        if(E){
          DATA[element.prop] = (element.propName && E[element.propName] && E[element.propName]) || E.value || E.id;
        }
      }  else {
        let E = inputs['input'+element.prop];

        if(E && E !== 'непонятно'){
          DATA[element.prop] = E;
        }
      }
      if (element.importantEdit) {
        RUNS.push(element.prop)
      }
    });

    RUNS = RUNS.map(e => {
      if(!DATA[e]) return e;

      return false
    }).filter(e => e);

    if(RUNS.length || !DATA) return null;

    DATA.id = selected.id;
    let A = EDIT(DATA);

    if(!A) return null; // TODO STATE ERROR 
    QA(A).then(()=>{
      let I = inputs;
      let S = selects;
      
      for (let pr in I) {
        I[pr] = "";
      }
      
      for (let pr in S) {
        S[pr] = null;
      }
      this.rerender();
      refetch && typeof refetch === 'function' && refetch()
    })
  }

  delete = () => {
    const {selected} = this.state;
    const { DEL, refetch } = this.props;

    if(selected && selected.id && DEL){
      let A = DEL({id: selected.id});
      
      if(!A) return null;
      QA(A).then((d)=>{
      })
    }

    this.setState({
      editor: false,
    });
    this.rerender();
    refetch && typeof refetch === 'function' && refetch()
  }

  selectList = (e) => {
    if( !e ) return null;
    const { elements, EDIT } = this.props;

    if(!EDIT) return null;
    
    if (!elements || !elements.length) return null;

    const {/* selects, */ inputs} = this.state;
    let I = inputs;

    elements && elements.length && elements.forEach((el)=>{
      if (el.type === 'input') {
        I['input' + el.prop] =  (e[el.prop] && e[el.prop]) || 'непонятно'
      }
    })

    this.setState({
      editor: true,
      selected: e,
      inputs: I,
    })
  }

  filter = (props) => {
    const {key, keys, str, fixed} = props;
    const {fullArray, array, fixedFilter} = this.state;

    if(!str || !str.length) {
      this.setState({
        array: fullArray,
        found: null,
        search: '',
      });

      return null;
    }

    let Arr = [];


    if (!fixedFilter && !fixed) {
      const strings = str.split(' ');

      strings.forEach(element => {
        if(!element || !element.length){
          return null;
        }
  
        const F = _filt2({str: element, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });
  
        F.array && F.array.length && F.array.forEach((el)=>{
          let Res = Arr.filter(e => e === el);
  
          if(!Res || !Res.length){
            Arr.push(el);
          }
        });
      });
    } else {
      const F = _filt2({str, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key] || Object.keys(fullArray[0]), });

      Arr = F.array;
    }

    this.setState({
      array: (Arr && Arr.length && Arr) || fullArray,
      found: Arr.length || (str && !Arr.length && '0') || null,
      search: str || '',
      // reset: false,
    })
  }

  // filterSelect = (props) => {
  //   const {key, keys, str} = props;
  //   const {fullArray, array} = this.state;

  //   const F = _filt2({str, array: array && array.length && array || [], fullArray: fullArray, keys: keys || [key], });

  //   this.setState({
  //     array: F.array,
  //     found: F.found,
  //     // reset: false,
  //   })
  // }

  sorting = (sort, sortMinus = false) => {
    const {array} = this.state;
    const NewSorting = [...array].sort((a,b) => {
      let comparison = 0;

      if(!a.hasOwnProperty(sort) || !b.hasOwnProperty(sort)) {

        return sortMinus ? 1 : -1;
      }
      if(!a[sort]) {
        
        return sortMinus ? -1 : 1;
      }
      if(!b[sort]) {
        
        return sortMinus ? 1 : -1;
      }
  
      const varA = (typeof a[sort] === 'string') ?
        a[sort].toUpperCase() : a[sort];
      const varB = (typeof b[sort] === 'string') ?
        b[sort].toUpperCase() : b[sort];

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return (
        sortMinus ? comparison  : (comparison * -1)
      );
    });

    this.setState({
      array: NewSorting,
    })
  }

  handleChange = (e) => {
    if(!e || !e.target || !e.target.name) return null;
    const { inputs } = this.state;
    let STATE = inputs;

    STATE[e.target.name] = e.target.value || "";
    this.setState({inputs: STATE })
  }

  selectChange = (parent, prop) => {
    if(!parent || !prop) return null;
    const {selects} = this.state;
    let STATE = selects;

    STATE['select'+prop] = {...parent};
    this.setState({selects: STATE })
  }

  componentWillMount(){
    this.revalue()
  }

  revalue = () => {
    const {elements} = this.props;

    elements && elements.length && elements.forEach(e => {
      if (e.prop && e.type && e.type === 'input' && e.value) {
        const DV = (typeof e.value === 'function' && e.value()) || e.value.toString();
        const E = {
          target: {
            value: DV,
            name: 'input'+e.prop
          }
        }

        this.handleChange(E);
      }
    });
  }

  deleteMany = () => {

  }

  rerender = () => {
    this.setState({
      editor: null,
      selected: null,
      load: null,
      // array: [],
      ok: "",
      listName: "",
      currentParent: null,
      selects: {},
      inputs: {},
      defValue: '',
      selectedId: [],
      // clear: true,
      prevFetch: {},
    },()=>{
      this.revalue();
      setTimeout(() => {
        this.setState({
          clear: false,
        })
      }, 300);
    });
  }

  selectRecursive = (e, next, last, type) => {
    last && this.selectList(e)
    e && next && next(e)
  }

  selectId = (id) => {

    let ids = [...this.state.selectedId];

    if (ids.find(e => e === id)) {
      console.log(ids.find(e => e === id));
      
    }

    this.setState({
      selectedId: [ids],
    })

  }

  render() {
    const {
      name,
      arrayName,
      elements,
      children,
      tableHeaders,
      searchKeys,
    } = this.props;
    const {
      editor,
      search,
      selected,
      inputs,
      selects,
      defValue,
      error,
      clear,
      array,
      fullArray,
      found,
      reset,
      load,
      selectedId,
    } = this.state;

    if (clear) return(<Loader />);

    return (
      <div className="COL">
        {(load && <Loader />) || <div className="marginChilds">
          <h3>{editor ? "Изменить" : "Создать"} {name} {editor ? selected.name || selected.value : ""}</h3>
          {error && <div>
            <span className="error">{error}</span>
          </div>}
          {
            elements && elements.length && elements.map((e,i)=>{
              if (!e.prop || (!e.edit && editor) || (!e.add && !editor)) return null;
              if(e.type && e.type === 'fetch' && e.fetch && e.fetch.length) {
                return(
                  <FetchedSelect
                    key={'selectAdd'+i}
                    fetch={e.fetch}
                    run={null}
                    prop={e.prop}
                    selectProp={e.selectProp}
                    value={null}
                    name={'select'+e.prop}
                    selects={null}
                    change={this.selectChange}
                    placeholder={e.name}
                  />)
              }
              if(e.array && e.array.length) {
                return(
                  <div key={'selectAdd'+i}>
                    <p>
                      {e.name}
                    </p>
                    <SelectSearch
                      name={'select'+e.prop}
                      value={selects && selects['select'+e.prop] && selects['select'+e.prop].value}
                      options={e.array}
                      onChange={(parent)=>this.selectChange(parent, e.prop, e.selectProp)}
                      placeholder={e.name} />
                  </div>) 
              }
              if(e.type === 'input'){
                return(
                  <div className="input-group" key={'inputsAdd'+i}>
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="btnGroupAddon">Добавить {e.name}</div>
                    </div>
                    <input
                      type={e.inputType || "text"}
                      name={'input'+e.prop}
                      className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon"
                      placeholder={e.name}
                      // defaultValue={editor ? selected.name || selected.value : ""}
                      value={inputs['input'+e.prop] || defValue}
                      onChange={this.handleChange}
                    />
                  </div>
                )
              }

              return <Loader key={"loader-selects"+i}/>;
            })
          }
          <button type="submit" className="btn btn-dark btn-lg" onClick={editor ? this.edit : this.add}>{editor ? "Изменить" : "Добавить"}</button>
          {editor ? <button type="cancel" className="btn btn-light" onClick={this.rerender} >Отмена</button> : null}
          {editor ? <Delete name="Удалить" agreeText="Вы уверены?" onClick={this.delete}/> : null}
        </div>}
        <div >
          <h3>{arrayName || name}</h3>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center', width: '100%'}} >
            
            <div className="input-group">
              <input type="search" onChange={(s)=>this.filter({keys: searchKeys, str: s && s.target && s.target.value || ''})}
                value={search} placeholder={"Поиск..."}
                style={{width: '100%', maxWidth: '500px', background: "#ffffff", padding: '5px',}}/>
              <div className="input-group-append">
                <div className="input-group-text">
                  <input type="checkbox" name="fixedFilter" onChange={(el)=>{this.setState({fixedFilter: el.target.checked, search: ''})}} />
                </div>
                <div className="input-group-text">
                  <span>точный поиск</span>
                </div>
                {(found || found === 0) && <div className="input-group-text">
                  <span className="Err">Найдено {found}</span>
                </div>}
              </div>
            </div>


            {/* <span className="btn btn-light" onClick={()=>this.setState({array: fullArray, search: '', found: null, reset: true,})}>очистить</span> */}
          </div>
          <div>
            <button type="button" className="btn btn-danger" disabled={(selectedId && selectedId.length && false) || true} onClick={()=>this.deleteMany(selectedId)}>Удалить</button>
          </div>

          
          {tableHeaders && tableHeaders.length && <GridTable sorting={this.sorting} reset={reset} filter={this.filter} array={array} selectId={this.selectId} fullArray={fullArray && [...fullArray]} headers={tableHeaders} />}
          {children || null}
        </div>
      </div>
    )
  }
}
