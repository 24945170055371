import client from "../../client";
import gql from "graphql-tag";
import { QA } from ".";

export const checkUserRights = async () => {
  const Q = `{
    userrights {
      fio
      name
      type
      rights{
        collid
        coll
        access
      }
      navs{
        name
        access
      }
    }
  }`;

  const a = await QA(Q).then((q)=>{
    let query = gql`
    {
      level @client
    }
    `;

    if (q && q.userrights){
      client.writeQuery({
        query: query,
        data: {
          level: q.userrights.type,
        },
      });

      return q.userrights;
    }

    return false
  })

  return a
}


export const _REPLSTR = (str) => {
  if(!str) return null;
  try {
    let STR = str.toString();

    STR = STR.replace(/\n/gi,"\\n");
    STR = STR.replace(/\s{2,}/gi, ' ');
    if(STR === ' ') return null;

    STR = STR.replace(/[^A-zА-я0-9,.!? ]/gi, '');
    if(!STR) return null;
  
    return STR;
  } catch (error) {

    return null;
  }
}
export const _REPLPHN = (str) => {
  if(!str) return null;
  try {
    let STR = str.toString();
    
    STR = STR.replace(/[^\d]/gi, "");
  
    if(!STR) return null;
    
    if(STR && (Number(STR[0]) === 7 || Number(STR[0]) === 8) ){
      STR = STR.slice(1, 11);
    }
  
    if(STR && STR.length){
      STR = `+7 (${STR[0] || ""}${STR[1] || ""}${STR[2] || ""}) ${STR[3] || ""}${STR[4] || ""}${STR[5] || ""} ${STR[6] || ""}${STR[7] || ""}${STR[8] || ""}${STR[9] || ""}`;
    }
    // 8 123 456 78 90

    return STR;
  } catch (error) {
    
    return null;
  }
}

export const _GETINIT = ({second, first, middle}) => {
  if(!second && !first && !middle) return null;

  try {
    let a = (second && _REPLSTR(second)) || '';
    let b = (first && _REPLSTR(first)) || '';
    let c = (middle && _REPLSTR(middle)) || '';
  
    return `${(a && a) || ''} ${(b && b[0] && b[0].toUpperCase() + '. ') || ''}${(c && c[0] && c[0].toUpperCase() + '.') || ''}`;
  } catch (error) {
    
    return null;
  }
}

export const _GetString = (length = 10) => {
  var str = '';

  for ( ; str.length < length; str += Math.random().toString(36).substr(2) );

  return str.substr(0, length);
}

// const Query = (...queries) => {
//   let TopBar = ({name,crumbs}) => {
//     return(
//       `TopBarState @client {
//                 name
//             }`
//     )
//   }


//   return(
//     `query Q{

//         }`
//   )
// }


export const BarName = (name) => {
  if(!name) return null;
  let query2 = gql`
      {
        TopBarState @client {
          name
        }
      }
      `;

  let Name = name || null;

  client.writeQuery({
    query: query2,
    data: {
      TopBarState: {
        name: Name,
        __typename: 'TopBarState'
      }
    },
  });
};


export const _checkUserRights = async () => {
  const Q = `{
    userrights {
      fio
      name
      type
      rights{
        collid
        coll
        access
      }
      navs{
        name
        access
      }
    }
  }`;

  const a = await QA(Q).then((q)=>{
    let query = gql`
    {
      TopBarState @client {
        name
      }
      level @client
    }
    `;

    if (q && q.userrights){
      client.writeQuery({
        query: query,
        data: {
          level: q.userrights.type,
          userrights: {
            navs: [...q.userrights.navs],
            __typename: 'userrights'
          }
        },
      });

      return q.userrights;
    }

    return false
  })

  return a
}

export const _getUserLevel = () => {
  let query = gql`query{
    level @client
  }
  `;

  const Level = client.readQuery({
    query,
  });

  return Level && Level.level || null;
}

export const Place = ({place, now, id, prevPid, prevPlace}) => {
  if(!place || place === now) return null;

  let query = gql`
    {
      AppState @client {
        place
        # pid
        # prevPid
        # prevPlace
      }
    }
    `;

  client.writeQuery({
    query: query,
    data: {
      AppState: {
        place: place.toLowerCase(),
        pid: id,
        // prevPid,
        // prevPlace,
        __typename: 'AppState'
      },
    },
  });
};
export const _hover = ({text = null, x = null, y = null, style = 'b'}) => {
  let query = gql`
    {
      hover @client {
        text
        x
        y
        style
      }
    }
    `;

  if (text) {
    setTimeout(() => {
      _hover({text: null, x: null, y: null})
    }, 2000);
  }

  client.writeQuery({
    query: query,
    data: {
      hover: {
        text,
        x,
        y,
        style,
        __typename: 'hover'
      }
    },
  })
};


export const _filter = ({string, thisValue, array, arrayZero, stateProp, compareProps = ['name', 'value'] }) => {
  const SARR = array || [];
  const PARR = arrayZero || [];
  const str =  (string && string.toString() && string.replace(/[^A-zА-я0-9 ]/gi, '')) || null;

  if(!str || !SARR || !SARR.length) {
    thisValue && thisValue.setState({
      [stateProp]: [...PARR],
      found: null,
    });

    return {
      array: [...PARR],
      found: null,
    }
  }
  const V = str.toLowerCase();
  let Arr = [];

  compareProps.forEach( prop => {
    PARR && PARR.length && PARR.filter(e => {


      if (e && compareProps && !Arr.includes(e)) {
        const regexp = new RegExp(V, "i");

        if (
          (e[prop] && e[prop].length && typeof e === 'object') &&
            ((e[prop][0] && e[prop][0].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp))
            ||
            (e[prop][1] && e[prop][1].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp)))
        ) {
          // if ( e[prop] && e[prop].length && typeof e === 'object' && ((e[prop].join(' ')).toString()).toLowerCase().match(regexp) /*&& (e[prop][0] && e[prop][0].toString().toLowerCase().match(regexp)) || (e[prop][1] && e[prop][1].toString().toLowerCase().match(regexp)))*/  ) {

          Arr.push(e);

          return e;
        }
        if(e[prop] && e[prop].toString().toLowerCase().replace(/[^A-zА-я0-9 ]/gi, '').match(regexp)) {
        
          Arr.push(e);

          return e;
        }

        return false;
      }
    
      return false;
    });
  });

  thisValue && thisValue.setState({
    [stateProp]: (Arr && Arr.length && Arr) || PARR,
    found: Arr.length,
  })

  return {
    array: (Arr && Arr.length && Arr) || PARR,
    found: Arr.length,
  }
}

export const _filt = ({str, array, fullArray, keys = ['name', 'value'] }) => {
  const SARR = [...array] || [];
  // const PARR = [...fullArray] || [];
  const PARR = [...array] || [];
  const Str =  (str && str.toString() && str.replace(/[^A-zА-я0-9 ]/gi, '')) || null;
  // const Str =  (str && str.toString()) || null;

  if(!Str || !SARR || !SARR.length) {
    return {
      array: [...PARR],
      found: null,
    }
  }
  const V = Str.toLowerCase();
  let Arr = [];

  keys.forEach( prop => {
    PARR && PARR.length && PARR.filter(e => {

      if (e && keys && !Arr.includes(e)) {
        const regexp = new RegExp(V, "i");

        if (
          (e[prop] && e[prop].length && typeof e === 'object') &&
            ((e[prop][0] && e[prop][0].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp))
            ||
            (e[prop][1] && e[prop][1].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp)))
        ) {
          Arr.push(e);

          return e;
        }
        if(e[prop] && e[prop].toString().toLowerCase().replace(/[^A-zА-я0-9 ]/gi, '').match(regexp)) {
        
          Arr.push(e);

          return e;
        }

        return false;
      }
    
      return false;
    });
  });

  return {
    array: (Arr && Arr.length && Arr) || PARR,
    found: Arr.length,
  }
}


export const _filt2 = ({str, array, fullArray, keys = ['name', 'value'] }) => {
  // const SARR = [...array] || [];
  // const PARR = [...fullArray] || [];
  // const PARR = [...array] || [];
  const Str =  (str && str.toString() && str.replace(/[^A-zА-я0-9 ]/gi, '')) || null;
  // const Str =  (str && str.toString()) || null;

  if(!Str || !Str.length) {
    return {
      // array: [...fullArray],
      array: [],
      found: null,
    }
  }
  const V = Str.toLowerCase();
  let Arr = [];

  keys.forEach( prop => {
    fullArray && fullArray.length && fullArray.filter(e => {

      if (e && keys && !Arr.includes(e)) {
        const regexp = new RegExp(V, "i");

        if (
          (e[prop] && e[prop].length && typeof e === 'object') &&
            ((e[prop][0] && e[prop][0].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp))
            ||
            (e[prop][1] && e[prop][1].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp)))
        ) {
          Arr.push(e);

          return e;
        }
        if(e[prop] && e[prop].toString().toLowerCase().replace(/[^A-zА-я0-9 ]/gi, '').match(regexp)) {
        
          Arr.push(e);

          return e;
        }

        return false;
      }
    
      return false;
    });
  });

  return {
    array: Arr && Arr.length && Arr,
    // array: (Arr && Arr.length && Arr) || fullArray,
    found: Arr.length,
  }
}


export const _filt3 = ({str, array, fullArray, keys = ['name', 'value'] }) => {
  const SARR = (array && array.length && [...array]) || [];
  // const PARR = [...fullArray] || [];
  // const PARR = [...array] || [];
  const Str =  (str && str.toString() && str.replace(/[^A-zА-я0-9 ]/gi, '')) || null;
  // const Str =  (str && str.toString()) || null;

  if(!Str || Str.length <= 1) {
    return {
      array: (fullArray && fullArray.length && [...fullArray]) || [],
      found: null,
    }
  }
  const V = Str.toLowerCase();
  let Arr = [];
  let ALL = [...SARR];

  keys.forEach( prop => {
    ALL && ALL.length && ALL.filter(e => {

      if (e && keys && !Arr.includes(e)) {
        const regexp = new RegExp(V, "i");

        if (
          (e[prop] && e[prop].length && typeof e === 'object') &&
            ((e[prop][0] && e[prop][0].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp))
            ||
            (e[prop][1] && e[prop][1].toString().replace(/[^A-zА-я0-9 ]/gi, '').toLowerCase().match(regexp)))
        ) {
          Arr.push(e);

          return e;
        }
        if(e[prop] && e[prop].toString().toLowerCase().replace(/[^A-zА-я0-9 ]/gi, '').match(regexp)) {
        
          Arr.push(e);

          return e;
        }

        return false;
      }
    
      return false;
    });
  });

  return {
    array: Arr && Arr.length && Arr,
    found: Arr.length,
  }
}


export const ToFloat = (val) => {
  if(val && typeof val === 'string' && val.match(/,/)) {
    return (val && parseFloat(((val).toString()).replace(/,/g, '.'))) || 0;
  }

  return (val && parseFloat(val)) || 0;
}