import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_TRANSPORT } from '../GQL/SERVER/QUERIES/ServerQuerys';
import { CutTime, ToHashColor, GETWEEKNEW, HoverCell, sortObjects } from '../CONSTANTS/Transformers';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import Comment from '../COMPONENTS/TABLEBOOTSTRAP/Comment';
import EditCapacityComment from '../COMPONENTS/TABLEBOOTSTRAP/EditCapacityComment';
import EditTransport from '../COMPONENTS/TABLEBOOTSTRAP/EditTransport';
import CreateWorkType from '../COMPONENTS/TABLEBOOTSTRAP/CreateWorkType';
import Loader from '../../Loader';
import { transportHours } from '../COMPONENTS/TABLEBOOTSTRAP/transportFormatter';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { Modal } from 'react-bootstrap';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

let objectFilterFunc, constructionFilterFunc, worktypeFilterFunc;

const changes = {
  value: 'объем',
  transports: 'транспорт',
}

export default class AreaTransport extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaData: {},
      modal: false,
      historymodal: false,
      historyid: null,
      emptyStrings: false, //скрывает пустые строки
      week: null,
      editComment: '',
      curDay: moment().format("YYYY-MM-DD"),
      canEdit: false, 
      dayCount: 4,
      objectFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      currentFilter: '', // текущий вид фильтра
      colums: [],
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, write }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else {
      this.getData()
      this.setState({canEdit: write})
    }
  }

  showModal = () => {
    this.setState({modal: true})
  }

  hideModal = () => {
    this.setState({modal: false})
    this.getData()
  }

  Historymodal = (id) => {
    this.setState({
      historymodal: !this.state.historymodal,
      historyid: id ? id : null, 
    })
  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.getData()})
  }

  changeWeekCount = (number) => {
    this.setState({dayCount: number}, () => { this.getData() });
  }

  resetFilter = () => {
    objectFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();

    this.setState({worktypeFilter: '',constructionFilter: '', objectFilter: '', currentFilter: ''})
  };

  validate = (newValue, fulldate) => {
    if (fulldate > Date.parse(new Date(Date.now()))) {
      return {
        valid: false,
        message: 'данные нельзя заполнять на будущие дни'
      };
    }
    if (isNaN(newValue)) {
      return {
        valid: false,
        message: 'данные не являются числом!'
      };
    }
    if (newValue < 0) {
      return {
        valid: false,
        message: 'данные не могут быть отрицательными!'
      };
    }

    return true;
  }

  emptyData =() => {}
  
  getData = () => {
    const { match } = this.props;
    const { curDay, dayCount } = this.state;
    const id = match.params.id;
    // const week = GETWEEK({date: curDay, last: dayCount});
    const week = GETWEEKNEW({date: curDay, last: dayCount});

    this.setState({ week, loaded: 0 })

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_TRANSPORT({id: id, day: curDay, dayNumber: dayCount })).then((data)=>{
      if(data && data.area) {
        this.setState({
          areaData: data.area,
          loaded: 1
        },()=>{
          this.getupdate(data.area)
        })
      } else this.setState({loaded: 2})

    })
  }

  getHeaders = () => {
    const { week, areaData, objectFilter, constructionFilter , worktypeFilter, canEdit, currentFilter } = this.state;
    let filterData = []
    let objects = {}, constructions = {}, worktype = {};
    let transports = []

    areaData.transports && areaData.transports.map(e => transports = [...transports, {name: `${(e.type ? e.type : "")} (${(e.firm ? e.firm : "Наш")}) ${(e.name ? e.name : "")} ${(e.number ? e.number : "")}`, value: e.id}]);

    areaData.workTypes.forEach((e) => {
      filterData = [...filterData, { object: e.object, construction: e.construction, worktype: `${e.value}, ${e.unit}`}]
    });

    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) 
      && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter !== "object") objects[e.object] = e.object
        if (currentFilter !== "construction") constructions[e.construction] = e.construction
        if (currentFilter !== "worktype") worktype[e.worktype] = e.worktype
      });

    //для текущего выбранного фильтра
    filterData.filter((v) => ((objectFilter && currentFilter !== "object") ? v.object === objectFilter : true) &&
    ((constructionFilter && currentFilter !== "construction") ? v.construction === constructionFilter : true) && ((worktypeFilter && currentFilter !== "worktype") ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter === "object") objects[e.object] = e.object
        if (currentFilter === "construction") constructions[e.construction] = e.construction
        if (currentFilter === "worktype") worktype[e.worktype] = e.worktype
      });

    objects = sortObjects(objects)
    constructions = sortObjects(constructions)
    worktype = sortObjects(worktype)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }
    
    if(!week || !week.length) return null;

    const headers = [
      { formatter: HoverCell, dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal, currentFilter: "object"})})},
      { formatter: HoverCell, dataField: 'construction', text: 'Сооружение', editable: false, sort: true, headerStyle: { width: '120px' }, 
        filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все',
          getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal, currentFilter: "construction"})})},
      { 
        formatter: HoverCell,
        dataField: 'worktype', text: 'Тип работ', editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: worktype, style:selectStyle, placeholder: 'все',
          getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal, currentFilter: "worktype"})})},
      ...week.map((e, i) => {
        const color = ToHashColor(e.date+i);

        return([
          { 
            dataField: 'dayF' + i + 1, 
            dateU: e.date, 
            cellType: "transports", 
            text: "Техника", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            formatter: transportHours,
            formatExtraData: {
              dayIndex: "" + i + 1,
              transports: transports
            },
            editorRenderer: (editorProps, value, row, column) => (
              <EditTransport { ...editorProps} id={row.id} date={column.dateU} transports={transports} value={ value } update={this.getData} dayId = {row["dayId" + i + 1]} />
            ),
            editable: () => canEdit && !(e.ufull > Date.now()),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconT',text:"Техника"}),
          }, 
          { 
            dataField: 'day' + i + 1, 
            dateU: e.date, 
            cellType: "value", 
            text: "Объем выполненных работ", 
            headerStyle: { fontSize: '13px', background: color, padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            formatter: (cell, row, rowIndex, formatExtraData)=>Comment(cell, row, rowIndex, formatExtraData, row["dayId" + i + 1] ? (() => this.Historymodal(row["dayId" + i + 1])) : null  ),
            formatExtraData: {
              comment: "comment" + i + 1,
              day: {...e},
            },
            editorRenderer: (editorProps, value, row, column) => (
              <EditCapacityComment { ...editorProps} id={row.id} date={column.dateU} value={ value } update={this.getData} comment = {row["comment" + i + 1]} dayId = {row["dayId" + i + 1]} />
            ),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconV',text:"Объем выполненных работ"}),
            validator: (newValue) => this.validate(newValue, e.ufull),
            editable: () => canEdit && !(e.ufull > Date.now()),
          } 
        ])}
      ).flat(),

    ]

    return headers
  }

  workTypes = (data) => {
    const { week, emptyStrings } = this.state;

    if (!data) return null;
    let DATA = data.workTypes || [];
    let colums = []

    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= moment(week[0].date) && moment(b.day)<= moment(week[week.length-1].date))) : e.days
      
      if (emptyStrings && days.length <1) return

      e.cols = { id: e.id, construction: e.construction, object: e.object, completed: e.completed, deleted: e.deleted, worktype: `${e.value}, ${e.unit}` }


      week.forEach((element, index) => {
        e.cols['day' + index + 1] = "";
        e.cols['dayF' + index + 1] = "";
        
        days.find(ins=>{
          if(!ins || !ins.day || !element.date) return false;
          let d = CutTime(ins.day);
          let d2 = CutTime(element.date);

          if(d === d2){
            e.cols['day' + index + 1] = ins.value || ""; 
            e.cols['comment' + index + 1] = (ins.comment && ins.comment) || ""
            e.cols['dayId' + index + 1] = ins.id || ""; 

            e.cols['dayF' + index + 1] = ins.transports || [];
            

            return true;
          }
          
          return false
        });
      });
      colums.push(e.cols)

      return e;
    });

    return colums;
  }

  getupdate = (data) => {
    const {emptyStrings, areaData} = this.state;
    const Data = data && data || areaData;

    if(!Data) return null;
    const newData = {name: Data.name, description: Data.description, id: Data.id, workTypes: emptyStrings ? ((Data.workTypes.length && [...Data.workTypes].filter(e => e.days.length)) || []) : Data.workTypes }
    const colums = this.workTypes(newData);

    if(colums && (!this.state.colums || !this.state.colums.length || this.state.colums !== colums)) this.setState({colums});
  }


  render() {
    const { match } = this.props;
    const { areaData, modal, canEdit, dayCount, loaded, emptyStrings, historymodal, historyid, curDay, colums } = this.state;
    const id = match.params.id;

    if (loaded === 1) {
      // const colums = this.workTypes(areaData);
      const headers = this.getHeaders();

      return (
        <div className={'Area'}>
          <div className="" style={{maxWidth:"750px",margin: '5px'}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="btnGroupAddon"><b>{areaData.name}</b></div>
                <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
              </div>
              <input type="date"
                className="form-control"
                aria-label="Input group example" aria-describedby="btnGroupAddon"
                name="trip-start"
                min="2018-01-01"
                max={(new Date().toISOString()).slice(0,10)}
                value={curDay}
                onChange={e=>this.changeDay(e)}
              />
              <button className={`btn btn-sm ${dayCount === 3 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(3)}>3</button>
              <button className={`btn btn-sm ${dayCount === 4 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(4)}>4</button>
              <button className={`btn btn-sm ${dayCount === 5 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(5)}>5</button>
              <button className={`btn btn-sm ${dayCount === 6 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(6)}>6</button>
              <button className={`btn btn-sm ${dayCount === 7 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(7)}>7</button>
              <button className={`btn btn-sm btn-secondary`} onClick={()=>this.setState({emptStrings: !emptyStrings}, ()=>{this.getupdate()})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
            </div>
          </div>
          <Table headers={headers} cols={colums} update={this.emptyData} resetFilter={this.resetFilter} addString={this.showModal} tabletype={canEdit ? "AllTable n24" : "AllTable n23"} simple={!canEdit} />
          <CreateWorkType id={id} modal={modal} deletedCols={colums.filter(col => (col.deleted === true || col.completed === true) )} hideModal={this.hideModal} brigadesDisable={true} />
        
          <Modal
            // size="sm"
            style={{color: "black"}}
            show={historymodal}
            onHide={this.Historymodal}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
              Последние изменения
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {historymodal && historyid && <Query query={gql`query H($id: String!){
                  GetDayHistory(id: $id){
                    name
                    value
                    date
                    user {
                      name
                    }
                  }
                }`} variables={{id: historyid}}>{
                  ({loading, data, error})=>{

                    if(loading) return 'loading'
                    if(data && data.GetDayHistory) {
                      return data.GetDayHistory.length && data.GetDayHistory.map((e,i)=>{
  
                        if(e && e.name) {
                          return <div className="ListTile" key={'dayhistory-'+i}>{ e.date && new Date(Number(e.date)).toLocaleString() } - Пользователь {e.user && e.user.name} изменил <i>{changes[e.name]}</i> {e.value ? "на" : ""} {e.value}</div>
                        }
  
                        return null;
                      }) || null
                    }

                    return 'none'
                  }
                }
              </Query>}
            </Modal.Body>
          </Modal>
        </div>
      )}

    if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
  }
}
