import React, { Component } from 'react'

import Root from './APP/COMPONENTS/ROOT/Root';
import LoginScreen from './APP/COMPONENTS/LOGIN/Login';
// import './App.css'
import './index.css'


export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLogin: false
    }
  }

  Login = (bool) => {
    this.setState({isLogin: bool})
  }

  render() {
    const { isLogin } = this.state

    if (isLogin) return <Root login={this.Login}/>

    return <LoginScreen login={this.Login} isLogin={isLogin} />
  }
}
