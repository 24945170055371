import React, { Component } from 'react'
import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { Signup_QA, USEREDIT_QA, DELETE_USER_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { USERS_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
// import Editorable from './Editorable.third.1';
import AdminGridEditor from './AdminGridEditor';
import { RenderEditorSelect, RenderEditor, RenderAddSelect, RenderEditSelect } from './EditorParts';

const RulesArr = {
  hi: 'Админ',
  monster: 'Админ',
  bri: 'Бригадир',
  guard: 'Охранник',
  see: 'Просмотр',
}

export default class UserMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
      users: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => {
    QA(USERS_QA).then((data => {
      data && this.setState({
        users: data.users,
      })
    }));
  }
  

  change = (inp) => {
    const { loadedHB } = this.state;

    let Filter = loadedHB.filter(e => (e.value.indexOf(inp.target.value) >= 0 && e) || null);

    this.setState({
      worktypes: Filter || loadedHB,
    })
  }

  render() {
    const { users } = this.state;

    const ELEMENTS = [
      {
        name: 'Имя для входа',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        important: true,
        prop: 'name',
        propName: null,
        array: [],
      },
      {
        name: 'ФИО',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        important: true,
        prop: 'fio',
        propName: null,
        array: [],
      },
      {
        name: 'Права',
        prop: 'type',
        propName: null,
        onRender: (props)=>RenderAddSelect({...props, /*propName: 'type', если не указано то Value*/ array: [{name: 'Админ', value: 'hi'}, {name: 'Бригадир', value: 'bri'}, {name: 'Охранник', value: 'guard'}, {name: 'Просмотр', value: 'see'}, {name: 'Обычный', value: ''}], }),
      },
      {
        name: 'Пароль',
        // value: () => Math.random().toString(36).substring(2, 5) + '-' + Math.random().toString(36).substring(2, 5) + '-' + Math.random().toString(36).substring(2, 5),
        type: 'input',
        add: true,
        edit: true,
        important: true,
        prop: 'password',
        propName: null,
        array: [],
        func: "",
      },
    ];

    const headers = [
      {
        name: '',
        prop: 'id',
      },
      {
        name: 'Логин',
        prop: 'name',
        // onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_TRANSP_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
      },
      {
        name: 'ФИО',
        prop: 'fio',
        onRender: ({Cell, Row, Col})=><RenderEditor query={USEREDIT_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
      },
      {
        name: 'Права',
        prop: 'type',
        onRender: (props)=><RenderEditSelect {...props} query={USEREDIT_QA} prop={props.Col && props.Col.prop} id={props.Row && props.Row.id} array={Object.keys(RulesArr).map(el => ({name: RulesArr[el], value: el }))} value={props.Cell} Cell={RulesArr[props.Cell]} />, // заменить рендер
        // onRender: ({Cell}) => RulesArr[Cell]
      },
      {
        name: 'Пароль',
        prop: 'password',
        onRender: ({Cell, Row, Col})=><RenderEditor query={USEREDIT_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
      },
    ];

    return(<AdminGridEditor
      // GET={TRANSP_QA}
      ADD={Signup_QA}
      EDIT={USEREDIT_QA}
      DEL={DELETE_USER_QA}
      name={'Пользователя'}
      arrayName={'Пользователи'}
      array={users}
      answerName={'user'}
      headers={headers}
      elements={ELEMENTS}
      searchKeys={['type','firm','area','name','number']}
      refetch={this.GetData}
    />)
    
    // return (
    //   <Editorable
    //     ADD={Signup_QA}
    //     // EDIT={Signup_QA}
    //     elements={ELEMENTS}
    //     name={'Пользователя'}
    //     arrayName={'Пользователи'}
    //     array={users}
    //     refetch={this.GetData}
    //     // lister
    //     tableHeaders={headers}
    //     searchKeys={['type','firm','area','name','number']}
    //   />
    // )
  }
}
