import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal } from 'react-bootstrap';
import { QA } from '../CONSTANTS';
import { GET_BRIGADE_DAYS } from '../GQL/SERVER/QUERIES/ServerQuerys';
import { CutTime, ToHashColor, GETWEEKNEW, HoverCell } from '../CONSTANTS/Transformers';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import Loader from '../../Loader';
import brigadeFormatter from '../COMPONENTS/TABLEBOOTSTRAP/brigadeFormatter';
import EditBrigadeWork from '../COMPONENTS/TABLEBOOTSTRAP/EditBrigadeWork';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';

const changes = {
  sick: "болеет",
  vacation: "отпуск",
  absenteeism: "прогул",
  dayoff: "выходной",
  fired: "уволено",
  onWork: "работает"
}


const diffStyle = (cell, factor) => {
  if((parseInt(cell) && parseInt(cell) !== parseInt(factor)) || (!parseInt(cell) && parseInt(factor)) || (parseInt(cell) && !parseInt(factor))) {
    return {
      backgroundColor: 'rgba(194, 41, 41, 0.1)'
    };
  }
}

export default class BrigadeDays extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaName: "",
      emptyStrings: false, //скрывает пустые строки
      historymodal: false, 
      historyid: null,
      brigadesData: [],
      colums: [],
      headers: [],
      week: null,
      curDay: moment().format("YYYY-MM-DD"),
      dayCount: 5,
      canEdit: false, 
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, write }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else {
      this.getData()
      this.setState({canEdit: write})
    }
  }

  
  Historymodal = (id) => {
    this.setState({
      historymodal: !this.state.historymodal,
      historyid: id ? id : null, 
      // historyid: "5dd268624ec51a1670f5bccd" 
    })
  }

  changeWeekCount = (number) => {
    this.setState({dayCount: number}, () => { this.getData() });
  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.getData()})
  }

  emptyData =() => {}

  getData = () => {
    const { match } = this.props;
    const { curDay, dayCount } = this.state;
    const id = match.params.id;
    // const week = GETWEEK({date: curDay || null, last: dayCount});
    const week = GETWEEKNEW({date: curDay || null, last: dayCount});
    

    this.setState({ week, loaded: 0 })

    if(!id) return null;
    QA(GET_BRIGADE_DAYS({id: id, day: curDay, dayNumber: dayCount})).then((data)=>{
      if(data && data.brigades && data.area) {
        this.setState({
          brigadesData: data.brigades,
          areaName: data.area.name,
          loaded: 1
        }, ()=>{
          this.updatedatas(data.brigades)
        })
      } else this.setState({ loaded: 2 })
    })
  }

  getHeaders = () => {
    const { week, canEdit } = this.state;
    
    if(!week || !week.length) return null;

    const headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'brigade', text: "Бригада", editable: false, sort: true, headerStyle: { width: '110px' }},
      ...week.map((e, i) => {
        const color = ToHashColor(e.date+i);

        return([
          { 
            dataField: 'dayF' + i + 1, 
            dateU: e.date, 
            cellType: "fact", 
            text: "Факт", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            editable: false,
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconF',text:"На основании данных, внесенных на странице Площадки по всем видам работ"}),
            style: (cell, row) => diffStyle(cell, row['dayW' + i + 1]),
          }, 
          { 
            dataField: 'dayW' + i + 1, 
            dateU: e.date, 
            cellType: "onWork", 
            text: "Работают",
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/ }, 
            editorStyle: {fontSize: '12px'},
            editable: () => canEdit && !(e.ufull > Date.now()),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconWon',text:"Работают"}),
            style: (cell, row) => diffStyle(cell, row['dayF' + i + 1]),
          }, 
          { 
            dataField: 'day' + i + 1, 
            dateU: e.date, 
            cellType: "notWork", 
            text: "Не работают", 
            headerStyle: { background: color, fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/ }, 
            formatter: (cell, row, rowIndex, formatExtraData)=>brigadeFormatter(cell, row, rowIndex, formatExtraData, row["dayId" + i + 1] ? (() => this.Historymodal(row["dayId" + i + 1])) : null ),
            editorStyle: {fontSize: '12px'},
            editorRenderer: (editorProps, value, row, column) => (
              <EditBrigadeWork { ...editorProps} id={row.id} date={column.dateU} value={ value } update={this.getData} dayId = {row["dayId" + i + 1]} />
            ),
            editable: () => canEdit && !(e.ufull > Date.now()),
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'iconWoff',text:"Не работают"}),
          }, 
        ])}
      ).flat(),

    ]

    return headers
  }

  getColumns = (data) => {
    const { week, emptyStrings } = this.state;

    if (!data) return null;
    let DATA = data || [];
    let colums = []

    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= moment(week[0].date) && moment(b.day)<= moment(week[week.length-1].date))) : e.days
      
      if (emptyStrings && days.length <1) return

      e.cols = { id: e.id, brigade: e.name }
      week.forEach((element, index) => {
        e.cols['dayW' + index + 1] = "";
        e.cols['dayF' + index + 1] = "";
        e.cols['day' + index + 1] = {};
        
        e.days.find(ins=>{
          if(!ins || !ins.day || !element.date) return false;
          let d = CutTime(ins.day);
          let d2 = CutTime(element.date);

          if(d === d2){
            e.cols['dayW' + index + 1] = ins.onWork || ""; 
            e.cols['dayF' + index + 1] = ins.factor ? ToFloat(ins.factor).toFixed(2) : ""; 
            e.cols['day' + index + 1] = ins.notWork || {}; 
            e.cols['dayId' + index + 1] = ins.id || ""; 

            return true;
          }
          
          return false
        });
      });
      colums.push(e.cols)

      return e;
    });

    return colums;
  }

  updatedatas = (brigadesData) => {

    const colums = this.getColumns(brigadesData || this.state.brigadesData);
    const headers = this.getHeaders();

    if(headers && headers.length && headers !== this.state.headers){
      this.setState({
        headers,
      })
    }
    if(colums && colums.length && colums !== this.state.colums){
      this.setState({
        colums,
      })
    }
  }

  render() {
    const { areaName, dayCount, loaded, colums, headers, curDay, emptyStrings, historymodal, historyid } = this.state;

    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>

    return (
      <div className={'Area'} style={{width: '100%',margin:'0'}}>
        <div style={{width: '100%', height: 'auto', margin:'0 auto', position: 'fixed', top: '0', right:'auto', left:'auto', display:'flex', flexDirection:'row', justifyContent:'center',
          alignContent:'center', alignItems:'center', zIndex: '9999',}}>
          {loaded === 0 && <Loader />}
        </div>
        {<div className="" style={{maxWidth:"750px",margin: '5px'}}>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="btnGroupAddon"><b>{areaName}</b></div>
              <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
            </div>
            <input type="date"
              className="form-control"
              value={curDay}
              aria-label="Input group example" aria-describedby="btnGroupAddon"
              name="trip-start"
              min="2018-01-01"
              max={(new Date().toISOString()).slice(0,10)}
              onChange={e=>this.changeDay(e)}
            />
            <button className={`btn btn-sm ${dayCount === 3 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(3)}>3</button>
            <button className={`btn btn-sm ${dayCount === 5 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(5)}>5</button>
            <button className={`btn btn-sm ${dayCount === 7 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeekCount(7)}>7</button>
            <button className={`btn btn-sm btn-secondary`} onClick={()=>this.setState({emptyStrings: !emptyStrings}, ()=>{this.updatedatas()})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
          </div>
        </div>}
        {headers && headers.length && <Table headers={headers} cols={colums} update={this.emptyData} resetFilter={this.resetFilter} addString={this.showModal} tabletype="AllTable n31" simple={true} /> || null}
        
        <Modal
          // size="sm"
          style={{color: "black"}}
          show={historymodal}
          onHide={this.Historymodal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Последние изменения
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {historymodal && historyid && <Query query={gql`query H($id: String!){
                  GetBrigadeDayHistory(id: $id){
                    name
                    value
                    date
                    user {
                      name
                    }
                    data {
                      name
                      value
                    }
                  }
                }`} variables={{id: historyid}}>{
                ({loading, data})=>{

                  if(loading) return 'loading'
                  if(data && data.GetBrigadeDayHistory) {
                    return data.GetBrigadeDayHistory.length && data.GetBrigadeDayHistory.map((e,i)=>{
  
                      if(e && e.name) {
                        return (
                          <div className="ListTile" key={'dayhistory-'+i}>
                            { e.date && new Date(Number(e.date)).toLocaleString() } - Пользователь {e.user && e.user.name} выставил:
                            { e.data && e.data.length && e.data.map((e,i)=> 
                              <div key={'element-'+i}>
                                <i>{changes[e.name]}</i> {e.value}
                              </div>
                            )
                            }
                          </div>
                        )
                      }
  
                      return null;
                    }) || null
                  }

                  return 'none'
                }
              }
            </Query>}
          </Modal.Body>
        </Modal>

          
      </div>
    )
  }
}
