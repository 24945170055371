import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import PropTypes from 'prop-types'
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import '../../CSS/ROOTLEVEL/ROOT/root.css'
import '../../CSS/ROOTLEVEL/APP/app.css'
import '../../CSS/ROOTLEVEL/rootscreen.css';
import './../../CSS/style.css';

import NavBar from '../NAVBAR/NavBar';
import { PLACE, Hovered } from '../../CONSTANTS/Transformers';
import { getAppState } from '../../GQL/CACHE/QUERIES/CacheQueries';
import Redirector from './Redirector';
import RightsChecker from './RightsChecker';

const getHover = gql`
{
  hover @client {
    text
    x
    y
    style
  }
}
`;

class Root extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      data: null,
      place: null,
    }
  }

  static propTypes = {
    G: PropTypes.object.isRequired,
  }

  componentDidUpdate(){
    const P = (this.props.G
      && this.props.G.AppState
      && this.props.G.AppState.place
      && this.props.G.AppState.place.toLowerCase()) || null;

    if (P){
      PLACE({place: P})
    }
  }
  
  shouldComponentUpdate(nextProps) {
    if(nextProps.G.AppState !== this.props.G.AppState) return true;
    if(nextProps.h.hover !== this.props.h.hover) return true;

    return false;
  }

  render() {
    const {h} = this.props;

    return (
      <Router>
        <div className="Root">
          <NavBar {...this.props}/>
          <div className={'RootContentWrapper'}>
            <div className={"ContentWrapper "+ ((this.props.G.AppState && this.props.G.AppState.place && this.props.G.AppState.place.toLowerCase()) || 'areas')}>
              <Route path="/" exact component={Redirector} />
              <Route path="/:c/" exact render={(props) => <RightsChecker {...props} />} />
              <Route path="/:c/:id" render={(props) => <RightsChecker {...props} />} />
            </div>
          </div>
        </div>
        {h.hover && h.hover.text && <Hovered text={h.hover.text} x={h.hover.x} y={h.hover.y} style={h.hover.style} />}
      </Router>
    )
  }
}

export default compose(
  graphql(getAppState, { name: "G"}),
  graphql(getHover, { name: 'h' }),
)(withApollo(Root))
