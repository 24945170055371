import React, { Component } from 'react'
import { AUTH_TOKEN } from '../../../CONSTANTS';
import PropTypes from 'prop-types'

class Footer extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
  }
  logout = () => {
    const { login } = this.props;

    localStorage.setItem(AUTH_TOKEN, "");
    localStorage.setItem('userId', "");
    localStorage.setItem('userName', "");
    if (login) login(false)
  }
  
  render() {
    return (
      <div className="Foot">
        
        <button type="button" className="btn btn-link" onClick={this.logout}>Выйти</button>
      </div>
    )
  }
}

export default Footer
