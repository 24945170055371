import React, {Fragment} from 'react'
import { _getUserLevel, _hover } from '../../CONSTANTS/operations';

const brigadeFormatter = (cell, row, rowIndex, formatExtraData, history) => {
  let sum = 0;

  sum = sum + (cell.sick ? parseInt(cell.sick) : 0) + (cell.vacation ? parseInt(cell.vacation) : 0) + (cell.absenteeism ? parseInt(cell.absenteeism) : 0) + 
  (cell.dayoff ? parseInt(cell.dayoff) : 0) + (cell.fired ? parseInt(cell.fired) : 0)

  return (
    <Fragment>
      {history && typeof history === 'function' && _getUserLevel && _getUserLevel() && (_getUserLevel() === 'monster' || _getUserLevel() === 'hi') &&
        <div className="historyEvent"
          onMouseEnter={(e)=>{
            const rect = e.target.getBoundingClientRect();

            _hover({text: <div className="full" onClick={()=>{history(row.id);}}></div>, x: rect.x, y: rect.y, style: "t" });
          }}
        >
        </div>
      }
      <div className="tableHeader">
        {cell.absenteeism && cell.absenteeism !== "0" && <p>прогул: {cell.absenteeism}</p>}
        {cell.dayoff && cell.dayoff !== "0" && <p>выходной: {cell.dayoff}</p>}
        {cell.fired && cell.fired !== "0" && <p>уволено: {cell.fired}</p>}
        {cell.sick && cell.sick !== "0" && <p>болеет: {cell.sick}</p>}
        {cell.vacation && cell.vacation !== "0" && <p>отпуск: {cell.vacation}</p>}
        {sum !==0 && <p><b>всего: {sum}</b></p>}
      </div>
    </Fragment>
  );
}

export default brigadeFormatter 
