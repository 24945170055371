import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CutTimeObj } from '../CONSTANTS/Transformers2'

export default class Test extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    return (
      <div>
        <hr/>
        Сентябрь
        <hr/>
        1<div>
          {
            CutTimeObj(Date.parse('2018-09-09')).date
          }
        </div>
        2<div>
          {
            CutTimeObj(Date.parse('2018-09-10')).date
          }
        </div>
        <hr/>
          Октябрь
        <hr/>
        3<div>
          {
            CutTimeObj(Date.parse('2018-10-09')).date
          }
        </div>
        4<div>
          {
            CutTimeObj(Date.parse('2018-10-10')).date
          }
        </div>
        5<div>
          {
            CutTimeObj('2018-10-10').date
          }
        </div>

        6<div>
          {
            CutTimeObj('1539043200000').date
          }
        </div>
        7<div>
          {
            CutTimeObj(1539043200000).date
          }
        </div>
        8<div>
          {
            CutTimeObj().date
          }
        </div>
      </div>
    )
  }
}
