import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';
import { Mutation } from 'react-apollo';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { QA, _url, protocol } from '../../CONSTANTS';
import { uploadFile, DELETE_FILE } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { GET_FILES } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Confirmation from '../PARTS/Confirmation';

const baseStyle = {
  width: "100%",
  height: 100,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
};

const activeStyle = {
  borderStyle: 'solid',
  backgroundColor: '#cecece',
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

export default class UploadFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      successUpload: false,
      successDelete: false,
      modalPreview: false,
      modalDelete: false,
      files: [],
      fileName: '',
      fileUrl: '',
      fileId: ''
    }
  }
  
  static propTypes = {
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dayId: PropTypes.string,
  }

  getFiles = () => {
    const { dayId } = this.props;

    if (!dayId) return null;
    const getFiles = GET_FILES({
      id: dayId,
    });

    QA(getFiles).then((data)=>{
      if (data && data.files) 
        this.setState({
          files: data.files,
        })
    })
  }

  hideModalDelete = () => {
    this.setState({fileId: '', fileName:'', modalDelete: false})
  }

  deleteFile = (id, name) => {
    this.setState({fileId: id, fileName:name, modalDelete: true})
  }

  deleteFileMutation = () => {
    const {fileId} = this.state

    QA(DELETE_FILE({id: fileId})).then(()=>{
      console.warn("delete done")
      this.setState({successDelete: true, fileId: '', fileName:'', modalDelete: false})
      setTimeout(()=>{
        this.setState({
          successDelete: false,
        })
      }, 3000)
      setTimeout(()=>{
        this.getFiles()
      }, 3000)
    })
  }

  download = (file) => {
    axios({
      url: `${protocol}${_url}/download/${file.id}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {

      const url = window.URL.createObjectURL(new Blob([response.data], {type: file.mimeType}));

      this.setState({ fileUrl: url, fileName: file.name });
      if (['image/png', 'image/gif', 'image/jpeg'].indexOf(file.mimeType)>=0) {
        this.setState({ modalPreview: true });
      } else this.downloadLink()
    });
  }

  downloadLink = () => {
    const link = document.createElement('a');

    link.href = this.state.fileUrl;
    link.setAttribute('download', this.state.fileName);
    document.body.appendChild(link);
    link.click();
  }

  componentDidMount(){
    this.getFiles();
  }

  render() {
    const { id, date } = this.props;
    const { successUpload, successDelete, modalPreview, modalDelete, files, fileName, fileUrl } = this.state;

    let wait = false

    return (
      <div style={{width: "100%"}}>
        {files.length >0 && files.map((e, i)=>{
          return(
            <div key={e.id + ''+ i}>
              <span style={{cursor: 'pointer', color: "blue"}} onClick={()=>{this.download(e) }}>{e.name}</span> 
              
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 40 40" style={{fill: "#C74343", marginLeft: "7px", cursor: "pointer"}} onClick={()=>{this.deleteFile(e.id, e.name) }}>
                <path style={{fill: "#C74343", marginLeft: "5px"}} d="M33.143,4.414l3.443,3.443L25.15,19.293L24.443,20l0.707,0.707l11.436,11.436l-3.443,3.443
		L21.707,24.15L21,23.443l-0.707,0.707L8.857,35.586l-3.443-3.443L16.85,20.707L17.557,20l-0.707-0.707L5.414,7.857l3.443-3.443
		L20.293,15.85L21,16.557l0.707-0.707L33.143,4.414 M33.143,3L21,15.143L8.857,3L4,7.857L16.143,20L4,32.143L8.857,37L21,24.857
		L33.143,37L38,32.143L25.857,20L38,7.857L33.143,3L33.143,3z"></path>
              </svg>
              {/* this.deleteFile(e.id) */}
            </div>
          )
        })
        }
        <Mutation mutation={uploadFile} onCompleted={(data) => {
          if (data && data.uploadFile && data.uploadFile.id) {
            this.setState({successUpload: true})
            wait = false
            setTimeout(()=>{
              this.setState({
                successUpload: false,
              })
            }, 3000)
            setTimeout(()=>{
              this.getFiles()
            }, 3000)
          }
        }}>
          {upload => (
            <Dropzone className="files-drop" accept="image/*, application/pdf" maxSize={5000000} onDrop={([file]) => {
              if (file && ['image/png', 'image/gif', 'image/jpeg', 'application/pdf'].indexOf(file.type) != -1 && file.size <= 5000000) {
                wait = true
                upload({ variables: { id, date, file } })
              }
            }}>
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, rejectedFiles }) => {
                let styles = {...baseStyle}

                styles = isDragActive ? {...styles, ...activeStyle} : styles
                styles = isDragReject ? {...styles, ...rejectStyle} : styles

                return (
                  <div
                    {...getRootProps()}
                    style={styles}
                  >
                    <input {...getInputProps()} />
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",}}>
                      {wait && <div className="alert alert-warning" role="alert">Ждите...</div>}
                      {successUpload && <div className="alert alert-success" role="alert">Файл успешно сохранен</div>}
                      {successDelete && <div className="alert alert-success" role="alert">Файл успешно удален</div>}
                      {!wait && !successUpload && !successDelete && !isDragAccept && 'Переместите сюда файлы...'}
                      {!wait && !successUpload && !successDelete && isDragAccept && 'Переместите сюда файлы или нажмите для добавления...'}
                      {isDragReject || rejectedFiles.length > 0 && <div className="alert alert-danger" role="alert">Нельзя добавить такой тип документа. Принимаются png, gif, jpeg, pdf размером менее 5мб</div>}
                    </div>
                  </div>
                )
              }}
            </Dropzone>
          )}
        </Mutation>
        <Modal
          size="lg"
          style={{color: "#fff9"}}
          show={modalPreview}
          onHide={()=>{this.setState({modalPreview: !modalPreview})}}
          aria-labelledby="example-modal-sizes-title-lg"
        >                    
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Файл: {fileName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img alt="picPreview" src={fileUrl} style={{ margin: "0 auto", display: "block", maxHeight: "100%", maxWidth: "100%" }}/>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-lg btn-success" onClick={this.downloadLink}>Скачать</button>
          </Modal.Footer>
        </Modal>
        <Confirmation modal={ modalDelete } hideModal={ this.hideModalDelete } doAction={ this.deleteFileMutation } title={`Удалить файл ${fileName.slice(0, 20)}?`} />
      </div>
    )
  }
}
