import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS';

export default class RecursiveViewer extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props)
  
    this.state = {
      data: [],
      list: [],
      pos: 0,
      show: true,
      showMenu: true,
      last: false,
    }
  }
  
  componentWillMount(){
    const {recursive, startPos, startParam} = this.props;

    const query = (startPos && recursive && recursive[startPos] && recursive[startPos].query) || (recursive && recursive[0].query) || null;
    const arrayName = (startPos && recursive && recursive[startPos] && recursive[startPos].arrayName) || (recursive && recursive[0].arrayName) || null;
    const queryProp = (startPos && recursive && recursive[startPos] && recursive[startPos].queryProp) || (recursive && recursive[0].queryProp) || null;
    const queryValue = (startParam && startParam) || (startPos && recursive && recursive[startPos] && recursive[startPos].queryValue) || (recursive && recursive[0].queryValue) || null;
    const name = (startPos && recursive && recursive[startPos] && recursive[startPos].name) || (recursive && recursive[0].name) || null;
    const last = recursive && recursive.length === startPos && true || null;

    const P = {
      query,
      arrayName,
      queryProp,
      queryValue,
      position: startPos || 0,
      name,
      last,
    };

    this.fetch(P);
  }

  fetch = ({ query, arrayName, queryProp, queryValue, position, name, last }) => {
    try {
      if(!query) return null;
      let Props = "";

      if(queryProp && queryValue) {
        Props = {}
        Props[queryProp] = queryValue;
      }

      let Q = query && typeof query === 'function' && query(Props) || query;
      
      QA(Q).then((ans) => {
        if(!ans) return null;

        this.setState({
          list: (arrayName && ans && ans[arrayName] && ans[arrayName].length && ans[arrayName])
          || (ans && Object.keys(ans) && Object.keys(ans)[0] && ans[Object.keys(ans)[0]] && ans[Object.keys(ans)[0]][arrayName] && ans[Object.keys(ans)[0]][arrayName].length && ans[Object.keys(ans)[0]][arrayName])
          || (ans && Object.keys(ans) && Object.keys(ans)[0] && ans[Object.keys(ans)[0]] && ans[Object.keys(ans)[0]].length && ans[Object.keys(ans)[0]])
          || (ans && ans.length && ans)
          || [],
          name: name,
          pos: position+1,
          last: last && true || false,
        })
      });

    } catch (error) {
      return null;
    }

  }
  next = (data) => {
    const {pos} = this.state;
    const {recursive, startPos, startParam} = this.props;
    let POS = pos;

    if(!POS || !data || !recursive || !recursive.length || !recursive[POS] || pos === recursive.length) {
      POS = 0;
    }

    const P = {
      query: (recursive[POS].query) || null,
      arrayName: (recursive[POS].arrayName) || null,
      queryProp: (recursive[POS].queryProp) || null,
      queryValue: (data && recursive[POS].queryValue && data[recursive && recursive[POS].queryValue]) || (recursive && recursive[POS].queryValue) || null,
      position: POS,
      name: ((data && data.name && data.name + ' - ' ) || (data && recursive && data.value && recursive[POS] &&  recursive[POS].name && ((recursive[POS].name + ': - ') + (data.value))) || recursive[POS] && recursive[POS].name || "")|| null,
      last: recursive && recursive.length === (POS + 1) && true || null,
    };

    this.fetch(P);
  }
  clear = () => {

  }

  render() {
    const {recursive, startPos, startParam, SelectFunc, NextFunc, selectRecursive, } = this.props;
    const {st, list, name, pos, show, showMenu, last, idx} = this.state;

    return (
      <div className={'RecursiveViewer'}>
        <div className="Header RecItem" onClick={()=>{this.setState({show: !show})}}>{name}</div>
        {pos && pos > 1 && <button type="button" className="btn btn-secondary" onClick={()=>this.next()}>Наверх</button>}
        {show && <div className="Hierarchy">

          <div className="Overflowed">
            {
              list && list.length && list
                .sort((a,b)=>{
                  if (a.value < b.value ) {
                    return -1;
                  }
                  if (a.value > b.value ) {
                    return 1;
                  }
        
                  return 0;
                })
                .sort((a,b)=>{
                  if (a.value < b.value ) {
                    return -1;
                  }
                  if (a.value > b.value ) {
                    return 1;
                  }
        
                  return 0;
                })
                .map((e,i)=>{

                  if(!e) return null;
                  // const EL = [];

                  // Object.keys(e) && Object.keys(e).length && Object.keys(e).forEach(element => {
                  //   if(!element || element === 'id') return null;
                  //   if(!e[element] || typeof e[element] !== 'string' ) return null;

                  //   EL.push(<div className={'Rand'+element}>{e[element]}</div>)
                  // });

                  return(
                    <div className={"RecItem ItemWithMenuClicker" + ' colorpos'+pos} key={"RecItem"+i}
                      onClick={
                        ()=> true
                        // this.setState({
                        //   showMenu: !this.state.showMenu,
                        // })

                        //   SelectFunc
                        //   && typeof SelectFunc === 'function' && recursive
                        //   && recursive[pos-1]
                        //   && recursive[pos-1].onSelect
                        //   && recursive[pos-1].onSelect === 'editorSelect'
                        //   && SelectFunc(e, this.next)
                        // || (recursive &&
                        // recursive[pos] &&
                        // (!recursive[pos-1].onSelect) &&
                        // this.next(e)) ||
                        // (recursive &&
                        // recursive[pos-1] &&
                        // recursive[pos-1].onSelect &&
                        // typeof recursive[pos-1].onSelect === 'function' &&
                        // recursive[pos-1].onSelect(e)
                        // ) || true
                      }
                    > 

                      <div className="wrapperCol">
                        <div className="topwrap">
                          {Object.keys(e) && Object.keys(e).length && Object.keys(e)
                            .map((element,i) => {
                              if(!element || element === 'id') return null;
                              if(!e[element] || typeof e[element] !== 'string' ) return null;

                              return <div className={'Rand '+element} key={'Rand'+element+i}>{e[element]}</div>;
                            })}
                        </div>
                        {/* <div>
                          {recursive && recursive[pos] && recursive[pos].params && recursive[pos].params.length &&
                              recursive[pos].params.map((p,i)=>{

                                return(e[p] && <span key={"param"+i} className="param">{ e[p] }</span> || null) 
                              })
                          }
                        </div> */}
                        {this.state['showMenu'+e.id] && <div className="showMenu">
                          {pos && recursive && pos === recursive.length && <div className="showMenuItem" onClick={()=>selectRecursive && typeof selectRecursive === 'function' && selectRecursive(e, this.next, last)}>Выбрать</div>}
                          {/* {!last && <div className="showMenuItem" onClick={()=>this.next(e)}>Перейти</div> */}
                        </div>}
                        
                      </div>
                      <div className={"wrapper"+ (this.state['showMenu'+e.id] && ' m' || "")}>
                        <div className="clicker" onClick={()=>this.setState({['showMenu'+e.id]: !this.state['showMenu'+e.id]})}></div>
                        <div className="nexter" onClick={()=>this.next(e)}></div>

                      </div>

                      {/* 
                      <div className="btn-group" role="group" aria-label="Basic example">
                        { recursive &&
                          recursive[pos] && (recursive[pos-1].onSelect) && <button type="button" className="btn btn-secondary" onClick={() => this.next(e)}>Перейти</button>
                        }
                        { recursive &&
                        recursive[pos-1] &&
                        recursive[pos-1].onSelect && typeof recursive[pos-1].onSelect === 'function' && recursive[pos-1].onNext && <button type="button" className="btn btn-secondary"
                          onClick={() => recursive[pos-1].onSelect(e)}>Выбрать</button> }
                      </div> */}



                    </div>
                  )
                }) || null
            }</div>
        </div>}
      </div>
    )
  }
}
