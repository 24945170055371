import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import SelectSearch from 'react-select-search';
import { Button } from 'react-bootstrap';
import HighchartsReact from "highcharts-react-official";
// Import Highcharts
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_GANTT } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Loader from '../../Loader';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';

Highcharts.setOptions({
  lang: {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль','Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сеп', 'Окт', 'Ноя', 'Дек'],
    shortWeekdays: ['Вс', 'Пн', 'Вт', 'Ср','Чт', 'Пт', 'Сб'],
    Week: "неделя",
    loading: 'Загрузка...',
    noData: 'Нет данных для отображения',
    contextButtonTitle: 'Контекстное меню',
    exportButtonTitle: "Экспорт",
    printButtonTitle: "Печать",
    rangeSelectorFrom: "С",
    rangeSelectorTo: "По",
    rangeSelectorZoom: "Период",
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG',
    printChart: 'Напечатать график'
  },
  rangeSelector: {
    buttons: [
      { count: 1, type: 'month', text: '1 м'}, 
      { count: 3, type: 'month', text: '3 м'},
      { count: 6, type: 'month', text: '6 м'},
      { count: 1, type: 'year' , text: '1 год'},
      { type: 'all', text: 'всё'}
    ],
    inputEnabled: false,
    selected: 0
  },
  credits: {
    enabled: false,
  },
});

highchartsGantt(Highcharts);

export const renderOption = (option) => {
  const styleParent = {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    float: 'left',
  };
  
  return (
    <div className="renderParent"
      style={styleParent}
    >
      {option.name}
    </div>
  )
}


const mounths = [
  {name: '1 неделя', value: 7},
  {name: '2 недели', value: 14},
  {name: '1 месяц', value: 30},
  {name: '2 месяца', value: 60},
  {name: '3 месяца', value: 90},
]

export default class Gantt extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaData: {},
      colums: [],
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
      overflow: false,
      dayMax: parseInt(moment().format("x")),
      dayMin:  0,
      curDay: moment().format("YYYY-MM-DD"),
      dayCount: 14,
      stringCount: 0,
      series: [],
      brigadeFilter: '', //текущие филтры
      objectFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      currentFilter: '', // текущий вид фильтра
      brigades: [], //массивы выбора фильтра
      objects: [],
      constructions: [],
      worktypes: [],
      height: props.height
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
    this.setState({height: window.innerHeight});
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else {
      this.getData()
    }
  }

  getData = () => {
    const { match } = this.props;
    const { curDay, dayCount } = this.state;
    const id = match.params.id;

    this.setState({ loaded: 0 })

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_GANTT({ id: id, day: curDay, dayNumber: dayCount })).then((data)=>{
      if(data && data.area) {

        this.setState({
          areaData: data.area,
        },()=>{
          this.getupdate(data.area)
        })
      } else this.setState({loaded: 2})

    })
  }

  filter = () => {
    const {  colums, objectFilter, brigadeFilter, constructionFilter , worktypeFilter, currentFilter } = this.state;

    if(!colums && !colums.length) return null;
    let filterData = [], brigades = [], objects = [], constructions = [], worktypes = [], wtCount = [];
    let dayMin = parseInt(moment().format("x")), dayMax = 0;

    this.setState({
      loaded: 0
    });

    filterData = colums.filter((v) => (objectFilter ? v.object === objectFilter : true) && (brigadeFilter ? v.brigade === brigadeFilter : true)
      && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true))

    filterData.forEach(e => {
      if (e.start < dayMin ) dayMin = e.start
      if (e.end > dayMax ) dayMax = e.end

      if (wtCount.indexOf(e.id) === -1) wtCount.push(e.id)
      if (!brigades.find(({name}) => name === e.brigade)) brigades = [...brigades, {name: e.brigade, value: e.brigade}]
      if (!objects.find(({name}) => name === e.object)) objects = [...objects, {name: e.object, value: e.object}]
      if (!constructions.find(({name}) => name === e.construction)) constructions = [...constructions, {name: e.construction, value: e.construction}]
      if (!worktypes.find(({name}) => name === e.worktype)) worktypes = [...worktypes, {name: e.worktype, value: e.worktype}]
    });


    brigades.sort(function(a,b) {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
  
      return x < y ? -1 : x > y ? 1 : 0;
    });

    objects.sort(function(a,b) {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
  
      return x < y ? -1 : x > y ? 1 : 0;
    });

    constructions.sort(function(a,b) {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
  
      return x < y ? -1 : x > y ? 1 : 0;
    });

    worktypes.sort(function(a,b) {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
  
      return x < y ? -1 : x > y ? 1 : 0;
    });
    
    this.setState({
      brigades: currentFilter === "brigade" ? this.state.brigades : brigades, 
      objects: currentFilter === "object" ? this.state.objects : objects, 
      constructions: currentFilter === "construction" ? this.state.constructions : constructions, 
      worktypes: currentFilter === "worktype" ? this.state.worktypes : worktypes, 
      dayMin, dayMax,
      overflow: (filterData.length > 1000) ? true : false,
      stringCount: wtCount.length,
      series: [{
        name: 'Работы',
        data: (filterData.length > 1000) ? filterData.slice(0, 1000) : filterData,
      }],
    },()=>{
      this.setState({loaded: 1})
    });
  }

  workTypes = (data) => {
    if (!data) return null;
    let DATA = data.workTypes || [];
    let colums = []
    // let stringCount = 0
    let dayMin = parseInt(moment().format("x")), dayMax = 0;

    DATA.map(e => {
      if (!e.deleted) {
        // stringCount = stringCount + 1
        if (e.days && e.days.length) {

          e.days.forEach(day => {
            if (day.value && parseFloat(day.value) > 0) {
              const dateStart = parseInt(moment(parseInt(day.dateParse)).format("x"))
              const dateEnd = parseInt(moment(dateStart).add(1, 'days').format("x"))

              if (dateStart < dayMin ) dayMin = dateStart
              if (dateEnd > dayMax ) dayMax = dateEnd
              const column = { 
                id: e.id, brigade: e.brigade, construction: e.construction, object: e.object, worktype: `${e.value}, ${e.unit}`, wt: e.value, edizm: e.unit,
                name: `${e.value} [${e.unit}] (${e.construction}/${e.brigade})`, value: day.value,  start: dateStart, end: dateEnd, comment: day.comment,
              }; 

              colums.push(column)
            }
          });
        }
      
        return e;
      }
    })
    
    this.setState({dayMin, dayMax/* , stringCount */})
    
    colums.sort((function(a, b){
      return a.start - b.start;
    }))
    
    // console.warn("colums", colums);
    
    return colums;
  }

  getupdate = (data) => {
    const { areaData } = this.state;
    let Data = data && data || areaData;

    if(!Data) return null;
    const colums = this.workTypes(Data);

    if(colums && (!this.state.colums || !this.state.colums.length || this.state.colums !== colums)) this.setState({colums},() => this.filter());
  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({loaded: 0, curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => this.getData())
  }

  changeDayCount = (value) => this.setState({loaded: 0, dayCount: value }, () => this.getData());

  resetFilter = () => this.setState({loaded: 0, worktypeFilter: '',constructionFilter: '', objectFilter: '', brigadeFilter: '', currentFilter: ''}, () => this.filter())
  
  render() {
    const { 
      areaData, loaded, dayMin, /* dayMax, */ stringCount, series, 
      objectFilter, brigadeFilter, constructionFilter, worktypeFilter, 
      brigades, objects, constructions, worktypes, overflow,
      dayCount, curDay, height
    } = this.state;

    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
    if (loaded === 0 ) return <Loader />

    console.warn("stringCount", stringCount);
    // console.warn("days11", moment(dayMin).format("YYYY-MM-DD"), moment(dayMax).format("YYYY-MM-DD"))
    
    return (
      <div className={'Area'} style={{width: '100%',margin:'0'}}>
        <div className="input-group-prepend" style={{maxHeight: '50px'}}> 
          <div className="input-group-text" id="btnGroupAddon"><b>{areaData.name}</b></div>
          <div className="input-group-text" id="btnGroupAddon">Дата</div>
          <input type="date"
            style={{width: '170px'}}
            className="form-control"
            value={curDay}
            aria-label="Input group example" aria-describedby="btnGroupAddon"
            name="trip-start"
            min="2018-01-01"
            max={(new Date().toISOString()).slice(0,10)}
            onChange={e=>this.changeDay(e)}
          />
          <SelectSearch options={mounths} style={{width: '80px'}} renderOption={renderOption} value={dayCount} onChange={(value) => this.changeDayCount(value.value)} placeholder="Месяцы" />
          {(objects.length > 0) && <SelectSearch options={objects} renderOption={renderOption} value={objectFilter} onChange={(value) => {this.setState({loaded: 0, currentFilter: "object", objectFilter: value.value}, () => this.filter())}} placeholder="Объект" />}
          {(brigades.length > 0) && <SelectSearch options={brigades} renderOption={renderOption} value={brigadeFilter} onChange={(value) => {this.setState({loaded: 0, currentFilter: "brigade", brigadeFilter: value.value}, () => this.filter())}} placeholder="Бригада" />}
          {(constructions.length > 0) && <SelectSearch options={constructions} renderOption={renderOption} value={constructionFilter} onChange={(value) => {this.setState({loaded: 0, currentFilter: "construction", constructionFilter: value.value}, () => this.filter())}} placeholder="Сооружение" />}
          {(worktypes.length > 0) && <span className="SelectSearchWidthMore"> <SelectSearch options={worktypes} renderOption={renderOption} value={worktypeFilter} onChange={(value) => {this.setState({loaded: 0, currentFilter: "worktype", worktypeFilter: value.value}, () => this.filter())}} placeholder="Работа" /></span>}
          <Button className="btn btn btn-info" onClick={() => this.resetFilter()}>Очистить</Button>
          {overflow && <div className="alert alert-danger" style={{height: '50px', width: '100px', fontSize: "10px"}} role="alert">Данные не полные!</div>}
          {(!objects.length && !brigades.length || !constructions.length || !worktypes.length || objects.length === 0 && brigades.length === 0 || constructions.length === 0|| worktypes.length ===0 )
          && <div className="alert alert-danger" style={{height: '50px', width: '200px', fontSize: "12px"}} role="alert">Нет данных по фильтру!</div>}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"ganttChart"}
          options={{
            // title: {
            //   text: 'Диаграмма Гантта по работам'
            // },
            chart: {
              // height: 300  + stringCount*20
              height: height-60,
            },
            xAxis: {
              min: dayMin,
              labels: {
                style: {
                  fontSize: '11px',
                }
              }
              // max: dayMax
            },
            yAxis: {
              uniqueNames: true,
              scrollbar: {
                enabled: true,
              },
              // min: 0,
              max: stringCount < 20 ? (stringCount-1) : 20,
              tickLength: 0,
              labels: {
                style: {
                  fontSize: '11px',
                }
              }
            },
            tooltip: {
              formatter: function () {
                return 'дата: <b>' + moment(this.x).format("DD/MM/YYYY") + '</b><br> объект: <b>' + this.point.object + '</b><br> сооружение: <b>' + this.point.construction
                + '</b><br> бригада: <b>' + this.point.brigade + '</b><br> работа: <b>' + this.point.wt + '</b><br> ед.изм.: <b>' + this.point.edizm + (this.point.comment ? '</b><br> коментарий: <b>' + this.point.comment : '')
              }
            },
            series: series,
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return this.point.value + (this.point.comment ? '<em>*</em>' : '')
                  },
                  style: {
                    cursor: 'default',
                    pointerEvents: 'none',
                    color: "contrast",
                    // fontSize: '10px',
                    textOutline: '0px contrast'
                  }
                }
              }
            },
            // rangeSelector: {
            //   enabled: true,
            //   selected: 2
            // },
            navigator: {
              enabled: true,
              liveRedraw: true,
              series: {
                type: 'gantt',
                pointPlacement: 0.5,
                pointPadding: 0.25
              },
              yAxis: {
                min: 0,
                max: 3,
                reversed: true,
                categories: []
              }
            },
          }}
        />
      </div>
    )
  }
}
