import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { AREAS_QA, USERS_QA, RIGHTS_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import { QA } from '../../CONSTANTS';
import { Nav_Links } from '../../CONSTANTS/Transformers';

const Styles = {
  wrapper: {
    display: 'block',
    width:'30%',
    maxWidth:'400px',
    // minWidth:'320px',
    maxHeight: '300px',
    overflow: 'auto',
    border: '1px solid rgba(0,0,0,.1)',
    background: '#eeeeee',
    padding: '20px',
  },
  gridLine: {
    margin: '5px 0',
    display: 'grid',
    gridTemplateColumns: 'auto 40px',
    width:'100%',
    maxWidth:'300px',
    background: '#ffffff',
    padding: '5px',
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,.1)',
    cursor: 'pointer',
  },
}


export default class RightsEdit extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props)
  
    this.state = {
      array_areas: [],
      array_users: [],
      array_navs: [],

      added_users: [],
      added_rights: [],
      added_navs: [],

      new_rights: {},
      editRights: {},
      editorRights: {},
      message: '',
      edit: false,
    }
  }
  

  componentDidMount(){
    this.GetRights()
    this.GetAreas()
    this.GetNavs()
    this.GetUsers()

  }

  makeRights = () => {
    const Rights = this.state.new_rights;

    if(!Rights){
      
      return null;
    }

    if(!Rights.rights || !Rights.rights.length ){
      
      this.setState({message: 'Нужно выбрать площадки'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });
      
      return null;
    }
    if(!Rights.users || !Rights.users.length ){

      this.setState({message: 'Нужно выбрать сотрудников'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });

      
      return null;
    }
    if(!Rights.name){

      this.setState({message: 'Нужно добавить название пресета!'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });

      
      return null;
    }

    let Q = `
      mutation {
        rights(
          data:{
            name: "${Rights.name}"
            rights: [${Rights.rights.map((e)=>`{collid: "${e.collid}", access: "${e.access}"}`).join(',')}]
            ${(Rights.navs && `navs: [${Rights.navs.map((e)=>`{name: "${e.name}", access: "${e.access}"}`).join(',')}]`) || ''}
            users: [${Rights.users.map((e)=>`"${e}"`).join(',')}]
          }
        ){
          create{
            id
          }
        }
      }
    `;

    QA(Q).then(data=>{
      data && this.setState({
        edit: false,
        new_rights: {},
        editRights: {},
        editorRights: {},
        message: 'Новые права созданы!'},
      ()=>{
        this.GetRights()
        setTimeout(() => {
          this.setState({message: ''});
        }, 6000);
      });
    })
  }


  updateRights = () => {
    const Rights = this.state.new_rights;

    if(!Rights){
      
      return null;
    }

    if(!Rights.rights || !Rights.rights.length ){
      
      this.setState({message: 'Нужно выбрать площадки'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });
      
      return null;
    }
    if(!Rights.users || !Rights.users.length ){

      this.setState({message: 'Нужно выбрать сотрудников'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });

      
      return null;
    }
    if(!Rights.name){

      this.setState({message: 'Нужно добавить название пресета!'},
        ()=>{
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });

      
      return null;
    }

    let Q = `
      mutation {
        rights(
          data:{
            id: "${Rights.id}"
            name: "${Rights.name}"
            rights: [${Rights.rights.map((e)=>`{collid: "${e.collid}", access: "${e.access}"}`).join(',')}]
            ${(Rights.navs && `navs: [${Rights.navs.map((e)=>`{name: "${e.name}", access: "${e.access}"}`).join(',')}]`) || ''}
            users: [${Rights.users.map((e)=>`"${e}"`).join(',')}]
          }
        ){
          create{
            id
          }
        }
      }
    `;

    QA(Q).then(data=>{
      data && data.rights && this.setState({
        edit: false,
        new_rights: {},
        editRights: {},
        editorRights: {},
        message: data.rights.create && data.rights.create.id ? 'Права обновлены' : 'Что-то пошло не так'},
      ()=>{
        this.GetRights()
        setTimeout(() => {
          this.setState({message: ''});
        }, 6000);
      });
    })
  }

  removeRights = (id) => {
    if(!id){
      
      return null;
    }

    let Q = `
      mutation {
        rights(
          data:{
            id: "${id}"
          }
        ){
          delete
        }
      }
    `;

    QA(Q).then(data=>{
      
      data && data.rights && this.setState({message: data.rights.delete ? 'Права удалены' : 'Что-то пошло не так'},
        ()=>{
          this.GetRights()
          setTimeout(() => {
            this.setState({message: ''});
          }, 6000);
        });
    })
  }

  GetNavs = async () => {
    const Navs = Nav_Links.map(e => e);

    this.setState({
      array_navs: Navs,
      addNavs: true,
    })
  }
  GetRights = async () => {
    QA(RIGHTS_QA).then((data => {
      data.rights && this.setState({
        array_rights: data.rights,
      })
    }));
  }
  GetAreas = async () => {
    QA(AREAS_QA).then((data => {
      data.areas && this.setState({
        array_areas: data.areas,
        addAreas: true,
      })
    }));
  }
  GetUsers = async () => {
    QA(USERS_QA).then((data => {
      data.users && this.setState({
        array_users: data.users,
        addUsers: true,
      })
    }));
  }

  appendRight = ({value, type, rights}) => {
    const {new_rights} = this.state;
    let N = {...new_rights};

    N.rights = N.rights || [];
    N.users = N.users || [];
    N.navs = N.navs || [];
    N.name = N.name || '';

    if(type === 'name'){
      N.name = value;

      this.setState({
        new_rights: N,
      })
    }

    if(type === 'areas'){

      if(N.rights && !N.rights.length && rights && value){
        let E = {};

        E.collid = value;
        E.access = rights === 1 ? 'r':'rw';

        N.rights.push(E);
      }
      else if(N.rights && N.rights.length && rights && value && !N.rights.find(e => e.collid === value)){
        let E = {};

        E.collid = value;
        E.access = rights === 1 ? 'r':'rw';

        N.rights.push(E);
      }

      else if(N.rights && N.rights.length && !rights && value && N.rights.find(e => e.collid === value)){
        let Idx = N.rights.indexOf(N.rights.find(e => e.collid === value));

        N.rights.splice(Idx, 1);
      }
      else if(N.rights && N.rights.length && rights && value && N.rights.find(e => e.collid === value)){
        let Idx = N.rights.indexOf(N.rights.find(e => e.collid === value));
        let E = {};

        E.collid = value;
        E.access = rights === 1 ? 'r':'rw';

        // N.rights.push(E);
        N.rights.splice(Idx, 1, E);


      }
      else{
        N.rights = [...N.rights].map((e, i, arr) =>{
          if(rights && e.collid === value){
            let E = e;
  
            E.access = rights === 1 ? 'r':'rw';
  
            return E;
          }
          if(!rights && e.collid === value){
  
            return null;
          }
  
          return e;
        }).filter(e => e);
      }

      this.setState({
        new_rights: N,
      })
    }
    if(type === 'users'){
      if(N.users && !N.users.length && rights && value){
        let E = null;

        E = value;

        N.users.push(E);
      }
      else if(N.users && N.users.length && rights && value && !N.users.find(e => e === value)){
        let E = null;

        E = value;

        N.users.push(E);
      }
      else if(N.users && N.users.length && !rights && value && N.users.find(e => e === value)){
        let Idx = N.users.indexOf(N.users.find(e => e === value));
        
        N.users.splice(Idx, 1);
      }
      else{
        N.users = [...N.users].map((e) =>{
          if(rights && e === value){
            let E = null;

            E = value;
  
            return E;
          }
          if(!rights && e === value){
  
            return null;
          }
  
          return e;
        }).filter(e => e);
      }

      this.setState({
        new_rights: N,
      })
    }


    if(type === 'navs'){
      if(N.navs && !N.navs.length && rights && value){
        let E = {};

        E.name = value;
        E.access = rights === 1 ? 'r':'rw';

        N.navs.push(E);
      }
      else if(N.navs && N.navs.length && rights && value && !N.navs.find(e => e.name === value)){
        let E = {};

        E.name = value;
        E.access = rights === 1 ? 'r':'rw';

        N.navs.push(E);
      }

      else if(N.navs && N.navs.length && !rights && value && N.navs.find(e => e.name === value)){
        let Idx = N.navs.indexOf(N.navs.find(e => e.name === value));

        N.navs.splice(Idx, 1);
      }
      else if(N.navs && N.navs.length && rights && value && N.navs.find(e => e.name === value)){
        let Idx = N.navs.indexOf(N.navs.find(e => e.name === value));
        let E = {};

        E.name = value;
        E.access = rights === 1 ? 'r':'rw';

        // N.navs.push(E);
        N.navs.splice(Idx, 1, E);


      }
      else{
        N.navs = [...N.navs].map((e, i, arr) =>{
          if(rights && e.name === value){
            let E = e;
  
            E.access = rights === 1 ? 'r':'rw';
  
            return E;
          }
          if(!rights && e.name === value){
  
            return null;
          }
  
          return e;
        }).filter(e => e);
      }

      this.setState({
        new_rights: N,
      })
    }
  }

  edit = (rights, index) => {
    if(!rights || !rights.id) return null;

    this.setState({
      edit: false,
      new_rights: {},
      editRights: {},
      editorRights: {},
    },()=>{
      const { array_areas, array_users, array_navs } = this.state;
      let Edit_Rights = {};
      let Rights = {};
  
      Rights.id = rights.id;
      Edit_Rights.id = rights.id;
      Rights.name = rights.name;
      Edit_Rights.name = rights.name;
      Rights.users = [];
      Rights.rights = [];
      Rights.navs = [];
  
      let R = array_areas.map((e)=>{
        let r = (rights.rights && rights.rights.length && rights.rights.find(a => a.collid === e.id)) || null;
        let E = {...e};
  
        E.rights = (r && r.access === 'r' && 1 ) || (r && r.access === 'rw' && 2 ) || null;
        let Ef = {collid: e.id, access: (r && r.access) || null};
  
        r && Rights.rights.push(Ef);
  
        return E;
      });
      let U = array_users.map((e)=>{
        let r = (rights.users && rights.users.length && rights.users.find(a => a.id === e.id)) || null;
        let E = {...e};
        
        E.rights = (r && 1) || null;
        let Ef = e.id;
  
        r && Rights.users.push(Ef);
  
        return E;
      });
      let N = array_navs.map((e)=>{
        let r = (rights.navs && rights.navs.length && rights.navs.find(a => a.name === e.place)) || null;
        let E = {...e};
  
        E.rights = (r && r.access === 'r' && 1 ) || (r && r.access === 'rw' && 2 ) || null;
        let Ef = {name: e.place, access: (r && r.access) || null};
  
        r && Rights.navs.push(Ef);
  
        return E;
      });
  
      Edit_Rights.users = [...U];
      Edit_Rights.rights = [...R];
      Edit_Rights.navs = [...N];

      this.setState({
        edit: true,
        new_rights: Rights,
        editRights: rights,
        editorRights: Edit_Rights,
      });
    });
  }

  add = (type) => {

    if(type === 'areas'){
      const { array_areas, } = this.state;

      if(!array_areas || !array_areas.length){
        this.GetAreas()
      }else{
        this.setState({
          addAreas: true,
        })
      }

      return null;
    }
    if(type === 'users'){
      const { array_users, } = this.state;

      if(!array_users || !array_users.length){
        this.GetUsers()
      }else{
        this.setState({
          addUsers: true,
        })
      }

      return null;
    }
    if(type === 'navs'){
      const { array_navs } = this.state;

      if(!array_navs || !array_navs.length){
        this.GetNavs()
      }else{
        this.setState({
          addNavs: true,
        })
      }

      return null;
    }
  }

  render() {
    const { addAreas, addUsers, addNavs, array_rights, array_areas, array_users, array_navs, new_rights, message, edit, editorRights} = this.state;

    return (
      <div style={{padding:'10px',}}>
        <div>
          <div><h3>{edit ? "Изменить" : "Добавить"}</h3></div>
          {new_rights == 1 && <div style={{maxWidth: '80%', height:'auto', overflow: 'auto', maxHeight:'600px', padding:'10px',}}>
            <div style={{background: '#ffffff', padding: '10px', margin: '5px 0',  border: '1px solid rgba(0,0,0,.1)',}}>
              <h3>{new_rights.name}</h3>
              <div style={{display:'flex', flexDirection:'row'}}>
                {new_rights.rights && new_rights.rights.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                  {new_rights.rights && new_rights.rights.map((rr,rri)=>{
                    let Area = array_areas && array_areas.length && array_areas.find(e => e.id === rr.collid)

                    return(
                      <RightSee key={rr.collid + '-' + rri} name={(Area && Area.name) || rr.collid} rights={rr.access}/>
                    )
                  })
                  }
                </div> || null}
                {(new_rights.users && new_rights.users.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                  {new_rights.users && new_rights.users.map((u,ui)=>{

                    return(
                      <RightSee key={u + '-' + ui} name={u.fio || u.name || u} rights={null}/>
                    )
                  })}
                </div>) || null}
                {(new_rights.navs && new_rights.navs.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                  {new_rights.navs && new_rights.navs.map((n,ni)=>{

                    return(
                      <RightSee key={n.name + '-' + ni} name={n.name} rights={n.access}/>
                    )
                  })}
                </div>) || null}
              </div>
            </div>
          </div>
          }
          {edit && editorRights && <div style={{background: '#ffffff', padding: '10px', margin: '5px 0',  border: '1px solid rgba(0,0,0,.1)',}}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Название пресета прав</span>
              </div>
              <input type="text" className="form-control" defaultValue={editorRights.name} onChange={(e)=>this.appendRight({value:e.target.value, type: 'name'})}/>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              {
                editorRights.rights && editorRights.rights.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                  {
                    editorRights.rights.map((e,i)=>{

                      return(
                        // <div style={Styles.gridLine}>{i}</div>
                        <RightValue key={e.id +'-'+ i} name={e.name} value={e.id} type={'areas'} def={e.rights} click={this.appendRight}/>
                      )
                    }) || 'nn'
                  }</div> || null
              }
              {
                editorRights.navs && editorRights.navs.length && <div className="OverBox" style={null /*Styles.wrapper*/}>{
                  editorRights.navs.map((e,i)=>{

                    return(
                      <RightValue key={e.place} name={e.name} type={'navs'} value={e.place} def={e.rights} click={this.appendRight}/>
                    )
                  }) || 'nn'
                }</div> || null
              }
              {
                editorRights.users && editorRights.users.length && <div className="OverBox" style={null /*Styles.wrapper*/}>{
                  editorRights.users.map((e,i)=>{

                    return(
                      <RightValue key={e.id +'-'+ i} name={e.name} type={'users'} value={e.id} def={e.rights} click={this.appendRight}/>
                    )
                  }) || 'nn'
                }</div> || null
              }
            </div>
          </div>}
          {!edit && <div style={{background: '#ffffff', padding: '10px', margin: '5px 0',  border: '1px solid rgba(0,0,0,.1)',}}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Название пресета прав</span>
              </div>
              <input type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" onChange={(e)=>this.appendRight({value:e.target.value, type: 'name'})}/>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              {
                !addAreas && <div><button type="button" className="btn btn-outline-secondary" onClick={()=>this.add('areas')}>+ Прикрепить Площадку</button></div>
              }
              {
                !addNavs && <div><button type="button" className="btn btn-outline-secondary" onClick={()=>this.add('navs')}>+ Прикрепить Раздел</button></div>
              }
              {
                !addUsers && <div><button type="button" className="btn btn-outline-secondary" onClick={()=>this.add('users')}>+ Прикрепить Сотрудника</button></div>
              }
              {
                addAreas && array_areas && array_areas.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                  {
                    array_areas.map((e,i)=>{

                      return(
                        // <div style={Styles.gridLine}>{i}</div>
                        <RightValue key={e.id +'-'+ i} name={e.name} value={e.id} type={'areas'} click={this.appendRight}/>
                      )
                    }) || 'nn'
                  }</div> || null
              }
              {
                addNavs && array_navs && array_navs.length && <div className="OverBox" style={null /*Styles.wrapper*/}>{
                  array_navs.map((e,i)=>{

                    return(
                      <RightValue key={e.place} name={e.name} type={'navs'} value={e.place} click={this.appendRight}/>
                    )
                  }) || 'nn'
                }</div> || null
              }
              {
                addUsers && array_users && array_users.length && <div className="OverBox" style={null /*Styles.wrapper*/}>{
                  array_users.map((e,i)=>{

                    return(
                      <RightValue key={e.id +'-'+ i} name={e.name} type={'users'} value={e.id} click={this.appendRight}/>
                    )
                  }) || 'nn'
                }</div> || null
              }
            </div>
          </div>}
          {message && <div className="Message">
            {message}
          </div>}
          <div>
            {!edit && <button type="button" className="btn btn-outline-primary" onClick={this.makeRights}>Создать</button>}
            {edit && <button type="button" className="btn btn-outline-success" onClick={this.updateRights}>Сохранить</button>}
            {edit && <button type="button" className="btn btn-light" onClick={()=>this.setState({
              edit: false,
              new_rights: {},
              editRights: {},
              editorRights: {},
            })}
            >отменить</button>}
          </div>
        </div>


        <div style={{background: '#ffffff', padding: '10px', margin: '5px 0', overflow: 'auto',  border: '1px solid rgba(0,0,0,.1)',}}>
          {array_rights && array_rights.length && array_rights.map((r,ri)=>{
            return(
              <div key={r.id + '-' + ri} style={{padding: '10px', margin: '5px 0',  borderBottom: '1px solid rgba(0,0,0,.1)',}}>
                <h3>{r.name}</h3>
                <div style={{display:'flex', flexDirection:'row'}}>
                  <button type="button" className="btn btn-outline-info" onClick={()=>this.edit(r, ri)}>изменить</button>
                  <button type="button" className="btn btn-light" onClick={()=>this.removeRights(r.id)}>удалить</button>
                </div>

                <div style={{display:'flex', flexDirection:'row'}}>
                  {r.rights && r.rights.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                    {
                      r.rights.map((rr,rri)=>{
                        let Area = array_areas && array_areas.length && array_areas.find(e => e.id === rr.collid)

                        return(
                          <RightSee key={rr.id + '-' + rri} name={Area && Area.name || rr.id} rights={rr.access}/>
                        )
                      })
                    }

                  </div> || null}
                  {r.users && r.users.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                    {r.users.map((u,ui)=>{

                      return(
                        <RightSee key={u.id + '-' + ui} name={u.fio || u.name} rights={null}/>
                      )
                    })}
                  </div> || null}
                  {r.navs && r.navs.length && <div className="OverBox" style={null /*Styles.wrapper*/}>
                    {r.navs.map((n,ni)=>{
                      const NavName = array_navs.find(nav=> nav.place === n.name );

                      return(
                        <RightSee key={n.id + '-' + ni} name={(NavName && NavName.name) || n.name} rights={n.access}/>
                      )
                    })}
                  </div> || null}
                </div>
              </div>
            )
          })
          }
        </div>
      </div>
    )
  }
}


export const RightValue = ({name, value, type, click, def}) => {

  const [rights, setRigths] = useState(def || 0);


  const Click = () => {
    if(rights === 2){
      setRigths(0)
      click && typeof click === 'function' && click({value, rights: 0, type})

      return null;
    }
    
    setRigths(rights+1)
    click && typeof click === 'function' && click({value, rights: rights+1, type})
  }


  return (
    <div className={"Checker" + (rights ? ' bg' : '')} style={null/*Styles.gridLine*/} onClick={Click}>
      <div style={null}>{name}</div>
      <div style={{borderLeft: '1px solid rgba(0,0,0,.1)', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
        {rights === 0 && ''}
        {rights === 1 && ''}
        {rights === 2 && ''}
        <div style={{width:'10px', height: '10px', display: 'block', background: (rights === 0 && '') || (rights === 1 && 'salmon') || (rights === 2 && 'seagreen'), borderRadius: '3px' }}></div>
      </div>
    </div>
  )
}

export const RightSee = ({name, rights}) => {
  return (
    <div className={"RightSee" + (rights ? ' bg' : '')} style={null/*Styles.gridLine*/}>
      <div style={null}>{name}</div>
      <div style={{borderLeft: '1px solid rgba(0,0,0,.1)', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
        {rights === '' && ''}
        {rights === 'r' && ''}
        {rights === 'rw' && ''}
        <div style={{width:'10px', height: '10px', display: 'block', background: (!rights && '') || (rights === 'r' && 'salmon') || (rights === 'rw' && 'seagreen'), borderRadius: '3px' }}></div>
      </div>
    </div>
  )
}
