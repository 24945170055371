import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_DAYS_MONTH } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import Loader from '../../Loader';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';
import { sortObjects } from '../CONSTANTS/Transformers';

let objectFilter;

export default class RGrAreaMonth extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaName: "",
      emptyStrings: false, //скрывает пустые строки
      queryData: [],
      monthCount: 6,
      showConstruction: true,
      curDay: moment().format("YYYY-MM-DD"),
      dates: [],
      colums: [],
      headers:[],
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.changeDate()
    this.checkUser()
  }

  changeMonthCount = (number) => {
    this.setState({monthCount: number}, () => { this.changeDate() });
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else this.getData()
  }

  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.changeDate()});
  }

  changeDate = () => {
    const { monthCount, curDay } = this.state;
    let i = monthCount
    const curDates = []

    while (i--) curDates.push({
      first: moment(curDay).subtract(i, 'months').startOf('month'), 
      last: moment(curDay).subtract(i, 'months').endOf('month'),
      fact: 0
    })

    this.setState({
      dates: curDates
    },
    ()=>{
      this.getupdate()
    })
  }

  resetFilter = () => {
    objectFilter();
  };

  switchObjects = () => {
    const { showConstruction } = this.state;

    this.resetFilter()
    this.setState({showConstruction: !showConstruction}, () => { this.getData() });
  }


  getData = () => {
    const { match } = this.props;
    const { showConstruction } = this.state;
    const id = match.params.id;

    this.setState({ loaded: 0 })

    if(!id) return null;

    QA(GET_AREA_DAYS_MONTH({id: id, month: true, showConstruction})).then((data)=>{
      if (data && data.groupDays && data.area && data.groupDays.length > 0) {
        this.setState({
          queryData: data.groupDays,
          areaName: data.area.name,
          loaded: 1
        }, ()=>{
          this.getupdate(data.groupDays)
        })
      } else {
        this.setState({loaded: 2})
      }
    }
    )

  }

  getHeaders = (objects) => {
    const { dates, showConstruction } = this.state
    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }

    let headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { dataField: 'object', text: showConstruction ? "Сооружение" : "Объект", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilter = filter;}}), footer:""},
    ]

    let weekHeaders = []

    for (let j=0; j < dates.length; j++){
      const week = [
        { 
          dataField: 'monthFact' + j, 
          text: "затраты с " + dates[j].first.format("DD/MM") + " по " + dates[j].last.format("DD/MM") + " (факт)", 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, dates[j].first.format("DD/MM") + " - " + dates[j].last.format("DD/MM"), {icon:'iconF',text: "трудозатраты (факт) за месяц, чел-дн", footer: "факт"}),
          formatter: (cell) => {
            if (!cell || ToFloat(cell) === 0 ) return ""
  
            return cell
          },
          // footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
          footer: dates[j].fact.toFixed(2)
        }, 
        { 
          dataField: 'diff' + j,
          text: "доля для расчета зп с " + dates[j].first.format("DD/MM") + " по " + dates[j].last.format("DD/MM"), 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, dates[j].first.format("DD/MM") + " - " + dates[j].last.format("DD/MM"), {icon:'iconP',text: "Доля для расчета зп", footer: "доля, %"}),
          formatter: (cell, row) => {
            if (!row['monthFact' + j] || ToFloat(row['monthFact' + j]) === 0 || !dates[j].fact || dates[j].fact === 0) return ""

            return (100*row['monthFact' + j]/dates[j].fact).toFixed(2)
          },
          // footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2)
          footer: ""
        }, 
      ]

      weekHeaders = [...weekHeaders, ...week]
    }

    headers = [...headers, ...weekHeaders]

    return headers
  }

  groupDays = (data) => {
    const { dates, emptyStrings } = this.state

    if (!data || !data.length) return null;
    let colums = [], objects = {};

    const sumDates = dates; // ФИЧА С ССЫЛКАМИ!!

    for (let j=0; j < dates.length; j++){
      sumDates[j].fact = 0
    }
    data.map(e => {      
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= dates[0].first &&  moment(b.day)<= dates[dates.length-1].last)) : e.days

      if (emptyStrings && days.length <1) return


      objects[e.object] = e.object;

      let curDates = [];

      for (let j=0; j < dates.length; j++){
        curDates.push ({fact: 0})
      }

      const sumWork = e.days.reduce(function(a, b) {

        for (let j=0; j < dates.length; j++){
          if (moment(b.day) >=dates[j].first && moment(b.day) <=dates[j].last) {
            curDates[j].fact = (curDates[j].fact ? curDates[j].fact : 0) + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
            sumDates[j].fact = (sumDates[j].fact ? sumDates[j].fact : 0) + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0)); // ФИЧА С ССЫЛКАМИ!!
          }
        }

        return a + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
      }, 0)

      e.cols = { id: e.id, object: e.object,
        factAll: ToFloat(sumWork).toFixed(2) ,
      }

      for (let j=0; j < dates.length; j++){
        e.cols["monthFact" + j] = curDates[j].fact.toFixed(2)
      }

      colums.push(e.cols)

      return e;
    });

    objects = sortObjects(objects)

    return {colums, objects};
  }


  getupdate = (queryData) => {
    let Data = queryData || this.state.queryData;

    if(!Data || !Data.length) return null;

    const {colums, objects} = this.groupDays(Data);
    const headers = this.getHeaders(objects);

    if(colums && (!this.state.colums || !this.state.colums.length || this.state.colums !== colums)){

      this.setState({
        colums,
      });
    }
    if(headers && (!this.state.headers || !this.state.colums.headers || this.state.headers !== headers)){
      this.setState({
        headers,
      });
    }

  }

  render() {
    const { areaName, monthCount, loaded, colums, headers, emptyStrings, curDay, showConstruction } = this.state;
    
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>

    return (
      <div className={'Area'} style={{width: '100%',margin:'0'}}>
        <div style={{width: '100%', height: 'auto', margin:'0 auto', position: 'fixed', top: '0', right:'auto', left:'auto', display:'flex', flexDirection:'row', justifyContent:'center',
          alignContent:'center', alignItems:'center', zIndex: '9999',
        }}>
          {loaded === 0 && <Loader />}
        </div>
        {colums && colums.length && headers && headers.length && <div className="" style={{maxWidth:"950px",margin: '5px'}}>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="btnGroupAddon"><b>{areaName}</b></div>
              <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
            </div>
            <input type="date"
              className="form-control"
              aria-label="Input group example" aria-describedby="btnGroupAddon"
              name="trip-start"
              min="2018-01-01"
              max={(new Date().toISOString()).slice(0,10)}
              value={curDay}
              onChange={e=>this.changeDay(e)}
            />
            <button className={`btn btn-sm ${monthCount === 4 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(4)}>4</button>
            <button className={`btn btn-sm ${monthCount === 5 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(5)}>5</button>
            <button className={`btn btn-sm ${monthCount === 6 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(6)}>6</button>
            <button className={`btn btn-sm ${monthCount === 7 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(7)}>7</button>
            <button className={`btn btn-sm ${monthCount === 8 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(8)}>8</button>
            <button className={`btn btn-sm ${monthCount === 9 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(9)}>9</button>
            <button className={`btn btn-sm ${monthCount === 10 && "btn-success" || "btn-btn-warning" } `} onClick={()=>this.changeMonthCount(10)}>10</button>
            <button className="btn btn-sm btn-primary" onClick={this.switchObjects}>Показать {showConstruction ? "объекты" : "сооружения"}</button>
            <button className={`btn btn-sm btn-btn-secondary } `} onClick={()=>this.setState({emptyStrings: !emptyStrings}, ()=>{this.getupdate()})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
          </div>
        </div> || null}
        {colums && colums.length && headers && headers.length && <Table headers={headers} cols={colums} update={this.getData} updateState={this.updateState} resetFilter={this.resetFilter} tabletype={"AllTable n21"} simple={true} /> || null}
      </div>
    )
    // }


  }
}
