import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GET_INFO } from '../../GQL/SERVER/QUERIES/ServerQuerys'
import { MUTATE_INFO, DELETE_INFO } from '../../GQL/SERVER/MUTATOR/ServerMutators'
import { QA } from '../../CONSTANTS'
import { _filt, _getUserLevel } from '../../CONSTANTS/operations'
// const crypto = require('crypto');



// const secret = 'Password used to generate key';
// const algorithm = 'aes-192-cbc';
// const key = crypto.scryptSync(secret, 'salt', 24);
// const iv = Buffer.alloc(16, 0);

export default class InfoTraining extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props)
  
    this.state = {
      array: [],
      arrayFull: [],
      admin: false,
      edit: '',

      name: '',
      position: '',
      text: '',
    }
  }
  

  componentDidMount(){

    this.getData()
  }

  getData = () => {


    
    const Level = _getUserLevel();

    if (Level && (Level === 'hi' || Level === 'monster')) {
      this.setState({
        admin: true,
      })

      QA(GET_INFO()).then((data)=>{
        if (data && data.info){
          this.setState({
            array: data.info,
            arrayFull: data.info,
          })
        }
      }
      )
    }
  }

  append = (id, N, T, I) => {
    const name = this.state.name || N || null;
    // const text = (this.state.text && /*(this.state.text).replace(/\n/g, '\\n')*/(this.state.text).replace(/[\s]{2,}/g, ' ')) || T || null;
    const text = (this.state.text && (`${this.state.text}`).replace(/[\s]{2,}/g, ' ')) || T || null;
    const position = (this.state.position && `${Number(this.state.position)}` ) || `${Number(I)}`  || null;

    if (!name || !text) {
      return null;
    }

    let Obj = {};

    if(id) Obj.id = id;

    // const hashname = crypto.createCipheriv(algorithm, key, iv).update(name);
    // const hashtext = crypto.createCipheriv(algorithm, key, iv).update(text);
    //   console.log('hashname',hashname);
    //   console.log('hashtext',hashtext);

    // const backname = crypto.createDecipheriv(algorithm, key, iv)
    //   .update(hashname);
    // const backtext = crypto.createDecipheriv(algorithm, key, iv)
    //   .update(hashtext);

    //   console.log('backname',backname);
    //   console.log('backtext',backtext);


    Obj.name = name;
    Obj.text = text;
    Obj.position = position;

    if(id){
      let Array = this.state.array.map((el)=>{
        if(el.id === id) {
          let A = Obj;

          return A;
        }

        return el;
      });

      this.setState({
        array: [...Array],
        arrayFull: [...Array],
        edit: null,
      }, ()=>{
        QA(MUTATE_INFO(Obj)).then((data)=> {

          console.log(MUTATE_INFO(Obj));
          

          if (data && data.info && data.info.mutate){
            return true;
          }
        }
        )
      });

      return true;
    }
    else{
      this.setState({
        array: [...this.state.array, {id: '0', name, text }],
        arrayFull: [...this.state.array, {id: '0', name, text }],
        edit: null,
      }, ()=>{
        QA(MUTATE_INFO(Obj)).then((data)=> {
          if (data && data.info && data.info.mutate){

            let Array = this.state.array.map((el, i)=>{
              if(el.id === '0') {
                let A = el;
                
                A.id = data.info.mutate;

                return A;
              }

              return el;
            });

            this.setState({
              array: [...Array],
              arrayFull: [...Array],
              edit: null,
            })
          }
        }
        )
      });
    }
  }

  remove = (id) => {
    if(id){
      let Array = this.state.array.filter((el)=> el.id !== id );

      this.setState({
        array: [...Array],
        arrayFull: [...Array],
        edit: null,
      }, ()=>{
        QA(DELETE_INFO({id})).then((data)=> {
          if (data && data.info && data.info.delete){
            return true;
          }
        }
        )
      });

      return true;
    }

    return null;
  }

  changevalue = (text, element) => {

    console.log(text);
    
    this.setState({
      // [element]: text.replace(/\n/g, '\\n').replace(/"/g, '\''),
      [element]: text,
    })
  }

  filter = (str) => {
    const {array} = this.state;

    const F = _filt({str, array: array, keys: ['name'], });

    this.setState({
      array: (str && F.array) || this.state.arrayFull,
      found: F.found,
    })
  }

  render() {
    const {array, admin, edit, found} = this.state;

    return (
      <div className="InfoBlocks">

        {admin && <div className="Editor">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">Название</span>
            </div>
            <input type="text" className="form-control" onChange={(elem)=>this.changevalue(elem.target.value, 'name')}/>
            <div className="input-group-append">
              <span className="input-group-text" id="">порядок</span>
              <input type="text" className="form-control" onChange={(elem)=>this.changevalue(elem.target.value, 'position')}/>
            </div>
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Новый текст</span>
            </div>
            <textarea className="form-control" aria-label="With textarea" cols="30" rows="10" onChange={(elem)=>this.changevalue(elem.target.value, 'text')}></textarea>
          </div>
          <div>
            <button className="btn btn-outline-primary" type="button" onClick={()=>this.append()}>+ Добавить</button>
          </div>
        </div> || null}
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Поиск</span>
          </div>
          <input type="search" className="form-control"  onChange={(e)=>this.filter(e && e.target && e.target.value || null)} placeholder="Поиск" />
          {(found || found === 0) && <span className="Err">Найдено: {found}</span>}
        </div>
        
        {
          array && array.length && array.sort((a,b)=>{
            if (!a.position) return 0;
            if (!b.position) return 0;
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            
            return 0;
          }).map((el,i)=>{
            return(
              <div className="InfoBlock" key={'infoblocks-'+el.id+' '+i}>
                <div>
                  <h3><span>{el.position}</span> {el.name}</h3>
                  {!edit && admin && <button className="btn btn-outline-primary" type="button" onClick={()=>{admin && this.setState({edit: el.id})}} >+ Изменить</button>}
                  {!edit && admin && <button className="btn btn-outline-danger" type="button" onClick={()=>{admin && this.remove(el.id)}} >удалить</button>}
                  {edit && edit === el.id && <div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="">Название</span>
                      </div>
                      <input type="text" className="form-control" onChange={(elem)=>this.changevalue(elem.target.value, 'name')} defaultValue={el.name}/>
                      <div className="input-group-append">
                        <span className="input-group-text" id="">порядок</span>
                        <input type="text" className="form-control" onChange={(elem)=>this.changevalue(elem.target.value, 'position')} defaultValue={el.position}/>
                      </div>
                    </div>
                    
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Новый текст</span>
                      </div>
                      <textarea className="form-control" aria-label="With textarea" cols="30" rows="10" onChange={(elem)=>this.changevalue(elem.target.value, 'text')} defaultValue={el.text}></textarea>
                    </div>
                    <div>
                      <button className="btn btn-outline-primary" type="button" onClick={()=>this.append(el.id, el.name, el.text, el.position)}>+ Изменить</button>
                      <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({edit: null})}>отменить</button>
                    </div>
                  </div>
                  }
                </div>
                <div>
                  {/* <pre>
                    {el.text}
                  </pre> */}
                  {el.text && ((el.text).split('\n')).map((txt, indx)=>{

                    return(
                      <p key={'textpart-'+i+' '+el.id+' '+indx} className="marginBottom">
                        {txt}
                      </p>
                    )
                  }) || null}
                </div>


              </div>
            )
          }) || null
        }
        
      </div>
    )
  }
}
