import React, { Component } from 'react'

import { QA } from '../../CONSTANTS';
import { /* ADD_WORKTYPE, ADD_UNIT, ADD_BRIGADE, EDIT_CONSTR, ADD_CONSTRUCTION, */ ADD_CONSTRUCTION_QA, EDIT_CONSTR_QA, DELETE_CONSTR_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { AREAS_QA, AREA_ID_QA, CONSTR_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
// import AdminList from './Editor';
import Editorable from './Editorable.third.1';

import '../../CSS/PARTS/lists.css'
import { RenderEditor, RenderAddMultiFetchSelect, FetchedSelect } from './EditorParts';
import AdminGridEditor from './AdminGridEditor';

export default class ConstructMutator extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => await QA(CONSTR_QA).then((data => {
    data && this.setState({
      constructions: data.constructions,
    })
  })).then(()=> true);
  
  render() {
    const {constructions } = this.state;
    const ELEMENTS = [
      {
        name: 'Название',
        type: 'input',
        important: true,
        prop: 'name',
      },
      {
        name: 'Объект',
        type: 'fetch',
        important: true,
        prop: 'parent',
        onRender: (props) => <FetchedSelect {...props} change={(target)=>props.set(target.value)} fetch = {[
          { query: AREAS_QA, graphname: 'areas', name: 'Площадка', propQuery: 'id', help: 'Выберите Площадку' },
          { query: AREA_ID_QA, propQuery: 'id', selectedParam: 'id', graphname: 'objects', name: 'Объект', help: 'Выберите Объект', use: true },
          // { query: OBJECTS_QA, propQuery: 'id', selectedParam: 'parent' }
        ]} />
      },
    ];
    // const ELEMENTS = [
    //   {
    //     name: 'Название',
    //     value: null,
    //     type: 'input',
    //     add: true,
    //     edit: true,
    //     importantAdd: true,
    //     importantEdit: true,
    //     prop: 'name',
    //     propName: null,
    //     array: [],
    //   },
    //   {
    //     name: 'Объект',
    //     value: null,
    //     type: 'fetch',
    //     add: true,
    //     edit: false,
    //     importantAdd: true,
    //     importantEdit: false,
    //     prop: 'parent',
    //     propName: null,
    //     array: null,
    //     fetch: [
    //       { query: AREAS_QA, use: false, graphname: 'areas', name: 'Площадка', help: 'Выберите Площадку' },
    //       { query: AREA_ID_QA, propQuery: 'id', selectedParam: 'id', graphname: 'objects', use: true, name: 'Объект', help: 'Выберите Объект' },
    //       // { query: OBJECTS_QA, propQuery: 'id', selectedParam: 'parent', use: true }
    //     ],
    //   },
    // ];

    const headers = [
      {
        name: 'Конструкции',
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_CONSTR_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        onClick: null,
        editCell: null,
        onSelect: null,
        prop: 'name',
        select: true,
        compareProps: ['name'],
      },
      {
        name: 'Объект',
        onRender: null,
        onClick: null,
        editCell: null,
        onSelect: null,
        prop: 'parentName',
        select: true,
        compareProps: ['parentName'],
      },
    ];
    

    return(<AdminGridEditor
      // GET={TRANSP_QA}
      ADD={ADD_CONSTRUCTION_QA}
      EDIT={EDIT_CONSTR_QA}
      DEL={DELETE_CONSTR_QA}
      name={'Сооружения'}
      arrayName={'Сооружения'}
      array={constructions}
      answerName={'constructions'}
      refetch={this.GetData}
      headers={headers}
      elements={ELEMENTS}
      searchKeys={['type','firm','area','name','number']}
    />)
    // return (
    //   <Editorable
    //     ADD={ADD_CONSTRUCTION_QA}
    //     EDIT={EDIT_CONSTR_QA}
    //     DEL={DELETE_CONSTR_QA}
    //     elements={ELEMENTS}
    //     name={'Сооружения'}
    //     arrayName={'Сооружения'}
    //     array={constructions}
    //     refetch={this.GetData}
    //     tableHeaders={headers}
    //     searchKeys={['type','firm','area','name','number']}
    //     // lister
    //   />
    // )
    // return (
    //   <AdminList
    //     ADD={ADD_CONSTRUCTION_QA}
    //     EDIT={EDIT_CONSTR_QA}
    //     name={'Сооружения'}
    //     array={constructions}
    //     refetch={this.GetData}
    //     arrayProp={'name'}
    //     parentsArray={objects}
    //     parentName={'Объект'}
    //     parentProp={'parent'}
    //   />
    // )
  }
}
