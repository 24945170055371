import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_QA_FULL } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import Loader from '../../Loader';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';
import { HoverCell, sortObjects } from '../CONSTANTS/Transformers';

let brigadeFilterFunc, objectFilterFunc, constructionFilterFunc, worktypeFilterFunc;

const sortFunc = (a, b, order) => {
  if (order === 'desc') {
    return ToFloat(b) - ToFloat(a);
  }
  
  return ToFloat(a) - ToFloat(b); // desc
}

const diffStyle = (cell) => {
  if(ToFloat(cell) < -10) {
    return {
      backgroundColor: 'rgba(194, 41, 41, 0.1)'
    };
  }
  if(ToFloat(cell) > 30) {
    return {
      backgroundColor: 'rgba(49, 170, 59, 0.1)'
    };
  }
}

const diff = (plan, fact) => {
  let diff = 0

  if (plan && plan !== 0/*|| fact !== 0*/) diff = -(1-fact/plan)*100
  // diff = ((fact-plan)/((plan+fact)/2)) *50

  if (isNaN(diff)) console.warn("aaa", plan, fact);
  

  return diff.toFixed(2)
}

export default class RArea extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaData: {},
      emptyStrings: false, //скрывает пустые строки
      curDay: moment().format("YYYY-MM-DD"),
      lastWeekStart: '',
      thisWeekStart: '',
      lastWeekEnd: '',
      thisWeekEnd: '',
      canEdit: false, 
      brigadeFilter: '',
      objectFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      currentFilter: '', // текущий вид фильтра
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  emptyData =() => {
    
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, write }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else {
      this.getData()
      this.setState({canEdit: write})
    }
  }
  
  changeDay = (e, curDate) => {
    const date = (e && e.target && e.target.value) || curDate

    const dateNow = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const lastWeekEnd = moment(dateNow).weekday(0);
    const lastWeekStart = moment(dateNow).weekday(-6);
    const thisWeekStart = moment(dateNow).weekday(1);
    const thisWeekEnd = moment(dateNow).weekday(7);


    this.setState({
      lastWeekStart,
      thisWeekStart,
      lastWeekEnd,
      thisWeekEnd,
      curDay: dateNow,
    })
  }

  resetFilter = () => {
    brigadeFilterFunc();
    objectFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();

    this.setState({worktypeFilter: '',constructionFilter: '', objectFilter: '', brigadeFilter: '', currentFilter: ''})
  };

  validate = (newValue, fulldate) => {
    if (fulldate && (fulldate > Date.parse(new Date(Date.now())))) {
      return {
        valid: false,
        message: 'данные нельзя заполнять на будущие дни'
      };
    }
    if (isNaN(newValue)) {
      return {
        valid: false,
        message: 'данные не являются числом!'
      };
    }
    if (newValue < 0) {
      return {
        valid: false,
        message: 'данные не могут быть отрицательными!'
      };
    }

    return true;
  }

  getData = (date) => {
    const {match} = this.props;
    const id = match.params.id;

    this.changeDay(null, date)
    this.setState({ loaded: 0 })

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_QA_FULL({id: id, day: date || null })).then((data)=>{
      if (data && data.area){
        this.setState({
          areaData: data.area,
          loaded: 1
        })
      } else this.setState({loaded: 2})
    }
    )
  }

  getHeaders = () => {
    const { areaData, lastWeekStart, thisWeekStart, lastWeekEnd, thisWeekEnd,  objectFilter, brigadeFilter, constructionFilter , worktypeFilter, canEdit, currentFilter } = this.state
    let filterData = [], brigades = {}, objects = {}, constructions = {}, worktype = {}

    areaData && areaData.workTypes && areaData.workTypes.forEach((e) => {
      filterData = [...filterData, { brigade: e.brigade, object: e.object, construction: e.construction, worktype: `${e.value}, ${e.unit}`}]
    });
    //для фильтров кроме текущего 
    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) && (brigadeFilter ? v.brigade === brigadeFilter : true)
      && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter !== "object") objects[e.object] = e.object
        if (currentFilter !== "brigade") brigades[e.brigade] = e.brigade
        if (currentFilter !== "construction") constructions[e.construction] = e.construction
        if (currentFilter !== "worktype") worktype[e.worktype] = e.worktype
      });

    //для текущего выбранного фильтра
    filterData.filter((v) => ((objectFilter && currentFilter !== "object") ? v.object === objectFilter : true) && ((brigadeFilter && currentFilter !== "brigade") ? v.brigade === brigadeFilter : true)
      && ((constructionFilter && currentFilter !== "construction") ? v.construction === constructionFilter : true) && ((worktypeFilter && currentFilter !== "worktype") ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter === "object") objects[e.object] = e.object
        if (currentFilter === "brigade") brigades[e.brigade] = e.brigade
        if (currentFilter === "construction") constructions[e.construction] = e.construction
        if (currentFilter === "worktype") worktype[e.worktype] = e.worktype
      });

    objects = sortObjects(objects)
    brigades = sortObjects(brigades)
    constructions = sortObjects(constructions)
    worktype = sortObjects(worktype)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }
    const headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal, currentFilter: "object"})})},
      { formatter: HoverCell, dataField: 'brigade', text: "Бригада", editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: brigades, style:selectStyle, placeholder: 'все',
        getFilter: (filter) => {brigadeFilterFunc = filter}, onFilter: filterVal => this.setState({brigadeFilter: filterVal, currentFilter: "brigade"})})},
      { formatter: HoverCell, dataField: 'construction', text: 'Сооружение', editable: false, sort: true, headerStyle: { width: '120px' }, 
        filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все',
          getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal, currentFilter: "construction"})})},
      { formatter: HoverCell, dataField: 'worktype', text: 'Тип работ', editable: false, sort: true, headerStyle: { width: '110px' },  filter: selectFilter({options: worktype, style:selectStyle, placeholder: 'все',
        getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal, currentFilter: "worktype"})})},
      { dataField: 'preDefValue', text: 'Норма чел-час на ед.', editable: false, sort: false, headerStyle: { width: '50px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "", {icon:'iconN', text: "норма, чел-час на ед", footer: "Норма"}),
      },
      { dataField: 'coeffNorm', text: 'К нормы', cellType: "coeffNorm", sort: false, headerStyle: { width: '50px' },
        editable: () => canEdit,
        formatter: (cell) => {
          if (!cell || ToFloat(cell) === 0 || ToFloat(cell) === 1) return 1

          return cell
        },
        validator: (newValue) => this.validate(newValue),
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "", {icon:'iconKs', text: "коэффициент сложности (1 - соответствует норме)", footer: "К нормы"}),
      },
      { dataField: 'valueProject', text: 'V: по проекту', cellType: "valueProject", sort: false, headerStyle: { width: '60px' },
        editable: () => canEdit,
        formatter: (cell) => {
          if (!cell || ToFloat(cell) === 0 ) return ""

          return cell
        },
        validator: (newValue) => this.validate(newValue),
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "", {icon:'iconVp',text: "объем по проекту", footer: "V по проекту"}),
      },
      { dataField: 'valueRemain', text: 'V: осталось', editable: false, sort: false, headerStyle: { width: '60px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "", {icon:'iconVo',text: "объем, оставшийся к выполнению", footer: "V: осталось"}),
      },
      { 
        dataField: 'valuePlanWeekPrev', 
        text: "объем с " + lastWeekStart.format("DD/MM") + " по " + lastWeekEnd.format("DD/MM") + " (план)",  
        editable: () => canEdit,
        dateU: lastWeekStart.format("YYYY-MM-DD"), 
        cellType: "valuePlan", 
        validator: (newValue) => this.validate(newValue, lastWeekEnd.format("YYYY-MM-DD")),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconV',text: "объем работ за предыдущую неделю", footer: "план"}),
      }, 
      { 
        dataField: 'valueFactWeekPrev', 
        text: "объем с " + lastWeekStart.format("DD/MM") + " по " + lastWeekEnd.format("DD/MM") + " (факт)",  
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconV',text: "объем работ за предыдущую неделю", footer: "факт"}),
      }, 
      { 
        dataField: 'valueWeekPrevDiff', 
        text: "Разница, %",  
        editable: false,
        style: (cell) => diffStyle(cell),
        sort: true,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
      }, 

      { 
        dataField: 'workPlanWeekPrev', 
        text: "затраты с " + lastWeekStart.format("DD/MM") + " по " + lastWeekEnd.format("DD/MM") + " (норма)", 
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconF',text: "трудозатраты по норме за предыдущую неделю, чел-дн", footer: "по норме"}),
      }, 
      { 
        dataField: 'workFactWeekPrev', 
        text: "затраты с " + lastWeekStart.format("DD/MM") + " по " + lastWeekEnd.format("DD/MM") + " (факт)", 
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconF',text: "трудозатраты (факт) за предыдущую неделю, чел-дн", footer: "факт"}),
      }, 
      { 
        dataField: 'workWeekPrevDiff', 
        text: "Разница, %", 
        style: (cell) => diffStyle(cell),
        sort: true,
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, lastWeekStart.format("DD/MM") + " - " + lastWeekEnd.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
      }, 

      { 
        dataField: 'valuePlanWeek', 
        dateU: thisWeekStart.format("YYYY-MM-DD"), 
        cellType: "valuePlan", 
        validator: (newValue) => this.validate(newValue, thisWeekEnd.format("YYYY-MM-DD")),
        text: `объем с ${thisWeekStart.format("DD/MM")} по ${thisWeekEnd.format("DD/MM")} (план)`, 
        editable: () => canEdit,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, `${thisWeekStart.format("DD/MM")} - ${thisWeekEnd.format("DD/MM")}`, {icon:'iconV',text: "объем работ за текущую неделю", footer: "план"}),
      }, 
      { 
        dataField: 'valueFactWeek', 
        text: "объем с " + thisWeekStart.format("DD/MM") + " по " + thisWeekEnd.format("DD/MM" + " (факт)"), 
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, thisWeekStart.format("DD/MM") + " - " + thisWeekEnd.format("DD/MM"), {icon:'iconV',text: "объем работ за текущую неделю", footer: "факт"}),
      }, 
      { 
        dataField: 'valueWeekDiff', 
        text: "Разница, %", 
        style: (cell) => diffStyle(cell),
        sort: true,
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, thisWeekStart.format("DD/MM") + " - " + thisWeekEnd.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
      }, 

      { 
        dataField: 'workPlanWeek', 
        text: "затраты с " + thisWeekStart.format("DD/MM") + " по " + thisWeekEnd.format("DD/MM") + " (норма)", 
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, thisWeekStart.format("DD/MM") + " - " + thisWeekEnd.format("DD/MM"), {icon:'iconF',text: "трудозатраты по норме за текущую неделю, чел-дн", footer: "по норме"}),
      }, 
      { 
        dataField: 'workFactWeek', 
        text: "затраты с " + thisWeekStart.format("DD/MM") + " по " + thisWeekEnd.format("DD/MM") + " (факт)", 
        editable: false,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, thisWeekStart.format("DD/MM") + " - " + thisWeekEnd.format("DD/MM"), {icon:'iconF',text: "трудозатраты (факт) за текущую неделю, чел-дн", footer: "факт"}),
      }, 
      { 
        dataField: 'workWeekDiff', 
        text: "Разница, %", 
        editable: false,
        style: (cell) => diffStyle(cell),
        sort: true,
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, thisWeekStart.format("DD/MM") + " - " + thisWeekEnd.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
      }, 

      { dataField: 'valuePlanAll', text: 'объем итого (план)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconV',text: "объем работ (план)", footer: "план"})},
      { dataField: 'valueFactAll', text: 'объем итого (факт)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconV',text: "объем работ (факт)", footer: "факт"})},
      { 
        dataField: 'valueAllDiff', 
        text: 'Разница, %',
        sort: true, 
        editable: false, 
        sortFunc: (a, b, order) => sortFunc(a, b, order),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconP',text: "разница, %", footer: "%"}),
      },

      { dataField: 'workPlanAll', text: 'затраты итого (норма)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconF',text: "трудозатраты по норме, чел-дн", footer: "по норме"}) },
      { dataField: 'workFactAll', text: 'затраты итого (факт)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconF',text: "трудозатраты (факт), чел-дн", footer: "факт"})},
      { 
        dataField: 'workAllDiff', 
        text: 'Разница, %', 
        editable: false, 
        sort: true, 
        sortFunc: (a, b, order) => sortFunc(a, b, order),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconP',text: "разница, %", footer: "%"}),
      },
    ]

    return headers
  }

  workTypes = (data) => {
    const { lastWeekStart, thisWeekStart, lastWeekEnd, thisWeekEnd, emptyStrings } = this.state

    if (!data) return null;
    let DATA = data.workTypes || [];
    let colums = []

    DATA.map(e => {      
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= lastWeekStart &&  moment(b.day)<= thisWeekEnd)) : e.days

      if (emptyStrings && days.length <1) return

      let volWeekprev = 0,
        volWeekprevPlan = 0,
        volWeek = 0,
        volWeekPlan = 0,
        workWeekprev = 0,
        workWeekprevPlan = 0,
        workWeek = 0,
        workWeekPlan = 0,
        sumWork = 0,
        sumWorkPlan = 0,
        sumvaluePlan = 0

      const sumvalue = days.reduce(function(a, b) {
        if (moment(b.day) >=thisWeekStart && moment(b.day) <=thisWeekEnd) {
          volWeek= volWeek + (b.value ? ToFloat(b.value) : 0)
          workWeek= workWeek + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
          volWeekPlan = volWeekPlan + (b.valuePlan ? ToFloat(b.valuePlan) : 0)
        }
        if (moment(b.day) >=lastWeekStart && moment(b.day) <=lastWeekEnd) {
          volWeekprev= volWeekprev + (b.value ? ToFloat(b.value) : 0)
          workWeekprev= workWeekprev + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
          volWeekprevPlan = volWeekprevPlan + (b.valuePlan ? ToFloat(b.valuePlan) : 0)
        }

        sumWork= sumWork + ((b.coefficient ? ToFloat(b.coefficient) : 1) * (b.factor ? parseInt(b.factor) : 0));
        sumvaluePlan = sumvaluePlan + (b.valuePlan ? ToFloat(b.valuePlan) : 0)

        if (b.value && b.value > 0 && e.value === "Бетонирование входных групп, крылец/пандусов") console.warn("VALUE", b.value, b.day, b.id, e.value, e.brigade, e.construction)

        return a + (b.value ? ToFloat(b.value) : 0);
      }, 0)

      workWeekPlan = e.preDefValue ? ToFloat(e.preDefValue)*volWeek/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0) ? e.coeffNorm : 1) : 0 
      workWeekprevPlan = e.preDefValue ? ToFloat(e.preDefValue)*volWeekprev/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0 )? e.coeffNorm : 1) : 0 
      sumWorkPlan = e.preDefValue ? ToFloat(e.preDefValue)*sumvalue/11*((e.coeffNorm && ToFloat(e.coeffNorm) !== 0) ? e.coeffNorm : 1) : 0

      if (e.value === "Бетонирование входных групп, крылец/пандусов") console.warn("VALUE", e.brigade, e.construction, e.valueProject)

      e.cols = { 
        id: e.id, brigade: e.brigade, construction: e.construction, object: e.object, completed: e.completed, 
        deleted: e.deleted, worktype: `${e.value}, ${e.unit}`, preDefValue: e.preDefValue, 
        
        valueProject: e.valueProject ? e.valueProject : "", coeffNorm: e.coeffNorm ? e.coeffNorm : "", 
        valueRemain: ((e.valueProject && parseFloat(e.valueProject) !== 0) ? (ToFloat(e.valueProject) - (sumvalue ? ToFloat(sumvalue) : 0)).toFixed(2) : ""),
        valueFactAll: sumvalue ? ToFloat(sumvalue).toFixed(2) : 0,  workFactAll: ToFloat(sumWork).toFixed(2) ,
        valuePlanAll: ToFloat(sumvaluePlan).toFixed(2),  workPlanAll: ToFloat(sumWorkPlan).toFixed(2) ,
        valueFactWeekPrev: ToFloat(volWeekprev).toFixed(2),  valueFactWeek: ToFloat(volWeek).toFixed(2),
        valuePlanWeekPrev: ToFloat(volWeekprevPlan).toFixed(2), valuePlanWeek: ToFloat(volWeekPlan).toFixed(2),
        workPlanWeekPrev: ToFloat(workWeekprevPlan).toFixed(2), workPlanWeek: ToFloat(workWeekPlan).toFixed(2),
        workFactWeekPrev: ToFloat(workWeekprev).toFixed(2), workFactWeek: ToFloat(workWeek).toFixed(2), 

        workAllDiff: sumWorkPlan !== 0 ? -diff(ToFloat(sumWorkPlan), ToFloat(sumWork)) : 0,
        valueAllDiff: sumvaluePlan !==0 ? diff(ToFloat(sumvaluePlan), ToFloat(sumvalue)) : 0,
        valueWeekPrevDiff: volWeekprevPlan !==0 ? diff(ToFloat(volWeekprevPlan), ToFloat(volWeekprev)) : "",
        valueWeekDiff: volWeekPlan !==0 ? diff(ToFloat(volWeekPlan), ToFloat(sumvalue)) : "",
        workWeekPrevDiff: workWeekprev !==0 ? -diff(ToFloat(workWeekprevPlan), ToFloat(workWeekprev)) : "",
        workWeekDiff: workWeekPlan !==0  ? -diff(ToFloat(workWeekPlan), ToFloat(workWeek)) : "",
      }

      colums.push(e.cols)

      return e;
    });

    return colums;
  }

  render() {
    const { areaData, loaded, emptyStrings, curDay } = this.state;

    if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
    if (loaded === 1) {
      const colums = this.workTypes(areaData || this.state.areaData);
      const headers = this.getHeaders();

      return (
        <div className={'Area'} style={{width: '100%',margin:'0'}}>
          <div style={{width: '100%', height: 'auto', margin:'0 auto', position: 'fixed', top: '0', right:'auto', left:'auto', display:'flex', flexDirection:'row', justifyContent:'center',
            alignContent:'center', alignItems:'center', zIndex: '9999',}}>
            {loaded === 0 && <Loader />}
          </div>
          <div className="" style={{maxWidth:"1000px",margin: '5px'}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="btnGroupAddon"><b>{areaData.name}</b></div>
                <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
              </div>
              <input type="date"
                className="form-control"
                aria-label="Input group example" aria-describedby="btnGroupAddon"
                name="trip-start"
                min="2018-01-01"
                max={(new Date().toISOString()).slice(0,10)}
                value={curDay}
                onChange={e=>this.changeDay(e)}
              />
              <button className={`btn btn-sm btn-btn-secondary } `} onClick={()=>this.setState({emptyStrings: !emptyStrings})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
              <div className="alert alert-warning" style={{width: '450px', fontSize: "14px", maxHeight: '100%', margin: "0"}} role="alert">после ввода параметров следует обновлять данные (F5)!</div>
            </div>
          </div>
          {headers && headers.length && <Table headers={headers} cols={colums} update={()=>this.emptyData()} resetFilter={this.resetFilter} tabletype="AllTable n38" simple={true} /> || null}
        </div>
      )
    }
  }
}
