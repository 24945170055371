import { WebSocketLink } from 'apollo-link-ws';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import { split, ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
// import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

// import introspectionQueryResultData from './fragmentTypes.json';
import { _url, protocol, wsProtocol } from './APP/CONSTANTS';
import resolvers from './APP/GQL';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData
// });

const httpLink = createUploadLink({
  uri: `${protocol}${_url}/`,
});

const middlewareLink = setContext((req, previousContext) => {
  const jwt = localStorage.getItem('auth-token');

  if (jwt) {
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  return previousContext;
});

const httpLinkWithAuthToken = middlewareLink.concat(httpLink);

const cache = new InMemoryCache(/*{fragmentMatcher}*/);

const stateLink = withClientState({
  cache,
  resolvers,
  defaults: {
    hover: {
      text: null,
      x: null,
      y: null,
      style: null,
      __typename: 'hover',
    },
    pid: null,
    place: null,
    prevId: null,
    prevPlace: null,
    level: null,
    userrights: {
      navs: [{
        name: null,
        access: null,
        __typename: 'nav'
      }],
      __typename: 'userrights'
    },
    AppState:{
      cid: null,
      ctype: null,
      component: null,
      v: null,
      element: null,
      position: null,
      param: null,
      parent: null,
      pid: null,
      prevPid: null,
      prevId: null,
      place: null,
      prevPlace: null,
      __typename: 'AppState',
    },
    TopBarState: {
      name: null,
      crumbs: [null,null],
      back:{
        cId: null,
        cName: null,
        cParent: null,
        __typename: 'Back'
      },
      __typename: 'TopBarState',
    },
  }
});

const prelink = ApolloLink.from([stateLink, httpLinkWithAuthToken]);
const wsLink = new WebSocketLink({
  uri: `${wsProtocol}${_url}/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams() {
      return { Authorization: `Bearer ${localStorage.getItem('auth-token')}` };
    },
    jwt: localStorage.getItem('auth-token'),
  }
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);

    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  prelink,
);

export const client = new ApolloClient({
  link,
  cache
});

export const Client = client;

export default client;
