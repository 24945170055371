import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS';
import { WT_QA2, TRANSP_TYPES_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import {
  // _filter,
  _REPLSTR,
  _filt } from '../../CONSTANTS/operations';
// import Loader from '../../../Loader';
// import TableTilerFn from './TableTiler';
import GridTable from './GridTable.1';
import { EDIT_HANDBWT_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { RenderEditor } from '../ADMIN/EditorParts';





export default class HandBook extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props)
  
    this.state = {
      worktypes: [],
      AllWorktypes: [],
      transports: [],
      edit: null,
      comment: null,
      admin: null,
      found: null,
    }
  }

  componentDidMount(){
    const Q = `{
      me{
        type
      }
    }`;

    QA(Q).then((q)=>{
      if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
        this.setState({
          admin: true,
        })
      }
    })
    this.GetData()
  }

  GetDataTransport = async () => {
    if (!this.state.transports || !this.state.transports.length) {
      QA(TRANSP_TYPES_QA).then((data => {
        data && this.setState({
          transports: data.transportTypes,
          // areas: data.areas,
        })
      }));
    }
  }

  GetData = async () => {
    QA(WT_QA2).then((data => {
      if(data && data.worktypes && data.worktypes.length){

        const Data = data.worktypes.sort((a,b)=>{
          if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase() ) {
            return -1;
          }
          if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase() ) {
            return 1;
          }

          return 0;
        })
          .sort((a,b)=>{
            if (!a.parentName) return -1;

            if (a.parentName && b.parentName && a.parentName.toLowerCase() < b.parentName.toLowerCase() ) {
              return -1;
            }
            if (a.parentName && b.parentName && a.parentName.toLowerCase() > b.parentName.toLowerCase() ) {
              return 1;
            }

            return 0;
          })
          .sort((a,b)=>{
            if (!a.parents) return -1;

            if (a.parents && b.parents && a.parents < b.parents ) {
              return -1;
            }
            if (a.parents && b.parents && a.parents > b.parents ) {
              return 1;
            }

            return 0;
          })
          .sort((a,b)=>{
            if (!a.parents || !a.parents[1]) return -1;

            if (a.parents && b.parents && a.parents[1] && b.parents[1] && a.parents[1].toLowerCase() < b.parents[1].toLowerCase() ) {
              return -1;
            }
            if (a.parents && b.parents && a.parents[1] && b.parents[1] && a.parents[1].toLowerCase() > b.parents[1].toLowerCase() ) {
              return 1;
            }

            return 0;
          })
          .sort((a,b)=>{
            if (!a.parents || !a.parents[0]) return -1;

            if (a.parents && b.parents && a.parents[0] && b.parents[0] && a.parents[0].toLowerCase() < b.parents[0].toLowerCase() ) {
              return -1;
            }
            if (a.parents && b.parents && a.parents[0] && b.parents[0] && a.parents[0].toLowerCase() > b.parents[0].toLowerCase() ) {
              return 1;
            }

            return 0;
          });


        this.setState({
          worktypes: Data,
          AllWorktypes: Data,
        });
      }


      // this.filter({ string: 'телей', thisValue: this, array: data.worktypes, stateProp: 'worktypes' })
    }));
  }

  makeComment = (id) => {
    const {comment} = this.state;

    if(id){
      QA(`mutation{
        hbworkType(data:{
          ${(id && `id: "${id}"`) || ""}
          description: "${_REPLSTR(comment) || ""}"
          }){
          update
        }
      }
    `).then(data => {
        let Arr = this.state.AllWorktypes;
        let El = Arr[Arr.findIndex((e)=> e.id === id )];

        El.description = comment;
        Arr[Arr.findIndex((e)=> e.id === id )] = El;
        this.setState({
          edit: null,
          AllWorktypes: Arr,
          worktypes: Arr,
        })
      });
    }
  }

  filter = (props) => {
    const {key, keys, str} = props;
    const {AllWorktypes} = this.state;

    const F = _filt({str, array: AllWorktypes, keys: keys || [key], });

    this.setState({
      worktypes: F.array,
      found: F.found,
    })
  }

  sorting = (sort, sortMinus = false) => {
    const {worktypes, AllWorktypes,} = this.state;

    const NewSorting = AllWorktypes.sort((a,b) => {

      let comparison = 0;

      // let sort = 'description';
      // let sortMinus = 0;
      if(!a.hasOwnProperty(sort) || !b.hasOwnProperty(sort)) {

        return sortMinus ? 1 : -1;
      }
    
      if(!a[sort]) {
        
        return sortMinus ? -1 : 1;
      }
      if(!b[sort]) {
        
        return sortMinus ? 1 : -1;
      }
  
      const varA = (typeof a[sort] === 'string') ?
        a[sort].toUpperCase() : a[sort];
      const varB = (typeof b[sort] === 'string') ?
        b[sort].toUpperCase() : b[sort];
  

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return (
        sortMinus ? comparison  : (comparison * -1)
      );
    });

    this.setState({
      worktypes: NewSorting,
    })
  }

  handleChange = (e) => {
    if(e && e.target){
      let Str = e.target.value || null;

      Str = (Str && _REPLSTR(Str)) || null;
      this.setState({
        comment: Str,
      })
    }
  }

  render() {
    const {worktypes, AllWorktypes, edit, comment, admin, found, transports} = this.state;

    const headers = [
      {
        name: 'Раздел',
        onRender: null,
        onClick: null,
        editCell: null,
        onSelect: null,
        prop: 'sectionName',
        select: true,
        compareProps: ['sectionName'],
      },
      {
        name: 'Группа',
        onRender: null,
        onClick: null,
        editCell: null,
        onSelect: null,
        prop: 'groupName',
        select: true,
        compareProps: ['groupName'],
      },
      {
        name: 'Тип работ',
        onRender: null,
        editCell: null,
        onClick: null,
        prop: 'value',
      },
      {
        name: 'ЕИ',
        onRender: null,
        editCell: null,
        onClick: null,
        prop: 'unit',
      },
      {
        name: 'Норма, чел-час /ед',
        onRender: null,
        editCell: null,
        onClick: null,
        prop: 'preDefValue',
        maxWidth: '80px',
      },
      {
        name: 'Норма, маш-час /ед',
        onRender: ({Cell, Row, Col}) => (
          <div className="tableCellButton" style={{height: "100%", width: "100%", minHeight: "30px"}}>{
            Cell && Cell.length && Cell.map((tr, itr)=>{
              return(
                <div className="CellRow" key={"transportcell_"+itr}>
                  <div className="CellCol">{tr.name}</div>
                  <div className="CellCol">{tr.preDefValue}</div>
                </div>
              )
            }) || null
          }
          </div>),
        editCell: null,
        onClick: null,
        prop: 'transports',
        maxWidth: '180px',
      },
      {
        name: 'Комментарий',
        onRender: ({Cell, Row, Col}) => <RenderEditor rights={'admin'} query={EDIT_HANDBWT_QA} Cell={Cell} prop={Col && Col.prop} type="textarea" id={Row && Row.id} />,
        editCell: null,
        onClick: null,
        prop: 'description',
      },
    ];

    return (
      <div className="HandbookWrapper">
        <div className="handbookHead">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">Поиск</span>
            </div>
            <input type="search" className="form-control"  onChange={(e)=>this.filter({ str: e && e.target && e.target.value || '', keys: ['sectionName', 'groupName', 'value', 'unit', 'prop', 'description', 'parents'] })} placeholder="Поиск" aria-label="Username" aria-describedby="basic-addon1" />
            {(found || found === 0) && <span className="Err">Найдено: {found}</span>}
          </div>
        </div>

        <GridTable headers={headers} array={worktypes} fullArray={[...AllWorktypes]} sorting={this.sorting} filter={this.filter} />
      </div>
    )
  }
}

