import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_TRANSPORT_CSV } from '../GQL/SERVER/QUERIES/ServerQuerys';
import { CutTime, ToHashColor, GETWEEKNEW, HoverCell, sortObjects } from '../CONSTANTS/Transformers';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import Loader from '../../Loader';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { ToFloat } from '../CONSTANTS/operations';

let objectFilterFunc, constructionFilterFunc, transportFilterFunc, worktypeFilterFunc, wtGroupFilterFunc, wtSectionFilterFunc;

export default class CSVTransport extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaName: "",
      transportsData: [],
      emptyStrings: false, //скрывает пустые строки
      week: null,
      curDay: moment().format("YYYY-MM-DD"),
      objectFilter: '',
      transportFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      wtGroupFilter: '',
      wtSectionFilter: '',
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else this.getData()
  }


  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.getData()})
  }

  resetFilter = () => {
    objectFilterFunc();
    transportFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();
    wtGroupFilterFunc(); 
    wtSectionFilterFunc();

    this.setState({
      objectFilter: '',
      transportFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      wtGroupFilter: '',
      wtSectionFilter: '',
    })
  };

  getData = () => {
    const { match } = this.props;
    const { curDay} = this.state;
    const id = match.params.id;
    const last = moment(curDay).diff(moment(curDay).startOf('month'), 'days') + 1
    const week = GETWEEKNEW({date: curDay, last});
    
    this.setState({ week, loaded: 0 })

    if(!id) return null;
    QA(GET_TRANSPORT_CSV({id: id, csv: true})).then((data)=>{
      console.warn("aaa", data.transports);
      
      if(data && data.transports && data.area) {
        for (var i=0; i<data.transports.length; i++)  data.transports[i].id = i
        this.setState({
          transportsData: data.transports,
          areaName: data.area.name,
          loaded: 1
        })
      } else this.setState({loaded: 2})

    })
  }

  getHeaders = () => {
    const { week, transportsData, objectFilter, constructionFilter, transportFilter, worktypeFilter, wtGroupFilter, wtSectionFilter } = this.state;
    let filterData = [], objects = {}, transports = {}, constructions = {}, worktypes = {}, wtGroups = {}, wtSections = {};

    transportsData.forEach((e) => {
      filterData = [...filterData, { 
        object: e.object, construction: e.construction, wtGroup: e.group, wtSection: e.section, worktype: e.worktype,
        transport: (e.type ? e.type : "") + " (" + (e.firm ? e.firm : "Наш") + ") "+ (e.name ? e.name + " " : " ") + (e.number ? e.number : "")}]
    });
    
    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) && (transportFilter ? v.transport === transportFilter : true)
    && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true)
    && (wtGroupFilter ? v.wtGroup === wtGroupFilter : true) && (wtSectionFilter ? v.wtSection === wtSectionFilter : true)
    )
      .forEach(e => {
        objects[e.object] = e.object
        transports[e.transport] = e.transport
        constructions[e.construction] = e.construction
        worktypes[e.worktype] = e.worktype
        wtGroups[e.wtGroup] = e.wtGroup
        wtSections[e.wtSection] = e.wtSection
      });

    objects = sortObjects(objects)
    transports = sortObjects(transports)
    constructions = sortObjects(constructions)
    worktypes = sortObjects(worktypes)
    wtGroups = sortObjects(wtGroups)
    wtSections = sortObjects(wtSections)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }

    if(!week || !week.length) return null;

    const headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'construction', text: "Сооружение", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'transport', text: "Техника", editable: false, sort: true, headerStyle: { width: '250px' },  footer: "ИТОГО на странице", filter: selectFilter({options: transports, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {transportFilterFunc = filter}, onFilter: filterVal => this.setState({transportFilter: filterVal})})},
      { formatter: HoverCell, dataField: 'wtSection', text: "Раздел работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: wtSections, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {wtSectionFilterFunc = filter}, onFilter: filterVal => this.setState({wtSectionFilter: filterVal})})},               
      { formatter: HoverCell, dataField: 'wtGroup', text: "Группа работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: wtGroups, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {wtGroupFilterFunc = filter}, onFilter: filterVal => this.setState({wtGroupFilter: filterVal})})},                
      { formatter: HoverCell, dataField: 'worktype', text: "Тип работ", editable: false, sort: true, headerStyle: { width: '110px' }, footer: "", filter: selectFilter({options: worktypes, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal})})},

      ...week.map((e, i) => {
        const color = ToHashColor(e.date+i);

        return([
          { 
            dataField: 'day' + i + 1, 
            dateU: e.date, 
            cellType: "hours", 
            text: e.day + ' ' + e.month, 
            headerStyle: { background: color, fontSize: '13px', padding: '2px'}, 
            editorStyle: {fontSize: '12px'},
            editable: false,
            headerFormatter: (column, colIndex, _, date = e.wday + " " + e.day + " " + e.month ) => headerFormatter(column, colIndex, date, {icon:'',text:"Часы"}),
            footer: columnData => columnData.reduce((acc, item) => acc + (item ? ToFloat(item) : 0), 0).toFixed(2),
            footerStyle: {
              fontSize: '10px'
            }
          }, 
        ])}
      ).flat(),
      { dataField: 'sumHours', text: 'итого часов', editable: false, headerStyle: { fontSize: '13px', padding: '2px', width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'',text: "Итого часов"}) },
    ]

    return headers
  }

  getColumns = (data) => {
    const { week, emptyStrings } = this.state;

    if (!data) return null;
    let DATA = data || [];
    let colums = []

    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= moment(week[0].date) && moment(b.day)<= moment(week[week.length-1].date))) : e.days
      
      if (days.length <1) return

      e.cols = { 
        id: e.id, object: e.object, construction: e.construction, worktype: e.worktype, wtGroup: e.group, wtSection: e.section,
        transport: (e.type ? e.type : "") + " (" + (e.firm ? e.firm : "Наш") + ") " + (e.name ? e.name + " " : " ") + (e.number ? e.number : ""),
      }
      week.forEach((element, index) => {
        e.cols['day' + index + 1] = "";
        
        e.days.find(ins=>{
          if(!ins || !ins.day || !element.date) return false;
          let d = CutTime(ins.day);
          let d2 = CutTime(element.date);

          if(d === d2){
            e.cols['day' + index + 1] = ins.hours || ""; 

            return true;
          }
          
          return false
        });
      });

      const sumHours = e.days.reduce(function(a, b) {
        return a + (b.hours ? ToFloat(b.hours) : 0);
      }, 0)

      e.cols.sumHours = sumHours.toFixed(2)
      colums.push(e.cols)

      return e;
    });

    return colums;
  }

  render() {
    const { transportsData, loaded, curDay, areaName, emptyStrings } = this.state;

    if (loaded === 1) {
      const colums = this.getColumns(transportsData);
      const headers = this.getHeaders();

      return (
        <div className={'Area'}>
          <div className="" style={{maxWidth:"750px",margin: '5px'}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="btnGroupAddon"><b>{areaName}</b></div>
                <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
              </div>
              <input type="date"
                className="form-control"
                aria-label="Input group example" aria-describedby="btnGroupAddon"
                name="trip-start"
                min="2018-01-01"
                max={(new Date().toISOString()).slice(0,10)}
                value={curDay}
                onChange={e=>this.changeDay(e)}
              />
              <button className={`btn btn-sm btn-btn-secondary } `} onClick={()=>this.setState({emptyStrings: !emptyStrings})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
            </div>
          </div>
          <Table headers={headers} cols={colums} update={this.getData} resetFilter={this.resetFilter} addString={this.showModal} csv={true} tabletype="AllTable" simple={true} />
        </div>
      )}
    if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
  }
}
