import React, { Component } from 'react'
import Loader from '../../../Loader';
import PropTypes from 'prop-types'
import { QA } from '../../CONSTANTS';
import SelectSearch from 'react-select-search';

export class Lists extends Component {
  render() {
    const {array, arrayName, name, arrayProp, selected} = this.props;

    return (
      <div className="Lists">
        <div className="header">
          <h3>{arrayName || name}</h3>
          <div>
            <input type="search" onChange={this.filter} placeholder={"Поиск..."}/>
          </div>
        </div>
        <div className="inner">
          {(array && array.length &&
                array.sort((a,b)=>{
                  if (a.name < b.name ) {
                    return -1;
                  }
                  if (a.name > b.name ) {
                    return 1;
                  }
            
                  return 0;
                }).sort((a,b)=>{
                  if (a.parents < b.parents ) {
                    return -1;
                  }
                  if (a.parents > b.parents ) {
                    return 1;
                  }
            
                  return 0;
                }).map((e,i)=>{
                  const Parents = (e.parents && typeof e.parents === 'object' && e.parents.length && e.parents) || [e.parentName];

                  return(
                    <div className={"ListTile"+((selected && selected.id === e.id && " a") || "")} key={'hanbooklist-'+i} onClick={()=>this.selectList(e)}>
                      <span className="Name">{e[arrayProp] || e.name || e.value || "нет названия"}</span>
                      {/* {e.parentName && <span className="ParentName">{e.parentName}</span>} */}
                      {Parents && Parents.length && <span className="ParentName">{Parents.join(' > ')}</span>}
                      {e.param && <span className="ParentName">{ e.param }</span>}
                    </div>
                  )
                })) || <Loader />
          }
        </div>
      </div>
    )
  }
}
export class FetchedSelect extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      error: null,
      position: null,
      data: null,
      Val: '',
      array: [],
      selected: null,
      prevState: {},
      fetchname: null,
      fetchHelp: null,
      clear:false,
      selectedNames: [],
      preventFetch: false,
    }
  }


  static propTypes = {
    change: PropTypes.func,
    fetch: PropTypes.array,
    prop: PropTypes.string,
  }

  fetchData = ({query, propQuery, selectedParam, graphname, position, name, help}) => {
    /**
     * query - функция принимающая параметры или строка
     * propQuery - название параметра который будет передан в query, не применяется для query без параметров
     * selectedParam - строка которая будет передана вместе с параметром в query, является ключом объекта предыдущего query, не применяется для query без параметров
     * graphname - ключ объекта предыдущего query, иначе первый
     * position - автоматический параметр, номер текущего query
     * name - название текущего query
     */
    // const {fetchname} = this.state;

    if(name){
      // let NewNames = fetchname && fetchname.length && [...fetchname, name] || [name];

      this.setState(
        {
          // fetchname: [...NewNames],
          fetchname: name,
          fetchHelp: help,
        }
      )
    }

    if (!query) return null;
    let Q = query;

    if (propQuery && selectedParam && typeof query === 'function') {
      Q = query({[propQuery]: selectedParam});
    }
    if ((!propQuery || !selectedParam) && typeof query === 'function') {
      Q = query();
    }
    QA(Q).then((data)=>{
      if(data && Object.keys(data) && Object.keys(data)[0] && data[Object.keys(data)[0]]){
        // const DATA = graphname && data[Object.keys(data)[0]][graphname] && data[Object.keys(data)[0]][graphname].length && data[Object.keys(data)[0]][graphname] || data[Object.keys(data)[0]];
        const DATA = (graphname && data && data[Object.keys(data) && Object.keys(data)[0]] && data[Object.keys(data)[0]][graphname] 
          && data[Object.keys(data)[0]][graphname].length && data[Object.keys(data)[0]][graphname]) || data[Object.keys(data)[0]];
        const D =  DATA || [];

        if (!D || !D.length){
          this.setState({preventFetch: true,error: "Не найдено ни одного типа - "+name});

          return null;
        }

        this.setState({
          data: D,
          position: position+1,
          array: D,
          prevFetch: {query, propQuery, selectedParam, graphname, position, name, help},
        })
      }
    })
  }
  nextData = (target, prop, name) => {
    try {
      const {change, fetch} = this.props;
      const {position, selectedNames} = this.state;

      this.setState({
        Val: target.value,
        selected: target,
        selectedNames: [...selectedNames, `${name || ''} ${target.name || target.value || ''}` ],
      })
  
      if(/*fetch.length === position || */fetch[Number(position) - 1 || 0].use){
        this.setState({
          preventFetch: true,
        })
        change(target, prop)
      } else {
        this.fetchData({
          query: fetch[Number(position)].query || null,
          propQuery: fetch[Number(position)].propQuery || null,
          selectedParam: (target[fetch[Number(position)].selectedParam || 'parent']) || null,
          graphname: fetch[Number(position)].graphname || null,
          position: position,
          name: fetch[Number(position)].name || null,
          help: fetch[Number(position)].help || null,
        })
      }
    } catch (error) {
      return null;
    }
  }

  clear = () => {
    this.setState({
      error: null,
      position: null,
      data: null,
      Val: '',
      array: [],
      selected: null,
      prevState: {},
      fetchname: null,
      fetchHelp: null,
      selectedNames: [],
      preventFetch: false,
    })
    // this.fetchData(this.state.prevFetch)
    const {fetch} = this.props;

    fetch && fetch.length && this.fetchData({
      query: fetch[0].query || null,
      propQuery: fetch[0].propQuery || null,
      selectedParam: fetch[0].selectedParam || null,
      graphname: fetch[0].graphname || null,
      use: fetch[0].use || null,
      position: 0,
      name: fetch[0].name || null,
      help: fetch[0].help || null,
    })
  } 

  componentDidMount(){
    const {fetch} = this.props;

    fetch && fetch.length && this.fetchData({
      query: fetch[0].query || null,
      propQuery: fetch[0].propQuery || null,
      selectedParam: fetch[0].selectedParam || null,
      graphname: fetch[0].graphname || null,
      use: fetch[0].use || null,
      position: 0,
      name: fetch[0].name || null,
      help: fetch[0].help || null,
    })
  }

  render() {
    const {prop} = this.props;
    const {/* data, position, placeholder, clear, */ selected, Val, array, fetchname, fetchHelp, selectedNames, preventFetch, error} = this.state;

    return(
      <div className="marginer">
        {error &&
          <div className={'error'}>
            {error}
          </div>
        }
        {selected && <div className="Tags">
          <div className="tag" onClick={()=>this.clear()}>
            {selectedNames && selectedNames.join(' > ')}
          </div>
        </div>}
        {!preventFetch && <div>
          <p>{fetchHelp}</p>
          <SelectSearch
            name={'select'+prop}
            value={Val}
            options={array}
            placeholder={fetchHelp}
            onChange={(target)=>this.nextData(target, prop, fetchname)}
          />
        </div>}
      </div>
    );
  }
}


export class Delete extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      agree: false,
      disabled: true,
    }
  }

  click = () => {
    setTimeout(() => {
      this.setState({
        disabled: false,
      });
    }, 1500);
    this.setState({
      agree: true,
    });
  }
  agree = () => {
    const {onClick} = this.props;
    
    this.setState({
      agree: false,
      disabled: true,
    });
    onClick && typeof onClick === 'function' && onClick()
  }
  cancel = () => {
    this.setState({
      agree: false,
      disabled: true,
    });
  }
  
  render() {
    const {onClick, name, agreeText} = this.props;
    const {agree, disabled} = this.state;

    return (
      <div className="Agreetor">
        {!agree && <div className="btn btn-dark" onClick={this.click}>{name}</div>}
        {agree && <div className="Agree">
          <div className="Header">
            {agreeText}
          </div>
          <div className={"btn btn-dark" + (disabled ? " disabled" : "")} onClick={disabled ? null : this.agree}>{name}</div>
          <div className="btn btn-light" onClick={this.cancel}>{'нет'}</div>
        </div>
        }
      </div>
    )
  }
}


const Inp = ({returnValue, prop, defValue}) => {

  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <input type="text" defaultValue={defValue} onChange={(inp)=>{ typeof returnValue === 'function' && returnValue(inp.target.value, prop)}}/>
    </div>
  )
}
const TextArea = ({returnValue, prop, defValue}) => {

  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <textarea type="text" defaultValue={defValue} onChange={(inp)=>{ typeof returnValue === 'function' && returnValue(inp.target.value, prop)}}/>
    </div>
  )
}
const Select = async ({returnValue, prop, defValue, query}) => {

  const Arr = query && await QA(query).then((data)=>{

    return data;
  });
  // const [Value, setValue] = useState(defValue || "");

  return (
    <div className="inputs">
      <select defaultValue={defValue}>
        {Arr && Arr.length && Arr.map((e,i)=>{

          return(
            <option key={'options-'+i} value={e.value}>{e.name}</option>
          )
        }) || null}
      </select>
    </div>
  )
}

export class RenderEditor extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      admin: false,
      value: "",
      defValue: "",
    }
  }

  componentDidMount(){
    const {Cell} = this.props;



    this.setState({
      value: Cell,
      defValue: Cell,
    })
  }

  componentDidUpdate(){
    const {Cell,} = this.props;



    if (Cell !== this.state.defValue) {
      this.setState({
        value: Cell,
        defValue: Cell,
      })
    }
  }

  toEdit = () => {
    const {value, editCell, admin} = this.state;

    const Q = `{
      me{
        type
      }
    }`;

    if(!admin && this.props.rights && this.props.rights === 'admin'){
      QA(Q).then((q)=>{
        if (q && q.me && q.me.type && (q.me.type === 'hi' || q.me.type === 'monster')) {
          this.setState({
            editCell: true,
            admin: true,
          })
        }
      });

      return null;
    }
    
    if (admin || !this.props.rights) {

      this.setState({editCell: true,});
      
      return null;
    }

    // this.setState({editCell: true,});
  }

  save = () => {
    const {query, saveParams, prop, id, type, beforeSave} = this.props;
    const {value, editCell} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы

    // let TextArea = null;

    // if (type === "textarea") {
    //   TextArea = value && value.split('\n') || null;
    // }


    const Value = (beforeSave && typeof beforeSave === 'function' && beforeSave(value)) || value || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });
    
    if(!Q) return null;
    


    QA(Q).then((data)=>{
      this.setState({
        value: Value,
        editCell: !editCell,
      });
    })
    
  }
  
  render() {
    const {type} = this.props;
    const {editCell, value, admin} = this.state;
    

    if (!editCell) {
      return(<div style={{height: '100%', width: '100%', minHeight: '30px'}} onClick={()=>{ this.toEdit()}}>
        {value}
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%', minHeight: '30px'}} >
          <div>{type !== "textarea" && value}{type === "textarea" && value}</div>
          <div className="input-group">
            {type !== "textarea" && <input
              type={type || "text"}
              className="form-control"
              onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value} /> }
            {
              type === "textarea" && <textarea className="form-control" rows="3" onChange={(e)=>this.setState({value: e.target.value})} defaultValue={value} placeholder={value}/>
            }

            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}












export class RenderEditorSelect extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      value: "",
      defvalue: "",
    }
  }

  componentDidMount(){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    this.setState({
      value: Cell,
      defvalue: Cell,
      array: selectArray && selectArray.length && selectArray,
    })
  }

  componentDidUpdate(prevProps, prevState){
    const {Cell, selectProp, selectArray,  selectQuery, selectArrayName} = this.props;

    // if (prevState.value !== this.state.value) {
    // }

    if (Cell !== prevProps.Cell) {
      this.setState({
        value: Cell,
        defvalue: Cell,
      })
    }


    if (selectArray && selectArray.length && selectArray !== this.state.array) {
      this.setState({
        array: selectArray && selectArray.length && selectArray,
      })
    }
  }

  selectQuery = () => {
    const {Cell, selectProp, selectQuery, selectArrayName} = this.props;
    const {value, editCell, array} = this.state;

    const Q = selectQuery && typeof selectQuery === 'string' && selectQuery || selectQuery && typeof selectQuery === 'function' && selectQuery();

    if(!Q || array && array.length) return null;

    QA(Q).then(data=>{

      if(data && data[selectArrayName]){
        this.setState({
          array: data[selectArrayName],
        })
      }
    })
  }

  openEditor = () => {
    const {value, editCell, array} = this.state;

    this.setState({editCell: true});
    !array && this.selectQuery()
  }

  save = () => {
    const {query, saveParams, prop, id, Col} = this.props;
    const {value, select, editCell, array} = this.state;
    //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы
    const Value = select || "";
    const Q = id && query && typeof query === 'function' && query({[prop]: Value, id });

    QA(Q).then((data)=>{
      this.setState({
        value: value,
        editCell: !editCell,
      });
    })
  }
  
  render() {
    const {editCell, value, array} = this.state;
    const {Cell, Col} = this.props;

    if (!editCell) {
      return(<div className="tableCellButton" style={{height: '100%', width: '100%', minHeight: '30px'}} onClick={this.openEditor}>
        {(array && array.length && (array.find(e => e.id === value) && array.find(e => e.id === value).name || array.find(e => e.id === value) && array.find(e => e.id === value).value || null)) || value}
      </div>)
    }
    if (editCell) {
  
      return(
        <div style={{height: '100%', width: '100%'}} >
          {/* <div>{value}</div> */}
          <div className="input-group">
            {array && array.length && <select className="custom-select" id="inputGroupSelect04" onChange={(e)=>{ this.setState({select: e.target.value, value: (e.target.selectedOptions[0].label) ||"" })}} defaultValue={'0'}>
              <option value="0"></option>
              {
                array.map((opt, i)=>{

                  if(opt && opt.id){
                    return(
                      <option value={opt.id} key={"option-"+ (Col && Col.prop) + '-' + i}>
                        {opt.name || opt.value || '...'}
                      </option>
                    )
                  }
                })
              }
            </select>}
            <div className="input-group-append">
              <button className="btn btn-outline-success" type="button" onClick={this.save}>Сохранить</button>
              <button className="btn btn-outline-secondary" type="button" onClick={()=>this.setState({editCell: !editCell, value: Cell})}>отмена</button>
            </div>
          </div>
        </div>
      )
    }
  
    return(null)
  }
}










export class RenderArrayEditor extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      editCell: false,
      value: [],
      defvalue: [],
      NewValues: [],
      loading: false,
    }
  }

  componentDidMount(){
    const {Cell} = this.props;

    this.setState({
      value: Cell ||  [],
      defvalue: Cell || [],
    });
  }

  // componentDidUpdate(){
  //   const {Cell} = this.props;

  //   if (Cell && Cell.length && (!this.state.value || !this.state.value.length || Cell !== this.state.value)) {
  //     this.setState({
  //       value: Cell ||  [],
  //       defvalue: Cell || [],
  //     })
  //   }
  //   if (!Cell.length && this.state.value.length) {
  //     this.setState({
  //       value: [],
  //       defvalue: [],
  //     })
  //   }
  // }
  componentDidUpdate(prevProps, prevState){
    const {Cell} = this.props;

    if (prevProps.Cell !== Cell) {
      this.setState({
        value: Cell ||  [],
        defvalue: Cell || [],
      })
    }
    // if (!Cell.length && this.state.value.length) {
    //   this.setState({
    //     value: [],
    //     defvalue: [],
    //   })
    // }
  }

  // selectQuery = () => {
  //   const {Cell, arrays, selectQuery, selectArrayName} = this.props;
  //   const {value, editCell, array} = this.state;

  //   arrays && arrays.length && array.foreach((arr)=>{

  //     if (arr && arr.array && arr.array.length) {
  //       return null;
  //     }
  //   })
  // }

  getArrays = () => {
    const {Cell, arrays, getArrays, edit} = this.props;
    // const {value, editCell, array} = this.state;

    if (edit && edit['name'] && edit['name'] && edit['name'].options && edit['name'].options.length) {
     
      this.setState({
        // array: arrays,
        editCell: true,
        loading: false,
      });

      return null;
    } else {
      this.setState({
        loading: true,
      },()=>{
        getArrays()

        setTimeout(() => {
          this.getArrays()
        }, 2000);
      });

      return null;
    }
  }

  // editProp = ({index, targetvalue}) => {
  //   const {value, editCell, array} = this.state;

  //   let Val = value && [...value] || [];
    
  //   if(!index){
  //     Val.push({name: null, preDefValue: targetvalue || null,});
  //   }

  //   // if(index && value && value.length) {
  //   //   Val = value.
  //   // }

  //   // this.setState({
  //   //   value: 
  //   // })

  // }

  // save = () => {
  //   const {query, saveParams, prop, id, Col, Row} = this.props;
  //   const {value, select, editCell, arrays} = this.state;

  //   let ClearState = {};
  //   let QueryParams = {};
  //   let NewValues = [];
  //   let ValuesData = arrays && arrays.length && arrays.map((e,i)=>{
  //     if(!e || !e.prop) return null;

  //     ClearState["prop"+e.prop] = "";
  //     if(this.state["selectedValue"+e.prop]){
  //       NewValues.push(this.state["selectedValue"+e.prop]);
  //     }

  //     if(this.state["prop"+e.prop]){
  //       QueryParams[e.prop] = this.state["prop"+e.prop];
  //     }

  //     return(`${e.prop}: "${this.state["prop"+e.prop]}"`);
  //   }).join(', ');
  //   //TODO save params функция для проверок отправляемого значения - can be null, количество символов, разрешенные символы

  //   // const Value = select || "";

  //   // this.setState({
  //   //   NewValues: [...this.state.NewValues, NewValues.join(' ')],
  //   //   ...ClearState
  //   // })
    
  //   const Q = id && query && typeof query === 'function' && query({[prop]: id, ...QueryParams });

  //   QA(Q).then((data)=>{
  //     this.setState({
  //       NewValues: [...this.state.NewValues, NewValues.join(' ')],
  //       ...ClearState
  //     })
  //   })
  // }

  save = () => {
    const {value} = this.state;
    const {query, edit, propsRender, prop, arrayProp, id} = this.props;

    if (!value
       || !value.length
       || !id 
       || !query 
       || !propsRender 
       || !prop 
       || !propsRender.length 
       || !arrayProp
       || typeof query !== 'function') return null;

    const ValuesData = value.filter(e=>e).map((e)=>{
      const params = propsRender.map(prp => `${prp}: "${e[prp] || ""}"`).join(', ');
      
      if(!params) return null;

      return `{${params}}`;
    })
      .filter(e=>e)
      .join(', ');
    const Q = query({[prop]: id, [arrayProp]: `${ValuesData}` });

    QA(Q).then((data)=>{
      this.setState({
        editCell: false,
      })
    })
  }

  removeOneOf = (idx, id) => {
    const {value} = this.state;
    const {remove} = this.props;

    if(!value || !value.length) return null;

    const NewArr = [...value].filter((e,i)=> i !== idx );

    this.setState({
      value: NewArr,
    }, ()=>{
      if(id && remove && remove.query && typeof remove.query === 'function'){
        const Q = remove.query({[remove.prop || 'id']: id});
  
        Q && QA(Q);
      }
    });
  }

  setValue = (newvalue, key, idx) => {
    if(!key) return null;

    const {value} = this.state;
    const NewArr = value && value.length && value.map((el,ix) => {
      if(ix === idx) {
        let E = el;

        E[key] = newvalue || '';
      }

      return el;
    });


    this.setState({
      value: [...NewArr],
    },()=>{
    });
  }


  append = () => {
    const {value} = this.state;
    const {edit} = this.props;

    let E = {};

    for (const prop in edit) {
      E[prop] = '';
    }


    let NewArr = value && value.length && [...value] || [];

    this.setState({
      value: [...NewArr, E],
    },()=>{
      // this.setState({
      //   loading: true,
      // }, ()=>{
      //   setTimeout(() => {
      //     this.setState({
      //       loading: false,
      //     })
      //   }, 100);
      // })
    })
  }


  // makeValue = ({element, props}) => {
  //   return (element && props && props.length && props.map((e)=>{
  //     if(!e || !element[e]) return null;

  //     return element[e];
  //   })) || null;
  // }
  
  render() {
    const {editCell, value, defvalue, array, NewValues, loading} = this.state;
    const {Cell, Col, Row, propsRender, arrays, editProps, edit} = this.props;

    if(loading){
      return(
        <Loader/>
      )
    }
      
    return(<div className="tableCellButton" style={{height: '100%', width: '100%', minHeight: '30px'}}>
      <div onClick={this.getArrays} style={{width: '100%', minHeight: '30px'}}>
        { (!value || !value.length) && Cell && typeof Cell === 'object' && Cell.length && Cell.map((cellPart, i) => {
          return(
            <div className="CellRow" key={"cellPart-"+i}>
              {
                Object.keys(cellPart).map(key => {
                  if(propsRender && propsRender.length && propsRender.includes(key)){
                    return <div className="CellCol" key={'cellcol'+i+key}>{cellPart[key]}</div>;
                  }

                  return null;
                }) || null
              }
            </div>
          )
        }) || null}
        {value && typeof value === 'object' && value.length && value.map((cellPart, i) => {
          return(
            <div className="CellRow" key={"cellPart-"+i}>
              {
                Object.keys(cellPart).map(key => {
                  if(propsRender && propsRender.length && propsRender.includes(key)){
                    return <div className="CellCol" key={'cellcol'+i+key}>{cellPart[key]}</div>;
                  }

                  return null;
                }) || null
              }
            </div>
          )
        }) || null}
      </div>
      
      {
        editCell && <div>
          <div style={{height: '100%', width: '100%'}} >
            {value && typeof value === 'object' && value.length && value.map((cellPart, i) => {

              return(
                <div className="cell" key={"cellPart-"+i}>
                  <div className="CellRow input-group">
                    {
                      propsRender.map(key => {
                        if(!edit[key]) return null;
                        if (edit[key] && edit[key].type && edit[key].type === 'select' && edit[key].options) {

                          return(
                            <select className="custom-select" key={'select'+i+key} onChange={(e)=>{this.setValue(e.target.value, key, i)}} value={value[i][key]}>
                              <option value="0"></option>
                              {
                                edit[key].options.length && edit[key].options.map((opt, i)=>{
                
                                  if(opt && opt.id){
                                    return(
                                      <option value={opt.value} key={"option-"+ (Col && Col.prop) + '-' + i}>
                                        {opt.name || opt.value}
                                      </option>
                                    )
                                  }
          
                                  return null;
                                })
                              }
                            </select>
                          )
                        }

                        return( <div className="CellCol" key={'cellcol'+i+key}>
                          <input type={edit[key].type || "text"} onChange={(e)=>{ this.setValue(e.target.value, key, i) }} value={value[i][key]}/>
                        </div>)
                      }) || null
                    }
                  </div>
                  <button className="btn btn-outline-danger btn-sm" type="button" onClick={()=>this.removeOneOf(i, value[i].id)}>x</button>
                </div>
              )
            }) || null}
          </div>
          <div className="btn-group" style={{margin: '10px 0 0'}}>
            <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.append}>+</button>
            <button className="btn btn-outline-success btn-sm" type="button" onClick={this.save}>Сохранить</button>
            <button className="btn btn-outline-secondary btn-sm" type="button" onClick={()=>this.setState({editCell: false, value: defvalue})}>отм</button>
          </div>
        </div>
      }

    </div>
    )
  
  }
}
