import React, { Component, Fragment } from 'react'

import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { ADD_WORKTYPE_QA, EDIT_WTYPE_QA, DELETE_WTYPE_QA, ADD_TECHNICS_NORMS_QA, REM_TECHNICS_NORMS_QA, } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { WT_UNITS_QA, SECT_PARENTS_QA, SECT_PARENT_ID_QA, TRANSP_QA, TRANSP_TYPES_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
// import Editorable from './Editorable.third.1';
import { RenderEditor, RenderEditorSelect, RenderEditorMultiple, RenderArrayEditor } from './EditorParts.2';
import { ToFloat } from '../../CONSTANTS/operations';
import AdminGridEditor from './AdminGridEditor';
import { FetchedSelect, RenderAddSelect } from './EditorParts';

export default class WorkTypeMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
      sections: [],
      editValue: "",
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => await QA(WT_UNITS_QA).then((data => {

    console.log(data);
    
    data && this.setState({
      worktypes: data.worktypes,
      units: data.units,
    })
  }));

  GetDataForMultiple = async () => {
    if (!this.state.transports || !this.state.transports.length) {
      QA(TRANSP_TYPES_QA).then((data => {
        data && this.setState({
          transports: data.transportTypes,
          // areas: data.areas,
        })
      }));
    }
  }
  
  render() {
    const {worktypes, units, transports } = this.state;
    const ELEMENTS = [
      {
        name: 'Название',
        value: null,
        defaultValue: this.state.editValue,
        type: 'input',
        important: true,
        prop: 'value',
        propName: null,
        array: [],
      },
      {
        name: 'Разделы',
        type: 'fetch',
        important: true,
        prop: 'section',
        propName: 'id',
        onRender: (props) => <FetchedSelect {...props} change={(target)=>props.set(target.id)} fetch = {[
          { query: SECT_PARENTS_QA, propQuery: 'id', selectedParam: 'id', graphname: null, name: 'Раздел' },
          { query: SECT_PARENT_ID_QA, propQuery: 'id', selectedParam: 'id', graphname: null, use: true, name: 'Группа' },
        ]} />
      },
      {
        name: 'Единица измерения',
        important: true,
        prop: 'link',
        propName: 'id',
        array: units,
        onRender: (props)=>RenderAddSelect({...props, propName: 'id', array: units, }), // заменить рендер
      },
      {
        name: 'Норму',
        type: 'input',
        important: false,
        prop: 'preDefValue',
        inputType: 'number' 
      },
    ];

    const headers = [
      {
        name: '',
        prop: 'id',
        maxWidth: '30px',
      },
      {
        name: 'Раздел',
        prop: 'sectionName',
        select: true,
        compareProps: ['sectionName'],
      },
      {
        name: 'Группа',
        prop: 'groupName',
        select: true,
        compareProps: ['groupName'],
      },
      {
        name: 'Тип работ',
        select: true,
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_WTYPE_QA} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        prop: 'value',
      },
      {
        name: 'ЕИ',
        onRender: ({Cell, Row, Col})=><RenderEditorSelect Col={Col} Row={Row} query={EDIT_WTYPE_QA} selectProp={'id'} selectArray={units} Cell={Cell} prop={'link'} id={Row && Row.id} />,
        prop: 'unit',
      },
      {
        name: 'Норма',
        onRender: ({Cell, Row, Col})=><RenderEditor query={EDIT_WTYPE_QA} beforeSave={ToFloat} Cell={Cell} prop={Col && Col.prop} id={Row && Row.id} />,
        prop: 'preDefValue',
      },
      {
        name: 'Техника',
        onRender: ({Cell, Row, Col})=><RenderArrayEditor
          getArrays={this.GetDataForMultiple}
          edit={{
            name: {type: 'select', options: transports, },
            preDefValue: {type: 'number', },
          }}
          remove={{query: REM_TECHNICS_NORMS_QA, prop: 'id', param: 'id'}}
          prop={'workType'}
          arrayProp={'transport'}
          propsRender={['name', 'preDefValue']}
          query={ADD_TECHNICS_NORMS_QA}
          Cell={Cell}
          Col={Col}
          Row={Row}
          id={Row && Row.id} />,
        // onRender: ({Cell, Row, Col})=><RenderEditorMultiple
        //   getArrays={this.GetDataForMultiple}
        //   editProps={[{prop: 'name', editorType:'select', array: transports,}, {prop: 'preDefValue', editorType:'number'}]}
        //   arrays={[
        //     {array: transports, selectProp: 'name', prop: 'transport', propsShow: ['name', 'preDefValue'], header: "Тип техники" },
        //     // {array: areas, selectProp: 'id', prop: 'area'},
        //     {prop: 'preDefValue', inputType: 'number', header: "норма техники"}
        //   ]}
        //   prop={'workType'}
        //   propsRender={['name', 'preDefValue']}
        //   query={ADD_TECHNICS_NORMS_QA}
        //   Cell={Cell}
        //   Col={Col}
        //   Row={Row}
        //   id={Row && Row.id} />,




        editCell: null,
        onClick: null,
        headerClick: null,
        prop: 'transports',
      },
    ];
    
    return (
      <AdminGridEditor
        ADD={ADD_WORKTYPE_QA}
        EDIT={EDIT_WTYPE_QA}
        DEL={DELETE_WTYPE_QA}
        name={'Тип работ'}
        arrayName={'Типы работ'}
        array={worktypes}
        answerName={'worktypes'}
        refetch={this.GetData}
        headers={headers}
        elements={ELEMENTS}
        searchKeys={['sectionName', 'groupName', 'value', 'unit', 'preDefValue']}
      />
    )
    // return (
    //   <Editorable
    //     ADD={ADD_WORKTYPE_QA}
    //     EDIT={EDIT_WTYPE_QA}
    //     DEL={DELETE_WTYPE_QA}
    //     elements={ELEMENTS}
    //     name={'Тип работ'}
    //     arrayName={'Типы работ'}
    //     array={worktypes}
    //     refetch={this.GetData}
    //     // lister={true}
    //     // recursive={recursive}
    //     tableHeaders={headers}
    //     params={['param', 'preDefValue']}
    //     searchKeys={['sectionName', 'groupName', 'value', 'unit', 'preDefValue']}
    //   />
    // )
  }
}
