import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';

export default class Confirmation extends Component {
  static propTypes = {
    doAction: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    const { modal, hideModal, doAction, title } = this.props;

    return (
      <Modal
        size="sm"
        style={{color: "black"}}
        show={modal}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button className="btn btn btn-success" onClick={ doAction } style={{width: "120px", left: '22px'}}> Подтвердить </button>
          <button className="btn btn btn-danger" onClick={ hideModal } style={{width: "120px", position: 'fixed', right: '22px'}}> Отмена </button>
        </Modal.Body>
      </Modal>
    )
  }
}
