/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import '../../CSS/PARTS/UniChild.css'

const Icon = ({src, alt, width = 30, height = 30}) => {
  if(!src) return null;
  let uri = src;

  if(src === 1 || src === true){
    uri = 'https://media.moddb.com/images/mods/1/31/30336/fallout-shelter-buttonjpg-0e23a7.1.1.jpg'
  }

  return (
    <div className="Icon" style={{width: width+'px',height: height+'px', minWidth: width+'px',minHeight: height+'px',maxWidth: width+'px',maxHeight: height+'px'}}>
      <img src={uri} alt={alt || null}/>
    </div>
  )
};
const Text = ({text}) => {
  if(!text) return null;

  return (
    <div className={'TextRow'}>
      {text}
    </div>
  )
};




export class UniChild extends Component {
  render() {
    const {icon, alt, /* id, type, parent, iconLeft, */ name, text, rightIcon, children, addClass, click, width = 30, height = 30} = this.props;


    let OnClick = ()=>{
      console.warn(this);

      return null;
    };

    if( click && typeof click === 'function' ){
      OnClick = click;
    }

    let Class = '';

    if(addClass){
      Class = ' ' + addClass.toString();
    }

    return (
      <div className={'UniChild' + Class} onClick={OnClick}>
        <div className="Top">
          {icon && !rightIcon && <Icon width={width} height={height} src={icon} alt={alt || name} />}
          {(name || text) && <Text text={name || text} />}
          {icon && rightIcon && <Icon width={width} height={height} src={icon} alt={alt || name} />}
        </div>
        {children ? <div className="Bottom">
          {children}
        </div> : null}
      </div>
    )
  }
}
export default UniChild
