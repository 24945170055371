import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter';

import { QA } from '../CONSTANTS';
import { GET_AREA_WORKTYPE_TRANSPORT } from '../GQL/SERVER/QUERIES/ServerQuerys';
import Table from '../COMPONENTS/TABLEBOOTSTRAP/Table';
import Loader from '../../Loader';
import headerFormatter from '../COMPONENTS/TABLEBOOTSTRAP/headerFormatter';
import { checkAreaAccess } from '../COMPONENTS/TABLEBOOTSTRAP/checkAreaAccess';
import { transportOnly, transportHoursOnly, transportNorms } from '../COMPONENTS/TABLEBOOTSTRAP/transportFormatter';
import { ToFloat } from '../CONSTANTS/operations';
import { HoverCell, sortObjects } from '../CONSTANTS/Transformers';

let  objectFilterFunc, constructionFilterFunc, worktypeFilterFunc;

const sortFunc = (a, b, order) => {
  const a1 = a.reduce((a, v) => (a + (v.norm ? ToFloat(v.norm) : 0)), 0)
  const b1 = b.reduce((a, v) => (a + (v.norm ? ToFloat(v.norm) : 0)), 0)

  if (order === 'desc') {
    return ToFloat(b1) - ToFloat(a1);
  }
  
  return ToFloat(a1) - ToFloat(b1); // desc
}

const diffStyle = (cell) => {
  if(ToFloat(cell) < -10) {
    return {
      backgroundColor: 'rgba(194, 41, 41, 0.1)'
    };
  }
  if(ToFloat(cell) > 30) {
    return {
      backgroundColor: 'rgba(49, 170, 59, 0.1)'
    };
  }
}

const diff = (plan,fact) => {
  let diff = 0

  if (plan !== 0 || fact !== 0) diff = -(1-fact/plan)*100
  // diff = ((fact-plan)/((plan+fact)/2)) *50

  return diff.toFixed(2)
}

export default class RTrArea extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      areaData: {},
      canEdit: false, 
      emptyStrings: false, //скрывает пустые строки
      objectFilter: '',
      constructionFilter: '',
      worktypeFilter: '',
      currentFilter: '', // текущий вид фильтра
      curDay: moment().format("YYYY-MM-DD"),
      weekCount: 2,
      week: [],
      loaded: 0, // 0 - грузиться, 1- загрузилос, 2- нет даты
    }
  }
  
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.checkUser()
    console.warn("aaaaa");
    
  }

  checkUser = async () => {
    const { match } = this.props;
    const id = match.params.id;
    const { access, write }  = await checkAreaAccess(id)

    if (!access) this.setState({loaded: 2})
    else {
      this.changeWeeks()
      this.setState({canEdit: write})
    }
  }
  
  updateState = () => {
    const { DATA } = this.state

    console.warn(DATA)
  }


  changeDay = (e) => {
    if (e && e.target && e.target.value) this.setState({curDay: moment(e.target.value).format("YYYY-MM-DD")}, () => {this.changeWeeks()})
  }
  

  changeWeeks = (num) => {
    const { weekCount, curDay } = this.state;

    if (num) {
      this.setState({weekCount: num})
    }
    let week = [];

    for (let i = 0; i < (num || weekCount) ; i++) {
      const params = {};

      params.start = moment(curDay).weekday(1-(i*7));
      params.end = moment(curDay).weekday(7-(i*7));
      week = [...week, params]
    }
    this.setState({week}, () => {this.getData()})
  }

  resetFilter = () => {
    objectFilterFunc();
    constructionFilterFunc();
    worktypeFilterFunc();

    this.setState({worktypeFilter: '',constructionFilter: '', objectFilter: '', brigadeFilter: '', currentFilter: ''})
  };

  validate = (newValue, fulldate) => {
    if (fulldate && (fulldate > Date.parse(new Date(Date.now())))) {
      return {
        valid: false,
        message: 'данные нельзя заполнять на будущие дни'
      };
    }
    if (isNaN(newValue)) {
      return {
        valid: false,
        message: 'данные не являются числом!'
      };
    }
    if (newValue < 0) {
      return {
        valid: false,
        message: 'данные не могут быть отрицательными!'
      };
    }

    return true;
  }
  
  normalize = (transports, norms, fakt) => {
    if (!norms || !norms.length || !transports || !transports.length) return []
    let tNorms = []
    
    for (let j=0; j < transports.length; j++){
      const find = norms.find(x => x.name === transports[j].type)
      const params = {
        id: transports[j].id,
        type: transports[j].type
      }

      if (find && (j === transports.length-1 ||  transports[j+1].type !== transports[j].type)) {
        params.norm = find.preDefValue * (fakt || 0)
      }
      tNorms = [...tNorms, params]
    }
    
    return tNorms
  }

  factorize = (sorted, data) => Object.values([...sorted, ...data].reduce((t, { id, hours, type }) => { //объединяем со всей техникой с нулевыми часами и суммируем все
    t[id] = { id, type: ((t[id] && t[id].type) || (type && type)), hours: (t[id] ? t[id].hours : 0) + ToFloat(hours)  };

    return t;
  }, {}))

  normaDiff = (plan, fact) => {
    let diff = []

    plan.forEach(e => {
      const params = {
        id: e.id,
        type: e.type
      }

      if (e.norm) {
        const facto = fact.reduce((a, b) => a + ((b.type === e.type) ? ToFloat(b.hours) : 0), 0)

        params.norm = ((1-facto/e.norm)*100).toFixed(2)
      }

      diff = [...diff, params]
    });

    return diff
  }
  
  getData = () => {
    const {match} = this.props;
    const id = match.params.id;

    this.setState({ loaded: 0 })

    if(!id) return null;
    QA(GET_AREA_WORKTYPE_TRANSPORT({id: id})).then((data)=>{
      if (data && data.area){
        this.setState({
          areaData: data.area,
          loaded: 1
        })
      } else this.setState({loaded: 2})
    }
    )
  }

  getHeaders = () => {
    const { areaData, objectFilter, constructionFilter , worktypeFilter, canEdit, week, currentFilter } = this.state
    let objects = {}, constructions = {}, worktype = {}
    let filterData = []

    areaData.workTypes.forEach((e) => {
      filterData = [...filterData, { brigade: e.brigade, object: e.object, construction: e.construction, worktype: `${e.value}, ${e.unit}`}]
    });

    filterData.filter((v) => (objectFilter ? v.object === objectFilter : true) 
      && (constructionFilter ? v.construction === constructionFilter : true) && (worktypeFilter ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter !== "object") objects[e.object] = e.object
        if (currentFilter !== "construction") constructions[e.construction] = e.construction
        if (currentFilter !== "worktype") worktype[e.worktype] = e.worktype
      });

    //для текущего выбранного фильтра
    filterData.filter((v) => ((objectFilter && currentFilter !== "object") ? v.object === objectFilter : true) &&
    ((constructionFilter && currentFilter !== "construction") ? v.construction === constructionFilter : true) && ((worktypeFilter && currentFilter !== "worktype") ? v.worktype === worktypeFilter : true))
      .forEach(e => {
        if (currentFilter === "object") objects[e.object] = e.object
        if (currentFilter === "construction") constructions[e.construction] = e.construction
        if (currentFilter === "worktype") worktype[e.worktype] = e.worktype
      });

    objects = sortObjects(objects)
    constructions = sortObjects(constructions)
    worktype = sortObjects(worktype)

    const selectStyle =  { backgroundColor: 'lightblue', height: '30px', fontSize: '12px', padding: 0, margin: 0, align: "center" }
    let headers = [
      { dataField: 'id', text: "id", editable: false , hidden: true},
      { formatter: HoverCell, dataField: 'object', text: "Объект", editable: false, sort: true, headerStyle: { width: '90px' },  filter: selectFilter({options: objects, style:selectStyle, placeholder: 'все', 
        getFilter: (filter) => {objectFilterFunc = filter}, onFilter: filterVal => this.setState({objectFilter: filterVal, currentFilter: "object"})})},
      { formatter: HoverCell, dataField: 'construction', text: 'Сооружение', editable: false, sort: true, headerStyle: { width: '90px' }, 
        filter: selectFilter({options: constructions, style:selectStyle, placeholder: 'все',
          getFilter: (filter) => {constructionFilterFunc = filter}, onFilter: filterVal => this.setState({constructionFilter: filterVal, currentFilter: "construction"})})},
      { formatter: HoverCell, dataField: 'worktype', text: 'Тип работ', editable: false, sort: true, headerStyle: { width: '90px' },  filter: selectFilter({options: worktype, style:selectStyle, placeholder: 'все',
        getFilter: (filter) => {worktypeFilterFunc = filter}, onFilter: filterVal => this.setState({worktypeFilter: filterVal, currentFilter: "worktype"})})},
      { dataField: 'transports', text: 'Техника', editable: false, sort: false, headerStyle: { width: '160px' },
        formatter: transportOnly,
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, null, {icon:'iconT',text:"Техника",  footer: "Техника"}),
      }
    ]

    let weekHeaders = []

    for (let j=(week.length-1); j>=0 ; j--){
      const weekHead = [
        { 
          dataField: 'volPlan' + j, 
          text: "объем с " + week[j].start.format("DD/MM") + " по " + week[j].end.format("DD/MM") + " (план)", 
          editable: () => canEdit,
          dateU: week[j].start.format("YYYY-MM-DD"), 
          cellType: "valuePlanTr", 
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconV',text: "объем работ", footer: "план"}),
        }, 
        { 
          dataField: 'volFact' + j, 
          text: "объем с " + week[j].start.format("DD/MM") + " по " + week[j].end.format("DD/MM") + " (факт)", 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconV',text: "объем работ", footer: "факт"}),
        }, 
        { 
          dataField: 'volDiff' + j, 
          text: "Разница, %", 
          editable: false,
          style: (cell) => diffStyle(cell),
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
        }, 
        { 
          dataField: 'workPlan' + j, 
          text: "маш-часы с " + week[j].start.format("DD/MM") + " по " + week[j].end.format("DD/MM") + " (по норме)", 
          editable: false,
          formatter: transportNorms,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconT',text: "маш-часы", footer: "по норме"}),
        }, 
        { 
          dataField: 'workFact' + j, 
          text: "маш-часы с " + week[j].start.format("DD/MM") + " по " + week[j].end.format("DD/MM") + " (факт)", 
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          formatter: transportHoursOnly,
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconT',text: "маш-часы", footer: "факт"}),
        }, 
        { 
          dataField: 'workDiff' + j, 
          text: "Разница, %", 
          formatter: transportNorms,
          editable: false,
          headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' }, 
          headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, week[j].start.format("DD/MM") + " - " + week[j].end.format("DD/MM"), {icon:'iconP',text: "разница, %", footer: "%"}),
        }
      ]

      weekHeaders = [...weekHeaders, ...weekHead]
    }

    const endHeaders = [
      { dataField: 'volPlanAll', text: 'объем итого (план)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconV',text: "объем работ (план)", footer: "план"})},
      { dataField: 'volFactAll', text: 'объем итого (факт)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconV',text: "объем работ (факт)", footer: "факт"})},
      { 
        dataField: 'volAllDiff', 
        text: 'Разница, %',
        // sort: true, 
        style: (cell) => diffStyle(cell),
        editable: false, 
        // sortFunc: (a, b, order) => sortFunc(a, b, order),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconP',text: "разница, %", footer: "%"}),
      },

      { dataField: 'workPlanAll', text: 'маш-часы (по норме)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        formatter: transportNorms,
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconT',text: "маш-часы по норме", footer: "по норме"}) },
      { dataField: 'workFactAll', text: 'маш-часы (факт)', editable: false, headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px'},
        formatter: transportHoursOnly,
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconT',text: "маш-часы (факт)", footer: "факт"})},
      { 
        dataField: 'workAllDiff', 
        text: 'Разница, !%', 
        editable: false, 
        formatter: transportNorms,
        sort: true, 
        sortFunc: (a, b, order) => sortFunc(a, b, order),
        headerStyle: { fontSize: '13px', padding: '2px', /* verticalAlign: 'top',*/  width: '55px' },
        headerFormatter: (column, colIndex) => headerFormatter(column, colIndex, "ИТОГО", {icon:'iconP',text: "разница, %", footer: "%"}),
      }
    ]

    headers = [...headers, ...weekHeaders, ...endHeaders]

    return headers
  }

  workTypes = (data) => {
    const { week, emptyStrings } = this.state

    if (!data) return null;
    let DATA = data.workTypes || [];
    let colums = []
    const transTypes = data.transports || []
    
    DATA.map(e => {
      const days = emptyStrings ? e.days.filter(b => (moment(b.day) >= week[week.length-1].start && moment(b.day) <= week[0].end)) : e.days
      
      if (emptyStrings && days.length <1) return
      
      if (e.days && e.days.length) {
        let curDates = [];
        let transports = [];
        let transportsNull = [];
        let volPlanAll = 0;

        for (let j=0; j < week.length; j++){
          curDates.push ({
            vol: 0,
            volPlan: 0,
            volDiff: 0,
            transports: []
          })
        }

        const volFactAll = e.days.reduce(function(a, b) {
          for (let j=0; j < week.length; j++){
            if (moment(b.day) >=week[j].start && moment(b.day) <=week[j].end) {
              curDates[j].vol = (curDates[j].vol ? curDates[j].vol : 0) + (b.value ? ToFloat(b.value) : 0)
              curDates[j].volPlan = (curDates[j].volPlan ? curDates[j].volPlan : 0) + (b.valuePlanTr ? ToFloat(b.valuePlanTr) : 0)
              if (b.transports && b.transports.length) curDates[j].transports = [...curDates[j].transports, ...b.transports]
            }
          }
          volPlanAll = volPlanAll + (b.valuePlanTr ? ToFloat(b.valuePlanTr) : 0)
          if (b.transports && b.transports.length) transports = [...transports, ...b.transports] //Объединяем массивы

          return a + (b.value ? ToFloat(b.value) : 0);
        }, 0)

        const volAllDiff = volPlanAll !== 0 ? diff(ToFloat(volPlanAll), ToFloat(volFactAll)) : 0

        transports = Object.values(transports.reduce((t, { id, hours }) => { //суммируем одинаковые транспорта
          t[id] = { id, hours: (t[id] ? t[id].hours : 0) + ToFloat(hours)  };

          return t;
        }, {}))

        transports.forEach(e => {  //обнуляем часы для добавления ниже в массивы (вкл по неделям)
          const find = transTypes.find(x => x.id === e.id)
          const params = {
            id: e.id,
            type: find ? find.type : "неизвестно",
            name: find ? ((find.type ? find.type : "") + " (" + (find.firm ? find.firm : "Наш") + ") " + (find.name ? find.name + " " : " ") + (find.number ? find.number : "")) : "неизвестно",
            norm: 0,
            hours: 0,
          }

          transportsNull = [...transportsNull, params]
        })

        transportsNull.sort(function(a, b) { // сортируем, чтобы по этому шаблону были все данные в строке !!   
          if (a.type === b.type) {
            return b.id > a.id ? 1 : -1;
          }
    
          return a.type > b.type ? 1 : -1;
        })

        const workFactAll = this.factorize(transportsNull, transports)
        const workPlanAll = this.normalize(transportsNull, e.transports, volFactAll) 
        const workAllDiff = this.normaDiff(workPlanAll, workFactAll)

        e.cols = { 
          id: e.id, brigade: e.brigade, construction: e.construction, object: e.object, worktype: `${e.value}, ${e.unit}`,
          volFactAll: volFactAll.toFixed(2), volPlanAll, volAllDiff, transports: transportsNull, workFactAll, workPlanAll, workAllDiff
        }
      
        for (let j=0; j < week.length; j++){
          const workFact =  this.factorize(transportsNull, curDates[j].transports)
          const workPlan =  this.normalize(transportsNull, e.transports, curDates[j].vol) 
          const workDiff =  this.normaDiff(workPlan, workFact)

          e.cols["volFact" + j] = curDates[j].vol.toFixed(2)
          e.cols["volPlan" + j] = curDates[j].volPlan.toFixed(2)
          e.cols["volDiff" + j] = curDates[j].volPlan !==0 ? diff(ToFloat(curDates[j].volPlan), ToFloat(curDates[j].vol)) : ""
          e.cols["workFact" + j] = workFact
          e.cols["workPlan" + j] = workPlan
          e.cols["workDiff" + j] = workDiff
        }

        colums.push(e.cols)

        return e;
      }});

    return colums;
  }

  render() {
    const { areaData, loaded, weekCount, curDay, emptyStrings } = this.state;
    
    if (loaded === 1) {
      const colums = this.workTypes(areaData);
      const headers = this.getHeaders();

      return (
        <div className={'Area'}>
          <div className="" style={{maxWidth:"650px",margin: '5px'}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="btnGroupAddon"><b>{areaData.name}</b></div>
                <div className="input-group-text" id="btnGroupAddon">Неделя:</div>
              </div>
              <input type="date"
                className="form-control"
                aria-label="Input group example" aria-describedby="btnGroupAddon"
                name="trip-start"
                min="2018-01-01"
                max={(new Date().toISOString()).slice(0,10)}
                value={curDay}
                onChange={e=>this.changeDay(e)}
              />
              <button className={`btn btn-sm ${weekCount === 1 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeeks(1)}>1</button>
              <button className={`btn btn-sm ${weekCount === 2 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeeks(2)}>2</button>
              <button className={`btn btn-sm ${weekCount === 3 && "btn-success" || "btn-secondary" } `} onClick={()=>this.changeWeeks(3)}>3</button>
              <button className={`btn btn-sm btn-secondary`} onClick={()=>this.setState({emptyStrings: !emptyStrings})}>{emptyStrings && `Показать` || `Скрыть`} пустые</button>
            </div>
          </div>
          <Table headers={headers} cols={colums} update={this.getData} updateState={this.updateState} resetFilter={this.resetFilter} tabletype="AllTable n34" simple={true} />
        </div>
      )}

    if (loaded === 0 ) return <Loader />
    if (loaded === 2 ) return <div>НЕТ ДАННЫХ!</div>
  }
}
