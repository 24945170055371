import React, { Component } from 'react'

import '../../CSS/PARTS/lists.css'
import { QA } from '../../CONSTANTS';
import { ADD_UNIT_QA, EDIT_UNIT_QA, DELETE_UNIT_QA } from '../../GQL/SERVER/MUTATOR/ServerMutators';
import { UNITS_QA } from '../../GQL/SERVER/QUERIES/ServerQuerys';
import Editorable from './Editorable';

export default class UnitMutator extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      loadedHB: [],
      worktypes: [],
      unit: null,
      units: [],
      brigades: [],
      areas: [],
      objects: [],
      constructions: [],
      editValue: '',
    }
  }
  
  componentDidMount(){
    this.GetData();
  }

  GetData = async () => {
    QA(UNITS_QA).then((data => {
      data && this.setState({
        units: data.units,
      })
    }));
  }
 
  render() {
    const { units } = this.state;
    const ELEMENTS = [
      {
        name: 'Название',
        value: null,
        type: 'input',
        add: true,
        edit: true,
        importantAdd: true,
        importantEdit: true,
        prop: 'value',
        propName: null,
        array: [],
      },
    ];
    
    return (
      <Editorable
        ADD={ADD_UNIT_QA}
        EDIT={EDIT_UNIT_QA}
        DEL={DELETE_UNIT_QA}
        elements={ELEMENTS}
        name={'Единицы измерения'}
        arrayName={'Единицы измерения'}
        array={units}
        refetch={this.GetData}
        lister
        searchKeys={['type','firm','area','name','number']}
      />
    )
  }
}
